import React from 'react';
import './style.css'
import ReactTooltip from "react-tooltip";
import ItemListaFiltroGlobalLinha from './ItemFiltroAtendimentoLinha';
import DommusTabelaPaginadaBack from '../../../DommusTabelaPaginadaBack';

export default function ListaAtendimentos({atendimentos, limit, setLimit, page, total, setPage}) {
    const linhas =
        atendimentos.map(atendimento => (
            <tr key={atendimento.id_oportunidade_atendimento}>
                <ItemListaFiltroGlobalLinha atendimento={atendimento} />
            </tr>
        ));
    const thead = (
        <tr>
            <th style={{display: 'flex'}}> 
                <p style={{marginTop: '16px'}}>Tabela de Atendimentos</p>
            </th>
        </tr>
    );

    return (
        <>
            <DommusTabelaPaginadaBack 
                setLimit={setLimit}
                limit={limit}
                page={page}
                total={total}
                linhasCabecalho={thead}
                setPage={setPage}
                linhasCorpo={linhas}
                optionsTabela={{}}
            />
            <ReactTooltip />
        </>
    );
}
