import crm from './crm';

const CSVService = {

	importar: (headers, data) => {
        return crm.post('/csv-lead/importar', {headers, data});
    },

    confirmar: (leads, options={}) => {
        return crm.post('/csv-lead/confirmar', {leads, options});
    },
};

export default CSVService;
