import React from 'react';
import 'moment/min/locales'
import './style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Cards } from './Cards';

interface ResumoProps {
    openAtalho: boolean
    closeAtalho: () => void
}

export default function Resumo({ openAtalho, closeAtalho }: ResumoProps) {
    return (
        <Cards closeAtalho={closeAtalho} openAtalho={openAtalho} />
    )
}

