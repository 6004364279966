import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import 'moment/min/locales'
import { cardsService } from './cardsService';
import { OportunidadeContext } from '../../../GlobalContext/globalContext';
import guService from '../../../../services/GuService';


interface CardsProps {
    openAtalho: boolean
    closeAtalho: () => void
}

export function Cards({ openAtalho, closeAtalho }: CardsProps) {
    const [dadosOportunidades, setDadosOportunidades] = useState(0);
    const [dadosOportunidadesDia, setDadosOportunidadesDia] = useState(0);
    const [dadosConversoes, setDadosConversoes] = useState(0);
    const [dadosConversoesDia, setDadosConversoesDia] = useState(0);
    const [dadosDesistencias, setDadosDesistencias] = useState(0);
    const [dadosDesistenciasDia, setDadosDesistenciasDia] = useState(0);
    const [dadosTarefas, setDadosTarefas] = useState(0);
    const [dadosTarefaAtrasadas, setDadosTarefasAtrasada] = useState(0);
    const [dadosRepresados, setDadosRepresados] = useState(0);
    const [totalTasksToPerform, setTotalTasksToPerform] = useState(0)
    const [totalVisits, setTotalVisits] = useState(0)
    const [totalVisitsToPerform, setTotalVisitsToPerform] = useState(0)
    const [totalLateVisits, setTotalLateVisits] = useState(0)
    const [totalAttendanceSlaTime, setTotalAttendanceSlaTime] = useState("00:00:00")
    const [totalPhones, setTotalPhones] = useState(0)
    const [totalDayPhones, setTotalDayPhones] = useState(0)
    const [totalEmails, setTotalEmails] = useState(0)
    const [totalDayEmails, setTotalDayEmails] = useState(0)
    const [totalMessages, setTotalMessages] = useState(0)
    const [totalDayMessages, setTotalDayMessages] = useState(0)
    const [totalProposals, setTotalProposals] = useState(0)
    const [totalDayProposals, setTotalDayProposals] = useState(0)
    const [totalComments, setTotalComments] = useState(0)
    const [totalDayComments, setTotalDayComments] = useState(0)
    const globalOportunidadeContext = useContext(OportunidadeContext)
    useEffect(() => {
        if (openAtalho) {
            cardsService.handleSetDadosOportunidades(setDadosOportunidades)
            cardsService.handleSetDadosOportunidadesDia(setDadosOportunidadesDia)
            cardsService.handleSetDadosConversoes(setDadosConversoes)
            cardsService.handleSetDadosConversoesDia(setDadosConversoesDia)
            cardsService.handleSetDadosDesistencias(setDadosDesistencias)
            cardsService.handleSetDadosDesistenciasDia(setDadosDesistenciasDia)
            cardsService.handleSetDadosTarefas(setDadosTarefas)
            cardsService.handleSetDadosTarefasAtrasada(setDadosTarefasAtrasada)
            cardsService.handleSetDadosRepresados(setDadosRepresados)
            cardsService.handleSetTasksToPerform(setTotalTasksToPerform)
            cardsService.handleSetTotalVisits(setTotalVisits)
            cardsService.handleSetTotalVisitsToPerform(setTotalVisitsToPerform)
            cardsService.handleSetTotalLateVisits(setTotalLateVisits)
            cardsService.handleSetTotalAttendanceSlaTime(setTotalAttendanceSlaTime)
            cardsService.handleSetTotalPhones(setTotalPhones)
            cardsService.handleSetTotalDayPhones(setTotalDayPhones)
            cardsService.handleSetTotalEmails(setTotalEmails)
            cardsService.handleSetTotalDayEmails(setTotalDayEmails)
            cardsService.handleSetTotalMessages(setTotalMessages)
            cardsService.handleSetTotalDayMessages(setTotalDayMessages)
            cardsService.handleSetTotalProposals(setTotalProposals)
            cardsService.handleSetTotalDayProposals(setTotalDayProposals)
            cardsService.handleSetTotalComments(setTotalComments)
            cardsService.handleSetTotalDayComments(setTotalDayComments)
        }
    }, [openAtalho]);
    function redirectToHome() {
        if(window.location.href.split('/')[3]) {
            window.location.href = '/'
            return
        }
        closeAtalho()
    }
return (
    <>
        <div className={"sidenav-overlay " + (openAtalho && "open")} onClick={closeAtalho}>
            &nbsp;
        </div>
        <section className={"atalhos " + (openAtalho && "open")} style={{ zIndex: 25, width: '25%' }}>
            <header>
                <div>Resumo de Informações</div>
                <div className={"close-pane"} onClick={closeAtalho}><FontAwesomeIcon icon={faArrowRight} /></div>
            </header>
            <div>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="semAtendimento">
                            <Card.Body onClick={() => {
                                guService.setLocalState('redirect', 'allOpvs')
                                globalOportunidadeContext.redirectToHome('allOpvs')
                                redirectToHome()
                            }}>
                                <Card.Text>Total de Oportunidades</Card.Text>
                                <Card.Title>
                                    {dadosOportunidades}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="slaAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Hoje</Card.Text>
                                <Card.Title>
                                    {dadosOportunidadesDia}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="totalLeads">
                            <Card.Body onClick={(e: any) => window.location.href = '/leads/represados'}>
                                <Card.Text>Represados</Card.Text>
                                <Card.Title>
                                    {dadosRepresados}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="leadsHoje">
                            <Card.Body onClick={() => {
                                guService.setLocalState('redirect', '')
                                redirectToHome()
                            }}>
                                <Card.Text>SLA de Atendimento</Card.Text>
                                <Card.Title>
                                    {totalAttendanceSlaTime}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="semAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsFromTransfers')
                                    globalOportunidadeContext.redirectToHome('opvsFromTransfers')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Transferências</Card.Text>
                                <Card.Title>
                                    {dadosConversoes}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="slaAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsFromTransfersOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsFromTransfersOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Transferências do Dia</Card.Text>
                                <Card.Title>
                                    {dadosConversoesDia}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="leadsDescartados">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithDiscardedLeads')
                                    globalOportunidadeContext.redirectToHome('opvsWithDiscardedLeads')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Leads Descartados</Card.Text>
                                <Card.Title>
                                    {dadosDesistencias}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="descartadosDia">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithDiscardedLeadsOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithDiscardedLeadsOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Descartados do Dia</Card.Text>
                                <Card.Title>
                                    {dadosDesistenciasDia}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr className="mt-3 mb-3" />
                <Card className="totalTarefas">
                    <Card.Body 
                        onClick={(e: any) => {
                            guService.setLocalState('redirect', 'opvsWithAttendanceTask')
                            globalOportunidadeContext.redirectToHome('opvsWithAttendanceTask')
                            closeAtalho()
                        }}>
                        <Card.Text>Total de Tarefas</Card.Text>
                        <Card.Title>
                            {dadosTarefas}
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="descartadosDia">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceTaskToDo')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceTaskToDo')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Tarefas a Realizar</Card.Text>
                                <Card.Title>{totalTasksToPerform}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="totalTarefasAtrasadas">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceDelayedTask')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceDelayedTask')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Tarefas Atrasadas</Card.Text>
                                <Card.Title>{dadosTarefaAtrasadas}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr className="mt-3 mb-3" />
                <Card className="totalTarefas">
                    <Card.Body 
                        onClick={(e: any) => {
                            guService.setLocalState('redirect', 'opvsWithAttendanceVisit')
                            globalOportunidadeContext.redirectToHome('opvsWithAttendanceVisit')
                            closeAtalho()
                        }}>
                        <Card.Text>Total de Visitas</Card.Text>
                        <Card.Title>
                            {totalVisits}
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="descartadosDia">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceVisitToDo')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceVisitToDo')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Visitas a Realizar</Card.Text>
                                <Card.Title>{totalVisitsToPerform}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="totalTarefasAtrasadas">
                            <Card.Body  
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceDelayedVisit')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceDelayedVisit')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Visitas Atrasadas</Card.Text>
                                <Card.Title>{totalLateVisits}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr className="mt-3 mb-2" />
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="semAtendimento">
                            <Card.Body  
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendancePhone')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendancePhone')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Telefonemas</Card.Text>
                                <Card.Title>
                                    {totalPhones}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="slaAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendancePhoneOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendancePhoneOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Telefonemas do Dia</Card.Text>
                                <Card.Title>
                                    {totalDayPhones}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="totalLeads">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceEmail')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceEmail')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Emails</Card.Text>
                                <Card.Title>
                                    {totalEmails}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="leadsHoje">
                            <Card.Body
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceEmailOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceEmailOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Emails do Dia</Card.Text>
                                <Card.Title>
                                    {totalDayEmails}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="semAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceWhatsapp')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceWhatsapp')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Mensagens (Whatsapp)</Card.Text>
                                <Card.Title>
                                    {totalMessages}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="slaAtendimento">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceWhatsappOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceWhatsappOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Mensagens (Whatsapp) do Dia</Card.Text>
                                <Card.Title>
                                    {totalDayMessages}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="totalLeads">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceProposal')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceProposal')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Propostas</Card.Text>
                                <Card.Title>
                                    {totalProposals}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="leadsHoje">
                            <Card.Body 
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceProposalOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceProposalOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Propostas do Dia</Card.Text>
                                <Card.Title>
                                    {totalDayProposals}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="linha">
                    <Col className="esquerda">
                        <Card className="semAtendimento">
                            <Card.Body  
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceComment')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceComment')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Total de Comentários</Card.Text>
                                <Card.Title>
                                    {totalComments}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="direita">
                        <Card className="slaAtendimento">
                            <Card.Body  
                                onClick={(e: any) => {
                                    guService.setLocalState('redirect', 'opvsWithAttendanceCommentOfTheDay')
                                    globalOportunidadeContext.redirectToHome('opvsWithAttendanceCommentOfTheDay')
                                    closeAtalho()
                                }}
                            >
                                <Card.Text>Comentários do Dia</Card.Text>
                                <Card.Title>
                                    {totalDayComments}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    </>
)}