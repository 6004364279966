import crm from './crm';

const CamposDinamicosService = {
    buscarCamposDinamicos: (page, limit) => {
        let uri = `/campos-dinamicos?limit=${limit}&page=${page}`
        
        return crm.get(uri);
    },
    buscarTipoCamposDinamicos: () => {
        let uri = '/tipo-campos-dinamicos'
        
        return crm.get(uri);
    },
    salvarNovoCampo: (payload) => {
        let uri = 'campos-dinamicos'
        
        return crm.post(uri, payload);
    },
    atualizarCampo: (payload, id) => {
        let uri = `campos-dinamicos/${id}`
        
        return crm.put(uri, payload);
    },
    excluirCampo: (id) => {
        let uri = `campos-dinamicos/${id}`
        
        return crm.delete(uri);
    }
};

export default CamposDinamicosService;
