import crm from "./crm";
import guService from "./GuService";

const perfilService = {
  buscarVistoUltimo: () => {
    return crm.get("/perfil-usuario/visto-ultimo");
  },
  tempoOnline: (id, filtro) => {
    return crm.get(`/visto-ultimo`, {params: {
      filtro: filtro,
      usuarios: id
    }})
  },
  buscarDadosPerfil: () => {
    return crm.get(`/perfil-usuario/logado`);
  },
  buscarInfoUsuario: (id) => {
    return crm.get(`/usuario/${id}`);
  },
  buscarDadosPublicosUsuario: (id) =>{
    return crm.get(`/perfil-usuario/publico/${id}`)
  },
  alteraVisualizacao: () => {
    return crm.put("/perfil-usuario/alterar-visualizacao", {
      exibicao: guService.getLocalState("exibicaoLista"),
    });
  },
  alteraConfiguracoes: (dados) => {
    return crm.put("/perfil-usuario/alterar-configuracao", dados);
  },
  alterarOrdenacaoPadrao: (dados) => {
    return crm.put(`/perfil-usuario/alterar-ordenacao-padrao`, dados);
  },
  alterarInfoUsuario: (id, dados) => {
    return crm.put(`/usuario/${id}`, dados);
  },
  index: () => {
    return crm.get('/perfil')
  },
  buscarCamposCadastrosTravados: (idPerfilUsuario) => {
    return crm.get(`/perfil-usuario/${idPerfilUsuario}/campos-travados`)
  },
  salvarCamposCadastrosTravados: (idPerfilUsuario, dados) => {
    return crm.post(`/perfil-usuario/${idPerfilUsuario}/campos-travados`, {dados})
  },
  updatePerfilUsuario: (idUsuario, campos) => {
    return crm.put(`/perfil-usuario/${idUsuario}`, {perfilusuario: campos})
  }

};

export default perfilService;
