import React from 'react';
import Tabs from "react-bootstrap/cjs/Tabs";
import Tab from "react-bootstrap/cjs/Tab";
import FormOferta from "./FormOferta";
import Permissions from "./Permissoes";
import BaseDeLeads from "./BaseDeLeads";

export default function ModalOferta({handleAddListaOferta, handleAtualizarListaOferta, closeModal, oferta, ofertaAlteracao, tabKey, setTabKey}) {
    return (
        <div>

            <Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key)}>
                <Tab eventKey="cadastro" title="Cadastro">
                    <FormOferta
                        handleAddListaOferta={handleAddListaOferta}
                        handleAtualizarListaOferta={handleAtualizarListaOferta}
                        closeModal={closeModal}
                        oferta={oferta}
                        setAbaAtiva={setTabKey}
                        ofertaAlteracao={ofertaAlteracao}
                    />
                </Tab>

                <Tab eventKey="base-de-leads" title="Base de Leads" disabled={!oferta}>
                    <BaseDeLeads oferta={oferta} ofertaAlteracao={ofertaAlteracao}/>
                </Tab>

                <Tab eventKey="permissoes" title="Permissões" disabled={!oferta}>
                    <Permissions oferta={oferta} ofertaAlteracao={ofertaAlteracao} closeModal={closeModal}/>
                </Tab>

            </Tabs>


        </div>
    );
}
