import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Row, Container, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import empreendimentoService from "../../../services/EmpreendimentoService";
import OportunidadeService from "../../../services/OportunidadeService";
import StatusOportunidadeService from "../../../services/StatusOportunidadeService";
import equipeService from "../../../services/EquipeService";
import { EmpreendimentoContext, OportunidadeContext } from "../../../components/GlobalContext/globalContext";
import { EquipeType } from "../../OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types";
import { StatusOportunidadeType } from "../../Configuracoes/FunilDeVendas/Funil/types";
import { EmpreendimentoType } from "../../../pages/DetalheOportunidade/Cadastro/DistributionForm";
import { GerenteType, OperadorType } from "../../EquipesPrePdvSelectIdComponent/types";
import { OportunidadePorStatusPaginadoType } from "../Visualizacao/FunilOportunidades/types";
import { ModalVisualizarAlteracaoMassa } from "./ModalVisualizarAlteracaoMassa";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService";
import { UsuarioContext } from "../../../contexts/UsuarioContext";
import perfilUsuarioService from "../../../services/PerfilUsuarioService";
import { isMobile } from "react-device-detect";

interface AlteracaoMassaProps {
  opvsSelecionadas: Array<OportunidadePorStatusPaginadoType>
  closeModal: () => void
  operadores: Array<OperadorType>
  gerentes: Array<GerenteType>
  todasOpvsSelecionadas?: boolean
  totalOpvsSelecionadas?: number
  filtrosOportunidade?: Array<any>|object
}

export interface IRetornoOpvs {
  opvs: Array<OportunidadePorStatusPaginadoType> | [],
  opvsNaoEnviadas: Array<OportunidadePorStatusPaginadoType> | [],
  dadosAlterados: DadosOportunidadeProps,
  etapaOpvAlterada: StatusOportunidadeType,
}

export interface DadosOportunidadeProps {
  id_empreendimento: string | null
  id_status_oportunidade: string | null
  id_equipe_pdv: string | null
  id_gerente_pdv: string | null
  id_usuario_atendimento: string | null
  id_equipe_pre_atendimento: string | null
  id_gerente_pre_atendimento: string | null
  id_usuario_pre_atendimento: string | null
  ativo: string | null
}

interface DadosAltMassaProps {
  oportunidades: Array<OportunidadePorStatusPaginadoType>
  dados_oportunidade: DadosOportunidadeProps
}

export default function AlteracaoMassa({
  opvsSelecionadas,
  closeModal,
  operadores,
  gerentes,
  todasOpvsSelecionadas=false,
  totalOpvsSelecionadas=0,
  filtrosOportunidade
}: AlteracaoMassaProps) {
  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] = useState(null as string | null);
  const [equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada] = useState(null as string | null);
  const [gerentePreAtendimentoSelecionado, setGerentePreAtendimentoSelecionado] = useState(null as string | null);
  const [usuarioPreAtendimentoSelecionado, setUsuarioPreAtendimentoSelecionado] = useState(null as string | null);
  const [equipePdvSelecionada, setEquipePdvSelecionada] = useState(null as string | null);
  const [gerentePdvSelecionado, setGerentePdvSelecionado] = useState(null as string | null);
  const [usuarioPdvSelecionado, setUsuarioPdvSelecionado] = useState(null as string | null);
  const [equipesPreAtendimento, setEquipesPreAtendimento] = useState([] as Array<EquipeType>);
  const [gestoresPreAtendimento, setGestoresPreAtendimento] = useState([] as Array<GerenteType>);
  const [usuariosPreAtendimento, setUsuariosPreAtendimento] = useState([] as Array<OperadorType>);
  const [equipesPdv, setEquipesPdv] = useState([] as Array<EquipeType>);
  const [gerentesPdv, setGerentesPdv] = useState([] as Array<GerenteType>);
  const [usuariosPdv, setUsuariosPdv] = useState([] as Array<OperadorType>);
  const [empreendimentos, setempreendimentos] = useState([] as Array<EmpreendimentoType>);
  const [status_oportunidade, setstatus_oportunidade] = useState([] as Array<StatusOportunidadeType>);
  const [statusOpvSelecionado, setstatusOpvSelecionado] = useState(null as string | null);
  const [retornoOpvs, setRetornoOpvs] = useState({opvs: [], opvsNaoEnviadas: []} as IRetornoOpvs);
  const [configuracoesAlteracaoEmMassa, setConfiguracoesAlteracaoEmMassa] = useState([] as Array<number>);
  const [ativo, setativo] = useState(null as string | null);
  const [openModalVisualizarAlteracao, setOpenModalVisualizarAlteracao] = useState(false);
  const [visualizarManterEmpreendimentoLead, setVisualizarManterEmpreendimentoLead] = useState(false);
  const [manterEmpreendimentoSelecionado, setManterEmpreendimentoSelecionado] = useState(false);
  const [podeEnviarSemEmpreendimento, setPodeEnviarSemEmpreendimento] = useState(false);
  const global = useContext(EmpreendimentoContext);
  const globalOportunidadeContext = useContext(OportunidadeContext);
  const {validarPermissaoPorTelaAreaAcao} = useContext(UsuarioContext);  

  useEffect(() => {
    buscaEmpreendimentos();
    buscaEtapasOportunidade();
    buscarConfiguracoesAlteracaoEmMassa();
    buscarConfigEmpreendimentoLead();
    setPodeEnviarSemEmpreendimento(validarPermissaoPorTelaAreaAcao('oportunidades', 'acoes_em_massa', 'escolher_qualquer_equipe'));
  }, []);

  useEffect(() => {
    if (empreendimentoSelecionado != null) {
      buscaEquipesPorEmpreendimento();
    }else{
      buscaEquipes()
      buscaGerentes()
      buscarOperadores()
    }
  }, [empreendimentoSelecionado]);

  useEffect(()=>{
    if(manterEmpreendimentoSelecionado){
      setEmpreendimentoSelecionado(null);
      adicionarTodasEquipes();
    }else{
      if(empreendimentoSelecionado){
        buscaEquipesPorEmpreendimento();
      }
    }
    setPodeEnviarSemEmpreendimento(manterEmpreendimentoSelecionado);
  },[manterEmpreendimentoSelecionado])

  function buscarConfigEmpreendimentoLead(){
    trackPromise(perfilUsuarioService.buscarConfig('visualizar_manter_empreendimento_do_lead')).then((response)=>{
        setVisualizarManterEmpreendimentoLead(response?.data?.data ?? false);
    }).catch((erro)=>{
        setVisualizarManterEmpreendimentoLead(false);
    });        
  }

  function handleManterEmpreendimentoSelecionado(value:boolean){
    setEquipePreAtendimentoSelecionada("");
    setEquipePdvSelecionada("");
    setGerentePreAtendimentoSelecionado("");
    setUsuarioPreAtendimentoSelecionado("");
    setGerentePdvSelecionado("");
    setUsuarioPdvSelecionado("");

    setManterEmpreendimentoSelecionado(value);
  }

  function adicionarTodasEquipes(){
    const equipesPdvList:any = [];
    const equipesPreAtendimentoList:any = [];
    global.equipes.forEach((equipe)=>{
        if (equipe.tipo_equipe == 'P') {
            equipesPdvList.push(equipe)
        } else {
            equipesPreAtendimentoList.push(equipe)
        }
    }) 
    setEquipesPreAtendimento(equipesPreAtendimentoList);
    setEquipesPdv(equipesPdvList);
  }

  function checagemEmpreendimentoEmOportunidade(listaOportunidades = [] as Array<OportunidadePorStatusPaginadoType>) {
    var opvsEmpreendimentosDiferente:Array<OportunidadePorStatusPaginadoType> = [];
    if(empreendimentoSelecionado){
      opvsEmpreendimentosDiferente = listaOportunidades.filter(item =>
          item.id_empreendimento !== Number(empreendimentoSelecionado)
      )
    }

    return opvsEmpreendimentosDiferente;
  }

  function handleSelecionarEmpreendimento(value:any){
      setEmpreendimentoSelecionado(null);
      setEquipePdvSelecionada(null);
      setEquipePreAtendimentoSelecionada(null);
      setGerentePdvSelecionado(null);
      setGerentePreAtendimentoSelecionado(null);
      setUsuarioPdvSelecionado(null);
      setUsuarioPreAtendimentoSelecionado(null);
      if (value) {
        setEmpreendimentoSelecionado(value);
      }
  }

  function buscarConfiguracoesAlteracaoEmMassa() {
    trackPromise(
      ConfiguracoesGestaoService.buscarConfiguracao('permissao_alteracao_empreendimento_em_massa')
      .then((response)=>{
        setConfiguracoesAlteracaoEmMassa(response.data as Array<number>);
      })
    )
  }

  function buscaEmpreendimentos() {
    trackPromise(
      empreendimentoService.buscarEmpreendimentos().then((response) => {
        if (response.data !== "") {
          setempreendimentos(response.data);
        }
      })
    );
  }

  function buscaEtapasOportunidade() {
    trackPromise(
      StatusOportunidadeService.listaEtapas().then((response) => {
        if (response.data !== "") {
          setstatus_oportunidade(response.data);
        }
      })
    );
  }

  function buscaEquipes() {
    trackPromise(
      equipeService.buscarEquipes().then((response) => {
        if (response.data !== "") {
          let equipesPdvList = [] as Array<EquipeType>;
          let equipesPreAtendimentoList = [] as Array<EquipeType>;
          Object.values(response.data).forEach((equipe: any) => {
            if (equipe.tipo_equipe == "P") {
              equipesPdvList.push(equipe);
            } else {
              equipesPreAtendimentoList.push(equipe);
            }
          });
          setEquipesPreAtendimento(equipesPreAtendimentoList);
          setEquipesPdv(equipesPdvList);
        }
      })
    );
  }

  function buscaGerentes() {
    let gerentesUsers = [] as Array<GerenteType>;
    let gestores = [] as Array<GerenteType>;
    if (gerentes != undefined && gerentes.length > 0) {
      gerentes.forEach((gerente) => {
        if (gerente.id_tipo_usuario == "7") {
          gerentesUsers.push(gerente);
        } else if (gerente.id_tipo_usuario == "25") {
          gestores.push(gerente);
        }
      });
    }
    setGestoresPreAtendimento(gestores);
    setGerentesPdv(gerentesUsers);
  }

  function buscarOperadores() {
    let corretores = [] as Array<OperadorType>;
    let atendentes = [] as Array<OperadorType>;
    if (operadores != undefined && operadores.length > 0) {
      operadores.forEach((atendente) => {
        if (atendente.id_tipo_usuario == "8") {
          corretores.push(atendente);
        } else if (atendente.id_tipo_usuario == "26") {
          atendentes.push(atendente);
        }
      });
    }
    setUsuariosPdv(corretores);
    setUsuariosPreAtendimento(atendentes);
  }

  function buscaEquipesPorEmpreendimento() {
    trackPromise(
      equipeService
        .buscaMembrosEquipes(empreendimentoSelecionado)
        .then((response) => {
          if (response.data !== "") {
            let equipesPdvList = [] as Array<EquipeType>;
            let equipesPreAtendimentoList = [] as Array<EquipeType>;
            Object.values(response.data).forEach((equipe: any) => {
              if (equipe.tipo_equipe == "P") {
                equipesPdvList.push(equipe);
              } else {
                equipesPreAtendimentoList.push(equipe);
              }
            });
            setEquipesPreAtendimento(equipesPreAtendimentoList);
            setEquipesPdv(equipesPdvList);
            setGerentePdvSelecionado(null)
            setGerentePreAtendimentoSelecionado(null)
            setUsuarioPdvSelecionado(null)
            setUsuarioPreAtendimentoSelecionado(null)
            setGerentesPdv([])
            setGestoresPreAtendimento([])
            setUsuariosPdv([])
            setUsuariosPreAtendimento([])
          }
        })
    );
  }
  function altMassaNv(){

    var dadosaltmassa = {} as DadosAltMassaProps;

    if((!empreendimentoSelecionado)  && statusOpvSelecionado == null && ativo == null && equipePdvSelecionada == null
    && equipePreAtendimentoSelecionada == null && gerentePdvSelecionado == null && gerentePreAtendimentoSelecionado == null
    && usuarioPreAtendimentoSelecionado == null && usuarioPdvSelecionado == null){
      Swal.fire(
        "Selecione pelo menos um campo para que a alteração seja feita",
        "",
        "warning"
      );
      return false
    }

    if((empreendimentoSelecionado) && (!equipePdvSelecionada && !equipePreAtendimentoSelecionada)){
      Swal.fire(
        "Selecione pelo menos uma equipe",
        "",
        "warning"
      );
      return false
    }else if((equipePdvSelecionada) && (!gerentePdvSelecionado || !usuarioPdvSelecionado)){
      Swal.fire(
        "Selecione um gerente e um corretor PDV",
        "",
        "warning"
      );
      return false
    }else if((equipePreAtendimentoSelecionada) && (!gerentePreAtendimentoSelecionado || !usuarioPreAtendimentoSelecionado)){
      Swal.fire(
        "Selecione um gerente e um corretor de Pré Atendimento",
        "",
        "warning"
      );
      return false
    }

    if(global.statusOportunidadetodasOpvs != 0){

      trackPromise(
        OportunidadeService.alteracaoEmMassaBuscaTodos(
          global.statusOportunidadetodasOpvs, globalOportunidadeContext.filtrosConfig
        ).then((response) => {
          let opvs = response.data;

          let todosdadosOpvs = opvs.concat(opvsSelecionadas);

          let dados_oportunidade = {} as DadosOportunidadeProps;

          if(empreendimentoSelecionado)
          dados_oportunidade["id_empreendimento"] = empreendimentoSelecionado

          if(statusOpvSelecionado != null)
            dados_oportunidade["id_status_oportunidade"] = statusOpvSelecionado

          if(equipePdvSelecionada)
            dados_oportunidade["id_equipe_pdv"] = equipePdvSelecionada

          if(gerentePdvSelecionado)
            dados_oportunidade["id_gerente_pdv"] = gerentePdvSelecionado

          if(usuarioPdvSelecionado)
            dados_oportunidade["id_usuario_atendimento"] = usuarioPdvSelecionado

          if(equipePreAtendimentoSelecionada)
            dados_oportunidade["id_equipe_pre_atendimento"] = equipePreAtendimentoSelecionada

          if(gerentePreAtendimentoSelecionado)
            dados_oportunidade["id_gerente_pre_atendimento"] = gerentePreAtendimentoSelecionado

          if(usuarioPreAtendimentoSelecionado)
            dados_oportunidade["id_usuario_pre_atendimento"] = usuarioPreAtendimentoSelecionado

          if(ativo != null)
          dados_oportunidade["ativo"] = ativo

          dadosaltmassa = {
            oportunidades: opvsSelecionadas.length > 0 ? todosdadosOpvs : response.data,
            dados_oportunidade
          };

          let opvsEmpreendimentoDiferentes = checagemEmpreendimentoEmOportunidade(dadosaltmassa.oportunidades);

          submitHandler(dadosaltmassa as DadosAltMassaProps, opvsEmpreendimentoDiferentes);
        })
      ).catch((error) => {
        Swal.fire(
          "Não foi possível buscar todas as OPVs em aberto para alteração",
          "",
          "error"
        );
      });
    }else{
        if (opvsSelecionadas.length > 0) {

          let dados_oportunidade = {} as DadosOportunidadeProps;

          if(empreendimentoSelecionado)
            dados_oportunidade["id_empreendimento"] = empreendimentoSelecionado

          if(statusOpvSelecionado)
            dados_oportunidade["id_status_oportunidade"] = statusOpvSelecionado

          if(equipePdvSelecionada)
            dados_oportunidade["id_equipe_pdv"] = equipePdvSelecionada

          if(gerentePdvSelecionado)
            dados_oportunidade["id_gerente_pdv"] = gerentePdvSelecionado

          if(usuarioPdvSelecionado)
            dados_oportunidade["id_usuario_atendimento"] = usuarioPdvSelecionado

          if(equipePreAtendimentoSelecionada)
            dados_oportunidade["id_equipe_pre_atendimento"] = equipePreAtendimentoSelecionada

          if(gerentePreAtendimentoSelecionado)
            dados_oportunidade["id_gerente_pre_atendimento"] = gerentePreAtendimentoSelecionado

          if(usuarioPreAtendimentoSelecionado)
            dados_oportunidade["id_usuario_pre_atendimento"] = usuarioPreAtendimentoSelecionado

          if(ativo != null)
            dados_oportunidade["ativo"] = ativo

          dadosaltmassa = {
            oportunidades: opvsSelecionadas,
            dados_oportunidade,
          };

          let opvsEmpreendimentoDiferentes = checagemEmpreendimentoEmOportunidade(dadosaltmassa.oportunidades);

          submitHandler(dadosaltmassa as DadosAltMassaProps, opvsEmpreendimentoDiferentes);
        }else {
          Swal.fire(
            "Não foi possível selecionar as oportunidades!",
            "",
            "warning"
          );
        }
      }

  }

  function configuraGerentes(tipo: string, idequipe: string) {
    let gerentesPdvList = [] as Array<GerenteType>;
    let gestoresPreAtendimentoList = [] as Array<GerenteType>;
    if (tipo == "pdv") {
      setEquipePdvSelecionada(idequipe);
      equipesPdv.forEach((equipe) => {
        if (equipe.id_equipe == idequipe) {
          equipe.gerentes.forEach((gerente) => {
            const item = {
              id_gerente: String(gerente.id_usuario),
              nome_gerente: gerente.nome,
              corretores: gerente.corretores,
              equipe_ativo: undefined,
              ativo_gerente: undefined,
              id_tipo_usuario: undefined,
              id_usuario: undefined,
              nome: undefined
            } as GerenteType;
            gerentesPdvList.push(item);
          });
        }
      });
      setGerentesPdv(gerentesPdvList);
    } else if (tipo == "pre") {
      setEquipePreAtendimentoSelecionada(idequipe);
      equipesPreAtendimento.forEach((equipe) => {
        if (equipe.id_equipe == idequipe) {
          equipe.gerentes.forEach((gerente) => {
            const item = {
              id_gerente: String(gerente.id_usuario),
              nome_gerente: gerente.nome,
              corretores: gerente.corretores,
              equipe_ativo: undefined,
              ativo_gerente: undefined,
              id_tipo_usuario: undefined,
              id_usuario: undefined,
              nome: undefined
            } as GerenteType;
            gestoresPreAtendimentoList.push(item);
          });
        }
      });
      setGestoresPreAtendimento(gestoresPreAtendimentoList);
    }
  }
  function configuraOperadores(tipo: string, idgerente: string) {
    let atendentesPreAtendimentoList = [] as Array<OperadorType>;
    let corretoresPdvList = [] as Array<OperadorType>;
    if (tipo == "pdv") {
      setGerentePdvSelecionado(idgerente);
      gerentesPdv.forEach((gerente) => {
        if (gerente.id_gerente == idgerente && gerente.corretores) {
          gerente.corretores.forEach((operador) => {
            const item = {
              id_operador: String(operador.id_usuario),
              nome_operador: operador.nome,
              equipe_ativo: undefined,
              gerente_ativo: undefined,
              corretor_ativo: undefined,
              id_tipo_usuario: undefined,
              nome: undefined,
              ativo: undefined,
              id_usuario: undefined
            } as OperadorType;
            corretoresPdvList.push(item);
          });
        }
      });
      setUsuariosPdv(corretoresPdvList);
    } else if (tipo == "pre") {
      setGerentePreAtendimentoSelecionado(idgerente);
      gestoresPreAtendimento.forEach((gerente) => {
        if (gerente.id_gerente == idgerente && gerente.corretores) {
          gerente.corretores.forEach((operador) => {
            const item = {
              id_operador: String(operador.id_usuario),
              nome_operador: operador.nome,
              equipe_ativo: undefined,
              gerente_ativo: undefined,
              corretor_ativo: undefined,
              id_tipo_usuario: undefined,
              nome: undefined,
              ativo: undefined,
              id_usuario: undefined
            } as OperadorType;
            atendentesPreAtendimentoList.push(item);
          });
        }
      });
      setUsuariosPreAtendimento(atendentesPreAtendimentoList);
    }
  }

  function submitHandler(dadosaltmassa: DadosAltMassaProps, listaOpvsComEmpreendimentoDiferente: Array<OportunidadePorStatusPaginadoType>) {

    var aplicarCheckbox = (empreendimentoSelecionado && listaOpvsComEmpreendimentoDiferente.length);
    let mensagem = "";
    let temPermissao = !configuracoesAlteracaoEmMassa.includes(Number(localStorage.getItem('@DommusCRM-tipoUsuario')));
    let placeholderInput = `<span ${temPermissao ? 'style=color:#999 title="Seu usuário não possui permissão!"' : ''}>Caso deseje
                            <b style='color: ${temPermissao ? '#999': 'red'}'>forçar</b> a alteração dos empreendimentos de todas as OPVs selecionadas, marque aqui!</span>`;
    if(aplicarCheckbox){
      mensagem = `<p><b>Atenção!</b> Essa alteração pode impactar na mudança de empreendimento de algumas oportunidades. </p>
                  <p>Das <b>${(dadosaltmassa.oportunidades?.length || 0)}</b> oportunidades selecionadas, <b>${(listaOpvsComEmpreendimentoDiferente.length)}</b> <b style="color:red">NÃO</b> estão associadas ao empreendimento marcado para alteração em massa!</p>
                  <p>Por padrão, <b style="color:red">NÃO</b> iremos realizar a alteração de empreendimento e equipe dessas oportunidades.<p/>`;
    }else{
      mensagem = `<b>${(todasOpvsSelecionadas ? totalOpvsSelecionadas : dadosaltmassa.oportunidades?.length || 0)}</b> oportunidades serão afetadas. Deseja prosseguir?`;
    }



    Swal.fire({
      title: "Deseja prosseguir?",
      html: mensagem,
      input: aplicarCheckbox ? 'checkbox' : undefined,
      inputValue: 0,
      inputPlaceholder: placeholderInput,
      icon: listaOpvsComEmpreendimentoDiferente.length ? "warning" : "question",
      width: aplicarCheckbox ? '74em' : '40em',
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, prosseguir!",
      cancelButtonText: "Não, fechar!.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
      willOpen: ()=>{
        if(temPermissao){
          Swal.getInput()?.setAttribute('disabled', "");
          Swal.getInput()?.setAttribute('title', "Seu usuário não possui permissão!");
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if(!result.value && empreendimentoSelecionado && listaOpvsComEmpreendimentoDiferente.length){
          dadosaltmassa.oportunidades = dadosaltmassa.oportunidades.filter((oportunidade) => {
            return !listaOpvsComEmpreendimentoDiferente.some(item=> item.id_oportunidade === oportunidade.id_oportunidade);
          })
        }

        if(!dadosaltmassa.oportunidades.length){
          Swal.fire({
            title: "Alteração em massa!",
            text: "Nenhuma oportunidade foi alterada",
          })
        }else{
          trackPromise(
            OportunidadeService.alteracaoEmMassa(
              {
                oportunidades: dadosaltmassa.oportunidades.map(item => item.id_oportunidade),
                dados_oportunidade: dadosaltmassa.dados_oportunidade,
                todasOpvsSelecionadas,
                filtrosOportunidade
              }
            ).then((response:any) => {
                var isOpvsNaoEnviadas = !result.value && empreendimentoSelecionado && listaOpvsComEmpreendimentoDiferente.length;

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Oportunidades alteradas com sucesso",
                  showConfirmButton: false,
                  timer: 2000,
                });
                setRetornoOpvs({
                  opvs: dadosaltmassa.oportunidades,
                  opvsNaoEnviadas:  isOpvsNaoEnviadas ? listaOpvsComEmpreendimentoDiferente : [],
                  dadosAlterados: dadosaltmassa.dados_oportunidade,
                  etapaOpvAlterada: dadosaltmassa.dados_oportunidade.id_status_oportunidade ? status_oportunidade.find((item) => item.id_status_oportunidade == Number(dadosaltmassa.dados_oportunidade.id_status_oportunidade)) : null,
                } as IRetornoOpvs)
                setOpenModalVisualizarAlteracao(true);
              })
              .catch((error) => {
                Swal.fire("Não foi possível alterar dados", "", "error");
                return false;
              })
          );
        }
      }
    });
  }

  return (
    <>
    <Container fluid>
      <Form
        className="formCadastro"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            altMassaNv();
          }
        }}
      >
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Empreendimento:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              onChange={(event) =>{ handleSelecionarEmpreendimento(event.target.value)}
              }
              value={empreendimentoSelecionado || ""}
              disabled={manterEmpreendimentoSelecionado}
            >
              <option value="">
                Selecione
              </option>
              {empreendimentos.map((empreendimento) => (
                <option key={empreendimento.id} value={empreendimento.id}>
                  {empreendimento.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Status Oportunidade:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              onChange={(event) => setstatusOpvSelecionado(event.target.value)}
            >
              <option hidden value="">
                Selecione
              </option>
              {status_oportunidade.map((status) => (
                <option
                  key={status.id_status_oportunidade}
                  value={status.id_status_oportunidade}
                >
                  {status.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Ativo:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              onChange={(event) => setativo(event.target.value)}
            >
              <option hidden value="">
                Selecione
              </option>
              <option value="1">Ativo</option>
              <option value="0">Inativo</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Equipe Pré Atendimento:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              onChange={(event) => {
                (empreendimentoSelecionado || podeEnviarSemEmpreendimento) ?
                configuraGerentes("pre", event.target.value) :
                setEquipePreAtendimentoSelecionada(event.target.value || null )
                if(!event.target.value){
                  setGerentePreAtendimentoSelecionado(null);
                  setUsuarioPreAtendimentoSelecionado(null);
                }
              }}
              value={Number(equipePreAtendimentoSelecionada)}
            >
              <option value="">
                Selecione
              </option>
              {(empreendimentoSelecionado || podeEnviarSemEmpreendimento) && equipesPreAtendimento.map((equipe) => (
                <option key={equipe.id_equipe} value={equipe.id_equipe}>
                  {equipe.nome_equipe}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Gestor Pré Atendimento:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              disabled={!(equipePreAtendimentoSelecionada)}
              onChange={(event) =>{
                empreendimentoSelecionado != null ?
                configuraOperadores("pre", event.target.value) :
                setGerentePreAtendimentoSelecionado(event.target.value)
              }}
            >
              <option value="">
                Selecione
              </option>
              {equipePreAtendimentoSelecionada && gestoresPreAtendimento.map((gestor) => (
                <option key={gestor.id_gerente} value={gestor.id_gerente}>
                  {gestor.nome_gerente}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Atendente:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              disabled={!(gerentePreAtendimentoSelecionado) || !(equipePreAtendimentoSelecionada)}
              onChange={(event) => setUsuarioPreAtendimentoSelecionado(event.target.value) }
              value={Number(usuarioPreAtendimentoSelecionado)}
            >
              <option hidden value="">
                Selecione
              </option>
              {gerentePreAtendimentoSelecionado && usuariosPreAtendimento.map((usuario) => (
                <option key={usuario.id_operador} value={usuario.id_operador}>
                  {usuario.nome_operador}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Equipe PDV:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              onChange={(event) => {
                (empreendimentoSelecionado || podeEnviarSemEmpreendimento) ?
                configuraGerentes("pdv", event.target.value) :
                setEquipePdvSelecionada(event.target.value || null)

                if(!event.target.value){
                  setGerentePdvSelecionado(null);
                  setUsuarioPdvSelecionado(null);
                }
              }}
              value={Number(equipePdvSelecionada)}
            >
              <option value="">
                Selecione
              </option>
              {(empreendimentoSelecionado || podeEnviarSemEmpreendimento) && equipesPdv.map((equipe) => (
                <option key={equipe.id_equipe} value={equipe.id_equipe}>
                  {equipe.nome_equipe}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Gerente PDV:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              disabled={!(equipePdvSelecionada)}
              onChange={(event) =>{
                empreendimentoSelecionado != null ?
                configuraOperadores("pdv", event.target.value) :
                setGerentePdvSelecionado(event.target.value)
              }}
            >
              <option value="">
                Selecione
              </option>
              {equipePdvSelecionada && gerentesPdv.map((gestor) => (
                <option key={gestor.id_gerente} value={gestor.id_gerente}>
                  {gestor.nome_gerente}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Corretor:</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              custom
              disabled={!(gerentePdvSelecionado) || !(equipePdvSelecionada)}
              onChange={(event) => setUsuarioPdvSelecionado(event.target.value)}
              value={Number(usuarioPdvSelecionado)}
            >
              <option hidden value="">
                Selecione
              </option>
              {gerentePdvSelecionado && usuariosPdv.map((usuario) => (
                <option key={usuario.id_operador} value={usuario.id_operador}>
                  {usuario.nome_operador}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className="mt-2">
          <Col lg={6}>
              {visualizarManterEmpreendimentoLead ?
                  <Form.Group controlId="manter_empreendimento_lead">
                      <Form.Check 
                          type="checkbox" 
                          label={isMobile ? "Ignorar estrutura de atendimento": "Ignorar estrutura de atendimento por Empreendimentos e Equipes"} 
                          id='manter_empreendimento_lead'
                          onChange={(e)=>{
                              handleManterEmpreendimentoSelecionado(e.target.checked);
                          }}
                      />
                  </Form.Group>
                  : <></>
              }
          </Col>
          <Col lg={6}>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                onClick={() => altMassaNv()}
                variant={"success"}
                type={"button"}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ marginRight: "15px" }}
                />
                Salvar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
    <ModalVisualizarAlteracaoMassa
      open={openModalVisualizarAlteracao}
      opvsRetorno={retornoOpvs}
      closeModal={()=>{
        setOpenModalVisualizarAlteracao(false)
        closeModal();
        setTimeout(() => {
                  window.location.reload();
                }, 1000);
      }}
    />
    </>
  );
}
