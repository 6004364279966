import React, { useContext, useEffect, useState } from 'react';
import '../../DetalheOportunidade/Status/index.css';
import { Form, Button, Row, Container, Col, Tabs } from 'react-bootstrap';
import OportunidadeService from '../../../services/OportunidadeService';
import swal from 'sweetalert';
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import guService from "../../../services/GuService";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import oportunidadeService from '../../../services/OportunidadeService';
import { FunilService } from '../../../services/FunilService';
import { DommusTab } from '../../../components/DommusTab';
import { cachearContexto } from '../../../helpers/cachearContexto';
import { CamposDinamicosContext } from '../../../contexts/CamposDinamicosContext/CamposDinamicosContext';
import { FunilContext } from '../../../contexts/FunilContext';
import { OportunidadeContext } from '../../../components/GlobalContext/globalContext';

export default function Status({
    id_oportunidade,
    statusAtual,
    closeModal,
    id_substatus_oportunidade,
    manipuladorMudancaStatus,
    setTabIndex,
    reload=true,
    mostrarModalNovoAtendimento = false,
    mudaParaAtendimento = null,
    oportunidade = null,
    camposDinamicos = null,
    funis = null
}) {
    const [statusSelecionado, setStatusSelecionado] = useState(statusAtual || null);
    const [tipoStatusSelecionado, setTipoStatusSelecionado] = useState();
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [listaSubstatus, setlistaSubstatus] = useState([])
    const [tabKey, setTabKey] = useState(0);
    const [funisComEtapas, setFunisComEtapas] = useState([])
    const [substatusSelecionado, setsubstatusSelecionado] = useState(id_substatus_oportunidade || null)
    const tipoUser = Number(guService.getLocalState('tipoUsuario'))
    const idUsuario = Number(guService.getLocalState('idUsuario'))
    const [permissaoAcessoAtualizacao, setPermissaoAcessoAtualizacao] = useState(false)
    const { camposTelaQualificacao, setCamposTelaQualificacao } = useContext(CamposDinamicosContext)
    const { listaFunis, setListaFunis } = useContext(FunilContext);
    const { oportunidadeSelecionada, setOportunidadeSelecionada } = useContext(OportunidadeContext)

    const sugestaoNovoAtendimento = () => {
        if (mostrarModalNovoAtendimento) {
            Swal.fire({
                titleText: "Deseja abrir um novo atendimento para esta oportunidade?",
                icon: "question",
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Sim, abrir!",
                cancelButtonText: "Não, obrigado.",
                customClass: {
                    confirmButton: "btn btn-primary mr-3",
                    cancelButton: "btn btn-danger",
                },
                buttonsStyling: false,
            }).then(result => result.isConfirmed ? mudaParaAtendimento(2, true) : "")
        }
    }

    useEffect(() => {
        cachearContexto(camposTelaQualificacao, setCamposTelaQualificacao, oportunidadeService.buscarCamposDinamicosTelaQualificacao, false, camposTelaQualificacao.total)

        if((oportunidadeSelecionada != null) && oportunidadeSelecionada.id_oportunidade != id_oportunidade) {
            oportunidadeService.buscarOportunidade(id_oportunidade)
                .then(res => setOportunidadeSelecionada(res.data))
                .catch(error => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar a oportunidade: " + error,
                        icon: 'error'
                    });
                });
        }

        cachearContexto(listaFunis, setListaFunis, FunilService.index)
    }, []);

    useEffect(() => {
        if(listaFunis.length) {
            const funis = listaFunis.map(funil => {
                //Filtra os objetos de funis_status_oportunidade apenas os que contém status_oportunidade
                funil.funil_status_oportunidade = funil.funil_status_oportunidade.filter(funil_status_oportunidade => funil_status_oportunidade.status_oportunidade)
                //Define na tab do funil em que o status atual da oportunidade está como padrão
                if(funil.funil_status_oportunidade.map(funil_status_oportunidade => funil_status_oportunidade.id_status_oportunidade).includes(statusAtual)) {
                    setTabKey(funil.id_funil)
                }
                return funil
            })
            setFunisComEtapas(funis);
        }
    }, [listaFunis])

    useEffect(() => {
        if(funisComEtapas.length) {
            validarPermissoesEtapas()
        }
    }, [funisComEtapas])

    function validarPermissoesEtapas() {
        const etapas = funisComEtapas.map(funil => funil.funil_status_oportunidade?.map(funilStatusOportunidade => funilStatusOportunidade?.status_oportunidade))
            .flat()
            .filter(etapa => etapa)
        const index = etapas.findIndex(x => x.id_status_oportunidade == statusAtual);
        setlistaSubstatus(etapas[index].substatus_oportunidade.filter(substatus => substatus.oculto == 0))
        if(etapas[index]?.tipo) {
            setTipoStatusSelecionado(etapas[index].tipo)
        }
        let etapaAtual = etapas.find(status => status.id_status_oportunidade == statusSelecionado)
        if(etapaAtual) {
            etapaAtual.permissao_acesso_atualizacao = etapaAtual.permissao_acesso
            if(etapaAtual.trava_atualizacao && etapaAtual.trava_atualizacao != "null" && etapaAtual.permissao_acesso) {
                if(etapaAtual.trava_atualizacao.id_usuario.length) {
                    etapaAtual.permissao_acesso.id_usuario = etapaAtual.permissao_acesso.id_usuario
                        .filter(id_usuario => !etapaAtual.trava_atualizacao.id_usuario.includes(id_usuario))
                }
                if(etapaAtual.trava_atualizacao.tipo_usuario.length) {
                    etapaAtual.permissao_acesso.tipo_usuario = etapaAtual.permissao_acesso.tipo_usuario
                        .filter(tipo_usuario => !etapaAtual.trava_atualizacao.tipo_usuario.includes(tipo_usuario))
                }
            }
        }
        if(etapaAtual.permissao_acesso_atualizacao ?
            (etapaAtual.permissao_acesso_atualizacao?.id_usuario.includes(idUsuario) || etapaAtual.permissao_acesso_atualizacao?.tipo_usuario.includes(tipoUser)) :
            ([1, 2, 3, 4, 25, 26].includes(tipoUser))
        ) {
            setPermissaoAcessoAtualizacao(true)
        }
    }

    const alterarStatus = (e) => {
        setBotaoDesabilitado(true)
        e.preventDefault();

        const status = {
            id_status_oportunidade: statusSelecionado,
            id_oportunidade: id_oportunidade,
            id_substatus_oportunidade: substatusSelecionado,
            id_funil: tabKey
        }

        trackPromise(
            OportunidadeService.atualizarStatus(status).then(
                (response) => {
                  manipuladorMudancaStatus(response?.data?.oportunidade?.status)
                    swal("Sucesso!", "O status da oportunidade foi atualizado com sucesso.", "success")
                        .then(() => sugestaoNovoAtendimento());

                    closeModal()
                    setOportunidadeSelecionada(response?.data?.oportunidade);

                    //se reload for true, a página será carregada
                    // if(reload && !mostrarModalNovoAtendimento){
                    //     setTimeout(() => {
                    //         window.location.reload();
                    //     }, 1500);
                    // }
                },
                (error) => {
                    if(error.response.status == 403){
                        Swal.fire(
                            'Ooops...',
                            error?.response?.data ?? 'Você não tem permissão para enviar esta oportunidade para a etapa selecionada.',
                            'warning'
                        )
                        return
                    }
                    if(error?.response?.data?.message){
                        Swal.fire(
                            'Ooops...',
                            error?.response?.data?.message,
                            'warning'
                        )
                    }else{
                        swal("Ooops...", "Ocorreu um erro ao atualizar o status: " + error.data, "error");
                    }
                }
            )
        )
    };

    function setStatus(status) {
        let dadosDinamicos = oportunidadeSelecionada?.interesse ? JSON.parse(oportunidadeSelecionada?.interesse?.dados_dinamicos) : null;
        let validaCampos = [];
        let camposVazios = [];
        if(dadosDinamicos && camposTelaQualificacao.total) {
            camposTelaQualificacao.campos.forEach(camposlista => {
                let campo = (camposlista?.etapas_vinculadas?.filter(campos => [campos].includes(statusAtual)))
                if (Array.isArray(campo) && campo.length) {
                    validaCampos.push(camposlista);
                }
            })
            validaCampos.forEach(camposValidados => {
                if (dadosDinamicos[camposValidados.id_campos_dinamicos]) {
                    camposVazios.push(camposValidados.label);
                }
            })
        }
        let nomeCampos = camposVazios.toString();
        if (Array.isArray(camposVazios) && camposVazios.length) {
            Swal.fire({
                title: 'Parece que há campos obrigatórios sem preencher, gostaria de preenche-los?',
                text: `Campos: ${nomeCampos}`,
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    setTabIndex(1)
                    closeModal()
                }
            })
        } else {
            setsubstatusSelecionado(null)
            setlistaSubstatus(status.status_oportunidade?.substatus_oportunidade?.filter(substatus => substatus.oculto == 0) || [])
            setStatusSelecionado(status.id_status_oportunidade);
        }

    }

    function setSubStatusOpv(id){
        setsubstatusSelecionado(id)
    }

    return <Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key || 0)} className="mb-0">
            {funisComEtapas.map((funil, index) => {
                return (
                    <DommusTab key={index} eventKey={funil.id_funil} title={funil.nome} className="pt-4 pb-0">
                        <Container fluid>
                            <Form className="formStatus pb-0">
                                <div //style={{display: 'flex', justifyContent: 'space-between'}}
                                className='row'
                                >
                                    <div className="col-6 pb-2"
                                    >
                                    {tipoStatusSelecionado != 'D' && !permissaoAcessoAtualizacao ?
                                        funil.funil_status_oportunidade.filter((item)=>item.id_status_oportunidade !== statusAtual).map((status) => {
                                            if(status.status_oportunidade.tipo == tipoStatusSelecionado || tipoStatusSelecionado == 'A'){
                                                if(!['D', 'G', 'V', 'E'].includes(status.status_oportunidade.tipo)){
                                                    return(
                                                        <Row key={status.id_status_oportunidade}>
                                                            <div
                                                                id={"status" + status.id_status_oportunidade}
                                                                onClick={() => { setStatus(status) }}
                                                                className={statusSelecionado === status.id_status_oportunidade ? 'statusBoxAtivo' : 'statusBox'}
                                                                style={{ backgroundColor: status.status_oportunidade.cor }}
                                                            >
                                                                {status.status_oportunidade.nome}
                                                            </div>
                                                        </Row>
                                                    )
                                                }
                                            }
                                        })
                                        :
                                        funil.funil_status_oportunidade.filter((item)=>item.id_status_oportunidade !== statusAtual).map((status) =>
                                            (status.status_oportunidade.tipo == 'P' || status.status_oportunidade.tipo == 'A') ?
                                                <div>
                                                    <Row key={status.id_status_oportunidade}>
                                                        <div
                                                            id={"status" + status.id_status_oportunidade}
                                                            className={statusSelecionado === status.id_status_oportunidade ? 'statusBoxAtivo' : 'statusBox'}
                                                            onClick={() => { setStatus(status) }}
                                                            style={{ backgroundColor: status.status_oportunidade.cor }}
                                                        >
                                                            {status.status_oportunidade.nome}
                                                        </div>
                                                    </Row>
                                                </div>
                                            : <></>
                                        )
                                    }
                                    </div>
                                    <div className="col-6 pb-2"
                                    >
                                    {
                                        listaSubstatus.length > 0 ?
                                        listaSubstatus.map((substatus, i) => (
                                            substatus?.id_status_oportunidade == statusSelecionado ?
                                            <Row key={substatus.id_substatus_oportunidade}>
                                                <div
                                                    className={substatusSelecionado === substatus.id_substatus_oportunidade ? 'subStatusBoxAtivo' : 'subStatusBox'}
                                                    onClick={() => { setSubStatusOpv(substatus.id_substatus_oportunidade) }}
                                                    style={{ backgroundColor: substatus.cor }}
                                                >
                                                    {substatus.nome}
                                                </div>
                                            </Row> : <></>
                                        ))
                                        :
                                        <div className='estiloNenhumSub'>Nenhum status adicionado a essa etapa</div>
                                    }
                                    </div>
                                </div>
                                {tipoStatusSelecionado == 'V' ?
                                <Row>
                                    <Alert variant="warning">
                                        Evolução automática pelo módulo "Dommus Vende"
                                    </Alert>
                                </Row> : <></>}

                                <Row className="formFooter">
                                    <Col>
                                        <Button disabled={botaoDesabilitado} variant="success" onClick={(e) => alterarStatus(e)} type="button">
                                            <FontAwesomeIcon
                                            icon={faSave}
                                            style={{ marginRight: "15px" }}
                                            />
                                            Salvar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </DommusTab>
                )
            })}
        </Tabs>
}
