import React, { useCallback } from 'react';
import './style.css'
import formatDommus from "../../../../helpers/format";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { celularMask } from '../../../InputTextMask';

export default function ItemListaFiltroGlobalLinha({atendimento}) {
    const handleClickLinha = useCallback(() => {
        window.open('/oportunidade/' + atendimento.id_oportunidade);
    });

    function montaPrimeiraColuna() {
        let col

        switch(atendimento.tipo) {
            case 'Telefonema':
                col = (<>
                    <div className="linha"><strong>OPV-{formatDommus.zeroPad(atendimento.id_oportunidade, 5)} | {atendimento.nome}</strong></div>
                    <div className="linha"><div><strong>Tipo do Atendimento:</strong> {atendimento.tipo}</div></div>
                    <div className="linha"><div><strong>Criado-em:</strong> {atendimento.criado_em.replace('-', ' às ')} <strong>por</strong> {atendimento.criado_por}</div></div>
                    <div className="linha"><div><strong>Contato: </strong>{celularMask(atendimento.contato) || 'Não Informado'}</div></div>
                </>)
                break
            case 'E-mail':
                    col = (<>
                        <div className="linha"><strong>OPV-{formatDommus.zeroPad(atendimento.id_oportunidade, 5)} | {atendimento.nome}</strong></div>
                        <div className="linha"><div><strong>Tipo do Atendimento:</strong> {atendimento.tipo}</div></div>
                        <div className="linha"><div><strong>Criado-em:</strong> {atendimento.criado_em.replace('-', ' às ')} <strong>por</strong> {atendimento.criado_por}</div></div>
                        <div className="linha"><div><strong>Contato: </strong>{atendimento.contato || 'Não Informado'}</div></div>
                    </>)
                break
            case 'Mensagem':
                    col = (<>
                        <div className="linha"><strong>OPV-{formatDommus.zeroPad(atendimento.id_oportunidade, 5)} | {atendimento.nome}</strong></div>
                        <div className="linha"><div><strong>Tipo do Atendimento:</strong> {atendimento.tipo}</div></div>
                        <div className="linha"><div><strong>Criado-em:</strong> {atendimento.criado_em.replace('-', ' às ')} <strong>por</strong> {atendimento.criado_por}</div></div>
                        <div className="linha"><div><strong>Contato: </strong>{celularMask(atendimento.contato) || atendimento.contato || 'Não Informado'}</div></div>
                    </>)
                break
            case 'Tarefa/Lembrete':
                col = (<>
                    <div className="linha"><strong>OPV-{formatDommus.zeroPad(atendimento.id_oportunidade, 5)} | {atendimento.nome}</strong></div>
                    <div className="linha"><div><strong>Tipo do Atendimento:</strong> {atendimento.tipo}</div></div>
                    <div className="linha"><div><strong>Criado-em:</strong> {atendimento.criado_em.replace('-', ' às ')} <strong>por</strong> {atendimento.criado_por}</div></div>
                    <div className="linha"><div><strong>Data do Agendamento: </strong>{atendimento.data} às {atendimento.hora.replace(':00', '')}</div></div>
                </>)
                break
            default:
                col = (<>
                    <div className="linha"><strong>OPV-{formatDommus.zeroPad(atendimento.id_oportunidade, 5)} | {atendimento.nome}</strong></div>
                    <div className="linha"><div><strong>Tipo do Atendimento:</strong> {atendimento.tipo}</div></div>
                    <div className="linha"><div><strong>Criado-em:</strong> {atendimento.criado_em.replace('-', ' às ')} <strong>por</strong> {atendimento.criado_por}</div></div>
                </>)
                break
        }
        return col
    }

    function montaSegundaColuna() {
        const observacao = atendimento.observacao ? 
            atendimento.observacao.length > 160 ? 
            `${atendimento.observacao.substr(0, 160)}...` : 
            atendimento.observacao : 
            'Não Informado'
        const feedback = atendimento.feedback ? 
            atendimento.feedback.length > 160 ? 
            `${atendimento.feedback.substr(0, 160)}...` : 
            atendimento.feedback : 
            'Não Informado'
        let col

        switch(atendimento.tipo) {
            case 'Telefonema':
                col = (<>
                    <div className="linha"><div className='texto-observacao'><strong>Observação:</strong> {observacao}</div></div>
                </>)
                break
            case 'Tarefa/Lembrete':
                col = (<>
                    <div className="linha"><div><strong>Tipo da Tarefa: </strong>{atendimento.tipo_tarefa || 'Não Informado'}</div></div>
                    <div className="linha"><div><strong>Concluído:</strong> {atendimento.tarefa_realizada}</div></div>
                    <div className="linha"><div className='texto-observacao'><strong>Observação:</strong> {observacao}</div></div>
                </>)
                break
            default:
                col = (<>
                    <div className="linha"><div className='texto-observacao'><strong>Observação:</strong> {observacao}</div></div>
                </>)
                break
        }
        if(['E-mail', 'Mensagem'].includes(atendimento.tipo)){
            col = (<>
                <div className="linha"><div className='texto-observacao'><strong>Observação:</strong> {observacao}</div></div>
                <div className="linha"><div className='texto-observacao'><strong>Feedback:</strong> {feedback}</div></div>
            </>)
        }
        return col
    }

    return (
        <td>
            <Row onClick={handleClickLinha}>
                <Col lg="6">
                    <Row>
                        <Col sm="2">
                            <FontAwesomeIcon style={{ height: '1.3em' }} size="5x" icon={faUserAlt} color="#183B64" />
                        </Col>
                        <Col className='px-1'>
                            {montaPrimeiraColuna()}
                        </Col>
                    </Row>
                </Col>
                    
                <Col lg="6">
                    <Row>
                        <div className='col-separator'></div>
                        <Col>
                            {montaSegundaColuna()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </td>
    )

}
