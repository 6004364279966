import { Option } from "react-multi-select-component";

const formatDommus = {
  formatDate: (date: string) => {
    var newDate = new Date(date) as any;
    var seconds = Math.floor((new Date() as any - newDate) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " anos atrás";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " mes(es) atrás";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " dia(s) atrás";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hora(s) atrás";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuto(s) atrás";
    }
    return Math.floor(seconds) + " segundo(s) atrás";
  },

  contarAtraso: (date:string) => {
    var newDate = new Date(date) as any;
    var dias = 0;
    var seconds = Math.floor((new Date() as any - newDate) / 1000);
    var interval = seconds / 31536000;
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) > 3) {
        return Math.floor(interval) - 3;
      }
    }
  },

  zeroPad: (num: number, places: number) => {
    return String(num).padStart(places, "0");
  },

  formataMysql: (data: string | undefined) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2];

      return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
    } else {
      return null;
    }
  },

  formataMysqlDateHour: (data: string) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2].split(" ")[0];
      var hora = data.split("/")[2].split(" ")[1];
      return (
        ano +
        "-" +
        ("0" + mes).slice(-2) +
        "-" +
        ("0" + dia).slice(-2) +
        " " +
        hora +
        ":" +
        "00"
      );
    } else {
      return null;
    }
  },

  handleDarkenColor(hex: string) {
    let strHex = hex
    if(![4, 7].includes(hex.length)) return '#171773'
    else if(hex.length == 4) {
        let newHex = '#'
        hex.slice(1).split("").forEach((c) => {
            newHex += c + c
        })
        strHex = newHex
    }
    let rgb = [
        [1, 3],
        [3, 5],
        [5, 7]
    ] as any
    let int
    rgb = rgb.map((attr: Array<number>) => {
        int = parseInt(strHex.slice(attr[0], attr[1]), 16)
        int = int - 50 >= 0 ? int - 50 : 0
        return int.toString(16).padStart(2, '0')
    })
    return `#${rgb.join('')}`
  },

  generateRandomString(num: number) {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1= ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < num; i++ ) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result1;
  },

  slaMask(valor: string) {
    if(!valor) {
      return ""
    }
    return valor.replace(/\D/g, "").substring(0, 6).replace(/(\d{2})(\d)/, "$1d, $2").replace(/(\d{2})(\d{2})/, "$1h, $2m")
  },

  formataValoresMultiSelect(opcoesSelecionadas: Option[]) {
    return Array.isArray(opcoesSelecionadas) && opcoesSelecionadas.length ? opcoesSelecionadas.map(opcao => opcao.value) : []
  },

  formatarHora(valor:string){    
    let onlyDigits = valor.replace(/[^0-9]/g, '');
    if(onlyDigits.length > 6){
      onlyDigits = onlyDigits.slice(0, -1);
    }
    return onlyDigits.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2:$3');        
  }

};
export default formatDommus;
