import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import OfertaAtivaService from "../../../../../services/OfertaAtivaService";
import OportunidadeService from "../../../../../services/OportunidadeService";
import Swal from "sweetalert2";
import crm from "../../../../../services/crm";
import EmpreendimentoService from "../../../../../services/EmpreendimentoService";
import {errorToast} from "../../../../../components/DommusToast";
import InputMoeda from "../../../../../components/InputMoeda";
import equipeService from '../../../../../services/EquipeService';
import { MultiSelect, Option } from "react-multi-select-component";
import { CampanhaType, LeadType, MidiaType, OrigemType } from '../../../../DetalheOportunidade/Cadastro/types';
import { ImportOptionsType } from './NaoDistribuidos';
import { CorretorEmpreendimentoType } from '../../../FiltroOfertaAtiva';
import { OportunidadeInfoFiltersType, OportunidadeFiltersType, DesistenciaTipoType, EquipeType } from './types';
import { StatusOportunidadeType } from "../../../../Configuracoes/FunilDeVendas/Funil/types";
import { swalErro } from '../../../../../helpers/DommusSwal';

interface FiltroOportunidadeProps {
    setListaLeads: (leads: Array<LeadType>) => void
    setFiltroSubmetido: (filters: OportunidadeFiltersType) => void
    leadsSelecionadosAlterados: boolean
    filtroInfo: OportunidadeInfoFiltersType
    setBtnFiltrar: (element: JSX.Element) => void
    setToggleLista: (value: boolean) => void
    setToggleFiltro: (value: boolean) => void
    setOptionsImportacao: (importOprtions: ImportOptionsType) => void
}

export default function FiltroOportunidade({
    setListaLeads,
    setFiltroSubmetido,
    leadsSelecionadosAlterados,
    filtroInfo,
    setBtnFiltrar,
    setToggleLista,
    setToggleFiltro,
    setOptionsImportacao
}: FiltroOportunidadeProps) {

    const [formStatus, setFormStatus] = useState('');
    const [formDataInicio, setFormDataInicio] = useState('');
    const [formDataFim, setFormDataFim] = useState('');
    const [formValorMax, setFormValorMax] = useState('');
    const [formValorMin, setFormValorMin] = useState('');
    const [listaStatus, setListaStatus] = useState([]);
    const [listaEmpreendimentos, setListaEmpreendimentos] = useState([]);
    const [listaEquipesPreAtendimento, setListaEquipesPreAtendimento] = useState([] as Array<Option>);
    const [listaEquipesPDV, setListaEquipesPDV] = useState([] as Array<Option>);
    const [listaCampanha, setListaCampanha] = useState([]);
    const [listaMidia, setListaMidia] = useState([]);
    const [listaOrigem, setListaOrigem] = useState([]);
    const [listaMotivosDescarte, setListaMotivosDescarte] = useState([]);
    const [selectedEmpreendimento, setSelectedEmpreendimento] = useState([]);
    const [selectedEquipePre, setSelectedEquipePre] = useState([]);
    const [selectedMotivoDescarte, setSelectedMotivoDescarte] = useState([]);
    const [selectedEquipePdv, setSelectedEquipePdv] = useState([]);
    const [selectedMidias, setSelectedMidias] = useState([]);
    const [selectedCampanhas, setSelectedCampanhas] = useState([]);
    const [selectedOrigem, setSelectedOrigem] = useState([]);
    const configs = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	};
    const handleFiltrarLeads = function () {
        if (leadsSelecionadosAlterados) {
            Swal.fire({
                titleText: "Leads selecionados não Importados",
                text: "Tem certeza que deseja realizar uma nova busca antes de importar os leads selecionados?",
                icon: 'question',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Não, mantenha.',
                customClass: {
                    confirmButton: 'btn btn-primary mr-3',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false

            }).then(
                (result) => {
                    if (!result.value) {
                        return;
                    } else {
                        handleSubmitFiltro()
                    }
                })
        } else {
            handleSubmitFiltro()
        }
    }
    const handleResetFilter = useCallback(() => {
        setFormStatus('');
        setFormDataInicio('');
        setFormDataFim('');
        setFormValorMax('');
        setFormValorMin('');
    }, []);
    const handleSubmitFiltro = useCallback(() => {
        if (
            !formStatus &&
            selectedEquipePre.length <= 0 &&
            selectedEquipePdv.length <= 0 &&
            selectedOrigem.length <= 0 &&
            selectedEmpreendimento.length <= 0 &&
            !formDataInicio &&
            !formDataFim &&
            !formValorMax &&
            !formValorMin &&
            selectedMidias.length <= 0 &&
            selectedCampanhas.length <= 0
        ) {
            Swal.fire({
                titleText: "Atenção",
                text: "Preencha algum dos campos para continuar",
                icon: 'info'
            });
            return;
        }

        let valueEmpreendimento = [] as Array<number>
        let valueEquipePre = [] as Array<number>
        let valueEquipePdv = [] as Array<number>
        let valueCampanhas = [] as Array<number>
        let valueMidias = [] as Array<number>
        let valueOrigem = [] as Array<number>
        let valueMotivosDescarte = [] as Array<number>

        selectedEmpreendimento.map((item: Option) => {
            var itens = item.value;
            valueEmpreendimento.push(itens);
        })
        selectedEquipePre.map((item: Option) => {
            var itens = item.value;
            valueEquipePre.push(itens);
        })
        selectedMotivoDescarte.map((item: Option) => {
            var itens = item.value;
            valueMotivosDescarte.push(itens);
        })
        selectedEquipePdv.map((item: Option) => {
            var itens = item.value;
            valueEquipePdv.push(itens);
        })
        selectedCampanhas.map((item: Option) => {
            var itens = item.value;
            valueCampanhas.push(itens);
        })
        selectedMidias.map((item: Option) => {
            var itens = item.value;
            valueMidias.push(itens);
        })
        selectedOrigem.map((item: Option) => {
            var itens = item.value;
            valueOrigem.push(itens);
        })

        submitFiltro(valueMidias, valueCampanhas, valueEmpreendimento, valueEquipePre, valueEquipePdv, valueOrigem, valueMotivosDescarte)

    }, [formStatus,
        selectedMotivoDescarte,
        selectedEquipePre,
        selectedEquipePdv,
        selectedOrigem,
        selectedEmpreendimento,
        formDataInicio,
        formDataFim,
        formValorMax,
        formValorMin,
        selectedMidias,
        selectedCampanhas])

    useEffect(() => {
        if (filtroInfo) {
            setFormStatus(filtroInfo.status || '');
            setFormDataInicio(filtroInfo.dataInicio || '');
            setFormDataFim(filtroInfo.dataFim || '');
            setFormValorMax(filtroInfo.valorMax || '');
            setFormValorMin(filtroInfo.valorMin || '');
        }
    }, [filtroInfo])

    useEffect(() => {
        buscaEquipes()
        trackPromise(
            EmpreendimentoService.buscarEmpreendimentos().then(
                (response) => {
                    const empreendimentoOptions = response.data.map((empreendimento: CorretorEmpreendimentoType) => {
                        return {
                            value: empreendimento.id,
                            label: empreendimento.nome
                        }
                    })
                    setListaEmpreendimentos(empreendimentoOptions);
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                        icon: "error"
                    })
                }
            ));
        trackPromise(
            OportunidadeService.buscarTiposDesistencia().then(
                (response) => {
                    const motivosDesistenciaOptions = response.data.map((motivoDesistencia: DesistenciaTipoType) => {
                        return {
                            value: motivoDesistencia.id_desistencia_tipo,
                            label: motivoDesistencia.nome
                        }
                    })
                    setListaMotivosDescarte(motivosDesistenciaOptions)
                }, (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar a lista de tipos: " + error.data,
                        icon: "error"
                    });
                }
            )
        )
        trackPromise(
            crm.get('origem').then(
                (response) => {
                    const origensOptions = response.data.map((origem: OrigemType) => {
                        return {
                            value: origem.id_origem,
                            label: origem.origem
                        }
                    });
                    setListaOrigem(origensOptions)
                }, (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar as origens: " + error,
                        icon: "error"
                    })
                }
            ));
        trackPromise(
            crm.get('campanha').then(
                (response) => {
                    const campanhasOptions = response.data.map((campanha: CampanhaType) => {
                        return {
                            value: campanha.id_campanha,
                            label: campanha.nome
                        }
                    });
                    setListaCampanha(campanhasOptions)

                }, (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar as origens: " + error,
                        icon: "error"
                    })
                }
            ));
        trackPromise(
            crm.get('midia').then(
                (response) => {
                    const midiasOptions = response.data.map((midia: MidiaType) => {
                        return {
                            value: midia.id_midia,
                            label: midia.descricao
                        }
                    });
                    setListaMidia(midiasOptions);
                }, (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar as origens: " + error,
                        icon: "error"
                    })
                }
            )
        );
        trackPromise(
            crm.get('oportunidade/listStatus').then(
                (response) => {
                    setListaStatus(response.data)
                    setaStatusDesistencia(response.data)
                }, (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar as origens: " + error,
                        icon: "error"
                    })
                }
            )
        );

    }, []);

    function setaStatusDesistencia(statusOp: Array<StatusOportunidadeType>){
        if(statusOp.length > 0){
            statusOp.map((item) => {
                if(item.tipo == 'D')
                    setFormStatus(String(item.id_status_oportunidade))
            })
        }
    }

    function buscaEquipes() {
        trackPromise(equipeService.buscarEquipesSemEmpreendimento().then(
            (response) => {
                if (response.data !== '') {
                    let equipesPdvList = [] as Array<EquipeType>;
                    let equipesPreAtendimentoList = [] as Array<EquipeType>;
                    response.data.forEach((equipe: EquipeType) => {
                        if (equipe.tipo_equipe == 'P') {
                            equipesPdvList.push(equipe)
                        } else {
                            equipesPreAtendimentoList.push(equipe)
                        }
                    })

                    const equipesPreAtendimentoOptions = equipesPreAtendimentoList.map((equipe) => {
                        return {
                            label: equipe.nome_equipe,
                            value: equipe.id_equipe
                        }
                    });

                    const equipesPDVOptions = equipesPdvList.map((equipe) => {
                        return {
                            label: equipe.nome_equipe,
                            value: equipe.id_equipe
                        }
                    });

                    setListaEquipesPreAtendimento(equipesPreAtendimentoOptions);
                    setListaEquipesPDV(equipesPDVOptions);
                }
            }
        ))
    }

    function submitFiltro(
        valueMidias: Array<number>,
        valueCampanhas: Array<number>,
        valueEmpreendimento: Array<number>,
        valueEquipePre: Array<number>,
        valueEquipePdv: Array<number>,
        valueOrigem: Array<number>,
        valueMotivosDescarte: Array<number>
    ){
        const formData = {
            status: (formStatus !== '') ? [formStatus] : null,
            equipePreAtendimento: valueEquipePre,
            equipePDV: valueEquipePdv,
            motivosDescarte: valueMotivosDescarte,
            origem: valueOrigem,
            empreendimento: valueEmpreendimento,
            dataInicio: (formDataInicio !== '') ? formDataInicio : null,
            dataFim: (formDataFim !== '') ? formDataFim : null,
            valorMax: (formValorMax !== '') ? formValorMax : null,
            valorMin: (formValorMin !== '') ? formValorMin : null,
            midia: valueMidias,
            campanha: valueCampanhas,
            limit: 0,
            tipo_importacao: 'F',
            tipo_filtro: 'oportunidades'
        }

        trackPromise(
            OfertaAtivaService.filtrar.oportunidades(formData).then(res => {
                setListaLeads(res.data);
                setOptionsImportacao({
                    origem: 'filtro'
                })
                setFiltroSubmetido(formData);
            }).catch(erro => {
                swalErro(`Muitos leads filtrados, adicione outro filtro e tente novamente. Erro: ${erro}`)
            })
        )
        setToggleFiltro(false)
        setToggleLista(true)
    }

    useEffect(() => {
        setBtnFiltrar(<>
            <Button onClick={handleFiltrarLeads} className="btn-block">Filtrar Oportunidades</Button>
            <Button onClick={handleResetFilter} className="btn-info btn-block">Limpar Filtro</Button>
        </>);
    }, [handleSubmitFiltro, handleResetFilter])

    return (
        <Form>
            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label id="empreendimento-multi-select">Empreendimento:</Form.Label>
                    {listaEmpreendimentos.length > 0 &&
                        <>
                            <MultiSelect options={listaEmpreendimentos}
                                onChange={setSelectedEmpreendimento}
                                value={selectedEmpreendimento}
                                overrideStrings={configs}
                                labelledBy="empreendimento-multi-select"
                            />
                        </>
                    }
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.Label>Status da oportunidade:</Form.Label>
                    <Form.Control as="select" custom value={formStatus}
                                  onChange={(event) => setFormStatus(event.target.value)}>
                        {/* <option value="">Selecione o status da oportunidade</option> */}
                        {listaStatus.map((item: StatusOportunidadeType) => (
                            <>
                            {item.tipo == 'D' ? <option selected value={item.id_status_oportunidade} key={item.nome}>{item.nome}</option> : <></> }
                            {item.tipo == 'E' ? <option value={item.id_status_oportunidade} key={item.nome}>{item.nome}</option> : <></> }
                            </>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col}>
                    <Form.Label id="motivos-descarte-multi-select">Motivos de descarte:</Form.Label>
                    {listaMotivosDescarte.length > 0 &&
                        <>
                             <MultiSelect options={listaMotivosDescarte}
                                onChange={setSelectedMotivoDescarte}
                                value={selectedMotivoDescarte}
                                overrideStrings={configs}
                                labelledBy="motivos-descarte-multi-select"
                            />
                        </>
                    }

                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label id="equipes-pre-atendimento-multi-select">Equipe pré-atendimento:</Form.Label>
                    {listaEquipesPreAtendimento.length > 0 &&
                        <>
                            <MultiSelect options={listaEquipesPreAtendimento}
                                onChange={setSelectedEquipePre}
                                value={selectedEquipePre}
                                overrideStrings={configs}
                                labelledBy="equipes-pre-atendimento-multi-select"
                            />
                        </>
                    }
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.Label id="equipes-pdv-multi-select">Equipe PDV:</Form.Label>
                    {listaEquipesPDV.length > 0 &&
                        <>
                            <MultiSelect options={listaEquipesPDV}
                                onChange={setSelectedEquipePdv}
                                value={selectedEquipePdv}
                                overrideStrings={configs}
                                labelledBy="equipes-pdv-multi-select"
                            />
                        </>
                    }
                </Form.Group>


            </Row>

            <Row>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Data início (cadastro):</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Digite a data inicial de cadastro"
                        value={formDataInicio}
                        onChange={(event: any) => setFormDataInicio(event.target.value)}/>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Data fim (cadastro):</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Digite a data final de cadastro"
                        value={formDataFim}
                        onChange={(event: any) => setFormDataFim(event.target.value)}/>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Valor disponível mínimo:</Form.Label>
                    <InputMoeda
                        type="text"
                        placeholder="Digite o valor mínimo disponível"
                        value={formValorMin}
                        onChangeHandle={setFormValorMin}
                        maskOptions={{}}
                    />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Valor disponível máximo:</Form.Label>
                    <InputMoeda
                        type="text"
                        placeholder="Digite o valor máximo disponível"
                        value={formValorMax}
                        onChangeHandle={setFormValorMax}
                        maskOptions={{}}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label id="midias-multi-select">Mídia:</Form.Label>
                    {listaMidia.length > 0 &&
                        <>
                            <MultiSelect options={listaMidia}
                                onChange={setSelectedMidias}
                                value={selectedMidias}
                                overrideStrings={configs}
                                labelledBy="midias-multi-select"
                            />
                        </>
                    }
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.Label id="campanhas-multi-select">Campanha:</Form.Label>
                    {listaCampanha.length > 0 &&
                        <>
                            <MultiSelect options={listaCampanha}
                                onChange={setSelectedCampanhas}
                                value={selectedCampanhas}
                                overrideStrings={configs}
                                labelledBy="campanhas-multi-select"
                            />
                        </>
                    }
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label id="origens-multi-select">Origem:</Form.Label>
                    {listaOrigem.length > 0 &&
                        <>
                            <MultiSelect options={listaOrigem}
                                onChange={setSelectedOrigem}
                                value={selectedOrigem}
                                overrideStrings={configs}
                                labelledBy="origens-multi-select"
                            />
                        </>
                    }
                </Form.Group>
            </Row>
        </Form>
    );
}
