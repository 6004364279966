import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import Select from "react-select";
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import "./index.css";
import oportunidadeService from '../../services/OportunidadeService';
import { apenasNumeros, celularMask, cepMask, cpfMask } from '../InputTextMask';
import { DommusIntlCurrencyInput } from '../DommusIntlCurrencyInput';
import { Option, MultiSelect } from "react-multi-select-component";
import { GrupoQualificacaoType } from '../../contexts/GruposQualificacaoContext/types';
import DommusToggle from '../DommusToggle';
import DommusRequiredSpan from '../DommusRequiredSpan';
import { cachearContexto } from '../../helpers/cachearContexto';
import { CamposDinamicosContext } from '../../contexts/CamposDinamicosContext/CamposDinamicosContext';
import { TipologiaType } from '../GlobalContext/types';
import { UsuarioContext } from '../../contexts/UsuarioContext';

export type CamposDinamicosType = {
    id_campos_dinamicos: number
    id_tipo_campos_dinamicos: number
    id_grupo_campos_dinamicos: number | null
    grupo: GrupoQualificacaoType | null
    label: string
    empreendimentos_vinculados: Array<Option> | null
    options: string
    ativo: string | undefined
    criado_em: string | undefined
    atualizado_em: string | null | undefined
    tipo: TipoCampoDinamicoType
    oculto?: number
}

enum EnumTipoCampoDinamico {
    TEXTO = 1,
    DATA = 2,
    CPF = 3,
    CEP = 4,
    TELEFONE = 5,
    VALOR = 6,
    SIMPLES_SELECAO = 7,
    MULTIPLA_SELECAO = 8,
    SIMPLES_SELECAO_DADOS_DINAMICOS = 9,
    MULTIPLA_SELECAO_DADOS_DINAMICOS = 10,
    CHECKBOX = 11,
}

export type TipoCampoDinamicoType = {
    id_tipo_campos_dinamicos: number
    nome: string
    ativo: string | undefined
    criado_em: string | undefined
    atualizado_em: string | null | undefined
}

export default function CamposDinamicos({...props}) {
    const [camposAdicionais, setCamposAdicionais] = useState([] as Array<JSX.Element>);
    const [dadosCampo, setDadosCampo] = useState( props.campos || {} as any);
    const [validados, setValidados] = useState([] as Array<CamposDinamicosType>);
    const [validadosComGrupos, setValidadosComGrupos] = useState([] as any);
    const [listaCamposOcultos, setListaCamposOcultos] = useState([] as Array<CamposDinamicosType>);
    const [camposOcultos, setCamposOcultos] = useState([] as JSX.Element[]);
    const [camposGrupos, setCamposGrupos] = useState([] as JSX.Element[])
    const { camposTelaQualificacao, setCamposTelaQualificacao } = useContext(CamposDinamicosContext)
    const {validarPermissaoPorTelaAreaAcao} = useContext(UsuarioContext);
    const permiteAlterarOrigem = validarPermissaoPorTelaAreaAcao('oportunidades', 'campos_dinamicos', 'alterar_origem');

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(() => {
        atualizarCampos();
    }, [props.optionsSelectEmpreendimento, props.localidades,
        props.tipologias, props.optionsSelectComoConheceu,
        props.optionsSelectNivelInteresse, props.empreendimento, props.campos, validados,
        props.optionsSelectUnidadeInteresse, props.optionsSelectUnidadeQualificado])

    useEffect(() => {
        if(camposTelaQualificacao.total) {
            montaCampos()
        }
    }, [camposTelaQualificacao])


    function atualizarCampos(){

        setCamposAdicionais(injetaCampos(validados) ?? []);
        setCamposOcultos(injetaCampos(listaCamposOcultos, true) ?? []);
        if(Object.keys(validadosComGrupos).length){
            let temp =  Object.keys(validadosComGrupos).sort().map(function(key, index) {
                return (
                    <DommusToggle key={index} title={key} toggle={true}>
                        {injetaCampos(validadosComGrupos[key])}
                    </DommusToggle>
                )
              }) as [];
            setCamposGrupos(temp);
        }
    }

    function agrupaDadosSalvar(dadosSalvar:any){
        let dados = Object.assign({}, ...Object.entries({...dadosSalvar}).map(([a,b]) => ({ [a]: b })))
       props.setObj(Object.assign(dados));

    }

    const handleMultiSelectChange = (e:any, id_campos_dinamicos:number) => {
        let camposGerais = dadosCampo;
        camposGerais[id_campos_dinamicos] = e
        setDadosCampo(camposGerais)
        agrupaDadosSalvar(camposGerais)
        atualizarCampos();
    }

    const handleCheckList = (event: any, label: string, id_campos_dinamicos: number, optionsCheck: any) => {
        let camposGerais = dadosCampo;
        camposGerais[id_campos_dinamicos] = optionsCheck;

        camposGerais[id_campos_dinamicos].map(function (item: any) {
            if (item.label == label) {
                item.checked = event ? 1 : 0;
                return item;
            } else {
                return item;
            }
        });
        camposGerais[id_campos_dinamicos] = camposGerais[id_campos_dinamicos].filter(function(item: any) {
            return item.checked === 1;
        });
        agrupaDadosSalvar(camposGerais)
        atualizarCampos();
    }

    const handleOptionsCheckBox = (valores: Array<any>, options: any) => {
        let resultado = [];
        if(valores?.length){
            resultado = JSON.parse(options).map((item: any, index: number) => {
                valores.forEach(valor => {
                    if(valor.label === item.label)
                    item.checked = valor.checked
                });
                return item;
            })
        } else{
            resultado = JSON.parse(options).map((item: any, index: number) => {
                item.checked = 0;
                return item;
            })
        }
        return resultado;
    }

    async function montaCampos() {
        let items = validaCamposEmpreendimento(props.empreendimentos, camposTelaQualificacao.campos);
        let avulsos:CamposDinamicosType[] = [];
        let comGrupos:any = [];

        items.forEach((campo:CamposDinamicosType) => {
            if(campo.id_grupo_campos_dinamicos){
                comGrupos.push(campo);
            }else{
                avulsos.push(campo);
            }
        });
        setValidados(avulsos);
        setValidadosComGrupos(
            comGrupos.reduce((groups:any, campo:CamposDinamicosType) => {
                const groupKey = campo.grupo?.descricao || "";
                if (!groups[groupKey]) {
                    groups[groupKey] = [];
                }
                groups[groupKey].push(campo);
                return groups;
                }, {})
        );
    }

    const buscaOptionsCamposPadrao = (label: any, props:any) => {
        let labelTrim:string = label.replaceAll(/\s/g,'');
        switch (labelTrim) {
            case 'Localização':
                return props.localidades;
            case 'Empreendimento':
                return props.optionsSelectEmpreendimento;
            case 'NíveldeInteresse':
                return props.optionsSelectNivelInteresse;
            case 'Tipologia':
                return props.tipologias.map((tipologia: TipologiaType) => {
                    return {
                        value: tipologia.id,
                        label: tipologia.descricao
                    }
                });
            case 'ComoConheceu':
                return props.optionsSelectComoConheceu;
            case 'UnidadesdoEmpreendimentodeInteresse':
                return props.optionsSelectUnidadeInteresse;
            case 'UnidadesdoEmpreendimentoQualificado':
                return props.optionsSelectUnidadeQualificado;
        }
    }

    const configsMultiSelect = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	}


    function validaCamposEmpreendimento(empreendimentos: Array<Option>, resValues:any ) {
        let camposOrganizados:any = [];
        let camposOcultosTemp:any = [];
        const camposFiltrados = resValues.filter((campoDinamico: CamposDinamicosType) => {
            return campoDinamico?.empreendimentos_vinculados?.map((empreendimento: Option) => Number(empreendimento.value)).includes(Number(props.id_empreendimento))
        })

        camposFiltrados.forEach((campos:CamposDinamicosType) => {
            if(campos.oculto){
                if(props.campos && Object.hasOwn(props.campos, campos.id_campos_dinamicos)){
                    camposOcultosTemp.push(campos);
                }
            }else{
                camposOrganizados.push(campos);
            }
        })
        setListaCamposOcultos(camposOcultosTemp.sort((a:any)=>{return a.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CHECKBOX ? 1 : -1}));
        return camposOrganizados.sort((a:any)=>{return a.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CHECKBOX ? 1 : -1});
    }

    function montaOptions(campos: string) {
        let options = [] as Array<JSX.Element>;
        let camposMarshalizado = JSON.parse(campos);
        if(camposMarshalizado && Array.isArray(camposMarshalizado)){
          camposMarshalizado.forEach((value: Option) => {
              options.push(
                  <>
                      <option value={value.value}>{value.label}</option>
                  </>
              )
          });
        }

        return options
    }

    function trataIdsEmpreeendimentos(empreendimentosVinculados:Array<Option>) {
       let dados =  empreendimentosVinculados.map((empreendimento:Option)=>{
                return empreendimento.value;
       })
       return dados;
    }

    function validarPermissaoAlteracao() {
        if (props.origem != 0) {
            if (permiteAlterarOrigem) {
                if (props.tipoOrigem == 'C' || props.tipoOrigem == 'O') {
                    return true;
                }
                return false;
            }
        }
    }

    function injetaCampos(listaItems: CamposDinamicosType[], oculto=false) {
        const campos = [] as Array<JSX.Element>
        let input: JSX.Element = <></>
        const qtdCampos = listaItems.length;
        if(qtdCampos) {
            let tamCol = qtdCampos >= 3 ? 4 : qtdCampos == 2 ? 6 : 12;
            const passo = qtdCampos >= 3 ? 3 : qtdCampos == 2 ? 2 : 1;
            listaItems.forEach((value: CamposDinamicosType, key: number) => {
                        tamCol = qtdCampos < 3 ? tamCol :
                            ((key == (qtdCampos-2)) && (qtdCampos % 3 == 2)) ? 6 :
                            ((key == (qtdCampos-1)) && (qtdCampos % 3 == 1)) ? 12 :
                            tamCol

                        if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.TEXTO) {
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled = {oculto}
                                    type="text"
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = e.target.value
                                        agrupaDadosSalvar(camposGerais)

                                    }}

                                />
                            </>)

                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.DATA) {
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled = {oculto}
                                    type="date"
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = e.target.value
                                        agrupaDadosSalvar(camposGerais)
                                    }}
                                />
                           </> )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CPF) {
                             input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled = {oculto}
                                    id={`campo-${value.id_campos_dinamicos}`}
                                    type="text"
                                    placeholder="Digite o CPF"
                                    defaultValue={cpfMask(props.campos[value.id_campos_dinamicos])}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = apenasNumeros(e.target.value)
                                        agrupaDadosSalvar(camposGerais)
                                        let $campoDinamico = document.querySelector(`#campo-${value.id_campos_dinamicos}`) as HTMLInputElement
                                        if($campoDinamico) {
                                            $campoDinamico.value = cpfMask(e.target.value)
                                        }
                                    }}
                                />
                           </> )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CEP) {
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled = {oculto}
                                    id={`campo-${value.id_campos_dinamicos}`}
                                    type="text"
                                    placeholder="Digite o CEP"
                                    defaultValue={cepMask(props.campos[value.id_campos_dinamicos])}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = apenasNumeros(e.target.value)
                                        agrupaDadosSalvar(camposGerais)
                                        let $campoDinamico = document.querySelector(`#campo-${value.id_campos_dinamicos}`) as HTMLInputElement
                                        if($campoDinamico) {
                                            $campoDinamico.value = cepMask(e.target.value)
                                        }
                                    }}
                                />
                           </> )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.TELEFONE) {
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled = {oculto}
                                    id={`campo-${value.id_campos_dinamicos}`}
                                    type="text"
                                    placeholder="Digite o Telefone"
                                    defaultValue={celularMask(props.campos[value.id_campos_dinamicos])}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = apenasNumeros(e.target.value)
                                        agrupaDadosSalvar(camposGerais)
                                        let $campoDinamico = document.querySelector(`#campo-${value.id_campos_dinamicos}`) as HTMLInputElement
                                        if($campoDinamico) {
                                            $campoDinamico.value = celularMask(e.target.value)
                                        }
                                    }}
                                />
                           </> )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.VALOR) {
                             input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <DommusIntlCurrencyInput
                                    disabled={oculto}
                                    currency="BRL"
                                    config={currencyConfig}
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    change={(e: any) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = apenasNumeros(e.target.value)
                                        agrupaDadosSalvar(camposGerais)
                                    }}
                                />
                          </>  )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.SIMPLES_SELECAO) {
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Form.Control
                                    disabled={oculto}
                                    as="select"
                                    custom
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = e.target.value
                                        agrupaDadosSalvar(camposGerais)
                                    }}
                                >
                                    <option value="">Selecione uma opção:</option>
                                    {montaOptions(value.options)}
                                </Form.Control>
                                </> )
                        } else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.MULTIPLA_SELECAO) {
                             input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Select
                                    isDisabled={oculto}
                                    isMulti
                                    placeholder="Selecione"
                                    options={JSON.parse(value.options) || []}
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    className="basic-multi-select"
                                    onChange={(e) => {
                                        let camposGerais = typeof dadosCampo === "string" ? JSON.parse(dadosCampo) : dadosCampo;
                                        camposGerais[value.id_campos_dinamicos] = e
                                        agrupaDadosSalvar(camposGerais)
                                    }}
                           />
                            </>)
                        }else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.SIMPLES_SELECAO_DADOS_DINAMICOS) {
                            let optionsSelect:any = buscaOptionsCamposPadrao(value.label, props)

                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <Select
                                    isDisabled={oculto}
                                    placeholder="Selecione"
                                    options={optionsSelect || []}
                                    defaultValue={props.campos[value.id_campos_dinamicos]}
                                    className="basic-multi-select"                                    
                                    onChange={(e) => {
                                        let camposGerais = dadosCampo
                                        camposGerais[value.id_campos_dinamicos] = e
                                        agrupaDadosSalvar(camposGerais)
                                    }}
                                />
                            </>)
                        }else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.MULTIPLA_SELECAO_DADOS_DINAMICOS) {

                            let optionsMulti:any = buscaOptionsCamposPadrao(value.label, props)
                            input = (<>
                                <Form.Label>{`${value.label}`}</Form.Label>
                                <MultiSelect
                                    className='multiselect-qualificacao'
                                    disabled={oculto}
                                    options={optionsMulti || []}
                                    value={Array.isArray(dadosCampo[value.id_campos_dinamicos]) ?
                                        dadosCampo[value.id_campos_dinamicos]
                                        : (typeof(dadosCampo[value.id_campos_dinamicos]) == 'string' ? JSON.parse(dadosCampo[value.id_campos_dinamicos]) : [])}
                                    labelledBy={"label-multiselect-dinamicos" + value.id_campos_dinamicos}
                                    overrideStrings={configsMultiSelect}
                                    onChange={(e:any) => { handleMultiSelectChange(e, value.id_campos_dinamicos)
                                    }}
                                />
                           </>)
                        }else if(value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CHECKBOX) {
                            let optionsCheck:any = handleOptionsCheckBox( props.campos[value.id_campos_dinamicos] , value.options);
                            input = (   <>
                            <fieldset  className="caixa-fieldset-checklist rounded">
                                <legend  className="titulo-fieldset-checklist">{value.label}</legend>
                                    <div id="conteudo-border-campos-dinamicos">
                                 {optionsCheck.map((item:any, index:number) => (
                                    <div key={index}>
                                        <input
                                        disabled={oculto}
                                        type="checkbox"
                                        checked={item?.checked == 1 ? true : false || false}
                                        onChange={(e:any)=>{handleCheckList(e.target.checked, item.label, value.id_campos_dinamicos, optionsCheck)}} />
                                      <span>{item.label}</span>
                                    </div>
                                 ))}
                                    </div>
                                </fieldset>
                                 </>
                            )
                        }
                            campos.push(
                                <>
                                    <Form.Group as={Col} md={value.id_tipo_campos_dinamicos === EnumTipoCampoDinamico.CHECKBOX ? "12" :  tamCol}>
                                        {input}
                                    </Form.Group>
                                </>
                            )
                    })

            const linhas = []
            if(campos && Array.isArray(campos)){
                for(let c = 0; c < campos.length;) {
                    if(passo === 3) {
                        linhas.push(
                            <Row key={c}>
                                {campos[c]}
                                {campos[c+1]}
                                {campos[c+2]}
                            </Row>
                        )
                    } else if(passo == 2) {
                        linhas.push(
                            <Row key={c}>
                                {campos[c]}
                                {campos[c+1]}
                            </Row>
                        )
                    } else {
                        linhas.push(
                            <Row key={c}>
                                {campos[c]}
                            </Row>
                        )
                    }
                    c += passo;
                }
            }
            return linhas;
        }
    }

    return (
        <>
            {camposGrupos}
                <Row>
                    <Form.Group
                    as={Col}
                    sm={12}
                    md={12}
                    lg={6}
                    controlId="formOrigem"
                    readOnly={
                       props.origemPreenchida != "" &&
                       props.origemPreenchida != undefined &&
                       props.origemPreenchida != null
                    }
                    > 
                    <Form.Label>
                        Origem: <DommusRequiredSpan />
                    </Form.Label>
                        <Form.Control
                            as="select"
                            disabled={validarPermissaoAlteracao()}
                            custom
                            onChange={(event) => {
                            props.setOrigem(event.target.value);
                            }}
                            value={props.origem}
                        >
                            <option value="">Selecione a origem:</option>
                            {props.montaOptionsOrigem()}
                        </Form.Control>
                    </Form.Group>


                    <Form.Group as={Col} sm={12} lg={6} md={12} controlId="formNivelInteresse">
                        <Form.Label>Temperatura:</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            onChange={(event) => {
                            props.setNivelInteresseSelecionado(event.target.value);
                            }}
                            value={props.nivelInteresseSelecionado}
                        >
                            <option value="">Selecione o nível de interesse</option>
                            {props.nivel_interesse && Array.isArray(props.nivel_interesse) && props.nivel_interesse.map((nivelInteresse) => (
                            <option
                                key={nivelInteresse.id_nivel_interesse}
                                value={nivelInteresse.id_nivel_interesse}
                            >
                                {nivelInteresse.descricao}
                            </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                {camposAdicionais}
            {camposOcultos.length ?
                <fieldset className='secao-inativos'>
                    <legend>Inativos</legend>
                {camposOcultos}
                </fieldset>
                :<></>
            }
        </>
    )
}
