import React, { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";
import { toastErro } from "../../helpers/DommusSwal";
import { MidiaComCustosPaginadoType } from "./types";
import MidiaService from "../../services/MidiaService";
import { trackPromise } from "react-promise-tracker";
import { useForm } from "react-hook-form";

interface MidiaInvestimentoProps {
  midiasInvestimentos: MidiaComCustosPaginadoType
  buscarMidiasComCustos: (mes: number, ano: number, limite: number, pagina: number) => void
  setMidiasInvestimentos: Dispatch<SetStateAction<MidiaComCustosPaginadoType>>
  register: () => any
  setValue: (campo: string, valor: any) => any
  montarNomeCampoCusto: (idMidia: number|undefined, nomeCampo: string, idCampanha?: any) => string
  getValues: (campo?: string) => { [x: string]: any }
  reset: () => void
}

export const MidiaInvestimentoContext = createContext({} as MidiaInvestimentoProps);

export function MidiaInvestimentoContextProvider({ children }: { children: ReactElement }) {
  const [midiasInvestimentos, setMidiasInvestimentos] = useState<MidiaComCustosPaginadoType>({} as MidiaComCustosPaginadoType)
  const { register, setValue, getValues, reset } = useForm()

  function montarNomeCampoCusto(idMidia: number|undefined, nomeCampo: string, idCampanha=null) {
    if(idCampanha) {
      return `campanhas.id_campanha_${idCampanha}_id_midia_${idMidia}.${nomeCampo}`
    }
    return `midias.id_midia_${idMidia}.${nomeCampo}`
  }

  function buscarMidiasComCustos(mes: number, ano: number, limite: number, pagina:number) {
    trackPromise(MidiaService.buscarMidiasComCustos({
      oculto: 0,
      custos: {
        mes,
        ano 
      }
    } as any, limite, pagina))
      .then(response => setMidiasInvestimentos(response.data))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        toastErro('Houve um erro ao tentar buscar as midias com custos.')
      })
  }

  return (
    <MidiaInvestimentoContext.Provider value={{ 
      midiasInvestimentos, setMidiasInvestimentos,
      buscarMidiasComCustos, montarNomeCampoCusto,
      register, setValue, getValues, reset
    }}>
      {children}
    </MidiaInvestimentoContext.Provider>
  );
}