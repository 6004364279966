import fileDownload from "js-file-download"
import React, { SetStateAction } from "react"
import { trackPromise } from "react-promise-tracker"
import swal from "sweetalert"
import Swal, { SweetAlertOptions } from "sweetalert2"
import guService from "./GuService"
import oportunidadeService from "./OportunidadeService"
import relatorioService from "./RelatorioService"
import { DadosGraficoType, OportunidadeContextFiltersType, FunilOportunidadesDataType, OportunidadeByStatusType, SubmitHandlerType, DadoSubGraficoType, SubCategoriaType } from "../components/GlobalContext/types"
import { EtapaType } from "../pages/Oportunidades/types"

interface PromiseFiltroProps {
    filtros: OportunidadeContextFiltersType
    setFiltrosConfig: (filtros: OportunidadeContextFiltersType) => void
    setOportunidades: (novaLista: Array<OportunidadeByStatusType>) => void
    setTotalVisualizacoes: (val: number) => void
}

interface BuscarFiltroListaProps {
    filtros: OportunidadeContextFiltersType
    setFiltros: (filtros: OportunidadeContextFiltersType) => void
    modeloVisualizacao: string
    setOportunidades: (novaLista: Array<OportunidadeByStatusType>) => void
    setTotalVisualizacoes: (val: number) => void
}

interface BuscarFiltroFunilProps {
    filtros: OportunidadeContextFiltersType 
    setFunil: (val: boolean) => void 
    setFiltroFunil:  (filtros: OportunidadeContextFiltersType) => void
    modeloVisualizacao: string
    setOportunidades: (novaLista: Array<OportunidadeByStatusType>) => void
    setTotalVisualizacoes: (val: number) => void
    setTitulo: React.Dispatch<SetStateAction<string>>
    setCategorias: React.Dispatch<SetStateAction<string[]>>
    setColunas: React.Dispatch<SetStateAction<EtapaType[]>>
    setData: React.Dispatch<SetStateAction<DadosGraficoType[]>>
    setDataSubCategorias: React.Dispatch<SetStateAction<DadoSubGraficoType[]>>
    checked: boolean
}

export const GlobalOportunidadeContextService = {
  submitHandler: ({
    filtros, 
    setFiltrosConfig, 
    setOportunidades, 
    setTotalVisualizacoes, 
    setFiltros, 
    modeloVisualizacao, 
    setFunil, 
    setFiltroFunil, 
    setTitulo, 
    setCategorias, 
    setColunas, 
    setData,
    setDataSubCategorias,
    checked
  }: SubmitHandlerType) => {
    GlobalOportunidadeContextService.promiseFiltro({filtros, setFiltrosConfig, setOportunidades, setTotalVisualizacoes})
    GlobalOportunidadeContextService.buscarFiltroLista({filtros, setFiltros, modeloVisualizacao, setOportunidades, setTotalVisualizacoes})
    GlobalOportunidadeContextService.buscarFiltroFunil({
      filtros, 
      setFunil, 
      setFiltroFunil, 
      modeloVisualizacao, 
      setOportunidades, 
      setTotalVisualizacoes, 
      setTitulo, 
      setCategorias, 
      setColunas, 
      setData,
      setDataSubCategorias,
      checked
    })
    guService.setLocalState('redirect', 'allOpvs')
  },

  promiseFiltro: ({filtros, setFiltrosConfig, setOportunidades, setTotalVisualizacoes}: PromiseFiltroProps) => {
    setFiltrosConfig(filtros);
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (filtros.exportar === true) {
          GlobalOportunidadeContextService.baixarRelatorio(filtros)
        }
      }, 0);
    });
  },

  buscarFiltroLista: ({filtros, setFiltros, modeloVisualizacao, setOportunidades, setTotalVisualizacoes}: BuscarFiltroListaProps) => {
    setFiltros(filtros);
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (filtros.exportar === true) {
          GlobalOportunidadeContextService.baixarRelatorio(filtros)
        }
        oportunidadeService.buscarTotais(filtros).then(
          (response) => {
            setTotalVisualizacoes(response.data.visualizacoes);
          },
          (error) => {
            swal(
              "Ooops...",
              "Ocorreu um erro ao buscar as oportunidades: " + error.data,
              "error"
            );
          }
        );
      }, 0);
    });
  },

  buscarFiltroFunil: ({
    filtros, 
    setFunil, 
    setFiltroFunil, 
    modeloVisualizacao, 
    setOportunidades, 
    setTotalVisualizacoes, 
    setTitulo, 
    setCategorias, 
    setColunas, 
    setData,
    setDataSubCategorias,
    checked
  }: BuscarFiltroFunilProps) => {
    setFunil(true);
    if(!checked && filtros.oportunidade) filtros.oportunidade.expirados_desistencias = !checked
    setFiltroFunil(filtros)
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (filtros.exportar === true) {
          GlobalOportunidadeContextService.baixarRelatorio(filtros)
        } 
        oportunidadeService.buscarTotais(filtros).then(
          (response) => {
            const oportunidades = Object.values(response.data.colunas) as Array<EtapaType>;
            const arraydata = oportunidades.map((oportunidade, index) => {
              if (index == 0) {
                setTitulo(oportunidade.nome);
              }
              return { 
                name: oportunidade.nome, 
                value: oportunidades[index].total,
                color: oportunidade.cor,
                idEtapa: oportunidade.id_status_oportunidade,
                tipo: oportunidade.tipo
              };
            }) as Array<FunilOportunidadesDataType>;
            const arraySubCategoria = oportunidades.map((oportunidade, index)=>{
              return { 
                categoria: oportunidade.nome, 
                data: oportunidade.substatus_list ? oportunidade.substatus_list.map((substatus, index)=>{
                  return(
                    {
                      name: substatus['nome'],
                      value: substatus['value'],
                    }
                  )
                }) as Array<SubCategoriaType> : []
              }
            })            
            const categoriasAtualizadas = oportunidades.map(etapa => etapa.nome)
            setCategorias(categoriasAtualizadas)
            setColunas(oportunidades);
            setData(arraydata);
            setDataSubCategorias(arraySubCategoria);
            setTotalVisualizacoes(response.data.visualizacoes);
            resolve(response.data);
          },
          (error) => {
            swal(
              "Ooops...",
              "Ocorreu um erro ao buscar as oportunidades: " + error.data,
              "error"
            );
          }
        );
      }, 0);
    });
  },
  baixarRelatorio:(filtros: any) => {
    trackPromise(
      relatorioService.relatorioGeral(filtros as any)
        .then((response) => {
          if(response.data){
            Swal.fire({
              titleText: "Relatório",
              text: "Relatório gerado com sucesso",
              icon: "success",
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: "Efetuar download",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            }).then((result) => {
              if(result.value){
                fileDownload(response.data, "relatorio-" + new Date().toISOString() + ".csv");
              }
            });
          } else {
            Swal.fire({
              titleText: "Relatório",
              text: "Ocorreu um erro ao gerar o relatório, entre em contato com o suporte.",
              icon: "error",
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            })
          }
      }).catch(error => {
        if(error.response.status == 422) {
          let opcoesAlerta = {
            titleText: 'Relatório de Base Completa',
            text: error.response.data.message,
            icon: 'info',
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'dommus-button'
            }
          } as SweetAlertOptions
          if(error.response.data.relatorioGerado) {
            opcoesAlerta.denyButtonText = 'Baixar Relatório Existente'
            opcoesAlerta.denyButtonColor = 'var(--dommus-color-2)'
            opcoesAlerta.showDenyButton = true
          }
          Swal.fire(opcoesAlerta).then(result => {
            if(result.isDenied) {
              relatorioService.baixarRelatorioOportunidadesDiario()
            }
          })
        }
      })
    );
  }
}