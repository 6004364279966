import React from "react";
import MidiaSocialIcone from "../../../../../components/MidiaSocialIcone";
import { format, parseISO } from "date-fns";
import { celularMask } from "../../../../../components/InputTextMask";
import { Row, Col } from "react-bootstrap";
import { CorretorEmpreendimentoType } from "../../../FiltroOfertaAtiva";
import { LeadType, OportunidadeType } from "../../../../DetalheOportunidade/Cadastro/types";

interface RowLeadProps {
    lead: OfertaAtivaLeadType
    empreendimentos: Array<CorretorEmpreendimentoType>
}

export type OfertaAtivaLeadType = {
    id_oferta_ativa: number
    id_lead: number
    id_oportunidade: number | null
    agendamento_descricao: string | null
    agendamento_data: string | null
    id_usuario_agendamento: number | null
    lead_resgatado: number
    lead_descartado: number
    criado_em: string 
    atualizado_em: string | null
    ativo: string
    lead: LeadType
    atendente: string | null
    oportunidade: OportunidadeType | null
}

export function RowLead({ lead, empreendimentos }: RowLeadProps) {
    return (
        <tr>
            <td style={{ cursor: "pointer" }}>
                <Row>
                <Col>
                    <div className="conteudoNaoDistribuido">
                    <div className="midia">
                        {lead.lead.midia && (
                        <>
                            <MidiaSocialIcone
                            midia={lead.lead.midia}
                            />
                        </>
                        )}
                    </div>
                    <div className="">
                        <div className="linha">
                        <div>
                            {lead.lead.nome}
                            {lead.lead.criado_em
                            ? format(
                                parseISO(lead.lead.criado_em),
                                " | dd/MM/yyyy' às ' HH:mm:ss"
                                )
                            : ""}
                        </div>
                        </div>
                        <div className="linha">
                        <div>
                            {lead.lead.email} |{" "}
                            {celularMask(lead.lead.telefone)}
                        </div>
                        </div>
                        <div className="linha">
                        {lead.lead.campanha != null ? (
                            <div>
                            Campanha: {lead.lead.campanha.nome}
                            </div>
                        ) : (
                            <div>Campanha: -</div>
                        )}
                        </div>
                        <div className="linha">
                        {lead.lead.id_empreendimento != null ? (
                            empreendimentos.length < 0 ?
                            empreendimentos.map((emp: CorretorEmpreendimentoType) => (
                                Number(emp.id) == lead.lead.id_empreendimento ?
                                <div>
                                Empreendimento: {emp.nome}
                                </div> : <></>
                            ))
                            : <></>
                        ) : (
                            <div>Empreendimento: -</div>
                        )}
                        </div>
                        <div className="linha">
                        {lead.atendente != null ? (
                            <div>
                            <b
                                style={{
                                color: "var(--dommus-color-green)",
                                }}
                            >
                                Resgatado por: {lead.atendente}
                            </b>
                            </div>
                        ) : (
                            <></>
                        )}
                        </div>
                        <div className="linha">
                        {lead.lead_descartado != 0 ? (
                            <div>
                            <b
                                style={{
                                color: "var(--dommus-color-red)",
                                }}
                            >
                                Descartado
                            </b>
                            </div>
                        ) : (
                            <></>
                        )}
                        </div>
                        {(lead.lead_resgatado === 1) || (lead.lead_descartado === 0 && lead.lead_resgatado === 0) ?
                            <div className="linha">
                            {lead.oportunidade !== null && ['P', 'A', 'V', 'G'].includes(lead.oportunidade?.status?.tipo as string) ? (
                                <div>                                
                                <b
                                    style={{
                                    color: "var(--dommus-color-red)",
                                    }}
                                >
                                    Lead Indisponível
                                </b>
                                </div>
                            ) : (
                                <div>                                
                                <b
                                    style={{
                                    color: "var(--dommus-color-green)",
                                    }}
                                >
                                    Lead Disponível
                                </b>
                                </div>
                            )}
                            </div>
                            : <></>
                        }
                        <div className="linha">
                        {lead.id_usuario_agendamento != null ? (
                            <div>
                            <b
                                style={{
                                color: "var(--dommus-color)",
                                }}
                            >
                                Agendado
                            </b>
                            </div>
                        ) : (
                            <></>
                        )}
                        </div>
                    </div>
                    </div>
                </Col>
                </Row>
            </td>
        </tr>
    )
}