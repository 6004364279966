import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import PesquisaPadrao from '../PesquisaPadrao';
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function PesquisaGeralInput() {

    const [pesquisa, setPesquisa] = useState('');
    const [campoPesquisa, setCampoPesquisa] = useState(false);
    const [mostrarPesquisa, setMostrarPesquisa] = useState(false);
    const pesquisaInput = useRef(null);

    useEffect(() => {
        if (pesquisaInput.current && !campoPesquisa) {
            pesquisaInput.current.focus();
        }
    }, [campoPesquisa]);

    useEffect(() => {
        if(pesquisa?.length > 1){
            setMostrarPesquisa(true);
        }else{
            setMostrarPesquisa(false);
        }
    }, [pesquisa]);

    const cleanPesquisa = () => {
        setPesquisa('');
        pesquisaInput.current.value = "";
    }


    return (
        <>
            <div>
                <Form.Group >
                    <Form.Control type="text"
                        placeholder="Pesquisar ... "
                        onChange={(event) => { setPesquisa(event.target.value) }}
                        ref={pesquisaInput}
                        className="input-pesquisa-control"
                    />
                    {pesquisa.length ? 
                        <button className='clean-input' onClick={()=>cleanPesquisa()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>    
                        :
                        <></>
                    }
                </Form.Group>
                {mostrarPesquisa &&
                    <>
                        <div className="search-wrapper">
                            <div className="search-content">
                                <div className="search-title">
                                    <h3>Resultado da pesquisa ...</h3>
                                    <div className={"filter-right-nav"}>
                                        <button
                                            className="close-search" id="close-search"
                                            onClick={() => {
                                                setMostrarPesquisa(false);
                                                setPesquisa([]);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className='btn-close'/>  
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                <PesquisaPadrao
                                    pesquisa={pesquisa}
                                    numPage="1"
                                    setCampoPesquisa={setCampoPesquisa} />
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        </>
    )
}
