import crm from "./crm";

const ConfiguracoesGestaoService = {
	editConfiguracoes: (dados) => {
        let uri = '/configuracao';
        return crm['put'].call(crm, uri, dados);
    },
	storeMidia: (dados) => {
        let uri = '/midia';
        return crm['post'].call(crm, uri, {midia: dados});
    },
	buscarConfiguracoes: () => {
        return crm.get('/configuracao');
    },
    buscarConfiguracao: (chave) => {
        return crm.get('/configuracao/chave/'+chave);
    },
	iconesMidia: () => {
		return crm.get('/midia/icones');
    },
    buscarMidiaOrigemCampanhaPadrao: () => {
        return crm.get('/config-crm/midia-origem-campanha-padrao');
    },
    editConfiguracoesIntegracao: (dados) => {
        let uri = '/configuracao/dados-integracao';
        return crm['put'].call(crm, uri, dados);
    },
};

export default ConfiguracoesGestaoService;
