import React from "react";
import MicroFrontend from "../../MicroFrontend";
import guService from '../../services/GuService';
import ReactShadowRoot from 'react-shadow-root';

export default function Widgets() {

  const usaWidgetsAtendimento = JSON.parse(guService.getLocalState('usaWidgetsAtendimento'));
  return  (
    <>
    { usaWidgetsAtendimento &&
        <div id="dommus-widgets-shadow">
          <ReactShadowRoot>
              <MicroFrontend
                host={"https://widgets.dommus.com.br"}
                type={"multiple"}
                name="dommus-widgets"
             />
         </ReactShadowRoot>

        </div>
     }
    </>
  );
}
