import { trackPromise } from "react-promise-tracker";
import perfilUsuarioService from "../../../services/PerfilUsuarioService";
import { actionReducerType } from "../types/actionReducerType";
import { reguaNutricaoReducerType } from "./types";


export const initialStateReguaNutricao:reguaNutricaoReducerType = {
   users: new Map()
}


export const reguaNutricaoReducer = (state:reguaNutricaoReducerType,action: actionReducerType) => 
{
    switch(action.type){
        case 'FILL_USERS':
            state.users.set('usersMap',action.payload.usersArray);
            return state;
        break;
    }

    return state
}