import React from "react";
import MenuTopo from "./components/MenuTopo";
import MenuLateral from "./components/MenuLateral";
import MenuRodape from "./components/MenuNavigationMobile";
import { isMobile } from "react-device-detect";
export default function Base({ conteudo, history }) {
  return (
    <div>
      <MenuTopo history={history} />
      <div className="wrapper">
        <div className="sidebar" id="sidebarMobile">
          <MenuLateral history={history} />
        </div>
        <div className="content">{conteudo}</div>
      </div>
      {isMobile ? <MenuRodape /> : <></>}
    </div>
  );
}
