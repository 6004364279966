import React, { useCallback } from 'react';
import { format, parseISO } from "date-fns";
import { celularMask } from "../../InputTextMask";
import './style.css'
import formatDommus from "../../../helpers/format";
import MidiaSocialIcone from "../../MidiaSocialIcone";

export default function ItemListaFiltroGlobalLinha({ oportunidade, oportunidadesSelecionadas, setarOpvsSelecionadas }) {
    const user = {
        'nome': oportunidade.envolvido_nome != null && oportunidade.envolvido_nome != "" ? oportunidade.envolvido_nome : oportunidade.lead_nome,
        'celular': oportunidade.envolvido_telefone != null && oportunidade.envolvido_telefone != "" ? oportunidade.envolvido_telefone : oportunidade.lead_telefone,
        'email': oportunidade.envolvido_email != null && oportunidade.envolvido_email != "" ? oportunidade.envolvido_email : oportunidade.lead_email
    }

    const midia = {
        'descricao': oportunidade.midia_descricao,
        'icone': oportunidade.midia_icone,
        'cor': oportunidade.midia_cor,
    }
    const handleClickLinha = useCallback(() => {
        window.open('/oportunidade/' + oportunidade.id_oportunidade);
    });

    function handleSelecionaOportunidade(oportunidade) {
        const selecionados = [...oportunidadesSelecionadas];
        let indexOportunidade = -1;

        selecionados.some((item, index) => {
          if (item.id_oportunidade === oportunidade.id_oportunidade) {
            indexOportunidade = index;
            return true;
          }
        });

        if (indexOportunidade >= 0) {
          selecionados.splice(indexOportunidade, 1);
        } else {
          selecionados.push(oportunidade);
        }
        setarOpvsSelecionadas(selecionados);
        listaSelecionados(selecionados);
        
        // if (selecionados.includes(id_oportunidade)) {
            //     const selecionadoIndex = selecionados.findIndex(item => item == id_oportunidade);
            //     selecionados.splice(selecionadoIndex, 1);
            // } else {
                //     selecionados.push(id_oportunidade);
                // }
                // setarOpvsSelecionadas(selecionados)
               // listaSelecionados(selecionados)
    }

    return (
        <td>
            <div className="conteudoNaoDistribuido">
                <div className="checkBox">
                    <input
                        //disabled={lead.email == (null ||  '') && lead.telefone == (null || '')}
                        type="checkbox"
                        id={"opv" + oportunidade.id_oportunidade}
                        value={1}
                        checked={Boolean (oportunidadesSelecionadas.find( item =>
                            oportunidade.id_oportunidade === item.id_oportunidade
                          ))}
                        onChange={() => handleSelecionaOportunidade(oportunidade)}
                    />
                </div>
                <div style={{display: 'flex'}} onClick={() => handleClickLinha()}>
                    <div className="midia" >
                        <MidiaSocialIcone midia={midia} />
                    </div>
                    <div className="">

                        <div className="linha"><div>{user.nome}{user.criado_em ? format(parseISO(user.criado_em), " | dd/MM/yyyy' às ' HH:mm:ss") : ''}</div></div>
                        <div className="linha"><div>{user.email} | {celularMask(user.celular)}</div></div>
                        <div className="linha">{oportunidade.campanha_nome != "" ? <div>Campanha: {oportunidade.campanha_nome}</div> : <div>Campanha: -</div>}</div>
                        <div className="linha">{oportunidade.nome_empreendimento != null ? <div>Empreendimento: {oportunidade.nome_empreendimento}</div> :
                                <div>Empreendimento: -</div>}</div>
                        <div className="linha">OPV-{formatDommus.zeroPad(oportunidade.id_oportunidade, 5)}</div>
                        
                    </div>
                </div>
            </div>
        </td>
    );

}
