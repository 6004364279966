import React, { useEffect, useState } from "react";
import DommusToggle from "../DommusToggle";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import crm from "../../services/crm";
import empreendimentoService from "../../services/EmpreendimentoService";
import { errorToast } from "../DommusToast";
import "./style.css";
import oportunidadeService from "../../services/OportunidadeService";
import DommusRequiredSpan from "../../components/DommusRequiredSpan";
import equipeService from "../../services/EquipeService";
import guService from "../../services/GuService";

export default function FormDistribuicao({ oportunidade, ...rest }) {
  const [dataDistribuicao, setDataDistribuicao] = useState({});

  const tipoUsuario = guService.getLocalState("tipoUsuario");
  const userDommusCrm = guService.getLocalState("idUsuario");

  const [equipesPreAtendimento, setEquipesPreAtendimento] = useState([]);
  const [gestoresPreAtendimento, setGestoresPreAtendimento] = useState([]);
  const [usuariosPreAtendimento, setUsuariosPreAtendimento] = useState([]);
  const [equipesPdv, setEquipesPdv] = useState([]);
  const [gerentesPdv, setGerentesPdv] = useState([]);
  const [usuariosPdv, setUsuariosPdv] = useState([]);

  const [mostrarBtnSalvar, setMostrarBtnSalvar] = useState(
    rest.mostrarBtnSalvar ? rest.mostrarBtnSalvar : true
  );

  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] =
    useState("");
  const [empreendimentoAlterado, setEmpreendimentoAlterado] = useState("");
  const [equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada] =
    useState("");
  const [
    gerentePreAtendimentoSelecionado,
    setGerentePreAtendimentoSelecionado,
  ] = useState("");
  const [
    usuarioPreAtendimentoSelecionado,
    setUsuarioPreAtendimentoSelecionado,
  ] = useState(tipoUsuario == 26 ? userDommusCrm : "");
  const [equipePdvSelecionada, setEquipePdvSelecionada] = useState("");
  const [gerentePdvSelecionado, setGerentePdvSelecionado] = useState("");
  const [usuarioPdvSelecionado, setUsuarioPdvSelecionado] = useState(
    tipoUsuario == 8 ? userDommusCrm : ""
  );

  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [isInEditMode, setisInEditMode] = useState(false);
  const [isEquipeEdit, setisEquipeEdit] = useState(false);
  const [isGestorEdit, setisGestorEdit] = useState(false);
  const [isAtendenteEdit, setisAtendenteEdit] = useState(false);
  const [isEquipePdvEdit, setisEquipePdvEdit] = useState(false);
  const [isGerenteEdit, setisGerenteEdit] = useState(false);
  const [isCorretorEdit, setisCorretorEdit] = useState(false);

  const [isFreshLoading, setIsFreshLoading] = useState(true);
  const [dadosFormDistribuicao, setDadosFormDistribuicao] = useState([]);

  //preenche o formulário de distribuição.
  useEffect(() => {
    if (oportunidade) {
      if (empreendimentoAlterado == "") {
        trackPromise(
          crm.get(`oportunidade/${oportunidade}`).then(
            (response) => {
              const {
                id_empreendimento,
                id_equipe_pre_atendimento,
                id_gerente_pre_atendimento,
                id_usuario_pre_atendimento,
                id_gerente_pdv,
                id_equipe_pdv,
                id_usuario_atendimento,
              } = response.data;
              setEmpreendimentoSelecionado(id_empreendimento);
              setEquipePreAtendimentoSelecionada(id_equipe_pre_atendimento);
              setGerentePreAtendimentoSelecionado(id_gerente_pre_atendimento);
              setUsuarioPreAtendimentoSelecionado(id_usuario_pre_atendimento);
              setEquipePdvSelecionada(id_equipe_pdv);
              setGerentePdvSelecionado(id_gerente_pdv);
              setUsuarioPdvSelecionado(id_usuario_atendimento);
              setDadosFormDistribuicao({
                id_empreendimento,
                id_equipe_pre_atendimento,
                id_gerente_pre_atendimento,
                id_usuario_pre_atendimento,
                id_gerente_pdv,
                id_equipe_pdv,
                id_usuario_atendimento,
              });
            },
            () => {}
          )
        );
      }
    }
  }, [equipesPdv]);

  useEffect(() => {
    const data = {
      oportunidade: {
        id_empreendimento: empreendimentoSelecionado,
        id_equipe_pre_atendimento: equipePreAtendimentoSelecionada,
        id_gerente_pre_atendimento: gerentePreAtendimentoSelecionado,
        id_usuario_pre_atendimento: usuarioPreAtendimentoSelecionado,
        id_gerente_pdv: gerentePdvSelecionado,
        id_equipe_pdv: equipePdvSelecionada,
        id_usuario_atendimento: usuarioPdvSelecionado,
        id_oportunidade: oportunidade,
      },
    };

    setDataDistribuicao(data);
    if (rest.parentGetDataDistribuicao) {
      rest.parentGetDataDistribuicao(data);
    }
  }, [
    empreendimentoSelecionado,
    equipePreAtendimentoSelecionada,
    gerentePreAtendimentoSelecionado,
    usuarioPreAtendimentoSelecionado,
    gerentePdvSelecionado,
    equipePdvSelecionada,
    usuarioPdvSelecionado,
    oportunidade,
  ]);

  function salvarOportunidade() {
    if (!usuarioPreAtendimentoSelecionado && !usuarioPdvSelecionado) {
      Swal.fire({
        titleText: "Atenção!",
        text: "Você deve selecionar um usuário de atendente ou corretor para distribuir a oportunidade",
        icon: "warning",
      });
      return;
    }
    if (
      empreendimentoSelecionado === "" ||
      ((tipoUsuario == 7 || tipoUsuario == 8) &&
        gerentePdvSelecionado === "") ||
      ((tipoUsuario == 7 || tipoUsuario == 8) && equipePdvSelecionada === "") ||
      (tipoUsuario == 8 && usuarioPdvSelecionado === "") ||
      ((tipoUsuario == 25 || tipoUsuario == 26) &&
        gerentePreAtendimentoSelecionado === "") ||
      ((tipoUsuario == 25 || tipoUsuario == 26) &&
        equipePreAtendimentoSelecionada === "") ||
      (tipoUsuario == 26 && usuarioPreAtendimentoSelecionado === "")
    ) {
      Swal.fire({
        titleText: "Atenção",
        text: "Preencha todos os campos de distribuição",
        icon: "warning",
      });
      return;
    }
    trackPromise(
      oportunidadeService.salvarOportunidade(dataDistribuicao).then(
        (res) => {
          Swal.fire({
            titleText: "Sucesso",
            text: "Dados de distribuição alterados",
            icon: "success",
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            window.location.reload(false);
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar a distribuição: " + error,
            icon: "error",
          });
        }
      )
    );

    setisInEditMode(false);
  }

  useEffect(() => {
    if (empreendimentoSelecionado) {
      buscaEquipes();
    }
  }, [empreendimentoSelecionado]);

  useEffect(() => {
    if (equipePreAtendimentoSelecionada) {
      configuraGerentes("pre", equipePreAtendimentoSelecionada);
    }
  }, [equipePreAtendimentoSelecionada, equipesPreAtendimento]);

  useEffect(() => {
    if (equipePdvSelecionada) {
      configuraGerentes("pdv", equipePdvSelecionada);
    }
  }, [equipePdvSelecionada, equipesPdv]);

  useEffect(() => {
    if (gerentePreAtendimentoSelecionado) {
      configuraOperadores("pre", gerentePreAtendimentoSelecionado);
    }
  }, [
    gerentePreAtendimentoSelecionado,
    equipesPreAtendimento,
    equipePreAtendimentoSelecionada,
    gestoresPreAtendimento,
  ]);

  useEffect(() => {
    if (gerentePdvSelecionado) {
      configuraOperadores("pdv", gerentePdvSelecionado);
    }
  }, [gerentePdvSelecionado, equipesPdv, equipePdvSelecionada, gerentesPdv]);

  //todo: algo tem que ser feito aqui, o isFresh tem que ser alterado para false logo após as variaveis serem carregadas e exibidas, porém, isso pode levar algum tempo.
  setTimeout(() => {
    setIsFreshLoading(false);
  }, 3000);

  useEffect(() => {
    setMostrarBtnSalvar(rest.mostrarBtnSalvar);
    trackPromise(
      empreendimentoService.buscarEmpreendimentosPorUsuario().then(
        (response) => {
          let listaEmpreendimentos = [];
          response.data.forEach((empreendimento) => {
            if(Number(empreendimento.ativo) == 1) {
              listaEmpreendimentos[empreendimento.id] = {
                id: Number(empreendimento.id),
                nome: empreendimento.nome,
              };
            }
          });
          const ordenados = listaEmpreendimentos.sort(function (a, b) {
            if ((a.nome && a.nome.toLowerCase()) < (b.nome && b.nome.toLowerCase())) return -1;
            if ((a.nome && a.nome.toLowerCase()) >  (b.nome && b.nome.toLowerCase())) return 1;
            return 0;
          });
          setEmpreendimentos(ordenados);
        },
        (error) => {
          errorToast.fire({
            text:
              "Ocorreu um erro ao buscar as dependências para cadastro: " +
              error,
          });
        }
      ).catch(error => {
        errorToast.fire({
          text:
            "Ocorreu um erro ao buscar as dependências para cadastro: " +
            error,
        });
      })
    );
  }, []);

  useEffect(()=>{
    const dados = rest?.dadosDefaulDistribuicao;
    if(dados){
      setEmpreendimentoSelecionado(dados?.id_empreendimento);
      setEquipePreAtendimentoSelecionada(dados?.id_equipe_pre_atendimento);
      setGerentePreAtendimentoSelecionado(dados?.id_gerente_pre_atendimento);
      setUsuarioPreAtendimentoSelecionado(dados?.id_usuario_pre_atendimento);
      setEquipePdvSelecionada(dados?.id_equipe_pdv);
      setGerentePdvSelecionado(dados?.id_gerente_pdv);
      setUsuarioPdvSelecionado(dados?.id_usuario_atendimento);
    }
  },[rest.dadosDefaulDistribuicao])

  useEffect(()=>{
    if(rest.empreendimentoSelecionado){
      setEmpreendimentoSelecionado(rest.empreendimentoSelecionado)
    }
  },[rest.empreendimentoSelecionado])

  function buscaEquipesPrimeiro(empreendimento) {
    if(!empreendimento){
      setEquipePreAtendimentoSelecionada("");
      setEquipePdvSelecionada("");
      setGerentePreAtendimentoSelecionado("");
      setUsuarioPreAtendimentoSelecionado("");
      setGerentePdvSelecionado("");
      setUsuarioPdvSelecionado("");
    }else{
      trackPromise(
        equipeService
          .buscaMembrosEquipes(empreendimentoSelecionado)
          .then((response) => {
            if (response.data !== "") {
              let equipesPdvList = [];
              let equipesPreAtendimentoList = [];
              Object.values(response.data).forEach((equipe) => {
                if (equipe.tipo_equipe == "P") {
                  equipesPdvList.push(equipe);
                } else {
                  equipesPreAtendimentoList.push(equipe);
                }
              });
              setEquipesPreAtendimento(equipesPreAtendimentoList);
              setEquipesPdv(equipesPdvList);
              equipesPdvList.map((item) => {
                setEquipePdvSelecionada(Number(item.id_equipe[0]));
                setGerentePdvSelecionado(Number(item.gerentes[0].id_usuario));
              });
            }
          })
      );
    }
  }

  function buscaEquipes() {
    trackPromise(
      equipeService
        .buscaMembrosEquipes(empreendimentoSelecionado)
        .then((response) => {
          if (response.data !== "") {
            let equipesPdvList = [];
            let equipesPreAtendimentoList = [];
            Object.values(response.data).forEach((equipe) => {
              if (equipe.tipo_equipe == "P") {
                equipesPdvList.push(equipe);
              } else {
                equipesPreAtendimentoList.push(equipe);
              }
            });
            setEquipesPreAtendimento(equipesPreAtendimentoList);
            setEquipesPdv(equipesPdvList);
          }
        })
    );
  }

  function configuraGerentes(tipo, idequipe) {
    let gerentesPdvList = [];
    let gestoresPreAtendimentoList = [];
    if (tipo == "pdv") {
      setEquipePdvSelecionada(idequipe);
      equipesPdv.forEach((equipe) => {
        if (Number(equipe.id_equipe) === Number(idequipe)) {
          equipe.gerentes.forEach((gerente) => {
            const item = {
              id_gerente: Number(gerente.id_usuario),
              nome_gerente: gerente.nome,
              ativo: gerente.ativo,
              ativo_equipe: gerente.ativo_equipe,
              corretores: gerente.corretores,
            };
            gerentesPdvList.push(item);
          });
        }
      });
      setGerentesPdv(gerentesPdvList);
      if(!gerentesPdvList?.length){
        setGerentePdvSelecionado(rest.dadosDefaulDistribuicao?.id_gerente_pdv ?? "");
        setUsuarioPdvSelecionado(rest.dadosDefaulDistribuicao?.id_usuario_atendimento ?? "");
      }
    } else if (tipo == "pre") {
      setEquipePreAtendimentoSelecionada(idequipe);
      equipesPreAtendimento.forEach((equipe) => {
        if (Number(equipe.id_equipe) === Number(idequipe)) {
          equipe.gerentes.forEach((gerente) => {
            const item = {
              id_gerente: Number(gerente.id_usuario),
              nome_gerente: gerente.nome,
              corretores: gerente.corretores,
              ativo: gerente.ativo,
              ativo_equipe: gerente.ativo_equipe
            };
            gestoresPreAtendimentoList.push(item);
          });
        }
      });
      setGestoresPreAtendimento(gestoresPreAtendimentoList);
      if(!gestoresPreAtendimentoList?.length){
        setGerentePreAtendimentoSelecionado(rest.dadosDefaulDistribuicao?.id_gerente_pre_atendimento ?? "");
        setUsuarioPreAtendimentoSelecionado(rest.dadosDefaulDistribuicao?.id_usuario_pre_atendimento ?? "");
      }
    }
  }
  
  function configuraOperadores(tipo, idgerente) {
    let atendentesPreAtendimentoList = [];
    let corretoresPdvList = [];
    if (tipo == "pdv") {
      setGerentePdvSelecionado(idgerente);
      setUsuarioPdvSelecionado("");
      gerentesPdv.forEach((gerente) => {
        if (Number(gerente.id_gerente) === Number(idgerente)) {
          gerente.corretores.forEach((operador) => {
            const item = {
              id_operador: Number(operador.id_usuario),
              nome_operador: operador.nome,
              ativo_equipe: operador.ativo_equipe,
            };
            corretoresPdvList.push(item);
          });
        }
      });
      setUsuariosPdv(corretoresPdvList);
    } else if (tipo == "pre") {
      setGerentePreAtendimentoSelecionado(idgerente);
      setUsuarioPreAtendimentoSelecionado("");
      gestoresPreAtendimento.forEach((gerente) => {
        if (Number(gerente.id_gerente) === Number(idgerente)) {
          gerente.corretores.forEach((operador) => {
            const item = {
              id_operador: Number(operador.id_usuario),
              nome_operador: operador.nome,
              ativo_equipe: operador.ativo_equipe,
            };
            atendentesPreAtendimentoList.push(item);
          });
        }
      });
      setUsuariosPreAtendimento(atendentesPreAtendimentoList);
    }
  }

  function changeEditMode(e) {
    e.preventDefault();
    setisInEditMode(!isInEditMode);
  }

  function changeEditEquipe(e) {
    e.preventDefault();
    setisEquipeEdit(!isEquipeEdit);
    setisGestorEdit(!isGestorEdit);
    setisAtendenteEdit(!isAtendenteEdit);
  }

  function changeEditEquipePDV(e) {
    e.preventDefault();
    setisEquipePdvEdit(!isEquipePdvEdit);
    setisGerenteEdit(!isGerenteEdit);
    setisCorretorEdit(!isCorretorEdit);
  }

  const linhaPreSelecionada = rest?.dadosDefaulDistribuicao?.id_equipe_pre_atendimento && rest?.dadosDefaulDistribuicao?.id_gerente_pre_atendimento && rest?.dadosDefaulDistribuicao?.id_usuario_pre_atendimento;
  const linhaPdvSelecionada = rest?.dadosDefaulDistribuicao?.id_equipe_pdv && rest?.dadosDefaulDistribuicao?.id_gerente_pdv && rest?.dadosDefaulDistribuicao?.id_usuario_atendimento;

  return (
    <>
      {oportunidade == null ? (
        <div className={`distribuicaoInterna ${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && (linhaPreSelecionada || linhaPdvSelecionada) ? 'campo-travados-oculto' : ""}`}>
          <DommusToggle title="Conversão do Lead" toggle={true}>
            <Row>
              <Form.Group as={Col} controlId="formNome" className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_empreendimento ? 'campo-travados-oculto' : ""}`}>
                <Form.Label>
                  <div className="divEdit">
                    <div>
                      Empreendimento: <DommusRequiredSpan />
                    </div>
                  </div>
                </Form.Label>

                <Form.Control
                  as="select"
                  custom
                  onChange={(event) => {
                    setEmpreendimentoSelecionado(event.target.value);
                    setEmpreendimentoAlterado(event.target.value);
                    buscaEquipesPrimeiro();
                  }}
                  value={empreendimentoSelecionado}
                  disabled={rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_empreendimento}
                >
                  <option value="">Selecione o empreendimento</option>
                  {empreendimentos.map((empreendimento) => (
                    <option key={empreendimento.id} value={empreendimento.id}>
                      {empreendimento.nome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {rest.origem}
            </Row>
            {tipoUsuario != 8 ? (
              <Row>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 12 : ""}
                  controlId="formNome"
                  className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_equipe_pre_atendimento) ? 'campo-travados-oculto' : ""}`}
                >
                  <Form.Label>
                    <div className="divEdit">
                      Equipe Pré Atendimento:{" "}
                      {tipoUsuario == 25 || tipoUsuario == 26 ? (
                        <DommusRequiredSpan />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form.Label>

                  <Form.Control
                    as="select"
                    className={`form-control`}
                    value={equipePreAtendimentoSelecionada}
                    custom
                    onChange={(event) =>
                      configuraGerentes("pre", event.target.value)
                    }
                    disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_equipe_pre_atendimento)}
                  >
                    <option value="">Distribuir...</option>
                    {equipesPreAtendimento.map((equipe) => (
                      <option key={equipe.id_equipe} value={equipe.id_equipe}>
                        {equipe.nome_equipe}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 6 : ""}
                  controlId="formNome"
                  className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_gerente_pre_atendimento ? 'campo-travados-oculto' : ""}`}
                >
                  <Form.Label>
                    Gestor Pré Atendimento:{" "}
                    {tipoUsuario == 25 || tipoUsuario == 26 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control"
                    value={gerentePreAtendimentoSelecionado}
                    custom
                    onChange={(event) =>
                      configuraOperadores("pre", event.target.value)
                    }
                    disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_gerente_pre_atendimento)}
                  >
                    <option value="">Distribuir...</option>
                    {gestoresPreAtendimento.map((gestor) =>
                        <option
                          key={gestor.id_gerente}
                          value={gestor.id_gerente}
                          disabled={gestor.ativo && gestor.ativo_equipe == "S" ? false : true}
                          className={gestor.ativo &&  gestor.ativo_equipe == "S" ? "" :  "pointerInativo"}
                        >
                          {gestor.nome_gerente}
                        </option>

                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 6 : ""}
                  controlId="formNome"
                  className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_usuario_pre_atendimento ? 'campo-travados-oculto' : ""}`}
                >
                  <Form.Label>
                    Atendente:{" "}
                    {tipoUsuario == 25 || tipoUsuario == 26 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control"
                    value={usuarioPreAtendimentoSelecionado}
                    custom
                    onChange={(event) =>
                      setUsuarioPreAtendimentoSelecionado(event.target.value)
                    }
                    disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_usuario_pre_atendimento)}
                  >
                    <option value="">Distribuir...</option>
                    {usuariosPreAtendimento.map((usuario) =>
                      usuario.ativo_equipe == "S" ? (
                        <option
                          key={usuario.id_operador}
                          value={usuario.id_operador}
                        >
                          {usuario.nome_operador}
                        </option>
                      ) : (
                        <option
                          style={{ cursor: "no-drop" }}
                          className="pointerInativo"
                          disabled
                          title="Usuário Inativo"
                          key={usuario.id_operador}
                          value={usuario.id_operador}
                        >
                          {usuario.nome_operador}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
            ) : (
              <></>
            )}

            <Row className={`lastRow`}>
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 12 : ""}
                controlId="formNome"
                className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_equipe_pdv ? 'campo-travados-oculto' : ""}`}
              >
                <Form.Label>
                  <div className="divEdit">
                    Equipe PDV:{" "}
                    {tipoUsuario == 7 || tipoUsuario == 8 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Label>
                <Form.Control
                  as="select"
                  className="form-control"
                  custom
                  onChange={(event) =>
                    configuraGerentes("pdv", event.target.value)
                  }
                  value={equipePdvSelecionada}
                  disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_equipe_pdv)}
                >
                  <option value="">Distribuir...</option>
                  {equipesPdv.map((equipe) => (
                    <option key={equipe.id_equipe} value={equipe.id_equipe}>
                      {equipe.nome_equipe}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 6 : ""}
                controlId="formNome"
                className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_gerente_pdv ? 'campo-travados-oculto' : ""}`}
              >
                <Form.Label>
                  Gerente PDV:{" "}
                  {tipoUsuario == 7 || tipoUsuario == 8 ? (
                    <DommusRequiredSpan />
                  ) : (
                    <></>
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  className="form-control"
                  custom
                  onChange={(event) =>
                    configuraOperadores("pdv", event.target.value)
                  }
                  value={gerentePdvSelecionado}
                  disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_gerente_pdv)}
                >
                  <option value="">Distribuir...</option>
                  {gerentesPdv.map((gestor) =>
                    <option
                    key={gestor.id_gerente}
                    value={gestor.id_gerente}
                    disabled={gestor.ativo &&  gestor.ativo_equipe == "S" ? false : true}
                    className={gestor.ativo && gestor.ativo_equipe == "S" ? "" :  "pointerInativo"}
                        >
                        {gestor.nome_gerente}
                      </option>

                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 6 : ""}
                controlId="formNome"
                className={`${rest?.camposTravadosOcultos && rest?.travarCamposCadastro && rest?.dadosDefaulDistribuicao?.id_usuario_atendimento ? 'campo-travados-oculto' : ""}`}
              >
                <Form.Label>
                  Corretor:{" "}
                  {tipoUsuario == 7 || tipoUsuario == 8 ? (
                    <DommusRequiredSpan />
                  ) : (
                    <></>
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  className="form-control"
                  custom
                  onChange={(event) =>
                    setUsuarioPdvSelecionado(event.target.value)
                  }
                  value={usuarioPdvSelecionado}
                  disabled={rest?.travarCamposCadastro && (rest?.dadosDefaulDistribuicao?.id_usuario_atendimento)}
                >
                  <option value="">Distribuir...</option>
                  {usuariosPdv.map((usuario) =>
                    usuario.ativo_equipe == "S" ? (
                      <option
                        key={usuario.id_operador}
                        value={usuario.id_operador}
                      >
                        {usuario.nome_operador}
                      </option>
                    ) : (
                      <option
                        style={{ cursor: "no-drop" }}
                        className="pointerInativo"
                        disabled
                        title="Usuário Inativo"
                        key={usuario.id_operador}
                        value={usuario.id_operador}
                      >
                        {usuario.nome_operador}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>
            </Row>
            {mostrarBtnSalvar ? (
              <Row className="formFooter mt-2">
                <Col>
                  <button
                    type="button"
                    onClick={() => salvarOportunidade()}
                    className="btn btn-success"
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ marginRight: "15px" }}
                    />
                    Salvar Conversão
                  </button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </DommusToggle>
        </div>
      ) : (
        <div className="distribuicaoInterna">
          <DommusToggle title="Conversão do Lead" toggle={true}>
            <Row>
              <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                  <div className="divEdit">
                    <div>
                      Empreendimento: <DommusRequiredSpan />
                    </div>
                    <div
                      className="dataTipEdit"
                      onClick={(e) => changeEditMode(e)}
                      data-tip="Editar"
                    >
                      <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                    </div>
                  </div>
                </Form.Label>
                {isInEditMode ? (
                  <Form.Control
                    as="select"
                    custom
                    onChange={(event) => {
                      setEmpreendimentoSelecionado(event.target.value);
                      setEmpreendimentoAlterado(event.target.value);
                      buscaEquipesPrimeiro();
                    }}
                    value={empreendimentoSelecionado}
                  >
                    <option value="">Selecione o empreendimento</option>
                    {empreendimentos.map((empreendimento) => (
                      <option key={empreendimento.id} value={empreendimento.id}>
                        {empreendimento.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  empreendimentos.map((empreendimento, index) =>
                    empreendimento.id == empreendimentoSelecionado ? (
                      <p className="font-weight-bold psize" key={index}>
                        {empreendimento.nome}
                      </p>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Form.Group>

              {rest.origem}
            </Row>
            {tipoUsuario != 8 ? (
              <Row>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 12 : ""}
                  controlId="formNome"
                >
                  <Form.Label>
                    <div className="divEdit">
                      Equipe Pré Atendimento:{" "}
                      {tipoUsuario == 25 || tipoUsuario == 26 ? (
                        <DommusRequiredSpan />
                      ) : (
                        <></>
                      )}
                      <div
                        className="dataTipEdit"
                        onClick={(e) => changeEditEquipe(e)}
                        data-tip="Editar"
                      >
                        <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                      </div>
                    </div>
                  </Form.Label>
                  {isEquipeEdit ? (
                    <Form.Control
                      as="select"
                      className="form-control"
                      value={equipePreAtendimentoSelecionada}
                      custom
                      onChange={(event) =>
                        configuraGerentes("pre", event.target.value)
                      }
                    >
                      <option value="">Distribuir...</option>
                      {equipesPreAtendimento.map((equipe) => (
                        <option key={equipe.id_equipe} value={equipe.id_equipe}>
                          {equipe.nome_equipe}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    equipesPreAtendimento.map((equipe, index) =>
                      equipe.id_equipe == equipePreAtendimentoSelecionada ? (
                        <p className="font-weight-bold psize" key={index}>
                          {equipe.nome_equipe}
                        </p>
                      ) : (
                        <></>
                      )
                    )
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 6 : ""}
                  controlId="formNome"
                >
                  <Form.Label>
                    Gestor Pré Atendimento:{" "}
                    {tipoUsuario == 25 || tipoUsuario == 26 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  {isGestorEdit ? (
                    <Form.Control
                      as="select"
                      className="form-control"
                      value={gerentePreAtendimentoSelecionado}
                      custom
                      onChange={(event) =>
                        configuraOperadores("pre", event.target.value)
                      }
                    >
                      <option value="">Distribuir...</option>
                      {gestoresPreAtendimento.map((gestor) =>

                          <option
                          key={gestor.id_gerente}
                          value={gestor.id_gerente}
                          disabled={gestor.ativo && gestor.ativo_equipe == "S" ? false : true}
                          className={gestor.ativo && gestor.ativo_equipe == "S" ? "" :  "pointerInativo"}
                              >
                              {gestor.nome_gerente}
                            </option>
                      )}
                    </Form.Control>
                  ) : (
                    gestoresPreAtendimento.map((gestor, index) =>
                      gestor.id_gerente == gerentePreAtendimentoSelecionado ? (
                        <p className="font-weight-bold psize" key={index}>
                          {gestor.nome_gerente}
                        </p>
                      ) : (
                        <></>
                      )
                    )
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={mostrarBtnSalvar ? 6 : ""}
                  controlId="formNome"
                >
                  <Form.Label>
                    Atendente:{" "}
                    {tipoUsuario == 25 || tipoUsuario == 26 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  {isAtendenteEdit ? (
                    <Form.Control
                      as="select"
                      className="form-control"
                      value={usuarioPreAtendimentoSelecionado}
                      custom
                      onChange={(event) =>
                        setUsuarioPreAtendimentoSelecionado(event.target.value)
                      }
                    >
                      <option value="">Distribuir...</option>
                      {usuariosPreAtendimento.map((usuario) =>
                        usuario.ativo_equipe == "S" ? (
                          <option
                            key={usuario.id_operador}
                            value={usuario.id_operador}
                          >
                            {usuario.nome_operador}
                          </option>
                        ) : (
                          <option
                            style={{ cursor: "no-drop" }}
                            className="pointerInativo"
                            disabled
                            title="Usuário Inativo"
                            key={usuario.id_operador}
                            value={usuario.id_operador}
                          >
                            {usuario.nome_operador}
                          </option>
                        )
                      )}
                    </Form.Control>
                  ) : (
                    usuariosPreAtendimento.map((gestor, index) =>
                      gestor.id_operador == usuarioPreAtendimentoSelecionado ? (
                        <p className="font-weight-bold psize" key={index}>
                          {gestor.nome_operador}
                        </p>
                      ) : (
                        <></>
                      )
                    )
                  )}
                </Form.Group>
              </Row>
            ) : (
              <></>
            )}

            <Row className="lastRow">
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 12 : ""}
                controlId="formNome"
              >
                <Form.Label>
                  <div className="divEdit">
                    Equipe PDV:{" "}
                    {tipoUsuario == 7 || tipoUsuario == 8 ? (
                      <DommusRequiredSpan />
                    ) : (
                      <></>
                    )}
                    <div
                      className="dataTipEdit"
                      onClick={(e) => changeEditEquipePDV(e)}
                      data-tip="Editar"
                    >
                      <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                    </div>
                  </div>
                </Form.Label>
                {isEquipePdvEdit ? (
                  <Form.Control
                    as="select"
                    className="form-control"
                    custom
                    onChange={(event) =>
                      configuraGerentes("pdv", event.target.value)
                    }
                    value={equipePdvSelecionada}
                  >
                    <option value="">Distribuir...</option>
                    {equipesPdv.map((equipe) => (
                      <option key={equipe.id_equipe} value={equipe.id_equipe}>
                        {equipe.nome_equipe}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  equipesPdv.map((equipe, index) =>
                    equipe.id_equipe == equipePdvSelecionada ? (
                      <p className="font-weight-bold psize" key={index}>
                        {equipe.nome_equipe}
                      </p>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 6 : ""}
                controlId="formNome"
              >
                <Form.Label>
                  Gerente PDV:{" "}
                  {tipoUsuario == 7 || tipoUsuario == 8 ? (
                    <DommusRequiredSpan />
                  ) : (
                    <></>
                  )}
                </Form.Label>
                {isGerenteEdit ? (
                  <Form.Control
                    as="select"
                    className="form-control"
                    custom
                    onChange={(event) =>
                      configuraOperadores("pdv", event.target.value)
                    }
                    value={gerentePdvSelecionado}
                  >
                    <option value="">Distribuir...</option>
                    {gerentesPdv.map((gestor) =>
                     <option
                     key={gestor.id_gerente}
                     value={gestor.id_gerente}
                     disabled={gestor.ativo && gestor.ativo_equipe == "S" ? false : true}
                     className={gestor.ativo &&  gestor.ativo_equipe == "S" ? "" :  "pointerInativo"}
                   >
                     {gestor.nome_gerente}
                   </option>
                    )}
                  </Form.Control>
                ) : (
                  gerentesPdv.map((gerente, index) =>
                    gerente.id_gerente == gerentePdvSelecionado ? (
                      <p className="font-weight-bold psize" key={index}>
                        {gerente.nome_gerente}
                      </p>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                md={mostrarBtnSalvar ? 6 : ""}
                controlId="formNome"
              >
                <Form.Label>
                  Corretor:{" "}
                  {tipoUsuario == 7 || tipoUsuario == 8 ? (
                    <DommusRequiredSpan />
                  ) : (
                    <></>
                  )}
                </Form.Label>
                {isCorretorEdit ? (
                  <Form.Control
                    as="select"
                    className="form-control"
                    custom
                    onChange={(event) =>
                      setUsuarioPdvSelecionado(event.target.value)
                    }
                    value={usuarioPdvSelecionado}
                  >
                    <option value="">Distribuir...</option>
                    {usuariosPdv.map((usuario) =>
                      usuario.ativo_equipe == "S" ? (
                        <option
                          key={usuario.id_operador}
                          value={usuario.id_operador}
                        >
                          {usuario.nome_operador}
                        </option>
                      ) : (
                        <option
                          style={{ cursor: "no-drop" }}
                          className="pointerInativo"
                          disabled
                          title="Usuário Inativo"
                          key={usuario.id_operador}
                          value={usuario.id_operador}
                        >
                          {usuario.nome_operador}
                        </option>
                      )
                    )}
                  </Form.Control>
                ) : (
                  usuariosPdv.map((usuario, index) =>
                    usuario.id_operador == usuarioPdvSelecionado ? (
                      <p className="font-weight-bold psize" key={index}>
                        {usuario.nome_operador}
                      </p>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Form.Group>
            </Row>
            {mostrarBtnSalvar ? (
              <Row className="formFooter mt-2">
                <Col>
                  <button
                    type="button"
                    onClick={() => salvarOportunidade()}
                    className="btn btn-success"
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ marginRight: "15px" }}
                    />
                    Salvar Conversão
                  </button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </DommusToggle>
        </div>
      )}
    </>
  );
}
