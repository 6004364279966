import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";

interface DommusAlertProps {
    variant?: string
    icone?: IconDefinition
    className?: string
    mensagem: string
} 

export function DommusAlert({variant="warning", icone=faExclamationTriangle, className="", mensagem}: DommusAlertProps) {
    return (
        <Alert variant={variant} className={className}>
            <FontAwesomeIcon icon={icone} /> {mensagem}
        </Alert>
    )
}