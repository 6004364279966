import React, { useEffect, useState, useContext } from "react";
import {
  faClock,
  faPlus,
  faEllipsisV,
  faEdit,
  faTrashAlt,
  faLink,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../components/DommusToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../DetalheOportunidade/Atendimento/index.css";
import { Row, Container } from "react-bootstrap";
import DommusToggle from "../../../components/DommusToggle";
import ModalDommus from "../../../components/Modal";
import NovoAtendimento from "../Atendimento/NovoAtendimento";
import formatDommus from "../../../helpers/format";
import { Dropdown } from "react-bootstrap";
import OportunidadeService from "../../../services/OportunidadeService";
import { parseISO, format } from "date-fns";
import DommusCheckboxToggle from "../../../components/DommusCheckboxToggle";
import ReactTooltip from "react-tooltip";
import { MotivoVisita } from "./MotivoVisita";
import { DateHelper } from "../../../helpers/DateHelper";
import DommusSwitch from "../../../components/DommusSwitch";
import guService from "../../../services/GuService";
import { EmpreendimentoContext } from "../../../components/GlobalContext/globalContext";
import { celularMask, formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../components/InputTextMask";
import moment from "moment";

export default function Atendimento({ history, oportunidade, dados, setTabIndex, abrirModalNovo = false, atualizarOportunidade,atualizarEnvolvidos,setAbriModalNovo=null}) {
  const [openNovoAtendimento, setOpenNovoAtendimento] = useState(false);
  const [openModalConfirmacaoVisita, setOpenModalConfirmacaoVisita] = useState(false);
  const [atendimentos, setAtendimentos] = useState(Object.keys(dados).length === 0 ? [] : dados);
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState({});
  const [visitaRealizada, setVisitaRealizada] = useState(false);
  const [tituloConfirmacaoVisita, setTituloConfirmacaoVisita] = useState("");
  const [indexAtendimento, setIndexAtendimento] = useState(null)
  const permissaoMarcarConcluido = guService.getLocalState('permissaoMarcarConcluido');
  const permissaoMarcarConcluidoTarefa = guService.getLocalState('permissaoMarcarConcluidoTarefa');
  const { resolverNomeOperador, resolverNomeGerente, resolverNomeEquipe } = useContext(EmpreendimentoContext);

  function inserirLista(atendimento) {
    const listaAtendimentos = atendimentos.slice();
    listaAtendimentos.unshift(atendimento);
    setAtendimentos(listaAtendimentos);
    setAtendimentoSelecionado({});
  }

  function atualizaLista(index, atendimento) {
    const listaAtendimentos = atendimentos.slice();
    listaAtendimentos[index] = atendimento;
    setAtendimentos(listaAtendimentos);
    setAtendimentoSelecionado({});
  }


  //atualiza os atendimentos (necessário para carregamento de aba por link)
  useEffect(() => {
    setAtendimentos(dados);
  }, [dados]);

  useEffect(() => {
    setOpenNovoAtendimento(abrirModalNovo)
    if(setAbriModalNovo){
      setAbriModalNovo(false);
    }
  },[]);

  function deleteAtendimento(id) {
    Swal.fire({
      titleText:
        "Deseja excluir o atendimento " +
        "#" +
        formatDommus.zeroPad(id, 4) +
        "?",
      text:
        "Tem certeza que deseja excluir o atendimento " +
        "#" +
        formatDommus.zeroPad(id, 4) +
        "? Os dados desta não serão mais visíveis no sistema.",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não, mantenha.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        trackPromise(
          OportunidadeService.excluirAtendimento(id).then(
            (response) => {
              const listaAtendimentos = atendimentos.slice();
              var index = atendimentos.findIndex(
                (x) => x.id_oportunidade_atendimento === id
              );
              listaAtendimentos.splice(index, 1);
              setAtendimentos(listaAtendimentos);
              atualizarOportunidade((current => !current));
            },
            (error) => {
              errorToast.fire({
                text: "Ocorreu um erro ao excluir o atendimento: " + error,
              });
            }
          )
        );
      }
    });
  }

  function editAtendimento(item, index) {
    setIndexAtendimento(index);
    setAtendimentoSelecionado(item);
    setOpenNovoAtendimento(true);
  }

  function abrirModalAtendimento() {
    setIndexAtendimento(null);
    setAtendimentoSelecionado({});
    setOpenNovoAtendimento(true);
  }

  function changeStatus(id_oportunidade_atendimento, status, dadosVisita=false) {
    const arrayAtendimentos = [...atendimentos];
    const atendimentoIndex = arrayAtendimentos.findIndex(
      (item) => id_oportunidade_atendimento == item.id_oportunidade_atendimento
    );

    trackPromise(
      OportunidadeService.alterarStatusAtendimento(
        id_oportunidade_atendimento,
        status
      )
        .then((res) => {
          if (res.status == 202) {
            arrayAtendimentos[atendimentoIndex].tarefa_realizada = res.data.tarefa_realizada;
            if(dadosVisita) {
              arrayAtendimentos[atendimentoIndex].visita = {
                visita_realizada: dadosVisita.visita_realizada,
                descricao: dadosVisita.descricao,
                id_tipo_oportunidade_atendimento_visita: dadosVisita.id_tipo_oportunidade_atendimento_visita
              }
            }
            if(res.data.status_oportunidade.id_status_oportunidade !== oportunidade.status?.id_status_oportunidade && (status == true))
            {
              Swal.fire({
                title: 'Alteração de Etapa',
                html: `A OPV foi atualizada para a etapa <strong>${res.data?.status_oportunidade?.nome}</strong>`,
                icon: 'success',
                showCloseButton: false,
                focusConfirm: true,
              }).then((result) => {
                Swal.fire({
                  titleText: "Deseja abrir um novo atendimento para esta oportunidade?",
                  icon: "question",
                  showCloseButton: false,
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonText: "Sim, abrir!",
                  cancelButtonText: "Não, obrigado.",
                  customClass: {
                      confirmButton: "btn btn-primary mr-3",
                      cancelButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                  }).then((result) => {
                      if (result.isConfirmed) {
                          openModalNewAtendimento(oportunidade.id_oportunidade);
                  }})
              })
              atualizarOportunidade((current)=>!current);
            }
            setAtendimentoSelecionado(res.data);
          } else if (res.status == 200) {
            errorToast.fire({ text: res.data });
          }
        })
        .catch((error) => {
          errorToast.fire({
            text: "Ocorreu um erro ao alterar o status o atendimento: " + error,
          });
        })
    );

    setAtendimentos(arrayAtendimentos);
  }

  function checkVisitaRealizada(status, atendimento, novoStatus) {
    if( status && atendimento.id_tipo_atendimento === 4 ) {
      Swal.fire({
        title: 'A Visita foi Realizada?',
        icon: 'question',
        showCloseButton: true,
        showDenyButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Sim ',
        denyButtonText: 'Não ',
        customClass: {
            confirmButton: 'btn btn-primary mr-3',
            denyButton: 'btn btn-danger'
        },
        buttonsStyling: false
      }).then((result) => {
        setAtendimentoSelecionado(atendimento)
        if ( result.isConfirmed ) {
          setTituloConfirmacaoVisita("Descrição")
          setVisitaRealizada(true)
          setOpenModalConfirmacaoVisita(true)
        } else if(result.isDenied){
          setVisitaRealizada(false)
          setTituloConfirmacaoVisita("Conte o motivo da visita não ter sido realizada ");
          setOpenModalConfirmacaoVisita(true)
        }
      })
    } else if(atendimento.id_tipo_atendimento == 4) {
      Swal.fire({
        title: 'Você deseja realmente cancelar a confirmação deste agendamento?',
        text: 'Os dados de confirmação informados anteriormente serão perdidos.',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Sim ',
        cancelButtonText: 'Não ',
        customClass: {
            confirmButton: 'btn btn-primary mr-3',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      }).then(result => {
        setAtendimentoSelecionado(atendimento)
        if(result.isConfirmed) {
          changeStatus(atendimento.id_oportunidade_atendimento, novoStatus)
        }
      })
      return
    } else {
      setAtendimentoSelecionado(atendimento)
      changeStatus(atendimento.id_oportunidade_atendimento, novoStatus)
    }
  }

  return (
    <Container fluid>
      {
        Array.isArray(atendimentos) ?
        atendimentos.length > 0 ? (
          atendimentos.map((item,index) => (
            <Row key={index}>
              <div className="card" onClick={(e) => {}}>
                <DommusToggle
                  title={
                    "#" +
                      formatDommus.zeroPad(item.id_oportunidade_atendimento, 4) +
                      " - " +
                      item !=
                      null &&
                    item != undefined &&
                    item.tipo_atendimento != null &&
                    item.tipo_atendimento != undefined
                      ? item.tipo_atendimento.nome
                      : ""
                  }
                  toggle={true}
                >
                  <div className="cardTopo cardTopoAtendimento">
                    <div className="conteudoItem">
                      {item.contato ? (
                        <div className="linha">
                          <div className="texto">
                            <strong>Contato: </strong>
                            {item.contato}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item.local ? (
                        <div className="linha">
                          <div className="texto">
                            <strong>Local: </strong>
                            {item.local}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {DateHelper.validarData(item.data) ? (
                        <div className="linha">
                          <div className="texto">
                            {item.id_tipo_atendimento == OportunidadeService.idsTiposAtendimento.FECHAMENTO_DE_PARCERIA ? <strong>Data do Fechamento: </strong> : (
                              <strong>{`Data da ${item.id_tipo_atendimento === 4 ? 'Visita: ' : 'Tarefa: '}`}</strong>
                            )}
                            {DateHelper.validarHora(item.hora)
                              ? format(
                                  parseISO(item.data + " " + item.hora),
                                  "dd/MM/yyyy' às ' HH:mm"
                                )
                              : format(parseISO(item.data), "dd/MM/yyyy")}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.observacao ? (
                        <div className="linha">
                          <div className="texto" style={{ maxWidth: '68rem' }}>
                            <strong>{item.id_tipo_atendimento == OportunidadeService.idsTiposAtendimento.FECHAMENTO_DE_PARCERIA ? 'Considerações:' : 'Observação:'} </strong>
                            {item.observacao}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.anexo ? (
                        <div className="linha">
                          <div className="texto">
                            <strong>Anexo: </strong>
                              <a href={item.anexo.url} target="_blank">{item.anexo.nome_arquivo || 'Anexo'} <FontAwesomeIcon icon={faFilePdf}/></a>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.feedback ? (
                        <div className="linha">
                          <div className="texto">
                            <strong>Feedback: </strong>
                            {item.feedback}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.responsavel_nome ? (
                        <div className="linha">
                          <div className="texto">
                            <strong>Responsável: </strong>
                            {item.responsavel_nome}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.visita && (
                        <fieldset className="caixa-visita rounded">
                          <legend className="titulo-fieldset">{(!item?.tarefa_realizada && item?.visita?.descricao.length) ? "Detalhes da Visita Anterior" : "Detalhes da Visita"}</legend>
                          <div className="linha">
                            <div className="texto">
                              <strong>Visita realizada? </strong>
                              {item.visita.visita_realizada ? 'Sim' : 'Não'}
                            </div>
                          </div>
                          {item.visita?.tipoVisita?.nome && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Motivo da não realização: </strong>
                                {item.visita?.tipoVisita?.nome}
                              </div>
                            </div>
                          )}
                          {item.visita?.descricao && (
                            <div className="linha">
                              <div className="texto">
                                <strong>{item.visita.visita_realizada ? 'Descrição da visita' : 'Descrição da visita não realizada'}: </strong>
                                {item.visita.descricao}
                              </div>
                            </div>
                          )}
                          {item.concluido_em && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Concluído em: </strong>
                                {format(parseISO(item.concluido_em), "dd/MM/yyyy' às 'HH:mm")}
                                {item.concluido_por &&
                                  (nomeResolvido =
                                    (resolverNomeOperador(item.concluido_por, "") ||
                                    resolverNomeGerente(item.concluido_por, ""))
                                  ) && (
                                  <div className="linha">
                                    <div className="texto">
                                      <strong> por  </strong>
                                      {nomeResolvido}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </fieldset>
                      )}
                      {item?.oportunidadeSecundaria && (
                        <fieldset className="caixa-visita rounded">
                          <legend className="titulo-fieldset">Oportunidade Vinculada</legend>
                          <div className="linha">
                            <div className="texto">
                              <strong>
                                <a href={`/oportunidade/${item.oportunidadeSecundaria.id_oportunidade}`} target="_blank">
                                  OPV - {formatDommus.zeroPad(item.oportunidadeSecundaria.id_oportunidade, 5)} <FontAwesomeIcon icon={faLink}/>
                                </a>
                              </strong>
                            </div>
                          </div>
                          {item.oportunidadeSecundaria?.data_venda && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Data da Venda: </strong>
                                {moment(item.oportunidadeSecundaria.data_venda).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          )}
                          {item.oportunidadeSecundaria?.valor_venda && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Valor da Venda: </strong>
                                R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(item.oportunidadeSecundaria.valor_venda)}
                              </div>
                            </div>
                          )}
                          {item.oportunidadeSecundaria?.id_equipe_pdv && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Equipe PDV: </strong>{resolverNomeEquipe(item.oportunidadeSecundaria?.id_equipe_pdv) || 'Não Encontrado'}
                              </div>
                            </div>
                          )}
                          {item.oportunidadeSecundaria?.id_gerente_pdv && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Gerente PDV: </strong>{resolverNomeGerente(item.oportunidadeSecundaria?.id_gerente_pdv) || 'Não Encontrado'}
                              </div>
                            </div>
                          )}
                          {item.oportunidadeSecundaria?.id_usuario_atendimento && (
                            <div className="linha">
                              <div className="texto">
                                <strong>Corretor: </strong>{resolverNomeOperador(item.oportunidadeSecundaria?.id_usuario_atendimento) || 'Não Encontrado'}
                              </div>
                            </div>
                          )}
                        </fieldset>
                      )}
                      {item.participantes && item.participantes.length > 0 && 
                      (
                        item.participantes.map((participante, index)=>{
                        return (
                          <div className="linha" key={index}>
                            <div className="texto">
                              <strong>Participante: </strong>
                              {`${participante.nome} [${participante?.participante_tipo?.descricao}] - ${celularMask(participante?.contato)}`}
                            </div>
                          </div>
                        )
                        })
                      )                      
                      }
                      {item.criado_em && (
                        <div className="linha">
                          <div className="texto">
                            <strong>Criado em: </strong>
                            {format(parseISO(item.criado_em), "dd/MM/yyyy' às 'HH:mm")}
                          </div>
                        </div>
                      )}
                      {(!item.visita) &&item.concluido_em && (
                        <div className="linha">
                          <div className="texto">
                            <strong>Concluído em: </strong>
                            {format(parseISO(item.concluido_em), "dd/MM/yyyy' às 'HH:mm")}
                            {item.concluido_por &&
                              (nomeResolvido =
                                (resolverNomeOperador(item.concluido_por, "") ||
                                resolverNomeGerente(item.concluido_por, ""))
                              ) && (
                              <div className="linha">
                                <div className="texto">
                                  <strong> por  </strong>
                                  {nomeResolvido}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    </div>
                    {JSON.parse(permissaoMarcarConcluido) && (item.id_tipo_atendimento === 4) && oportunidade?.usuario_pode_editar && (
                      <DommusSwitch
                        clickEvent={() => {
                          checkVisitaRealizada(!item.tarefa_realizada, item, !item.tarefa_realizada)
                        }}
                        checked={item.tarefa_realizada ? true : false}
                        tooltip={item.tarefa_realizada ? 'Concluído' : 'Não Concluído'}
                        label=""
                        labelDesativado="Concluída?"
                      />
                    )}
                    {JSON.parse(permissaoMarcarConcluidoTarefa) && (item.id_tipo_atendimento === 5) && oportunidade?.usuario_pode_editar && (
                      <div className="pull-left">
                        <DommusCheckboxToggle
                          value={item.tarefa_realizada || 0}
                          setValue={(novoStatus) => checkVisitaRealizada(novoStatus, item, novoStatus)}
                          label=""
                          tooltip={item.tarefa_realizada ? 'Concluído' : 'Não Concluído'}
                          labelDesativado="Concluída?"
                        />
                      </div>
                    )}
                    {!JSON.parse(permissaoMarcarConcluido) && (item.id_tipo_atendimento === 4 ) && !oportunidade?.usuario_pode_editar && (
                      <div className="pull-left">
                        <DommusSwitch
                          tooltip="Sem Permissão"
                          disabled
                        />
                      </div>
                    )}
                    {!JSON.parse(permissaoMarcarConcluidoTarefa) && (item.id_tipo_atendimento === 5 ) && !oportunidade?.usuario_pode_editar && (
                      <div className="pull-left">
                        <DommusSwitch
                          tooltip="Sem Permissão"
                          disabled
                        />
                      </div>
                    )}
                  </div>
                  <div className="cardBaixo">
                    <div className="data">
                      <FontAwesomeIcon
                          icon={faClock} color="#444444"
                          data-for="dateTipCard"
                      />
                      <ReactTooltip id="dateTipCard" place="top">
                          {DateHelper.validarData(item.data) ? (
                              DateHelper.validarHora(item.hora) ?
                                format(
                                  parseISO(item.data + " " + item.hora),
                                  "dd/MM/yyyy' às ' HH:mm"
                                )
                                : format(parseISO(item.data), "dd/MM/yyyy")
                            ) : (
                              <></>
                            )
                          }
                      </ReactTooltip>
                      {formatDommus.formatDate(item.atualizado_em)}{" "}
                      {item.nome_criado_por != null && item.nome_criado_por.length > 0
                        ? "| Cadastrado por: " + item.nome_criado_por
                        : ""}
                    </div>
                    {oportunidade?.usuario_pode_editar &&
                      <div className="icones">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className={"ellipsis-toggle"}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} color="#444444" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => editAtendimento(item, index)}>
                              <FontAwesomeIcon icon={faEdit} color="#444444" />{" "}
                              Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                deleteAtendimento(item.id_oportunidade_atendimento)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} color="#444444" />{" "}
                              Remover
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }
                  </div>
                </DommusToggle>
              </div>
            </Row>
          ))
        ) : (
          <Alert variant="warning">Não existem atendimentos cadastrados.</Alert>
        ) : <></>
      }
      <ModalDommus
        open={openNovoAtendimento}
        close={setOpenNovoAtendimento.bind(false)}
        content={
          <NovoAtendimento
            close={() => setOpenNovoAtendimento(false)}
            oportunidade={oportunidade?.id_oportunidade}
            atendimento={atendimentoSelecionado}
            inserirLista={inserirLista.bind(this)}
            index={indexAtendimento}
            atualizarEnvolvidos={atualizarEnvolvidos}
            setTabIndex={setTabIndex}
            atualizaLista={atualizaLista}
          />
        }
        titulo={Object.keys(atendimentoSelecionado).length ? "Editar Atendimento" : "Nova Atividade"}
      />
      <ModalDommus open={openModalConfirmacaoVisita}
        size={"md"}
        close={setOpenModalConfirmacaoVisita.bind(false)}
        content={
          <MotivoVisita
            visitaRealizada={visitaRealizada}
            closeModal={() => setOpenModalConfirmacaoVisita(false)}
            id_oportunidade_atendimento={atendimentoSelecionado.id_oportunidade_atendimento}
            changeStatus={changeStatus}
            abrirModalNovoAtendimento={() => setOpenNovoAtendimento(true)}
            setAtendimentoSelecionado={() => setAtendimentoSelecionado({})}
          />
        }
        titulo={tituloConfirmacaoVisita}
      />
      {oportunidade?.usuario_pode_editar &&
        <div
          id="atendimentoMobile"
          className="buttonNovaOportunidade"
          onClick={() => abrirModalAtendimento()}
        >
          <FontAwesomeIcon icon={faPlus} color="#fff" />
        </div>
      }
    </Container>
  );
}
