import React, { createContext, ReactElement, useState } from "react";
import PerguntaService from "../../services/PerguntaService";
import { trackPromise } from "react-promise-tracker";
import { swalErro } from "../../helpers/DommusSwal";
import { PerguntaType, TipoRespostaPerguntaType } from "./types";

interface PerguntaContextProps {
  listaPerguntas: PerguntaType[];
  listaTipoRespostaPerguntas: TipoRespostaPerguntaType[];
  recarregarPerguntas: () => void;
  recarregarTipoRespostaPerguntas: () => void;
  adicionarPergunta: (data: PerguntaType) => void;
  atualizarPergunta: (data: PerguntaType) => void;
  removerPergunta: (idPergunta: number) => void;
}

export const PerguntaContext = createContext({} as PerguntaContextProps );

export function PerguntaProvider({ children }: { children: ReactElement }) {
  const [listaPerguntas, setListaPerguntas] = useState<PerguntaType[]>([]);  
  const [listaTipoRespostaPerguntas, setListaTipoRespostaPerguntas] = useState<TipoRespostaPerguntaType[]>([]);

  
  const recarregarPerguntas = () => {
    trackPromise(PerguntaService.buscar())
      .then((response) => {
        setListaPerguntas(response.data.perguntas);
      })
      .catch((error) => {
        swalErro(
          `Houve um erro ao carregar as perguntas: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
        );
      });
  };

  const recarregarTipoRespostaPerguntas = () => {
    trackPromise(PerguntaService.buscarTipoResposta())
      .then((response) => {
        setListaTipoRespostaPerguntas(response.data);
      })
      .catch((error) => {
        swalErro(
          `Houve um erro ao carregar os tipos de resposta: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
        );
      });
  };

  const adicionarPergunta = (data: PerguntaType) => {
    setListaPerguntas((current) => {
      return [data,...current];
    });
  };

  const atualizarPergunta = (data: PerguntaType) => {
    setListaPerguntas((current) => {
      return current.map((pergunta) => {
        if (pergunta.id_pergunta === data.id_pergunta) {
          return data;
        }
        return pergunta;
      });
    });    
  };

  const removerPergunta = (idPergunta: number) => {
    setListaPerguntas((current) => {
      return current.filter(
        (pergunta) => pergunta.id_pergunta !== idPergunta
      );
    });
  };

  return (
    <PerguntaContext.Provider
      value={{
        listaPerguntas,
        listaTipoRespostaPerguntas,
        recarregarPerguntas,
        recarregarTipoRespostaPerguntas,
        adicionarPergunta,
        atualizarPergunta,
        removerPergunta,
      }}
    >
      {children}
    </PerguntaContext.Provider>
  );
}
