import React, { useState, useEffect, useContext } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Col } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import "../../DetalheOportunidade/Interesse/index.css";
import DommusToggle from "../../../components/DommusToggle";
import OportunidadeService from "../../../services/OportunidadeService";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { apenasNumeros } from "../../../components/InputTextMask";
import guService from "../../../services/GuService";
import CamposDinamicos from "../../../components/CamposDinamicos";
import { EmpreendimentoContext, OportunidadeContext } from "../../../components/GlobalContext/globalContext";
import { GrupoQualificacaoContext } from "../../../contexts/GruposQualificacaoContext/GrupoQualificacaoContext";
import { cachearContexto } from "../../../helpers/cachearContexto";
import { CamposDinamicosContext } from "../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import empreendimentoService from "../../../services/EmpreendimentoService";
import OrigemService from "../../../services/OrigemService";

export default function Interesse({ history, id_empreendimento, dados, oportunidade, atualiza }) {
  const [interesses, setInteresses] = useState(dados || []);
  const contextoEmpreendimentoGlobal = useContext(EmpreendimentoContext)
  const [optionsSelectEmpreendimento, setOptionsSelectEmpreendimentos] = useState([]);
  const [optionsSelectNivelInteresse, setOptionsSelectNivelInteresse] = useState([]);
  const [optionsSelectComoConheceu, setOptionsSelectComoConheceu] = useState([]);
  const [localizacao, setLocalizacao] = useState(interesses && interesses.localizacao ? JSON.parse(interesses.localizacao) : [] );
  const [valorDisponivel, setValorDisponivel] = useState(interesses && interesses.valor_disponivel || 0);
  const [pretensaoSinal, setPretensaoSinal] = useState(interesses && interesses.pretensao_sinal || 0);
  const [rendaFamiliar, setRendaFamiliar] = useState(interesses && interesses.renda_familia || 0);
  const [empreendimento, setEmpreendimento] = useState(interesses && interesses.empreendimento ? JSON.parse(interesses.empreendimento) : [] );
  const [tipologia, setTipologia] = useState(interesses && interesses.tipologia ? JSON.parse(interesses.tipologia) : [] );
  const [informacoes, setInformacoes] = useState(interesses && interesses.informacoes || "");
  const [idOportunidadeInteresse, setIdOportunidadeInteresse] = useState(interesses && interesses.id_oportunidade_interesse);
  const [quem_indicou, setQuemIndicou] = useState((interesses && interesses.quem_indicou) || '' );
  const [nivelInteresseSelecionado, setNivelInteresseSelecionado] = useState(interesses && interesses.id_nivel_interesse || 0);
  const [comoConheceuSelecionado, setComoConheceuSelecionado] = useState(interesses && interesses.id_como_conheceu || 0);
  const [origem, setOrigem] = useState((interesses && interesses.id_origem) || 0);
  const [tipoOrigem, setTipoOrigem] = useState((interesses && interesses?.tipo_origem) || null);
  const [origemPreenchida, setOrigemPreenchinda] = useState((interesses && interesses.id_origem) || 0);
  const [tempoPrevistoParaCompra, setTempoPrevistoParaCompra] = useState(interesses && interesses.tempo_previsto_compra || null);
  const [possuiDependentes, setPossuiDependentes] = useState(interesses && interesses.possui_dependentes || null);
  const [saldoFGTS, setSaldoFGTS] = useState(interesses && interesses.saldo_fgts || "" );
  const tipoPerfil = guService.getLocalState("tipoPerfil");
  const [valueCampos, setValueCampos] = useState(dados?.dados_dinamicos ? (((typeof JSON.parse(dados?.dados_dinamicos) == 'string') ? JSON.parse(JSON.parse(dados?.dados_dinamicos)) : JSON.parse(dados?.dados_dinamicos)) || []) : [])
  const {recarregarGruposQualificacao} = useContext(GrupoQualificacaoContext);
  const { niveisInteresse, setNiveisInteresse, origens, setOrigens } = useContext(OportunidadeContext)
  const { listaComoConheceu, recarregarComoConheceu, recarregarLocalidades, listaLocalidades, listaTipologia, recarregarTipologias } = useContext(CamposDinamicosContext)
  const [optionsSelectUnidadeInteresse, setOptionsSelectUnidadeInteresse] = useState([]);
  const [optionsSelectUnidadeQualificado, setOptionsSelectUnidadeQualificado] = useState([]);
  const { camposTelaQualificacao, setCamposTelaQualificacao } = useContext(CamposDinamicosContext)

  useEffect(() => {
    cachearContexto(niveisInteresse, setNiveisInteresse, OportunidadeService.buscarNivelInteresse)
    cachearContexto(camposTelaQualificacao, setCamposTelaQualificacao, OportunidadeService.buscarCamposDinamicosTelaQualificacao, false, camposTelaQualificacao.total);
    recarregarComoConheceu()
    cachearContexto(contextoEmpreendimentoGlobal.empreendimentosVisiveis, contextoEmpreendimentoGlobal.setEmpreendimentosVisiveis, empreendimentoService.buscarEmpreendimentosVisiveisUsuarioAtual)
    recarregarLocalidades()
    recarregarTipologias()
    cachearContexto(origens, setOrigens, OrigemService.buscarOrigens)
    montaOptionsSelectEmpreendimento()
    recarregarGruposQualificacao();
   }, []);

  useEffect(() => {
    if(niveisInteresse.length) {
      montaOptionsRecurso(niveisInteresse, setOptionsSelectNivelInteresse, 'id_nivel_interesse', 'descricao')
    }
  }, [niveisInteresse])

  useEffect(() => {
    if(listaComoConheceu.length) {
      montaOptionsRecurso(listaComoConheceu, setOptionsSelectComoConheceu, 'id_como_conheceu', 'descricao')
    }
  }, [listaComoConheceu])

  useEffect(() => {
    if (listaLocalidades.length && interesses && interesses.length && interesses[interesses.length-1].localizacao != null) {
      setLocalizacao(JSON.parse(interesses[interesses.length-1].localizacao));
    }
  }, [listaLocalidades])

  useEffect(() => {
    if (listaTipologia.length && interesses && interesses.length && interesses[interesses.length-1].tipologia != null) {
      setTipologia(JSON.parse(interesses[interesses.length-1].tipologia));
    }
  }, [listaTipologia]);

  useEffect(() => {
    montaOptionsSelectEmpreendimento();
  }, [contextoEmpreendimentoGlobal.empreendimentosVisiveis]);

  useEffect(() => {
    const indiceEmpreendimento = camposTelaQualificacao && Array.isArray(camposTelaQualificacao.campos) && camposTelaQualificacao.campos.findIndex((campo) =>campo.label == 'Empreendimento');
    let empreendimentoEntrada = null;
    if(indiceEmpreendimento && (indiceEmpreendimento > -1)){
        empreendimentoEntrada = valueCampos && valueCampos[indiceEmpreendimento + 1];
    }
    const novoOptionsUnidadeQualificado = [];
    let empreendimentoBusca = null;
    if(empreendimentoEntrada){
      if(Array.isArray(empreendimentoEntrada) && empreendimentoEntrada.length){
        empreendimentoBusca = empreendimentoEntrada[0].value;
      }else if((typeof empreendimentoEntrada === 'number') && empreendimentoEntrada){
        empreendimentoBusca = empreendimentoEntrada;
      }else if((typeof empreendimentoEntrada === 'object') && empreendimentoEntrada.value){
        empreendimentoBusca = empreendimentoEntrada.value;
      }
    }

    if(empreendimentoBusca){
      empreendimentoService.buscarUnidadesDisponiveis(empreendimentoBusca).then((response) => {
        if(Array.isArray(response?.data?.data)){
          response.data.data.forEach((unidade) => {
            if(unidade.id && (unidade.disponibilidade == 'D')){
              novoOptionsUnidadeQualificado.push({label:unidade.descricao, value:unidade.id});
            }
          })
        }
        setOptionsSelectUnidadeQualificado(novoOptionsUnidadeQualificado)

      }).catch((error) => console.log(error))

    }else{
      setOptionsSelectUnidadeQualificado(novoOptionsUnidadeQualificado)
    }
  }, [valueCampos, camposTelaQualificacao]);

  useEffect(() => {
    const novoOptionsUnidadeInteresse = [];
    if(oportunidade.id_empreendimento){
      empreendimentoService.buscarUnidadesDisponiveis(oportunidade.id_empreendimento).then((response) => {
        if(Array.isArray(response?.data?.data)){
          response.data.data.forEach((unidade) => {
            if(unidade.id && (unidade.disponibilidade == 'D')){
              novoOptionsUnidadeInteresse.push({label:unidade.descricao, value:unidade.id});
            }
          })
        }
        setOptionsSelectUnidadeInteresse(novoOptionsUnidadeInteresse)

      }).catch((error) => console.log(error))

    }else{
      setOptionsSelectUnidadeInteresse(novoOptionsUnidadeInteresse)
    }
  }, [oportunidade.id_empreendimento]);

  function montaOptionsSelectEmpreendimento() {
    if(interesses && interesses.length &&
      interesses[interesses.length-1].empreendimento != null &&
      JSON.parse(interesses[interesses.length-1].empreendimento)){
        setEmpreendimento(JSON.parse(interesses[interesses.length-1].empreendimento));
      }

    const novasOpcoesSelectEmpreendimento =  (Array.isArray(contextoEmpreendimentoGlobal.empreendimentosVisiveis) && contextoEmpreendimentoGlobal.empreendimentosVisiveis.map(empreendimento => {
      return {
        label: empreendimento.nome,
        value: empreendimento.id
      }
    })) || [];
    setOptionsSelectEmpreendimentos(novasOpcoesSelectEmpreendimento)
  }

  function montaOptionsRecurso(recurso, setState, valor, descricao) {
    const options = recurso.map((item) => {
      return { value: item[valor], label: item[descricao] };
    });
    setState(options);
  }

  function montaOptionsOrigem() {
    let optionsOrigem;
    if (tipoPerfil == "R") {
      optionsOrigem = origens.map((origem) =>
        origem.tipo == "R" ? (
          <option key={origem.id_origem} value={origem.id_origem}>
            {origem.origem}
          </option>
        ) : (
          <></>
        )
      );
    } else {
      optionsOrigem = origens.map((origem) => (
        <option key={origem.id_origem} value={origem.id_origem}>
          {origem.origem}
        </option>
      ));
    }
    return optionsOrigem;
  }

  function salvarInteresse(id) {
    const dados = {
      id_oportunidade: oportunidade.id_oportunidade,
      informacoes: informacoes,
      empreendimento: empreendimento,
      localizacao: localizacao,
      tipologia: tipologia,
      id_como_conheceu:
        comoConheceuSelecionado != 0 ? comoConheceuSelecionado : null,
      id_nivel_interesse:
        nivelInteresseSelecionado != 0 ? nivelInteresseSelecionado : null,
      id_origem: origem != 0 ? origem : null,
      saldo_fgts: saldoFGTS,
      tempo_previsto_compra: tempoPrevistoParaCompra,
      possui_dependentes: possuiDependentes,
      quem_indicou: quem_indicou ?? null,
      dados_dinamicos: valueCampos
    };
    if (idOportunidadeInteresse !== undefined) {
      dados.id_oportunidade_interesse = idOportunidadeInteresse;
    }
    if (pretensaoSinal != null) {
      dados.pretensao_sinal = apenasNumeros(pretensaoSinal);
    }
    if (pretensaoSinal != null) {
      dados.pretensao_sinal = apenasNumeros(pretensaoSinal);
    }
    if (rendaFamiliar != null) {
      dados.renda_familia = apenasNumeros(rendaFamiliar);
    }
    if (valorDisponivel != null) {
      dados.valor_disponivel = valorDisponivel;
    }
    if (saldoFGTS != null) {
      dados.saldo_fgts = apenasNumeros(saldoFGTS);
    }
    trackPromise(
      OportunidadeService.salvarInteresse(dados).then(
        (response) => {
          setIdOportunidadeInteresse(response.data.id_oportunidade_interesse);
          Swal.fire({
            titleText: "Atualizado!",
            text: "Qualificação salva com sucesso.",
            icon: "success",
          }).then(() => {
            atualiza(true);
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o interesse: " + error,
            icon: "error",
          });
        }
      )
    );
  }


  function alterarValorDisponivel(event, value, maskedValue){
    event.preventDefault();
  };

  return (
    <>
      <DommusToggle title="Dados de qualificação do Lead/Prospect" toggle={true}>
        <Form className="formCadastro">
          <CamposDinamicos
          origem={origem}
          origemPreenchida={origemPreenchida}
          setOrigem={setOrigem}
          tipoOrigem={tipoOrigem}
          montaOptionsOrigem={montaOptionsOrigem}
          setNivelInteresseSelecionado={setNivelInteresseSelecionado}
          nivelInteresseSelecionado={nivelInteresseSelecionado}
          nivel_interesse={niveisInteresse}
          campos={valueCampos}
          empreendimentos={empreendimento}
          id_empreendimento={id_empreendimento}
          setObj={setValueCampos}
          tipologias={listaTipologia}
          optionsSelectEmpreendimento={optionsSelectEmpreendimento}
          optionsSelectUnidadeInteresse={optionsSelectUnidadeInteresse}
          optionsSelectUnidadeQualificado={optionsSelectUnidadeQualificado}
          localidades={listaLocalidades}
          optionsSelectNivelInteresse={optionsSelectNivelInteresse}
          optionsSelectComoConheceu={optionsSelectComoConheceu} />

          <Row>
            <Form.Group as={Col} controlId="formObservacao">
              <Form.Label>Observação:</Form.Label>
              <Form.Control
                type="textarea"
                as="textarea"
                rows="3"
                value={informacoes}
                onChange={(event) => setInformacoes(event.target.value)}
              />
            </Form.Group>
          </Row>

            {oportunidade?.usuario_pode_editar &&
              <Row className="formFooter">
                <Col>
                  {interesses != undefined ? (
                    <button
                      type="button"
                      onClick={() => {
                        salvarInteresse(interesses.id_oportunidade_interesse);
                      }}
                      className="btn btn-success"
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{ marginRight: "15px" }}
                      />
                      Salvar
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        salvarInteresse();
                      }}
                      className="btn btn-success"
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{ marginRight: "15px" }}
                      />
                      Salvar
                    </button>
                  )}
                </Col>
              </Row>
            }
        </Form>
      </DommusToggle>

      <DommusToggle title="Lead Scoring" toggle={true}>
        <Alert variant="warning">
          Nenhum parâmetro de pontuação configurado
        </Alert>
      </DommusToggle>
    </>
  );
}
