import "./index.css";
import React, { useState, useEffect } from "react";
import PerfilService from "../../services/PerfilService";
import ReactTooltip from "react-tooltip";
import user from "../../assets/usuario.svg";
import guService from "../../services/GuService";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import perfilUsuarioService from "../../services/PerfilUsuarioService";

function DommusOnlineFlag() {
  const [status, setStatus] = useState(true);
  const [posicaoFila, setPosicaoFila] = useState(true);
  const statusClass = status ? "onlineFlag" : "offlineFlag";
  const statusText = status ? "online" : "offline";
  useEffect(() => {
    const interval = setInterval(() => {
      atualizarVisto();
    }, 60000);
    atualizarVisto();
  }, []);
  function atualizarVisto() {
    PerfilService.buscarVistoUltimo().then((response) => {
      var dados = response.data;
      var visto_ultimo = new Date(dados.visto_ultimo);
      var agora = new Date();
      guService.setLocalState("tipoPerfil", dados.tipo?.toString());
      guService.setLocalState("exibicaoLista", dados.exibicao?.toString());
      guService.setLocalState("intervaloCalendario", dados.intervalo_calendario?.toString());
      guService.setLocalState("idFunil", dados.id_funil);
      guService.setLocalState("exibir_nome_etapas_funil", dados.exibir_nome_etapas);
      guService.setLocalState("exibir_desistencia_expiracao_funil", dados.exibir_desistencia_expiracao_funil);
      guService.setLocalState("campos_cadastro_opv_travados_oculto", Number(dados.campos_cadastro_opv_travados_oculto ?? 0));
      guService.setLocalState("ativar_visita_cadastro_opv", Number(dados.ativar_visita_cadastro_opv ?? 0));
      guService.setLocalState("ativar_tarefa_cadastro_opv", Number(dados.ativar_tarefa_cadastro_opv ?? 0));
      guService.setLocalState("ativar_comentario_cadastro_opv", Number(dados.ativar_comentario_cadastro_opv ?? 0));

      if(dados.changelog === 0) {
        alertChangelog()
      }
      setPosicaoFila(dados.posicao_fila);
      visto_ultimo.setHours(
        visto_ultimo.getHours(),
        visto_ultimo.getMinutes() + dados.tempo_configuracao
      );
      if (visto_ultimo > agora) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    });
  }

  function alertChangelog()
  {
    Swal.fire({
      title: 'Novas Atualizações!',
      text: "Confira as novas funcionalidades do sistema.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferir Agora!',
      cancelButtonText: 'Mais tarde'
    }).then((result) => {
      if(result.isConfirmed || result.dismiss == 'cancel') {
        if(result.isConfirmed) {
          window.open(`${window.location.href.split('/').slice(0, 3).join('/')}/atualizacoes?changelog=current`, "_blank")
        }
        trackPromise(perfilUsuarioService.atualizarConfiguracao({
          id_usuario: guService.getLocalState('idUsuario'),
          perfil: {
            changelog: 1
          }
        }))
      }
    });
  }

  return (
    <>
      <ReactTooltip />
      <picture className={"picture-topo"}>
        <img
          className={statusClass}
          src={user}
          data-tip={
            "Olá, " +
            guService.getLocalState("nomeUsuario") +
            ". Você está " +
            statusText +
            ". " +
            posicaoFila
          }
        />
      </picture>
    </>
  );
}

export default DommusOnlineFlag;
