import { ParticipanteType } from "../../../../../../components/GlobalContext/types";

export type AtendimentoType = {
    id_oportunidade:number
    lead_nome:string  
    id_oportunidade_atendimento?: number | string;
    id_local?: number | string;
    tipo?: string;
    id_tipo_atendimento?: number | string;
    titulo?: string;
    contato?: string;
    observacao?: string;
    data?: string;
    hora?: string;
    feedback?: string;
    local?: string;
    tarefa_realizada?: string | number;
    id_tipo_tarefa?: number | string;
    id_tipo_visita?: number | string;
    id_oportunidade_secundaria?: number;
    data_inicial?: string;
    data_final?: string;
    concluido_por?: number | string;
    concluido_em?: string;
    criado_em?: string;
    responsavel?: string | number;
    atualizado_em?: string;
    ativo?: string;
    visita?: VisitaType
    participantes?: ParticipanteType[];    
}

export enum AtendimentoEnum{
    VISITA=4,
    TAREFA=5,
    COMENTARIO=7
}

export type VisitaType = {
    visita_realizada?: boolean | number;
    id_tipo_oportunidade_atendimento_visita?: string | number;
    descricao?: string;    
}