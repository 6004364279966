import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import DommusTabelaPaginada from "../../../../../components/DommusTabelaPaginada";
import LinhaLead from "./LinhaLead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LeadOportunidadeType, LeadType } from '../../../../DetalheOportunidade/Cadastro/types';

interface ListaLeadsProps {
    leads: any
    handleSubmitImportar: (leadsSelecionados: Array<number>) => void
    setLeadsSelecionadosAlterados: (val: boolean) => void
    exibirLeadsFiltrados: () => void
    exibeFiltrados: boolean
}

export default function ListaLeads({leads, handleSubmitImportar, setLeadsSelecionadosAlterados, exibirLeadsFiltrados, exibeFiltrados}: ListaLeadsProps) {
    const [leadsList, setLeadsList] = useState(leads)
    const [leadsSelecionados, setLeadsSelecionados] = useState([] as Array<number>);
    const [todosSelecionados, setTodosSelecionados] = useState(false);

    const thead = (
        <tr>
            <th>Leads - {leadsList.length} Leads filtrados - <Button onClick={() => handleSelecionarTodos()} className="btn btn-sm">Selecionar Todos</Button>
                <button type="button" onClick={() => {limparFiltro()}} className="btn btn-danger btn-sm float-right">
                    <FontAwesomeIcon icon={faTimesCircle}
                        style={{ marginRight: '10px' }} />Limpar Filtro
                </button>
            </th>
        </tr>
    );

    useEffect(() => {
        setLeadsList(leads)
        setLeadsSelecionados([]);
        mapLeadsList()
    }, [leads])

    function mapLeadsList() {
        if(leads.length) {
            if(!(leads[0].lead === undefined)) {
                setLeadsList(leads.map((lead: LeadOportunidadeType) => lead.lead))
            }
        } 
    }

    function handleSelecionaLead(id_lead: number) {
        const selecionados = [...leadsSelecionados] as Array<number>;
        if (selecionados.includes(id_lead)) {
            const selecionadoIndex = selecionados.findIndex(item => item == id_lead);
            selecionados.splice(selecionadoIndex, 1);
        } else {
            selecionados.push(id_lead);
        }
        setLeadsSelecionados(selecionados)
        setLeadsSelecionadosAlterados(true);
    }

    const handleSelecionarTodos = useCallback(() => {
        const selecionados = [] as Array<number>;
        if (!todosSelecionados) {
            leadsList.map((lead: LeadType) => {
                selecionados.push(lead.id_lead);
            })
            setTodosSelecionados(true);
            setLeadsSelecionados(selecionados);
        } else {
            setLeadsSelecionados([]);
            setTodosSelecionados(false);
        }

    }, [leads, todosSelecionados])


    const linhas = useMemo(() => {

        return leadsList.map((lead: LeadType) => (
            <LinhaLead
                lead={lead}
                key={lead.id_lead}
                selecionado={leadsSelecionados.includes(lead.id_lead)}
                setSelecionado={handleSelecionaLead}
            />
        ))
    }, [leads, leadsSelecionados]);

    function limparFiltro(){
        exibirLeadsFiltrados()
    }

    return (
        <>
            {leadsList && exibeFiltrados &&
            <Row>
                <Col>
                    <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas}/>
                    <div className="w-100">
                        <button className="btn btn-primary" onClick={() => handleSelecionarTodos()}>Selecionar todos</button>
                        <button type="button" onClick={() => {limparFiltro()}} className="btn btn-outline-danger mx-2 float-right">
                            <FontAwesomeIcon icon={faTimesCircle}
                                style={{ marginRight: '10px' }} />Limpar Filtro
                        </button>
                        {leadsSelecionados.length > 0 && <button className="btn btn-success float-right" onClick={() => handleSubmitImportar(leadsSelecionados)}>Importar</button>}
                    </div>
                </Col>
            </Row>
            }
        </>
    )
}
