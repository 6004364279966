export function opcoesGrafico(
    categorias,
    makeSeriesConfig,
    data,
    makeSeriesDrilldown,
    dataDrilldown,
    showLabelFunil
) {
    return {
        chart: {
            type: "bar",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "Etapas",
        },
        xAxis: {
            labels: { 
                enabled: showLabelFunil,              
            },
            type: "category",           
        },
        yAxis: {
            gridLineWidth: 0,
            min: 0,
            labels: { enabled: false },
            title: {
                text: "Qtde. de OPV's por Etapa",                
            }
        },
        legend: {
            reversed: true,
            enabled: false,
            backgroundColor: "black",
        },
        tooltip: {
            formatter: function () {
                if (this.series) {
                    if (this.series.name.indexOf("padding") !== -1) {
                        return false;
                    } else {
                        return "Leads: " + this.y + " Etapa: " + this.key;
                    }
                }
            },
        },
        setOptions: {
            lang: {
                drillUpText: "voltar {series.name}",
            },
        },
        plotOptions: {
            series: {
                stacking: "normal",
                centerInCategory: true,
                shadow: false,
                dataLabels: {
                    enabled: true,                   
                },
            },
            width: "100%",
            bar: {
                groupPadding: 0,
            },
        },
        series: makeSeriesConfig(data),
        drilldown: {
            breadcrumbs: {
                buttonTheme: {
                    fill: "#f7f7f7",
                    padding: 8,
                    stroke: "#cccccc",
                    "stroke-width": 1,
                },
                floating: true,
                position: {
                    align: "right",
                },
                showFullPath: false,
            },
            series: makeSeriesDrilldown(dataDrilldown),
        },
    };
}
