/* eslint-disable react/display-name */
import React, { useContext, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import "./index.css";
import { celularMask } from '../../InputTextMask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBuilding, faChevronDown, faChevronUp, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { EmpreendimentoContext } from '../../GlobalContext/globalContext';
import CalcularSLA from '../../../pages/Oportunidades/ListaOportunidades/ColunaOportunidade/OportunidadeCard/CalcularSLA';
import moment from 'moment';
import { parseSla } from '../../../pages/Oportunidades/Visualizacao/FunilOportunidades';
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import BibliotecaIcones from '../../BibliotecaIcones';


const OportunidadeRow = React.forwardRef(({ oportunidade, pesquisa }, ref) => {
  const {resolverNomeEmpreendimento, resolverNomeOperador} = useContext(EmpreendimentoContext);

  const dados = useMemo(()=>{

    let arrayPesquisa = pesquisa.split(',');
    let pattern = "";
    arrayPesquisa.forEach((element, index) => {
      if(index === arrayPesquisa.length - 1){
        pattern = pattern.concat(element.trim().normalize('NFD').replace(/[\u0300-\u036f-()]/g, ""));
      }else{
        pattern = pattern.concat(element.trim().normalize('NFD').replace(/[\u0300-\u036f-()]/g, "") + "|");
      }
    });

    pattern = new RegExp(pattern, 'ig');

    const matchesOPV = [...oportunidade?.id_oportunidade.toString().padStart(5,"0").matchAll(pattern)];
    const matchesNome = [... ((oportunidade?.envolvido_nome) || oportunidade?.lead_nome || "")?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").matchAll(pattern)];
    const matchesTelefone = [... (celularMask(oportunidade?.envolvido_telefone || oportunidade?.lead_telefone) || "" )?.matchAll(pattern)];
    const matchesEmail = [...(oportunidade?.lead_email?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, "") || "").matchAll(pattern)];

    let opv = null;
    let nome = [];
    let telefone = null;
    let email = null;

    matchesOPV.forEach((item)=>{
      opv = item['input'].split(item['0']).join(`<mark>${item['0']}</mark>`) ?? null;
    })
    matchesNome.forEach((item, index)=>{
      if(index === 0){
        nome = item['input']?.split(item['0']).join(`<mark>${item['0']}</mark>`);
      }else{
        nome = nome.split(item['0']).join(`<mark>${item['0']}</mark>`);
      }
    })
    matchesTelefone.forEach((item)=>{
      telefone = item['input'].split(item['0']).join(`<mark>${item['0']}</mark>`) ?? null;
    })
    matchesEmail.forEach((item)=>{
      email = item['input'].split(item['0']).join(`<mark>${item['0']}</mark>`) ?? null;
    })

    return {
      opv: opv ?? oportunidade?.id_oportunidade.toString().padStart(5,"0"),
      nome : nome.length ? nome : ((oportunidade?.envolvido_nome) || oportunidade?.lead_nome),
      telefone: telefone ?? celularMask(oportunidade?.envolvido_telefone || oportunidade?.lead_telefone),
      email: email ?? oportunidade?.lead_email,
    }
  },[oportunidade])

  const nivelInteresse = useMemo(()=>{
      switch (oportunidade?.nivel_interesse) {
        case 'Muito Frio':
            return (
              <div className='item-oportunidade'>
                <div className='d-flex flex-column'>
                    <FontAwesomeIcon
                        style={{fontSize: 14}}
                        color="#3D56B2"
                        icon={faChevronDown}
                        className="iconDouble"
                    />
                    <FontAwesomeIcon
                        style={{fontSize: 14}}
                        color="#3D56B2"
                        icon={faChevronDown}
                    />
                </div>
                <span style={{color: "#3D56B2"}}>{oportunidade?.nivel_interesse}</span>
              </div>
            );
        case 'Frio':
          return (
            <div className='item-oportunidade'>
              <FontAwesomeIcon
                  style={{fontSize: 14}}
                  color="#3D56B2"
                  icon={faChevronDown}
              />
              <span style={{color: "#3D56B2"}}>{oportunidade?.nivel_interesse}</span>
            </div>
          );
        case 'Normal':
          return(
            <div className='item-oportunidade'>
              <FontAwesomeIcon
                  style={{fontSize: 14}}
                  color="#FFAB00"
                  icon={faBars}
              />
              <span style={{color: "#FFAB00"}}>{oportunidade?.nivel_interesse}</span>
            </div>
          );
        case 'Quente':
          return(
            <div className='item-oportunidade'>
              <FontAwesomeIcon
                  style={{fontSize: 14}}
                  color="#FF5C58"
                  icon={faChevronUp}
              />
              <span style={{color: "#FF5C58"}}>{oportunidade?.nivel_interesse}</span>
            </div>
          );
        case 'Muito Quente':
          return(
            <div className='item-oportunidade'>
              <div className='d-flex flex-column'>
                  <FontAwesomeIcon
                      style={{fontSize: 14}}
                      color="#FF5C58"
                      icon={faChevronUp}
                      className="iconDouble"
                      />
                  <FontAwesomeIcon
                      style={{fontSize: 14}}
                      color="#FF5C58"
                      icon={faChevronUp}
                      />
              </div>
              <span style={{color: "#FF5C58"}}>{oportunidade?.nivel_interesse}</span>
            </div>
          )
          default:
            return(
              <div className='item-oportunidade'>
                <FontAwesomeIcon
                style={{fontSize: 14}}
                color="#666"
                icon={faMinusSquare}
                />
                <span>{"Sem definição"}</span>
              </div>
            )
          }
  },[oportunidade]);

  const postBody = (
    <>
      <div key={oportunidade.id}>
          {oportunidade &&
          <Card.Link
            className="card-pesquisa-geral w-100"
            onClick={() => { window.open(`/oportunidade/${oportunidade.id_oportunidade}`); }}
          >
              <div className="midia-icone-pesquisa" >
                <BibliotecaIcones
                    optionsSelectComIcones={''} setIdSelect={''} chaveLabelSelect={''}
                    chaveIdIconeSelecionado="" sizeIcone={'1x'}
                    colorIcone={oportunidade.midia_cor}
                    value={''} setValue={''}
                    estilo={''} iconeSelecionado={''}
                    chaveIcone={oportunidade.midia_icone} tipoExibicao={'icone'}
                />
              </div>
              <div className='dados-pesquisa-geral m-0 w-100'>
                <Row>
                  <Col>
                    <div className='opv-section'>
                      <span className='opv'>
                        OPV-{<div className='span-replace' dangerouslySetInnerHTML={{__html: dados.opv}}/>}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className='info-section w-100'>
                  <Col sm={5}>
                    <div className='contato-info'>
                      <span className='item-contato'>
                        <b>Nome:</b> <div className='span-replace' dangerouslySetInnerHTML={{__html: dados.nome}}/>
                      </span>
                      <span className='item-contato'>
                        <b>Telefone:</b> <div className='span-replace' dangerouslySetInnerHTML={{__html: dados.telefone || "---"}}/>
                      </span>
                      <span className='item-contato'>
                        <b>Email:</b> <div className='span-replace' dangerouslySetInnerHTML={{__html: dados.email || "---"}}/>
                      </span>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className='oportunidade-section'>
                      <div className='item-oportunidade'>
                        <FontAwesomeIcon icon={faSuitcase}/>
                        <span>{resolverNomeOperador(oportunidade?.corretor || oportunidade?.corretor_pre)}</span>
                      </div>
                      <div className='item-oportunidade'>
                        <FontAwesomeIcon icon={faBuilding}/>
                        <span>{resolverNomeEmpreendimento(oportunidade?.id_empreendimento)}</span>
                      </div>
                      {nivelInteresse}
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='data-section'>
                      <span>{moment(oportunidade.ultima_alteracao).format("DD/MM/YYYY HH:MM")}</span>
                      <div className='badgeTempo'>
                        <CalcularSLA
                            date={oportunidade.ultima_alteracao}
                            sla={parseSla("")}
                            sla_expira={parseSla("")}
                        />
                      </div>
                      <div className='etapa-item text-center' style={{background: oportunidade?.etapa_cor}}>
                        {oportunidade?.etapa_nome}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
          </Card.Link>}
      </div>
    </>
  )

  const content = ref
    ? <article ref={ref}>{postBody}</article>
    : <article>{postBody}</article>

  return content
})
export default OportunidadeRow
