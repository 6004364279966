import { trackPromise } from "react-promise-tracker"
import ConfiguracoesGestaoService from "../../../../services/ConfiguracoesGestaoService"
import { swalErro } from "../../../../helpers/DommusSwal"
import Swal from "sweetalert2"


export  const  buscarConfiguracaoMeiosComunicacao = async () =>  {
   
    try {
      const response = await ConfiguracoesGestaoService.buscarConfiguracao("meios_de_comunicacao_atendimento")
      return response.data
    } catch (error) {
        console.log(error)
        swalErro('Ocorreu um erro inesperado ao buscar as configurações, por favor tente novamente mais tarde')
    }
  }