import React from 'react';
import './styles.css'

export default function DommusBarraTitulo({children, className=""}) {
    return (
        <div className={`barra-titulo ${className}`}>
            {children}
        </div>
    );
}
