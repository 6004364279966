import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OportunidadePorStatusPaginadoType } from "../../FunilOportunidades/types";
import { NivelInteresseType } from "../../../../../components/FormFiltroPadrao/types";
import { faBars, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IconeInteresseProps {
    interesses: Array<NivelInteresseType>
    oportunidade: OportunidadePorStatusPaginadoType
    setInteresseSelecionado: (interesse: string) => void
    setOpenModal: (openModal: boolean) => void;
    setOportunidadeSelecionada: (oportunidade: OportunidadePorStatusPaginadoType) => void;
}

export function NivelInteresseIcone(
{ 
    interesses,
    oportunidade,
    setInteresseSelecionado,
    setOpenModal,
    setOportunidadeSelecionada
}: IconeInteresseProps ) 
{
    const [iconeInteresse, setIconeInteresse] = useState() as any;

    function buscaInteresse(item: OportunidadePorStatusPaginadoType) : String {
        let Interesse = ""
        if (item.nivelInteresse) {
            if (interesses.length > 0) {
                interesses.map((interesse) => {
                    if (
                        interesse.id_nivel_interesse ===
                        item.nivelInteresse.id_nivel_interesse
                    ) {
                        Interesse = interesse.descricao.toString();
                    }

                });
            }
        }
        return Interesse;
    }

    useEffect(() => {
        interesseOportunidade(buscaInteresse(oportunidade))
    }, []);

    const InteresseMuitoFrio = 
        <>
            <FontAwesomeIcon
                style={{fontSize: 14}}
                color="#3D56B2"
                icon={faChevronDown}
                className="iconDouble"
            />
            <FontAwesomeIcon
                style={{fontSize: 14}}
                color="#3D56B2"
                icon={faChevronDown}
            />
        </>
    ;

    const InteresseFrio = 
        <FontAwesomeIcon
            style={{fontSize: 14}}
            color="#3D56B2"
            icon={faChevronDown}
        />
    ;

    const InteresseNormal =
        <FontAwesomeIcon
            style={{fontSize: 14}}
            color="#FFAB00"
            icon={faBars}
        />
    ;

    const InteresseQuente = 
        <FontAwesomeIcon
            style={{fontSize: 14}}
            color="#FF5C58"
            icon={faChevronUp}
        />
    ;

    const InteresseMuitoQuente = 
        <>
            <FontAwesomeIcon
                style={{fontSize: 14}}
                color="#FF5C58"
                icon={faChevronUp}
                className="iconDouble"
            />
            <FontAwesomeIcon
                style={{fontSize: 14}}
                color="#FF5C58"
                icon={faChevronUp}
            />
        </>
    ;

    const nivel_interesse = 
    [
        {"id": 1, "descricao":"Muito Frio", "InteresseIconeComponente": InteresseMuitoFrio},
        {"id": 2, "descricao":"Frio", "InteresseIconeComponente": InteresseFrio},
        {"id": 3, "descricao":"Normal", "InteresseIconeComponente": InteresseNormal},
        {"id": 4, "descricao":"Quente", "InteresseIconeComponente": InteresseQuente},
        {"id": 5, "descricao":"Muito Quente", "InteresseIconeComponente": InteresseMuitoQuente},
    ]

    function interesseOportunidade(nivelInteresse: String)
    {
        let icone = nivel_interesse.find(item => item.descricao == nivelInteresse);

        if ( icone )
        {
            setIconeInteresse(icone)
        }
    }
    return (
        <>
            <div
                className="DoubleArrow"
                style={{cursor:`${oportunidade.usuario_pode_editar ? "pointer" : "not-allowed"}`}}
                data-tip={"Interesse " + buscaInteresse(oportunidade)}
                onClick={() => {
                    if(oportunidade.usuario_pode_editar){
                        setOpenModal(true);
                        setInteresseSelecionado(String(oportunidade.nivelInteresse.id_nivel_interesse));
                        setOportunidadeSelecionada(oportunidade);
                    }
                }}
            >
            {iconeInteresse ? iconeInteresse.InteresseIconeComponente : <></>}
            </div>
        </>
    )
}