import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../DommusRequiredSpan";
import { EmpreendimentoContext, OportunidadeContext } from "../GlobalContext/globalContext";
import { AtendimentoEnum } from "../../pages/Oportunidades/Visualizacao/OportunidadesListaPaginada/types/AtendimentoType";
import Swal from "sweetalert2";
import Select from "react-select";
import { cachearContexto } from "../../helpers/cachearContexto";
import oportunidadeService from "../../services/OportunidadeService";
import LocalAtendimentoService from "../../services/LocalAtendimentoService";
import { TipoVisitaService } from "../../services/TipoVisitaService";
import moment from "moment";
import DommusToggle from "../DommusToggle";
import { OportunidadeAtendimentoVisitaService } from "../../services/OportunidadeAtendimentoVisitaService";
import { trackPromise } from "react-promise-tracker";
import DommusSwitch from "../DommusSwitch";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { AtendimentoParticipantesVisita } from "../../pages/DetalheOportunidade/Atendimento/NovoAtendimento/AtendimentoParticipantesVisita";

interface FormAtendimentoCadastroOpvProps {
    idTipoAtendimento: number;
    dadosDistribuicao: any;
}

export function FormAtendimentoCadastroOpv({ idTipoAtendimento, dadosDistribuicao }: FormAtendimentoCadastroOpvProps) {
    const {
        tiposTarefa, setTiposTarefa,
        locaisAtendimento, setLocaisAtendimento,
        tiposVisita, setTiposVisita,
        tiposAtendimento, setTiposAtendimento
    } = useContext(OportunidadeContext)
    const methods = useFormContext();
    const { equipes } = useContext(EmpreendimentoContext);
    const [motivosVisita, setMotivosVisita] = useState<any[]>([]);

    const tipoAtendimento = useMemo(() => {
        return tiposAtendimento.find((tipo) => Number(tipo.id_tipo_atendimento) === Number(idTipoAtendimento))
    }, [tiposAtendimento])
    const watchTarefaRealizada = methods.watch('tarefa_realizada');
    const watchVisitaRealizada = methods.watch('visita.visita_realizada');

    useEffect(() => {
        cachearContexto(tiposAtendimento, setTiposAtendimento, oportunidadeService.buscarTiposAtendimentos);
        if (idTipoAtendimento === AtendimentoEnum.TAREFA) {
            cachearContexto(tiposTarefa, setTiposTarefa, oportunidadeService.buscarTiposTarefa)
        } else if (idTipoAtendimento === AtendimentoEnum.VISITA) {
            if (tipoAtendimento?.permitir_conclusao_no_cadastro) {
                trackPromise(OportunidadeAtendimentoVisitaService.buscarTipos())
                    .then(response => {
                        const motivosVisitaCarregados = response.data
                        setMotivosVisita(motivosVisitaCarregados)
                    })
                    .catch(erro => Swal.fire('Ooops...', `Houve um erro ao tentar buscar os tipos de visita. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`))
            }
            cachearContexto(locaisAtendimento, setLocaisAtendimento, LocalAtendimentoService.buscarDadosLocaisAtendimento)
            cachearContexto(tiposVisita, setTiposVisita, TipoVisitaService.buscar)
        }
    }, []);

    const listaAtendentes = useMemo(() => {
        let usuariosResponsaveis = [];
        if (dadosDistribuicao?.oportunidade?.id_usuario_pre_atendimento) {
            let equipePre = equipes.find((equipe) => Number(equipe.id_equipe) === Number(dadosDistribuicao?.oportunidade?.id_equipe_pre_atendimento));
            if (equipePre) {
                let gerente = equipePre.gerentes.find((gerente) => Number(gerente.id_usuario) === Number(dadosDistribuicao?.oportunidade?.id_gerente_pre_atendimento));
                usuariosResponsaveis.push({
                    label: gerente?.nome,
                    value: gerente?.id_usuario
                });
                if (gerente) {
                    let corretor = gerente.corretores.find((corretor) => Number(corretor.id_usuario) === Number(dadosDistribuicao?.oportunidade?.id_usuario_pre_atendimento));
                    if (corretor) {
                        usuariosResponsaveis.push({
                            label: corretor?.nome,
                            value: corretor?.id_usuario
                        });
                    }
                }

            }
        }

        if (dadosDistribuicao?.oportunidade?.id_usuario_atendimento) {
            let equipePdv = equipes.find((equipe) => Number(equipe.id_equipe) === Number(dadosDistribuicao?.oportunidade?.id_equipe_pdv));
            if (equipePdv) {
                let gerente = equipePdv.gerentes.find((gerente) => Number(gerente.id_usuario) === Number(dadosDistribuicao?.oportunidade?.id_gerente_pdv));
                usuariosResponsaveis.push({
                    label: gerente?.nome,
                    value: gerente?.id_usuario
                });
                if (gerente) {
                    let corretor = gerente.corretores.find((corretor) => Number(corretor.id_usuario) === Number(dadosDistribuicao?.oportunidade?.id_usuario_atendimento));
                    if (corretor) {
                        usuariosResponsaveis.push({
                            label: corretor?.nome,
                            value: corretor?.id_usuario
                        });
                    }
                }

            }
        }
        return usuariosResponsaveis;
    }, [dadosDistribuicao]);

    return (
        <>
            <Container fluid>
                <div className="formCadastro">
                    <div className="items-form">
                        {![6, 7].includes(Number(idTipoAtendimento)) && listaAtendentes.length > 0 && (
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Escolha a pessoa que irá executar este atendimento: <DommusRequiredSpan /></Form.Label>
                                    <select
                                        className="form-control"
                                        name="responsavel"
                                        ref={methods.register()}
                                    >
                                        <option value="">Selecione</option>
                                        {listaAtendentes.length && listaAtendentes.map((atentende, index) => <option key={index} value={atentende?.value ?? ""}>{atentende?.label ?? ""}</option>)}
                                    </select>
                                </Form.Group>
                            </Row>
                        ) || <></>}
                        {idTipoAtendimento == AtendimentoEnum.VISITA ? (
                            <>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>
                                            Data: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            name="data"
                                            type="date"
                                            min="2020-01-01"
                                            defaultValue={moment().format("YYYY-MM-DD")}
                                            placeholder="00/00/0000"
                                            ref={methods.register()}
                                            onBlur={(event: any) => {
                                                if (moment(event.target.value) < moment("2020-01-01")) {
                                                    methods.setValue("data", "");
                                                    Swal.fire({
                                                        titleText: "Ooops...",
                                                        text: "Escolha uma data posterior ao ano de 2020 ",
                                                        icon: "warning",
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>
                                            Hora: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            defaultValue={moment().format('HH:mm')}
                                            name="hora"
                                            ref={methods.register()}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formLocalAtendimento">
                                        <Form.Label>
                                            Local Atendimento: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            defaultValue="Selecione"
                                            name="id_local"
                                            ref={methods.register()}
                                        >
                                            <option value="" hidden>Selecione</option>
                                            {locaisAtendimento.length != 0 ? (
                                                locaisAtendimento.map((item) => (
                                                    <option value={item.id_local} key={item.id_local}>
                                                        {item.nome}{item.endereco != null ? ": " + item.endereco : ''}
                                                    </option>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formLocalAtendimento">
                                        <Form.Label>Tipo da Visita: <DommusRequiredSpan /></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="id_tipo_visita"
                                            ref={methods.register()}
                                        >
                                            <option value="" hidden>Selecione</option>
                                            {tiposVisita.length ? tiposVisita.map(tipo => <option value={tipo.id_tipo_visita} key={`id-tipo-visita-${tipo.id_tipo_visita}`}>{tipo.nome}</option>) : <></>}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>Descrição Local:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows={3}
                                            name="local"
                                            ref={methods.register()}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>Observação:</Form.Label>
                                        <Form.Control
                                            type="textarea"
                                            as="textarea"
                                            rows={3}
                                            name="observacao"
                                            ref={methods.register()}
                                        />
                                    </Form.Group>
                                </Row>
                                {tipoAtendimento?.permitir_conclusao_no_cadastro ?
                                    <DommusToggle title="Detalhes da Visita" toggle={true}>
                                        <Row>
                                            <Form.Group as={Col} className={`${!methods.getValues("visita.visita_realizada") ? 'pt-3' : 'pt-0'} mb-0`}>
                                                <Controller
                                                    control={methods.control}
                                                    name={'tarefa_realizada'}
                                                    render={({ onChange, onBlur, value }) =>
                                                        <DommusSwitch
                                                            label="Visita concluída?"
                                                            checked={Boolean(Number(value))}
                                                            clickEvent={(e: any) => {
                                                                methods.setValue("tarefa_realizada", !Boolean(Number(methods.getValues("tarefa_realizada"))));
                                                            }}
                                                            tooltip={Boolean(Number(value)) ? "Sim" : "Não"}
                                                            labelDesativado=""
                                                        />
                                                    }
                                                    defaultValue={""}
                                                />
                                                {watchTarefaRealizada ?
                                                    <Controller
                                                        control={methods.control}
                                                        name={'visita.visita_realizada'}
                                                        render={({ onChange, onBlur, value }) =>
                                                            <DommusSwitch
                                                                label="Visita Realizada"
                                                                checked={Boolean(Number(value))}
                                                                clickEvent={(e: any) => {
                                                                    methods.setValue("visita.visita_realizada", !Boolean(Number(methods.getValues("visita.visita_realizada"))));
                                                                }}
                                                                tooltip={Boolean(Number(methods.getValues("visita.visita_realizada"))) ? "Sim" : "Não"}
                                                                labelDesativado=""
                                                            />
                                                        }
                                                    /> : <></>
                                                }
                                            </Form.Group>
                                            {(watchTarefaRealizada && !watchVisitaRealizada) ? (
                                                <Form.Group as={Col} controlId="formNome">
                                                    <Form.Label>Motivo da visita não ter sido realizada: <DommusRequiredSpan /></Form.Label>
                                                    <select
                                                        className="form-control"
                                                        name="visita.id_tipo_oportunidade_atendimento_visita"
                                                        ref={methods.register()}
                                                    >
                                                        <option value="" hidden>Selecione um Motivo</option>
                                                        {motivosVisita.map((option, index) => <option key={index} value={option.id_tipo_oportunidade_atendimento_visita}>{option.nome}</option>)}
                                                    </select>
                                                </Form.Group>
                                            ) : <></>}
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} controlId="formNome">
                                                <Form.Label>Descrição da visita:<DommusRequiredSpan /></Form.Label>
                                                <Form.Control
                                                    type="textarea"
                                                    as="textarea"
                                                    rows={2}
                                                    name="visita.descricao"
                                                    ref={methods.register()}
                                                />
                                            </Form.Group>
                                        </Row>
                                        {(watchVisitaRealizada && watchTarefaRealizada) ?
                                            <Row>
                                                <FormProvider {...methods}>
                                                    <AtendimentoParticipantesVisita />
                                                </FormProvider>
                                            </Row>
                                            : <></>
                                        }
                                    </DommusToggle>
                                    : <></>
                                }
                            </>
                        ) : (
                            <></>
                        )}
                        {idTipoAtendimento == AtendimentoEnum.TAREFA ? (
                            <>
                                <Row>
                                    <Form.Group as={Col} controlId="formTitulo">
                                        <Form.Label className="inputLabel">
                                            Título: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            name="titulo"
                                            type="text"
                                            ref={methods.register()}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formTipo">
                                        <Form.Label className="inputLabel">
                                            Tipo de tarefa: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Controller
                                            control={methods.control}
                                            name="id_tipo_tarefa"
                                            as={Select}
                                            options={
                                                tiposTarefa.map(tipo => {
                                                    return {
                                                        value: tipo.id,
                                                        label: tipo.nome
                                                    }
                                                }) as []
                                            }
                                            menuPosition="fixed"
                                            isSearchable={true}
                                            placeholder="Selecionar"
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>
                                            Data: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            min="2020-01-01"
                                            type="date"
                                            placeholder="00/00/0000"
                                            defaultValue={moment().format('YYYY-MM-DD')}
                                            name="data"
                                            ref={methods.register()}
                                            onBlur={(event: any) => {
                                                if (moment(event.target.value) < moment("2020-01-01")) {
                                                    methods.setValue("data", "");
                                                    Swal.fire({
                                                        titleText: "Ooops...",
                                                        text: "Escolha uma data posterior ao ano de 2020 ",
                                                        icon: "warning",
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>
                                            Hora: <DommusRequiredSpan />
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            defaultValue={moment().format('HH:mm')}
                                            name="hora"
                                            ref={methods.register()}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>Observação:</Form.Label>
                                        <Form.Control
                                            type="textarea"
                                            as="textarea"
                                            name="observacao"
                                            ref={methods.register}
                                            rows={3}
                                        />
                                    </Form.Group>
                                </Row>
                                {(tipoAtendimento?.permitir_conclusao_no_cadastro) ? (
                                    <DommusToggle title="Detalhes da Tarefa" toggle={true}>
                                        <Form.Group as={Col} className={`${!watchTarefaRealizada ? 'pt-3' : 'pt-0'} mb-0`}>
                                            <Controller
                                                control={methods.control}
                                                name={'tarefa_realizada'}
                                                render={({ onChange, onBlur, value }) =>
                                                    <DommusSwitch
                                                        label="Visita concluída?"
                                                        checked={Boolean(Number(value))}
                                                        clickEvent={(e: any) => {
                                                            methods.setValue("tarefa_realizada", !Boolean(Number(methods.getValues("tarefa_realizada"))));
                                                        }}
                                                        tooltip={Boolean(Number(value)) ? "Sim" : "Não"}
                                                        labelDesativado=""
                                                    />
                                                }
                                                defaultValue={""}
                                            />
                                        </Form.Group>
                                    </DommusToggle>
                                ) : <></>}
                            </>
                        ) : (
                            <></>
                        )}
                        {idTipoAtendimento == AtendimentoEnum.COMENTARIO ? (
                            <>
                                <Row>
                                    <Form.Group as={Col} controlId="formNome">
                                        <Form.Label>Comentário:</Form.Label>
                                        <Form.Control
                                            name="observacao"
                                            ref={methods.register()}
                                            as={"textarea"}
                                            rows={3}
                                        />
                                    </Form.Group>
                                </Row>
                            </>
                        ): (
                            <></>
                        )
                        }
                    </div>
                </div>
            </Container>
        </>
    )
}