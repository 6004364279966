import React, { createContext, ReactElement, useMemo, useState } from "react";
import {
  AutomacaoCondicionanteTipoType,
  AutomacaoDataBaseTipoType,
  AutomacaoTipoRegraType,
  AutomacaoTipoType,
  AutomacaoType,
} from "../../pages/Configuracoes/Automacoes/types";
import AutomacaoService from "../../services/AutomacaoService";
import { trackPromise } from "react-promise-tracker";
import { swalErro } from "../../helpers/DommusSwal";

interface AutomacaoContextProps {
  listaAutomacoes: AutomacaoType[];
  listaTiposRegra: AutomacaoTipoRegraType[];
  listaAutomacaoTipos: AutomacaoTipoType[];
  listaAutomacaoDataBaseTipo: AutomacaoDataBaseTipoType[];
  listaAutomacaoCondicionanteTipos: AutomacaoCondicionanteTipoType[];
  listaCamposEnvovido: Map<string, string>;
  recarregarAutomacoes: () => void;
  recarregarTiposRegra: () => void;
  recarregarAutomacaoTipo: () => void;
  recarregarAutomacaoDataBaseTipo: () => void;
  recarregarAutomacaoCondicionanteTipo: () => void;
  adicionarAutomacao: (data: AutomacaoType) => void;
  atualizarAutomacao: (data: AutomacaoType) => void;
  removerAutomacao: (idAutomacao: number) => void;
  removerRegra: (idAutomacao: number, idRegra: number) => void;
}

export const AutomacaoContext = createContext({} as AutomacaoContextProps );

export function AutomacaoProvider({ children }: { children: ReactElement }) {
  const [listaAutomacoes, setListaAutomacoes] = useState<AutomacaoType[]>([]);
  const [listaTiposRegra, setListaTiposRegra] = useState<
    AutomacaoTipoRegraType[]
  >([]);
  const [listaAutomacaoDataBaseTipo, setListaAutomacaoDataBaseTipo] = useState<AutomacaoDataBaseTipoType[]>([]);
  const [listaAutomacaoTipos, setListaAutomacaoTipos] = useState<AutomacaoTipoType[]>([]);
  const [listaAutomacaoCondicionanteTipos, setListaAutomacaoCondicionanteTipos] = useState<AutomacaoCondicionanteTipoType[]>([]);

  const listaCamposEnvovido = useMemo(() => {
    return new Map<string, string>([
      ["nome", "Nome"],
      ["genero", "Gênero"],
      ["cpf", "CPF"],
      ["rg", "RG"],
      ["orgao_emissor", "Orgão Emissor"],
      ["data_emissao", "Data Emissão"],
      ["data_nascimento", "Data de Nascimento"],
      ["idade", "Idade"],
      ["email", "E-mail"],
      ["telefone", "Telefone"],
      ["celular", "Celular"],
      ["cep", "CEP"],
      ["rua", "Endereço"],
      ["bairro", "Bairro"],
      ["cidade", "Cidade"],
      ["estado", "Estado"],
    ]);
  }, []);

  const recarregarTiposRegra = () => {
    AutomacaoService.buscarTiposRegra().then((response: any) => {
      setListaTiposRegra(response.data);
    });
  };

  const recarregarAutomacaoTipo = () => {
    AutomacaoService.buscarAutomacoesTipos().then((response: any) => {
      setListaAutomacaoTipos(response.data);
    });
  };

  const recarregarAutomacaoDataBaseTipo = () => {
    AutomacaoService.buscarAutomacoesDataBaseTipos().then((response: any) => {
      setListaAutomacaoDataBaseTipo(response.data);
    });
  };

  const recarregarAutomacaoCondicionanteTipo = () => {
    AutomacaoService.buscarAutomacoesCondicionanteTipos().then((response: any) => {
      setListaAutomacaoCondicionanteTipos(response.data);
    });
  };

  const recarregarAutomacoes = () => {
    trackPromise(AutomacaoService.buscarAutomacoes())
      .then((response) => {
        setListaAutomacoes(response.data);
      })
      .catch((error) => {
        swalErro(
          `Houve um erro ao carregar as Automações: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
        );
      });
  };

  const adicionarAutomacao = (data: AutomacaoType) => {
    setListaAutomacoes((current) => {
      return [data,...current];
    });
  };

  const atualizarAutomacao = (data: AutomacaoType) => {
    setListaAutomacoes((current) => {
      return current.map((automacao) => {
        if (automacao.id_automacao === data.id_automacao) {
          return data;
        }
        return automacao;
      });
    });
  };

  const removerAutomacao = (idAutomacao: number) => {
    setListaAutomacoes((current) => {
      return current.filter(
        (automacao) => automacao.id_automacao !== idAutomacao
      );
    });
  };

  const removerRegra = (idAutomacao: number, idRegra: number) => {
    setListaAutomacoes((current) => {
      return current.map((automacao) => {
        if (automacao.id_automacao == idAutomacao) {
          automacao.regras = automacao.regras?.filter(
            (regra) => regra.id_automacao_regra !== idRegra
          );
        }
        return automacao;
      });
    });
  };

  return (
    <AutomacaoContext.Provider
      value={{
        listaTiposRegra,
        recarregarTiposRegra,
        recarregarAutomacaoTipo,
        recarregarAutomacaoDataBaseTipo,
        recarregarAutomacaoCondicionanteTipo,
        listaAutomacoes,
        listaAutomacaoTipos,
        listaAutomacaoDataBaseTipo,
        listaAutomacaoCondicionanteTipos,
        recarregarAutomacoes,
        adicionarAutomacao,
        atualizarAutomacao,
        removerAutomacao,
        removerRegra,
        listaCamposEnvovido,
      }}
    >
      {children}
    </AutomacaoContext.Provider>
  );
}
