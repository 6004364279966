import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import ofertaAtiva from "../../../../../services/OfertaAtivaService";
import "./index.css";
import EmpreendimentoService from "../../../../../services/EmpreendimentoService";
import Swal from "sweetalert2";
import { RowLead } from "./RowLead";
import DommusTabelaPaginadaBack from "../../../../../components/DommusTabelaPaginadaBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { OfertaAtivaType } from "..";

interface ListaLeadsPageProps {
  oferta: OfertaAtivaType
}

export default function ListaLeadsPage({ oferta }: ListaLeadsPageProps) {
  const [lista, setLista] = useState([]);
  const [limit, setLimit] = useState(8)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [empreendimentos, setempreendimentos] = useState([])
  const rows = lista.map(lead => <RowLead lead={lead} empreendimentos={empreendimentos} />)
  const thead = (
    <tr>
      <th>Leads - {count} </th>
    </tr>
  )

  useEffect(() => {
    trackPromise(ofertaAtiva.getLeads(`${oferta.id_oferta_ativa}?limit=${limit}&page=${page}`)
      .then((res) => {
          setLista(res.data.leads);
          setCount(res.data.count)
        })
    );
    buscaEmpreendimentos()
  }, [page, limit]);


  async function buscaEmpreendimentos(){
    await trackPromise(
        EmpreendimentoService.buscarEmpreendimentosPorUsuario().then((response) => {
            if (response.data !== undefined) {
              setempreendimentos(response.data)
            }
        }
        ).catch(error => Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
            icon: "error"
        }))            
    )
}

  return (
    <>
      {count && (
        <DommusTabelaPaginadaBack 
          setLimit={setLimit}
          limit={limit}
          page={page}
          total={count}
          linhasCabecalho={thead}
          setPage={setPage}
          linhasCorpo={rows}
          data=""
        />
      )}
      {!count && (
        <Alert key="warning" variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Nenhum lead encontrado.
        </Alert>
      )}
    </>
  );
}
