import React, { useEffect, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import './index.css';
import OportunidadeService from '../../../../services/OportunidadeService';
import Swal from 'sweetalert2';
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import DommusRequiredSpan from '../../../../components/DommusRequiredSpan';
import DommusCheckboxToggle from "../../../../components/DommusCheckboxToggle";
import StatusOportunidadeService from '../../../../services/StatusOportunidadeService';

export default function NovaDesistencia({ id_oportunidade, id_status_oportunidade, id_substatus_oportunidade, atualizarDesistencia, nao_pertube, setRefreshDesistencia, close }) {
    const [motivo, setMotivo] = useState("");
    const [tipoDesistencia, setTipoDesistencia] = useState(0);
    const [tiposList, setTiposList] = useState([]);
    const [naoPertube, setNaoPertube] = useState(nao_pertube || 0)
    const [switchNaoPertube, setSwitchNaoPertube] = useState(false)

    useEffect(() => {
        trackPromise(
            OportunidadeService.buscarTiposDesistencia({ oculto: 0 }).then(
                (response) => {
                    if (response.data !== '') {
                        if(id_status_oportunidade != null){
                            const result = response.data.filter(descarte => (descarte.id_status_oportunidade == id_status_oportunidade || descarte.id_status_oportunidade == null || descarte.id_status_oportunidade.includes(id_status_oportunidade) )) 
                            if(result.length > 0){
                                setTiposList(result)
                            }else{
                                setTiposList(response.data)
                            }
                        }else{
                            setTiposList(response.data);
                        }
                    }
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar a lista de tipos: " + error.data,
                        icon: "error"
                    });
                }
            )
        )
        trackPromise(
            StatusOportunidadeService.listaTodasEtapas()
                .then((res) => {
                    if(res.data.find(e => e.tipo == "NP")) {
                        setSwitchNaoPertube(true)
                    }
                })
        )
    }, []);

    const desistir = (e) => {
        e.preventDefault();
        if (motivo == "" || !tipoDesistencia || tipoDesistencia === "Selecione") {
            Swal.fire({
                titleText: "Campos inválidos",
                text: "Preencha o motivo e as considerações para prosseguir",
                icon: "warning"
            });
            return
        }
        const dados = {
            id_oportunidade: id_oportunidade,
            consideracoes: motivo,
            id_desistencia_tipo: tipoDesistencia,
            nao_pertube: naoPertube
        }
        trackPromise(
            OportunidadeService.desistencia(dados).then(
                (response) => {
                    if(response.data.erro) {
                        Swal.fire({
                            titleText: "Ooops...",
                            text: "Ocorreu um erro ao atualizar o status: " + (response?.data?.message ?? response.data.erro),
                            icon: "info"
                        });
                        close()
                        setRefreshDesistencia()

                        return 0;
                    }
                    Swal.fire({
                        titleText: "Sucesso",
                        text: "Desistência realizada com sucesso",
                        icon: "success"
                    }).then(response => {
                        if(atualizarDesistencia){
                            atualizarDesistencia()
                        }
                        close()
                        setRefreshDesistencia()
                    });                   
                  
                },
                (error) => {
                    if(error?.response.status == 403){
                        Swal.fire({
                            titleText: "Ooops...",
                            text: error?.response?.data,
                            icon: "info"
                        });
                    }else{
                        Swal.fire({
                            titleText: "Ooops...",
                            text: "Ocorreu um erro ao atualizar o status: " + (error?.response?.data?.message ?? error),
                            icon: "error"
                        });
                    }

                }
            )
        )

      
    };
    return <>
        <Container fluid>
            <Form className="formCadastro">
                {switchNaoPertube && (
                    <Row>
                        <DommusCheckboxToggle
                            className="switch-np"
                            value={nao_pertube}
                            setValue={() => setNaoPertube(!nao_pertube)}
                            label={`Enviar para etapa "Não Pertube"`}
                            tooltip={`Enviar para etapa "Não Pertube"`}
                        />
                    </Row>
                )}
                
                <Row>
                    <Form.Group as={Col} controlId="formGridState"  >
                        <Form.Label>Motivo <DommusRequiredSpan /></Form.Label>
                        <Form.Control as="select" defaultValue="Selecione"  onChange={(event) => setTipoDesistencia(event.target.value)}>
                            <option hidden>Selecione</option>
                            {tiposList.length != 0 ? tiposList.map(item =>
                                <option value={item.id_desistencia_tipo} key={item.id_desistencia_tipo}>{item.nome}</option>
                            ) : <></>}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Considerações: <DommusRequiredSpan /></Form.Label>
                        <Form.Control type="textarea" as="textarea" rows="3" onChange={(event) => setMotivo(event.target.value)} />
                    </Form.Group>
                </Row>

                <Row className="formFooter">
                    <Col>
                        <button type="button" onClick={(e) => desistir(e)} className="btn btn-success"><FontAwesomeIcon icon={faSave}
                            style={{ marginRight: '15px' }} />Salvar
					</button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>
}