import React, { createContext, ReactElement, useState } from "react";
import { swalErro } from "../../helpers/DommusSwal";
import { SubstatusType } from "../../pages/Configuracoes/FunilDeVendas/Funil/types";
import SubStatusService from "../../services/SubStatusService";

export const SubstatusOportunidadeContext = createContext({});

export function SubstatusOportunidadeProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [listaSubstatusOportunidade, setListaSubstatusOportunidade] = useState<
    Array<SubstatusType>
  >([]);

  const recarregarSubstatusOportunidade = () => {
    let filtro = {
      oportunidade: {
        expirados_desistencias: true,
      },
    };
    SubStatusService.listaSubStatus()
      .then((response) => {
        setListaSubstatusOportunidade(response.data);
      })
      .catch((erro) =>
        swalErro(
          `Houve um erro ao tentar carregar etapas. Status: ${erro?.response?.status}, Erro: ${erro?.response?.data?.message}, ${erro}`
        )
      );
  };

  return (
    <SubstatusOportunidadeContext.Provider value={{
        listaSubstatusOportunidade,
        recarregarSubstatusOportunidade,
    }}>
      {children}
    </SubstatusOportunidadeContext.Provider>
  );
}
