import React, {useCallback, useEffect, useState, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faTasks} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/min/locales";
import {momentLocalizer} from "react-big-calendar";
import DommusCalendar from "../../DommusCalendar";
import "./style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {trackPromise} from "react-promise-tracker";
import {errorToast} from "../../DommusToast";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService";
import guService from "../../../services/GuService";
import oportunidadeService from "../../../services/OportunidadeService";
import formatDommus from "../../../helpers/format";

interface AgendaProps {
    openAgenda: boolean,
    closeAgenda: () => void,
}

export default function Agenda({openAgenda, closeAgenda}: AgendaProps) {
    moment.locale("pt-br");

    const [events, setEvents] = useState([] as Array<object>);
    const [eventsTarefas, setEventsTarefas] = useState([]);
    const [trocar, setTrocar] = useState(false);
    const [dateType, setDateType] = useState("week");
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
    const [currentViewMode, setCurrentViewMode] = useState("week");
    const [selectedDateRange, setSelectedDateRange] = useState({start: null, end: null});
    const [exibeConcluidas, setExibeConcluidas] = useState(1)
    const [calendarInterval, setCalendarInterval] = useState('');
    const [isTasksChecked, setIsTasksChecked] = useState(true);
    const [isVisitsChecked, setIsVisitsChecked] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const handleSelectEvent = useCallback((event: any) => {
        if (event.id_oportunidade) {
            window.open(`/oportunidade/${event.id_oportunidade}?aba=atendimento`);
        }
        if (event.id_oferta_ativa) {
            window.open(`/oferta-ativa/${event.id_oferta_ativa}/agendados`);
        }
    }, []);


    const messages = {
        date: "Data",
        time: "Horário",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Semana de Trabalho",
        day: "Dia",
        month: "Mês",
        previous: "<",
        next: ">",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",
        noEventsInRange: "Não há mais eventos nesse período.",
        showMore: (total: any) => `+${total} mais`,
    };

    const handleCalendarNavigate = useCallback((date: any) => {
        setSelectedDate(moment(date).format("YYYY-MM-DD"));
    }, []);

    useEffect(() => {
        switch (currentViewMode) {
            case "month":
                handleSetAgendaWidth("90%");
                break;
            case "week":
                handleSetAgendaWidth("90%");
                break;
            case "day":
                handleSetAgendaWidth("40%");
                break;
            default:
                handleSetAgendaWidth("90%");
        }
    }, [currentViewMode]);

    useEffect(() => {
        trackPromise(
            ConfiguracoesGestaoService
                .buscarConfiguracao('exibe_concluidas_agenda')
                .then((response) => {
                    if (response.data !== "") {
                        setExibeConcluidas(response.data)
                    }
                })
        );
    }, [])


    const handleSetAgendaWidth = useCallback((widthProp = "50%") => {
        const el = document.querySelector("section.equipes") as HTMLElement;
        el.style.width = widthProp;
        el.style.minWidth = widthProp;
    }, []);

    let eventPropGetter = (event: any) => {
        let style = {
            backgroundColor: !event.finalizado ? event.cor : '',
            color: "#fff",
            border: event.tarefa_agendada ? '3px solid #747474' : 'none',
        };
        if(event.cor) {
            style = {
                "--event-color": event.cor,
                "--event-color-hover": formatDommus.handleDarkenColor(event.cor)
            } as any
        }

        return {
            style: style,
            className: event.finalizado ? "event-finished" : "",
        }
    };

    let formats = {
        dateFormat: "DD",
        dayFormat: "DD",
        weekdayFormat: "ddd",
        timeGutterFormat: (date: any, culture: string, localizer: any) => {
            return localizer.format(date, 'HH:mm', culture)
        },
        monthHeaderFormat: "MMMM/YYYY",
        weekHeaderFormat: "MM/YYYY",
        dayHeaderFormat: "dddd - DD/MM"
    };


    function configData(data: any) {
        var dia = data.getDate();
        var mes = data.getMonth() + 1;
        var ano = data.getFullYear();

        if (dia < 10)
            dia = '0' + dia;

        if (mes.toString().length < 2)
            mes = '0' + mes;

        return ano + "-" + mes + "-" + dia;
    }

    function configHora(data: any) {
        var horas = data.getHours();
        var minutos = data.getMinutes();
        var segundos = data.getSeconds();

        if (horas.toString().length < 2)
            horas = '0' + horas;

        if (minutos.toString().length < 2)
            minutos = '0' + minutos;

        if (segundos.toString().length < 2)
            segundos = '0' + segundos;

        return horas + ":" + minutos + ":" + segundos;
    }

    function startOfDate(dataInicio: any) {
        return dataInicio.startOf(dateType).format('YYYY-MM-DD');
    }

    function endOfDate(dataFim: any) {
        return dataFim.endOf(dateType).format("YYYY-MM-DD");
    }

    useEffect(() => {
        let valor = guService.getLocalState('intervaloCalendario') as string;
        setCalendarInterval((parseFloat(valor) * 2).toString() as string);
    }, [guService.getLocalState('intervaloCalendario'), selectedDateRange]);

    const handleGetEvents = useCallback(() => {

        if (!openAgenda) return;
        let dia = moment(selectedDate);
        let dataInicio = selectedDateRange.start != null ? moment(selectedDateRange.start).format('YYYY-MM-DD') : startOfDate(dia)
        let dataFim = selectedDateRange.end != null ? moment(selectedDateRange.end).format('YYYY-MM-DD') : endOfDate(dia)
        let filtros = JSON.parse(guService.getLocalState('filtros') as string);
        trackPromise(
            oportunidadeService.buscarAgenda(dataInicio, dataFim, isVisitsChecked, isTasksChecked, filtros)
                .then((response: any) => {
                        let nvlista = []
                        nvlista = response.data.filter(function (ev: any) {
                            return !ev.finalizado;
                        })

                        const arrayEventos =
                            exibeConcluidas ?
                                response.data.map((event: any) => {
                                    let startTime = new Date(
                                        moment(event.data + " " + "08:00:00").format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    );
                                    let endTime = new Date(
                                        moment(event.data + " " + "09:00:00")
                                            .add(calendarInterval, "minutes")
                                            .format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                    );
                                    if (event.hora) {
                                        startTime = new Date(
                                            moment(event.data + " " + event.hora).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                        );
                                        endTime = new Date(
                                            moment(event.data + " " + event.hora)
                                                .add(calendarInterval, "minutes")
                                                .format("YYYY-MM-DD HH:mm:ss")
                                        );
                                    }
                                    return {
                                        id: event.id_oportunidade_atendimento,
                                        id_oportunidade: event.id_oportunidade,
                                        id_oferta_ativa: event.id_oferta_ativa,
                                        cor: event.cor_tipo_tarefa,
                                        tarefa_agendada: event.tarefa_agendada,
                                        title: `${event.titulo}`,
                                        start: startTime,
                                        finalizado: event.finalizado,
                                        end: endTime,
                                        className: "event-finished"
                                    };
                                })
                                :
                                nvlista.map((event: any) => {
                                    let startTime = new Date(
                                        moment(event.data + " " + "08:00:00").format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    );
                                    let endTime = new Date(
                                        moment(event.data + " " + "09:00:00").format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    );
                                    if (event.hora) {
                                        startTime = new Date(
                                            moment(event.data + " " + event.hora).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                        );
                                        let endTime = new Date(
                                            moment(event.data + " " + "09:00:00").format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                        );

                                    }
                                    return {
                                        id: event.id_oportunidade_atendimento,
                                        id_oportunidade: event.id_oportunidade,
                                        id_oferta_ativa: event.id_oferta_ativa,
                                        title: `${event.titulo}`,
                                        cor: event.cor_tipo_tarefa,
                                        start: startTime,
                                        finalizado: event.finalizado,
                                        end: endTime,
                                        className: "event-finished",
                                    };
                                });
                        setEvents(arrayEventos);
                    },
                    (error) => {
                        errorToast.fire({text: `Ocorreu um erro ao : ${error}`});
                    }
                )
        )
    }, [openAgenda, selectedDate, isTasksChecked, isVisitsChecked, refresh]);

    const handleMainEvents = () => {
        return trocar === false ? events : eventsTarefas
    }

    useEffect(() => {
        if (openAgenda) {
            handleGetEvents();
        }
    }, [openAgenda, selectedDate, isTasksChecked, isVisitsChecked]);

    function handleRangeChange(range: any) {
        if (range.start) {
            setSelectedDate(range.start)
            setSelectedDateRange(range)
            setDateType('month');
        } else {
            setSelectedDate(range[0])
            setSelectedDateRange({start: null, end: null})
            if (range.length > 1) {
                setDateType('week');
            } else {
                setDateType('day');
            }
        }
    }

    return (
        <>
            <div
                className={"sidenav-overlay " + (openAgenda && "open")}
                onClick={() => {
                    closeAgenda();
                    let element = document.getElementsByClassName("rbc-btn-group")[0].children[0] as HTMLElement;
                    element.click();
                }}
            >
                &nbsp;
            </div>
            <section
                className={"equipes " + (openAgenda && "open")}
                style={{zIndex: 7, width: "100%"}}
            >
                <header>
                    <div className={"close-pane"}>
                        <FontAwesomeIcon icon={faArrowRight}
                                         onClick={() => {
                                             closeAgenda()
                                             let element = document.getElementsByClassName("rbc-btn-group")[0].children[0] as HTMLElement;
                                             element.click()
                                         }}
                        />
                    </div>
                </header>
                {openAgenda && <div>
                    <DommusCalendar
                        localizer={momentLocalizer(moment)}
                        events={handleMainEvents()}
                        views={["month", "week", "day", "agenda"]}
                        defaultView="week"
                        messages={messages}
                        startAccessor="start"
                        endAccessor="end"
                        formats={formats}
                        eventPropGetter={eventPropGetter}
                        onSelectEvent={(event: any) => {
                            handleSelectEvent(event);
                        }}
                        onNavigate={(event: any) => {
                            handleCalendarNavigate(event);
                        }}
                        onView={(event: any) => {
                            setCurrentViewMode(event);
                        }}
                        style={{height: '65vh'}}
                        steps={[30, 15, 7.5]}
                        onRangeChange={handleRangeChange}
                        onVisitsChange={(e: any) => {
                            setIsVisitsChecked(e);
                        }}
                        onTasksChange={(e: any) => {
                            setIsTasksChecked(e);
                        }}
                    />
                </div>}
            </section>
        </>
    );
}
