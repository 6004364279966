import React, { useState, useEffect } from 'react';
import '../NaoDistribuidos/index.css';
import { Dropdown } from "react-bootstrap";
import { Icon } from '@iconify/react';
import bagIcon from '@iconify-icons/ion/bag';
import bagCheck from '@iconify-icons/ion/bag-check';
import LeadService from '../../services/LeadService';
import ReactTooltip from 'react-tooltip';
import Swal from "sweetalert2";
import GuService from "../../services/GuService"

export default function NaoDistribuidos({ history }) {

  const [totalNaoDistribuidos, setTotalNaoDistribuidos] = useState(0);

  useEffect(() => {
    buscarNaoDistribuidos();
  }, []);
  function buscarNaoDistribuidos() {
    return LeadService.buscarCountNaoDistribuidos().then(
      (response) => {
        let dados = response.data;
        setTotalNaoDistribuidos(dados['leads'])
        GuService.setLocalState('tipoRecebimento', dados['tipo_recebimento'])
        if (dados['leads'] > 0 && dados['resgate'] == false) {
          abreNaoDistribuidos(dados['tipo_recebimento'], dados['leads'], false);
        }
      }
    )
  }
  function redistribuiLeads() {
    LeadService.redistribuiLeads().then(
      (response) => {
        if (response.status != 201) {
          Swal.fire({
            titleText: "Ops, você já resgatou um lead",
            text: response.data.mensagem,
            icon: "error"
          }).then(
            (result) => {
              setTimeout(function () {
                window.open(`/oportunidade/${response.data.id_oportunidade}`, '_blank');

                window.top.close();
              }, 100);
            }
          );;
        } else {
          setTimeout(function () {
            window.open(`/oportunidade/${response.data.id_oportunidade}`, '_blank');
            window.top.close();
          }, 100);
        }

      }
    )
  }
  async function abreNaoDistribuidos(tipo, contador, exibeAlerta) {
    if (contador === 0 && exibeAlerta === true) {
      Swal.fire({
        titleText: "Leads Represados",
        text: "Não existe nenhum lead represado qualificado para o seu atendimento",
        icon: "warning"
      });
    } else {
      if (tipo === 'M') {
        Swal.fire({
          titleText: "Leads Represados",
          html: 'Existe(m) ' + contador + ' lead(s) represado(s) qualificado(s) para o seu atendimento. Deseja resgatar TODOS os leads para atendimento?',
          icon: 'warning',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Resgatar Leads',
          cancelButtonText: 'Agora não',
          customClass: {
            confirmButton: 'btn btn-primary mr-3',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        }).then(
          (result) => {
            if (result.value) {
              redistribuiLeads();
            } else {
              //atualizaNotificacao(dados.id_notificacao)
            }
          }
        );
      }
      if (tipo === 'S') {
        Swal.fire({
          titleText: "Leads Represados",
          html: 'Existe(m) ' + contador + ' lead(s) represado(s) qualificado(s) para o seu atendimento. Você pode resgatar 1 lead por vez. Deseja resgatar 1 lead para atendimento? <br/><span style="font-size:12px">OBSERVAÇÃO: Você poderá resgatar um novo lead após registrar o atendimento do lead que está sendo resgatado neste momento.</span>',
          icon: 'warning',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Resgatar Lead',
          cancelButtonText: 'Agora não',
          customClass: {
            confirmButton: 'btn btn-primary mr-3',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        }).then(
          (result) => {
            if (result.value) {
              redistribuiLeads();
            } else {
              //atualizaNotificacao(dados.id_notificacao)
            }
          }
        );
      }
      if (tipo === 'N') {
        window.open(`/leads/represados`, '_blank');
      }
    }
  }

  return (
    <>
      <ReactTooltip />
      <Dropdown className="naoDistribuidosTopo" show={false} onClick={() => abreNaoDistribuidos( GuService.getLocalState('tipoRecebimento'), totalNaoDistribuidos, true)}>
        <Dropdown.Toggle className="notificacoes-dropdown">
          {totalNaoDistribuidos > 0 ? <Icon icon={bagCheck} width="30" data-tip={"Existem " + totalNaoDistribuidos + " leads represados, clique para acessar."} /> : <Icon icon={bagIcon} width="30" data-tip={"Não existe nenhum lead represado."} />}
          <div className="notificationIcon">{totalNaoDistribuidos}</div>
        </Dropdown.Toggle>
      </Dropdown>
    </>

  )

}
