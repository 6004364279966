import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../NovaOportunidade/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faRetweet,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import OportunidadeService from "../../../services/OportunidadeService";
import LeadService from "../../../services/LeadService";
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import DommusOptionalSpan from "../../../components/DommusOptionalSpan";
import utilsService from "../../../services/utils";
import DommusToggle from "../../../components/DommusToggle";
import { trackPromise } from "react-promise-tracker";
import Alert from "react-bootstrap/Alert";
import formatDommus from "../../../helpers/format";
import stringHelper from "../../../helpers/StringHelper";
// import PhoneInput from "react-phone-number-input/input";
import Swal from "sweetalert2";
import {
  cpfMask,
  cepMask,
  celularMask,
} from "../../../components/InputTextMask";
import FormDistribuicao from "../../../components/FormDistribuicao";
import guService from "../../../services/GuService";
import equipeService from "../../../services/EquipeService";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService";
import ModalDommus from "../../../components/Modal";
import Solicitacoes from "../../SolicitacoesClonagem/Solicitacao";
import StatusOportunidadeService from "../../../services/StatusOportunidadeService";
import { EmpreendimentoContext } from "../../../components/GlobalContext/globalContext";
import SubStatusService from "../../../services/SubStatusService";
import { EmpreendimentoType } from "../../DetalheOportunidade/Cadastro/DistributionForm";
import { CampanhaType, MidiaType, OrigemType } from "../../DetalheOportunidade/Cadastro/types";
import { NivelInteresseType } from "../../../components/FormFiltroPadrao/types";
import { StatusOportunidadeType, SubstatusType } from "../../Configuracoes/FunilDeVendas/Funil/types";
import useTelefoneInternacionalInput from "../../../hooks/useTelefoneInternacionalInput";
import {AtendimentoType} from "./../Visualizacao/OportunidadesListaPaginada/types/AtendimentoType";
import NovoAtendimento from "../../DetalheOportunidade/Atendimento/NovoAtendimento/"
import { CanaisContext } from "../../../contexts/CanaisContext";
import campanhaService from "../../../services/CampanhaService";
import { swalErro, toastErro } from "../../../helpers/DommusSwal";
import MidiaService from "../../../services/MidiaService";
import DommusSwitch from "../../../components/DommusSwitch";
import perfilService from "../../../services/PerfilService";
import { CamposCadastroOpvTravadosType } from "../types";
import { OportunidadePorStatusPaginadoType } from "../Visualizacao/FunilOportunidades/types";
import { FormAtendimentoCadastroOpv } from "../../../components/formAtendimentoCadastroOpv";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { ParticipanteType } from "../../../components/GlobalContext/types";

interface NovaOportunidadeProps {
    history?: {} | undefined,
    empreendimentos?: Array<EmpreendimentoType>,
    campanhas?: Array<CampanhaType>,
    inserirLista: (d: any) => void,
    midias?: Array<MidiaType>,
    origens?: Array<OrigemType>
    closeModal: () => void,
    exibeDialogoAtendimento?: boolean | undefined
    handleAbrirOportunidade?: (oportunidade:OportunidadePorStatusPaginadoType) => void;
}

export function NovaOportunidade({
  history,
  empreendimentos,
  campanhas,
  inserirLista,
  midias,
  origens,
  closeModal,
  exibeDialogoAtendimento,
  handleAbrirOportunidade = ()=>{}
} : NovaOportunidadeProps ) {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [refInputTelefone, objetoInputTelefone, getNumeroInternacional] = useTelefoneInternacionalInput();
  const [email, setEmail] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [cep, setCep] = useState("");
  const [campanha, setCampanha] = useState("");
  const [countCampanha, setCountCampanha] = useState("");
  const [countMidias, setCountMidias] = useState("");
  const [midia, setMidia] = useState("");
  const [origem, setOrigem] = useState(0);
  const [travaCampo, setTravaCampo] = useState(false);
  const [campoTravado, setCampoTravado] = useState("");
  const [idProcesso, setIdProcesso] = useState("");
  const [idLead, setIdLead] = useState("");
  const [idOportunidade, setIdOportunidade] = useState("");
  const [nivelInteresse, setNivelInteresse] = useState(3);
  const tipoUsuario = Number(guService.getLocalState("tipoUsuario"));
  const tipoPerfil = guService.getLocalState("tipoPerfil");
  const [openModalNovoAtendimento,setOpenModalNovoAtendimento] = useState<boolean>(false);
  const [novoAtendimento,setNovoAtendimento] = useState<AtendimentoType>({id_oportunidade:0,lead_nome:''});
  const [dataDistribuicao, setDataDistribuicao] = useState({} as any);
  const [cacheLeadInserir, setCacheLeadInserir] = useState({} as any);
  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] = useState("");
  const [idCanal, setIdCanal] = useState('')
  const [equipesPdv, setEquipesPdv] = useState([]);
  const [equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada] =
    useState("");
  const [equipesPreAtendimento, setEquipesPreAtendimento] = useState([] as any);
  const [equipePdvSelecionada, setEquipePdvSelecionada] = useState("");
  const [gerentesPdv, setGerentesPdv] = useState([]);
  const [gestoresPreAtendimento, setGestoresPreAtendimento] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [hideBotaoAcessar, setHideBotaoAcessar] = useState(false);
  const [idClone, setIdClone] = useState("");
  const [permissoesClonagem, setPermissoesClonagem] = useState([] as any);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [item, setItem] = useState([] as any);
  const [tipoExibicaoStatusOpv, setTipoExibicaoStatusOpv] = useState([])
  const [arrayStatusOpv, setArrayStatusOpv] = useState([])
  const [statusOportunidade, setStatusOportunidade] = useState(0)
  const [arraySubStatus, setArraySubStatus] = useState([] as Array<SubstatusType>)
  const [subStatus, setSubStatus] = useState(0)
  const [arrayNivelInteresse, setArrayNivelInteresse] = useState([])
  const [dadosOportunidade, setDadosOportunidade] = useState({});
  const [desabilitaOrigem, setDesabilitaOrigem] = useState([] as any)
  const [desabilitaCampanha, setDesabilitaCampanha] = useState([] as any)
  const [desabilitaMidias, setDesabilitaMidias] = useState([] as any)
  const global = useContext(EmpreendimentoContext);
  const { canais, buscarCanais } = useContext(CanaisContext)
  const exibirCanalOpv = JSON.parse(guService.getLocalState('exibirCanalOpv') || "false") ;
  const [permitirDuplicidadeOpv, setPermitirDuplicidadeOpv] = useState(false as Boolean)
  const [campanhasVisiveisUsuario, setCampanhasVisiveisUsuario] = useState<CampanhaType[]>([])
  const [midiasVisiveisUsuario, setMidiasVisiveisUsuario] = useState<MidiaType[]>([])
  const [configuracaoVinculoCampanhaMidia, setConfiguracaoVinculoCampanhaMidia] = useState(false)
  const [criarOpvSequencial, setCriarOpvSequencial] = useState<boolean>(false);
  const [origensValidas, setOrigensValidas] = useState(origens)
  const [travarCamposCadastro, setTravarCamposCadastro] = useState(false);
  const [camposCadastroTravados, setCamposCadastroTravados] = useState<CamposCadastroOpvTravadosType | null>({} as CamposCadastroOpvTravadosType);
  const [camposCadastroTravadosOculto, setCamposCadastroTravadosOculto] = useState(Number(guService.getLocalState('campos_cadastro_opv_travados_oculto') ?? false))
  const methodsAtendimentoVisita = useForm<AtendimentoType>({defaultValues: {
    id_tipo_atendimento: 4,
    data: moment().format("YYYY-MM-DD"),
    hora: moment().format("HH:mm"),
    tarefa_realizada: 0,
  }})
  const methodsAtendimentoTarefa = useForm<AtendimentoType>({defaultValues: {
    id_tipo_atendimento: 5,
    data: moment().format("YYYY-MM-DD"),
    hora: moment().format("HH:mm"),
    tarefa_realizada: 0,
  }})

  const methodsAtendimentoComentario = useForm<AtendimentoType>({defaultValues: {
    id_tipo_atendimento: 7,
    observacao: ""
  }})

  useEffect(() => {
    if (desabilitaMidias.includes(tipoUsuario) && midiasVisiveisUsuario?.length) {
      let valorMidia = midiasVisiveisUsuario[0]?.id_midia;
      setMidia(valorMidia.toString())
    }
  }, [desabilitaMidias, midiasVisiveisUsuario])

  useEffect(() => {
    if (desabilitaCampanha.includes(tipoUsuario) && campanhasVisiveisUsuario?.length) {
     let valorCampanha = campanhasVisiveisUsuario[0]?.id_campanha;
     setCampanha(valorCampanha.toString())
    }
  }, [desabilitaCampanha, campanhasVisiveisUsuario])

  useEffect(() => {
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('exibe_status_opv')
        .then((response) => {
          if (response.data !== "") {
            setTipoExibicaoStatusOpv(response.data)
          }
        })
    );
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('desabilita_origem_cadastro_opv')
        .then((response) => {
          if (response.data !== "") {
            setDesabilitaOrigem(response.data)
          }
        })
    );
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('desabilita_campanha_cadastro_opv')
        .then((response) => {
          if (response.data !== "") {
            setDesabilitaCampanha(response.data)
          }
        })
    );
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('desabilita_midias_cadastro_opv')
        .then((response) => {
          if (response.data !== "") {
            setDesabilitaMidias(response.data)
          }
        })
    );
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('permitir_opv_duplicidade_dados')
        .then((response) => {
          if (response.data !== "") {
            setPermitirDuplicidadeOpv(response.data == 1 ? true : false )
          }
        })
    );
    trackPromise(
      ConfiguracoesGestaoService
        .buscarConfiguracao('criar_opv_sequencial')
        .then((response) => {
          if (response.data !== "") {
            setCriarOpvSequencial(response.data)
          }
        })
    );
    buscarCamposCadastroTravados()
    buscaStatus()
    buscaNivelInteresse()
    buscarCanais({ oculto: 0 })
    buscarCampanhasVisiveisUsuario()
    buscarConfiguracaoVinculoCampanhaMidiaOrigem()

  }, [])

  useEffect(() => {
    if(configuracaoVinculoCampanhaMidia) {
      const campanhaSelecionada = campanhasVisiveisUsuario.find(campanhaVisivel => campanhaVisivel.id_campanha == Number(campanha))
      setMidiasVisiveisUsuario(campanhaSelecionada?.campanha_midias.map(campanhaMidia => campanhaMidia.midia) || [])
      setOrigensValidas([])
    }
  }, [campanha])

  useEffect(() => {
    if(configuracaoVinculoCampanhaMidia) {
      const origensTratadas = origens?.map(origemValida => {
        if(!Array.isArray(origemValida?.midias)) {
          origemValida.midias = typeof origemValida?.midias == 'string' && JSON.parse(origemValida?.midias || '[]') || []
        }
        return origemValida
      }).filter(origemValida => Array.isArray(origemValida.midias) && origemValida.midias.includes(Number(midia)))
      setOrigensValidas(origensTratadas)
    }
  }, [midia])

  const ativarCriacaoVisita = useMemo(()=>{
    return Number(guService.getLocalState("ativar_visita_cadastro_opv"));
  },[])

  const ativarCriacaoTarefa = useMemo(()=>{
    return Number(guService.getLocalState("ativar_tarefa_cadastro_opv"))
  },[])

  const ativarCriacaoComentario = useMemo(()=>{
    return Number(guService.getLocalState("ativar_comentario_cadastro_opv"))
  },[])

  function buscarConfiguracaoVinculoCampanhaMidiaOrigem() {
    trackPromise(ConfiguracoesGestaoService.buscarConfiguracao('vincular_campanha_midia_origem'))
      .then(response => {
        const configVinculoCampanhaMidia = !!response.data
        setConfiguracaoVinculoCampanhaMidia(configVinculoCampanhaMidia)
        if(configVinculoCampanhaMidia) {
          setOrigensValidas([])
        } else {
          buscarMidiasVisiveisUsuario()
        }
      })
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        swalErro('Houve um erro ao buscar a configuração do vinculo de campanha, mídia e origem.')
      })
  }

  function buscarMidiasVisiveisUsuario() {
    trackPromise(MidiaService.buscarMidias())
      .then(response => setMidiasVisiveisUsuario(response.data))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        swalErro('Houve um erro ao buscar as mídias.')
      })
  }

  function buscarCampanhasVisiveisUsuario() {
    trackPromise(campanhaService.buscarCampanhas())
      .then(response => setCampanhasVisiveisUsuario(response.data))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        swalErro('Houve um erro ao buscar as campanhas.')
      })
  }

  function handleAccess(){
    if(idClone == idOportunidade){
      window.open(`/oportunidade/${idOportunidade}`, "_blank")

    }
    if(idClone == idProcesso){
      window.open( guService.getLocalState("linkVendas") + "index_ui.php?mgr=MQ==&ui=NjM=&id_processo=" + idProcesso, "_blank" );    }
  }
  async function buscaStatus() {
    await trackPromise(
        StatusOportunidadeService.listaEtapas({ etapas_cadastro_oportunidade: true, id_funil: guService.getLocalState('idFunil') ?? 0 } as any).then((response) => {
            if (response.data !== undefined) {
                const buscaStatus = response.data;
                setArrayStatusOpv(buscaStatus);
                if(buscaStatus.length == 1) {
                  setStatusOportunidade(buscaStatus[0].id_status_oportunidade),
                  buscarSubStatusPorStatus(buscaStatus[0].id_status_oportunidade)
                }
            }
        }
        ).catch(error => Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar as etapas do funil de vendas: " + error,
            icon: "error"
        }))
    )
  }

  async function buscaNivelInteresse()
  {
        await trackPromise(
            OportunidadeService.buscarNivelInteresse().then((res) => {
                  if ( res.data !== undefined )
                  {
                        const nivelInteresse = res.data;
                        const nivelInteresseNormal = nivelInteresse.find((data: NivelInteresseType) => data.descricao === "Normal");

                        setArrayNivelInteresse(nivelInteresse);
                        setNivelInteresse(Number(nivelInteresseNormal?.id_nivel_interesse));
                  }
            })
        )
  }

  function configuraGerentes(tipo: any, idequipe: number) {
    let gerentesPdvList = [] as any;
    let gestoresPreAtendimentoList = [] as any;
    if (tipo == "pdv") {
      setEquipePdvSelecionada(String(idequipe));
      equipesPdv.forEach((equipe: any) => {
        if (equipe.id_equipe == idequipe) {
          equipe.gerentes.forEach((gerente: any) => {
            const item = {
              id_gerente: gerente.id_usuario,
              nome_gerente: gerente.nome,
              ativo: gerente.ativo,
              corretores: gerente.corretores,
            };
            gerentesPdvList.push(item);
          });
        }
      });
      setGerentesPdv(gerentesPdvList);
    } else if (tipo == "pre") {
      setEquipePreAtendimentoSelecionada(String(idequipe));
      equipesPreAtendimento.forEach((equipe: any) => {
        if (equipe.id_equipe == idequipe) {
          equipe.gerentes.forEach((gerente: any) => {
            const item = {
              id_gerente: gerente.id_usuario,
              nome_gerente: gerente.nome,
              corretores: gerente.corretores,
              ativo: gerente.ativo,
            };
            gestoresPreAtendimentoList.push(item);
          });
        }
      });
      setGestoresPreAtendimento(gestoresPreAtendimentoList);
    }
  }

  function buscaEquipes() {
    trackPromise(
      equipeService
        .buscaMembrosEquipes(empreendimentoSelecionado)
        .then((response) => {
          if (response.data !== "") {
            let equipesPdvList = [] as any;
            let equipesPreAtendimentoList = [] as any;
            Object.values(response.data).forEach((equipe: any) => {
              if (equipe.tipo_equipe == "P") {
                equipesPdvList.push(equipe);
              } else {
                equipesPreAtendimentoList.push(equipe);
              }
            });
            setEquipesPreAtendimento(equipesPreAtendimentoList);
            setEquipesPdv(equipesPdvList);
          }
        })
    );
  }

  function buscarCamposCadastroTravados(){
    trackPromise(perfilService.buscarCamposCadastrosTravados(guService.getLocalState('idUsuario'))).then((response)=>{
      let dados = response?.data;
      setCamposCadastroTravados(dados);
      setTravarCamposCadastro(dados ? true : false);
      if(response.data){
        setCampanha(dados?.campanha);
        setMidia(dados?.midia);
        setOrigem(dados?.origem);
        setStatusOportunidade(dados?.status_oportunidade);
        if(dados?.status_oportunidade){
          setStatusOportunidade(Number(dados?.status_oportunidade)),
          buscarSubStatusPorStatus(Number(dados?.status_oportunidade))
        }
        setSubStatus(dados?.substatus_oportunidade);
        setNivelInteresse(dados?.nivel_interesse);
        setIdCanal(dados?.canal);

      }
    }).catch((error)=>{
        Swal.fire(
          'Ooops... Ocorreu um erro ao carregar campos travados.',
          '',
          'error'
        )
    })
  }

  const abrirModalNovoAtendimento = (id_oportunidade:number,lead_nome:string) =>  {
    setOpenModalNovoAtendimento(true)
    setNovoAtendimento({id_oportunidade,lead_nome});
  }

  const callbackNovoAtendimento = () => {
    inserirLista(cacheLeadInserir);
    closeModal();
    global.setLeadCriado(true);
  }

  useEffect(() => {
    if (empreendimentoSelecionado) {
      buscaEquipes();
      verificaPermissao()
    }
  }, [empreendimentoSelecionado]);

  useEffect(() => {
    verificaPermissao()
  }, []);

  function buscaCep(cep: String) {
    if (cep.length >= 7) {
      utilsService.buscarPorCep(cep).then(
        (response) => {
          const dados = response.data;
          setBairro(dados.bairro);
          setCidade(dados.localidade);
          setEndereco(dados.logradouro);
          setEstado(dados.uf);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar o cep: " + error,
            icon: "error",
          });
        }
      );
    } else {
      setCep("");
    }
  }

  const handleGetDataDistribuicao = useCallback((data: any) => {
    setDataDistribuicao(data);
  }, []);
  function validaEmail(campo: String) {
    if (!stringHelper.IsEmail(email) && email.length > 0) {
      Swal.fire({
        titleText: "E-mail inválido",
        text: "Preencha um e-mail válido prosseguir",
        icon: "error",
      });
      return;
    } else {
      buscaDados(campo);
    }
  }

  function buscaDados(campo: String) {
    let numeroCelular = ""
    if(objetoInputTelefone && getNumeroInternacional) {
      numeroCelular = getNumeroInternacional() as any
    }
    setCelular(numeroCelular)
    if (numeroCelular != "" || cpf != "" || email != "") {
      const envolvidos = {
        cpf:
          cpf != "" && cpf != null
            ? cpf.split(".").join("").split("-").join("")
            : "",
        telefone: numeroCelular,
        email: stringHelper.IsEmail(email) ? email : "",
      };
      trackPromise(
        LeadService.buscarEnvolvidos(envolvidos, campo).then((response: any) => {
          if (response.data.status) {
            if(permitirDuplicidadeOpv){
              setCampoTravado("");
              setTravaCampo(false);
            }else{
              setCampoTravado(response.data.campo)
              setTravaCampo(true);
              setIdProcesso(response.data.id);
              setIdOportunidade(response.data.id_oportunidade);
              setIdLead(response.data.id_lead);

              if ( !response.data.id_lead && !response.data.id_oportunidade ) {
                  setCampoTravado('CELULAR');
              }
           }
          } else {
            setCampoTravado("");
            setTravaCampo(false);
          }
        })
      );
    } else {
      setCampoTravado("");
      setTravaCampo(false);
    }
  }

  function verificaPermissao() {
    trackPromise(
      ConfiguracoesGestaoService.buscarConfiguracao("solicitar_clonagem")
    ).then((res) => {
      setPermissoesClonagem(res.data)
    }).catch((error) => {
      Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao buscar as permissões de clonagem: " + error,
          icon: 'error'
      });
  })
  }

  function TransferirOportunidade(){
    if (!descricao) {
      Swal.fire({
        titleText: "Campos inválidos",
        text: "Digite uma descrição para sua solicitação",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      titleText: "Transferir OPV",
      text: `Tem certeza que deseja solicitar a tranferência ${
        String(idClone) === String(idOportunidade)
          ? "da oportunidade"
          : String(idClone) === String(idLead)
          ? "do Lead"
          : ""
      } ${idClone}?`,
      icon: "warning",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      cancelButtonText: "Agora não",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let solicitacao = {};
        if (String(idClone) === String(idOportunidade)) {
          solicitacao = {
            id_oportunidade: idOportunidade,
            descricao: descricao,
            tipo_solicitacao: "T"
          };
        } else if (String(idClone) === String(idLead)) {
          solicitacao = {
            id_lead: idLead,
            descricao: descricao,
            tipo_solicitacao: "T"
          };
        }

        trackPromise(OportunidadeService.solicitarClone(solicitacao))
          .then((res) => {
            if(permissoesClonagem.includes(tipoUsuario)) {
              setItem(res.data)
              setOpenModal(true)
              return
            }
            Swal.fire({
              titleText: "Sucesso",
              text: "Solicitação enviada com sucesso, aguarde a aprovação!",
              icon: "info",
            });
            closeModal();
          })
          .catch((error) => {
            Swal.fire({
              titleText: "Ooops...",
              text: error.response.data,
              icon: "error",
            });
          });
      } else {
        return;
      }
    });
  }

  function prepararTelefoneSalvar(telefone: string) {
    let telefoneCadastro = telefone != ""
        ? telefone
          .split(" ")
          .join("")
          .split("-")
          .join("")
          .replace(/[()]/g, "")
          .replace('+55', '')
      : "";
    if(telefoneCadastro.length <= 11) {
      telefoneCadastro = `+55${telefoneCadastro}`
    } else if(telefoneCadastro.length == 13) {
      telefoneCadastro = `+${telefoneCadastro}`
    }

    return telefoneCadastro
  }

  function clonarOportunidade() {
    if (!descricao) {
      Swal.fire({
        titleText: "Campos inválidos",
        text: "Digite uma descrição para sua solicitação",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      titleText: "Clonar OPV",
      text: `Tem certeza que deseja solicitar a clonagem ${
        String(idClone) === String(idOportunidade)
          ? "da oportunidade"
          : String(idClone) === String(idLead)
          ? "do Lead"
          : String(idClone) === String(idProcesso)
          ? "do Processo"
          : ""
      } ${idClone}?`,
      icon: "warning",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      cancelButtonText: "Agora não",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let solicitacao = {};
        if (String(idClone) === String(idOportunidade)) {
          solicitacao = {
            id_oportunidade: idOportunidade,
            descricao: descricao,
            tipo_solicitacao: "C"
          };
        } else if (String(idClone) === String(idLead)) {
          solicitacao = {
            id_lead: idLead,
            descricao: descricao,
            tipo_solicitacao: "C"
          };
        } else if ( String(idClone) === String(idProcesso) ) {
          solicitacao = {
            id_processo: idProcesso,
            descricao: descricao,
            tipo_solicitacao: "C"
          }
        }

        trackPromise(OportunidadeService.solicitarClone(solicitacao))
          .then((res) => {
              let dadosOpv = {
                  id_midia: midia,
                  id_campanha: campanha,
                  id_origem: origem
              };
              setDadosOportunidade(dadosOpv);
            if(permissoesClonagem.includes(Number(tipoUsuario))) {
              setItem(res.data)
              setOpenModal(true)
              return
            }
            Swal.fire({
              titleText: "Sucesso",
              text: "Solicitação enviada com sucesso, aguarde a aprovação!",
              icon: "info",
            });
            closeModal();
          })
          .catch((error) => {
            Swal.fire({
              titleText: "Ooops...",
              text: error.response.data,
              icon: "error",
            });
          });
      } else {
        return;
      }
    });
  }

  function salvarOportunidade() {
    let celular = null
    if(objetoInputTelefone && getNumeroInternacional) {
      celular = getNumeroInternacional() as any
    }
    if(!nome) {
      Swal.fire({
        titleText: "Campos inválidos",
        text: "Preencha o nome antes de prosseguir.",
        icon: "warning",
      });
      return;
    } else if(!email && !celular) {
      Swal.fire({
        titleText: "Campos inválidos",
        text: "Preencha o e-mail ou o celular para prosseguir",
        icon: "warning",
      });
      return;
    } else if(email && !stringHelper.isEmail(email)) {
        Swal.fire({
          titleText: "E-mail inválido",
          text: "Preencha um e-mail válido para prosseguir",
          icon: "warning",
        });
        setEmail("");
        return;
    } else if(!origem) {
      Swal.fire({
        titleText: "Campos inválidos",
        text: "Selecione uma origem antes de prosseguir.",
        icon: "warning",
      });
      return
    }

    const dadosAtendimentoVisita = methodsAtendimentoVisita.getValues();
    const dadosAtendimentoTarefa = methodsAtendimentoTarefa.getValues();
    const dadosAtendimentoComentario = methodsAtendimentoComentario.getValues();

    if(dadosAtendimentoVisita.id_local || dadosAtendimentoVisita.id_tipo_visita || dadosAtendimentoVisita.observacao || dadosAtendimentoVisita.local){
      if(!dadosAtendimentoVisita?.data){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione uma Data da visita antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(!dadosAtendimentoVisita?.hora){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione uma Hora da visita antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(!dadosAtendimentoVisita?.id_local){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione um Local da visita antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(!dadosAtendimentoVisita?.id_tipo_visita){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione um Tipo de visita antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(dadosAtendimentoVisita.tarefa_realizada && !dadosAtendimentoVisita.visita?.descricao){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Informe uma descrição para a visita.",
          icon: "warning",
        });
        return
      }else if(dadosAtendimentoVisita.tarefa_realizada && !dadosAtendimentoVisita.visita?.visita_realizada && !dadosAtendimentoVisita.visita?.id_tipo_oportunidade_atendimento_visita){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Informe uma motivo da não conclusão da visita.",
          icon: "warning",
        });
        return
      }
    }

    if(dadosAtendimentoTarefa.titulo || dadosAtendimentoTarefa.id_tipo_tarefa || dadosAtendimentoTarefa.observacao){
      if(!dadosAtendimentoTarefa?.data){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione uma Data da tarefa antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(!dadosAtendimentoTarefa?.hora){
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Selecione uma Hora da tarefa antes de prosseguir.",
          icon: "warning",
        });
        return
      }else if(!dadosAtendimentoTarefa?.id_tipo_tarefa){
          Swal.fire({
            titleText: "Campos inválidos",
            text: "Selecione um Tipo da tarefa antes de prosseguir.",
            icon: "warning",
          });
          return
      }
    }

    if (
      dataDistribuicao.oportunidade.id_empreendimento === "" ||
      ((tipoUsuario == 7 || tipoUsuario == 8) &&
        (dataDistribuicao.oportunidade.id_gerente_pdv === "" ||
          dataDistribuicao.oportunidade.id_gerente_pdv === null ||
          dataDistribuicao.oportunidade.id_gerente_pdv === undefined)) ||
      ((tipoUsuario == 7 || tipoUsuario == 8) &&
        (dataDistribuicao.oportunidade.id_equipe_pdv === "" ||
          dataDistribuicao.oportunidade.id_equipe_pdv === null ||
          dataDistribuicao.oportunidade.id_equipe_pdv === undefined)) ||
      (tipoUsuario == 8 &&
        (dataDistribuicao.oportunidade.id_usuario_atendimento === "" ||
          dataDistribuicao.oportunidade.id_usuario_atendimento === null ||
          dataDistribuicao.oportunidade.id_usuario_atendimento ===
            undefined)) ||
      ((tipoUsuario == 25 || tipoUsuario == 26) &&
        (dataDistribuicao.oportunidade.id_gerente_pre_atendimento === "" ||
          dataDistribuicao.oportunidade.id_gerente_pre_atendimento === null ||
          dataDistribuicao.oportunidade.id_gerente_pre_atendimento ===
            undefined)) ||
      ((tipoUsuario == 25 || tipoUsuario == 26) &&
        (dataDistribuicao.oportunidade.id_equipe_pre_atendimento === "" ||
          dataDistribuicao.oportunidade.id_equipe_pre_atendimento === null ||
          dataDistribuicao.oportunidade.id_equipe_pre_atendimento ===
            undefined)) ||
      (tipoUsuario == 26 &&
        (dataDistribuicao.oportunidade.id_usuario_pre_atendimento === "" ||
          dataDistribuicao.oportunidade.id_usuario_pre_atendimento === null ||
          dataDistribuicao.oportunidade.id_usuario_pre_atendimento ===
            undefined)) ||
      !campanha ||
      !midia ||
      (exibirCanalOpv && !idCanal)
    ) {
      Swal.fire({
        titleText: "Atenção",
        text: "Preencha todos os campos obrigatórios",
        icon: "warning",
      });
      return;
    }

    if(statusOportunidade)
      dataDistribuicao.oportunidade.id_status_oportunidade = statusOportunidade

    if(subStatus)
      dataDistribuicao.oportunidade.id_substatus_oportunidade = subStatus


    let dados: any = {
      front_permitir_dados_duplicados: permitirDuplicidadeOpv,
      nome: nome,
      email: email,
      telefone: celular,
      celular,
      midia: {
        id_midia: midia,
      },
      campanha: {
        id_campanha: campanha,
      },
      origem: {
        id_origem: origem,
      },
      interesse: {
          id_nivel_interesse: nivelInteresse,
      },
      endereco: endereco,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      cep: cep,
      numero: numero,
      complemento: complemento,
      cpf:
        cpf != ""
          ? cpf
              .replace('.', '')
              .replace('.', '')
              .replace('-', '')
          : "",
      atendimentos: [],
    };
    if(idCanal){
      dados.id_canal = idCanal
    }

    dataDistribuicao.atendimentos = [];

    if(dadosAtendimentoTarefa.id_tipo_tarefa){
      dadosAtendimentoTarefa.id_tipo_tarefa = (dadosAtendimentoTarefa?.id_tipo_tarefa as any)?.value;
      dadosAtendimentoTarefa.id_tipo_atendimento = 5;
      dataDistribuicao.atendimentos.push(dadosAtendimentoTarefa);
    }
    
    if(dadosAtendimentoVisita.id_tipo_visita){
      dadosAtendimentoVisita.id_tipo_atendimento = 4;
      if(dadosAtendimentoVisita?.participantes?.length){
        dadosAtendimentoVisita.participantes = dadosAtendimentoVisita?.participantes.map((participante)=>{
          participante.id_participante_tipo = (participante.id_participante_tipo as any).value;
          return participante;
        }) as ParticipanteType[];
      }
      dataDistribuicao.atendimentos.push(dadosAtendimentoVisita);
    }

    if(dadosAtendimentoComentario.observacao){
      dadosAtendimentoComentario.id_tipo_atendimento = 7;
      dataDistribuicao.atendimentos.push(dadosAtendimentoComentario);
    }

    trackPromise(
      OportunidadeService.salvarLead(dados, dataDistribuicao).then(
        (response) => {
          let showCancel = false;
          let textAlert  = "Nenhuma OPV criada, redistribua o lead pela tela de represados."
          if(response?.data?.lead_oportunidades[0]?.oportunidade?.id_oportunidade){
            textAlert = `OPV-${response?.data?.lead_oportunidades[0]?.oportunidade.id_oportunidade} salva com sucesso`;
            showCancel  = true;
          }
          Swal.fire({
            titleText: "Sucesso",
            text: textAlert,
            icon: "success",
            showCloseButton: false,
            showCancelButton: showCancel,
            showDenyButton: true,
            showConfirmButton: criarOpvSequencial,
            focusConfirm: false,
            cancelButtonText: `Abrir OPV-${response?.data?.lead_oportunidades[0]?.oportunidade.id_oportunidade}`,
            confirmButtonText: `Ok, obrigado`,
            denyButtonText: 'Cadastrar nova OPV',
            customClass: {
              confirmButton: "btn btn-primary",
              denyButton: "btn btn-success",
              cancelButton: "btn btn-abrir-oportunidade",
              actions: "btn-action-sweet-criar-opv"
            },
            buttonsStyling: false,
          }).then((result) => {
              if (result.isDenied) {
                  if(criarOpvSequencial){
                    limparCampos();
                  }else{
                    closeModal();
                    criarAtendimento(response, dados);
                  }
              }else if(result.isConfirmed){
                closeModal();
              }else if(result.isDismissed){
                  handleAbrirOportunidade(response?.data?.lead_oportunidades[0]?.oportunidade);
                  closeModal();
              }
              global.setLeadCriado(true);
              inserirLista(response.data);
          });
        },
        (error) => {
          let mensagensErro = error.response.data;
          if(mensagensErro) {
            Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um ou mais erros ao salvar a oportunidade: " + Object.values(mensagensErro).join('\n'),
              icon: "warning",
            });
          } else {
            Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um erro ao salvar a oportunidade: " + error,
              icon: "error",
            });
          }
        }
      )
    );
  }

  function limparCampos(){
    setCampanha(camposCadastroTravados?.campanha as string ?? "");
    setMidia(camposCadastroTravados?.midia as string ?? "");
    setOrigem(camposCadastroTravados?.origem as number ?? 0);
    setStatusOportunidade(camposCadastroTravados?.status_oportunidade as number ?? 0);
    setSubStatus(camposCadastroTravados?.substatus_oportunidade as number ?? 0);
    setNivelInteresse(camposCadastroTravados?.nivel_interesse as number ?? 0);
    setIdCanal(camposCadastroTravados?.canal as string ?? 0);
    setNome("");
    setEmail("");
    setTelefone("");
    setEndereco("")
    setBairro("")
    setCidade("")
    setEstado("")
    setCep("")
    setNumero("")
    setComplemento("")
    setCpf("")
  }

  function criarAtendimento(response:any, dados:any){
    if(exibeDialogoAtendimento && response.data?.lead_oportunidades &&
      response.data.lead_oportunidades?.length && response.data.lead_oportunidades[0]?.oportunidade?.id_oportunidade){
      Swal.fire({
        titleText: "Registrar Atividade?",
        text: "Deseja registrar uma nova atividade na oportunidade criada?",
        icon: "question",
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        showDenyButton: true,
        focusConfirm: false,
        confirmButtonText: "Sim",
        denyButtonText: 'Não',
        customClass: {
          confirmButton: "btn btn-primary",
          denyButton: "btn btn-danger"
        },
        buttonsStyling: false,
      }).then((result) => {
        if(result.isConfirmed){
          setCacheLeadInserir(response.data)
          abrirModalNovoAtendimento(response.data.lead_oportunidades[0]?.oportunidade?.id_oportunidade, dados.nome);
        }else{
          inserirLista(response.data);
          closeModal();
          global.setLeadCriado(true);
        }
      })
    }else{
      inserirLista(response.data);
      closeModal();
      global.setLeadCriado(true);
    }
  }

  function buscarMidiaOrigem(id: number) {
    let index = origensValidas?.findIndex(function (object) {
      return object.id_origem === id;
    });
    if ( origensValidas && index && index > -1 && origensValidas[index].midias != "null") {
      midias = JSON.parse(origensValidas[index].midias);

      setCountMidias(midias ? String(midias.length) : "1");
    }

    return midias != null && midias.length > 0 ? midias[0] : 1;
  }

  function buscarCampanhaOrigem(id: CampanhaType["id_campanha"]) {
    let index = origensValidas?.findIndex(function (object) {
      return object.id_origem === id;
    });
    if (origensValidas && index && origensValidas[index].campanhas != "null") {
      campanhas = JSON.parse(origensValidas[index].campanhas);
      setCountCampanha(campanhas ? String(campanhas.length) : "0");
    }

    return campanhasVisiveisUsuario != null && campanhasVisiveisUsuario.length > 0 ? campanhasVisiveisUsuario[0] : "";
  }

  function buscarValueOrigem() {
    let id_origem = origem;
    if(origensValidas?.length == 1) {
      id_origem = origensValidas[0].id_origem
      if(id_origem != origem) {
        setOrigem(id_origem)
      }
    }
    // if(tipoUsuario == 8 || tipoUsuario == 7) {
    //   if(campanha) {
    //     setCampanha(String(buscarCampanhaOrigem(id_origem)));
    //   }
    //   if(midia == "") {
    //     setMidia(String(buscarMidiaOrigem(id_origem)));
    //   }
    // }
    return id_origem;
  }

  function montaOptionsOrigem() {
    let optionsOrigem;
    if (tipoPerfil == "R") {
      optionsOrigem = origensValidas?.map((origemOpcao) =>
        origemOpcao.tipo == "R" ? (
          <option
            key={origemOpcao.id_origem}
            selected={origemOpcao.id_origem == origem}
            value={origemOpcao.id_origem}
          >
            {origemOpcao.origem}
          </option>
        ) : (
          <></>
        )
      );
    } else {
      optionsOrigem = origensValidas?.map((origemOpcao) => (
        <option
          key={origemOpcao.id_origem}
          selected={origem == origemOpcao.id_origem}
          value={origemOpcao.id_origem}
        >
          {origemOpcao.origem}
        </option>
      ));
    }
    if(Array.isArray(optionsOrigem) && !optionsOrigem.length) {
      optionsOrigem = <></>
    }
    return optionsOrigem;
  }

  function alertProcesso() {
    Swal.fire({
      titleText: "Ooops...",
      text: "A ação de clonagem do processo deverá ser realizada através do módulo Dommus Vende.",
      icon: "info",
    })
  }

  function buscarSubStatusPorStatus(id: number){
    setSubStatus(0)
    SubStatusService.buscaSubstatusPorStatus(id).then((response) => {
      setArraySubStatus(response.data)
    })
  }

  function montaOpcoesCanais() {
    return canais.map(canal => <option key={`canal-${canal.id_canal}`} value={canal.id_canal}>{canal.nome}</option>);
  }

  function handleTravarCamposCadastro(){
    const {oportunidade} = dataDistribuicao;
    let campos = null;
    if(!travarCamposCadastro){
      campos = {
        campanha: campanha ?? null,
        midia: midia ?? null,
        origem: origem ?? null,
        status_oportunidade: statusOportunidade ?? null,
        substatus_oportunidade: subStatus ?? null,
        nivel_interesse: nivelInteresse ?? null,
        canal: idCanal ?? null,
        distribuicao: oportunidade
      }
    }
    trackPromise(perfilService.salvarCamposCadastrosTravados(guService.getLocalState('idUsuario'), campos)).then((response)=>{
      setCamposCadastroTravados(response?.data);
      setTravarCamposCadastro((current)=>!current)
    }).catch((error)=>{
      toastErro("Erro ao travar campos de cadastro!!!" + error);
      setTravarCamposCadastro(false);
      setCamposCadastroTravados(null);
    })
  }

  function handleCamposTravadosVisualizacao(checked:number){
    let campos = {
      campos_cadastro_opv_travados_oculto: checked,
    };
    if(checked){
      Swal.fire({
        titleText: "Deseja ocultar campos travados",
        text: "Os campos travados serão ocultados. Caso deseje visualizar novamente esses campos, desmarque essa opção.",
        icon: "question",
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        showDenyButton: true,
        focusConfirm: false,
        confirmButtonText: "Sim, ocultar",
        denyButtonText: 'Não, manter',
        customClass: {
          confirmButton: "btn btn-primary",
          denyButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if(result.isConfirmed){
          trackPromise(perfilService.updatePerfilUsuario(guService.getLocalState('idUsuario'), campos)).then((response)=>{
            setCamposCadastroTravadosOculto(checked);
            guService.setLocalState("campos_cadastro_opv_travados_oculto", checked);
          }).catch((error)=>{
            toastErro("Erro ao alterar visualização dos campos de cadastro!!!" + error);
          })
        }
      })
    }else{
        trackPromise(perfilService.updatePerfilUsuario(guService.getLocalState('idUsuario'), campos)).then((response)=>{
          setCamposCadastroTravadosOculto(checked);
          guService.setLocalState("campos_cadastro_opv_travados_oculto", checked);
        }).catch((error)=>{
          toastErro("Erro ao alterar visualização dos campos de cadastro!!!" + error);
        })
    }

  }

  return (
    <>
      <Form className="formCadastro">
        <DommusToggle title="Dados do Lead" toggle={true}>
          <Row>
            <Form.Group as={Col} controlId="formNome" className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.campanha && campanha ? 'campo-travados-oculto' : ""}`}>
              <Form.Label>
                Campanha: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                as="select"
                custom
                value={campanha}
                onChange={(event) => setCampanha(event.target.value)}
                disabled={ countCampanha === "1"  ? campanhasVisiveisUsuario && campanhasVisiveisUsuario.length <= 1 ? true : false :false
                ||  desabilitaCampanha.includes(tipoUsuario) ? true : (travarCamposCadastro && camposCadastroTravados?.campanha && campanha) ? true : false
                }
              >
                <option>Selecione a campanha</option>
                {campanhasVisiveisUsuario != undefined && campanhasVisiveisUsuario && campanhasVisiveisUsuario.map((campanha) => (
                  <option
                    key={campanha.id_campanha}
                    value={campanha.id_campanha}
                  >
                    {campanha.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formNome" className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.midia && midia ? 'campo-travados-oculto' : ""}`}>
              <Form.Label>
                Mídia: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                disabled={countMidias === "1" ||  desabilitaMidias.includes(tipoUsuario) ? true : (travarCamposCadastro && camposCadastroTravados?.midia && midia) ? true : false}
                as="select"
                value={midia}
                custom
                onChange={(event) => setMidia(event.target.value)}
              >
                <option>Selecione a mídia</option>
                {midiasVisiveisUsuario !== undefined && midiasVisiveisUsuario.length > 0 && midiasVisiveisUsuario.map((midia) => (
                  <option key={midia.id_midia} value={midia.id_midia}>
                    {midia.descricao}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formNome" className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.origem && origem ? 'campo-travados-oculto' : ""}`}>
              <Form.Label>
                Origem: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                as="select"
                value={buscarValueOrigem()}
                disabled={ desabilitaOrigem.includes(tipoUsuario)
                  ? tipoPerfil == "R"
                    ? false
                    : origensValidas && origensValidas.length === 1 ? true : false
                  : travarCamposCadastro && camposCadastroTravados?.origem && origem ? true : false
                }
                custom
                onChange={(event) => setOrigem(Number(event.target.value))}
              >
                <option value="" >Selecione a origem</option>
                {montaOptionsOrigem()}
              </Form.Control>
            </Form.Group>
            { exibirCanalOpv &&
              <Form.Group as={Col} controlId="formNome" className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.canal && idCanal ? 'campo-travados-oculto' : ""}`}>
                <Form.Label>Meio de entrada do Lead: <DommusRequiredSpan /></Form.Label>
                <Form.Control
                  as="select"
                  value={idCanal}
                  custom
                  disabled={(!canais.length || (travarCamposCadastro && camposCadastroTravados?.canal && idCanal)) as boolean}
                  onChange={
                    (event) => setIdCanal(event.target.value)
                    }
                >
                  <option value="">Selecione uma Opção</option>
                  {montaOpcoesCanais()}
                </Form.Control>
              </Form.Group>
            }
          </Row>
          {
            tipoExibicaoStatusOpv.map((item, i) => (
              Number(tipoUsuario) == item ?
              <>
                <Row key={i}>
                  <Form.Group
                    md={arraySubStatus.length > 0 ? 6 : 12}
                    as={Col}
                    controlId="formNome"
                    className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.status_oportunidade && statusOportunidade ? 'campo-travados-oculto' : ""}`}
                  >
                    <Form.Label>
                      Etapa da Oportunidade:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={statusOportunidade}
                      custom
                      onChange={(event) => (
                        setStatusOportunidade(Number(event.target.value)),
                        buscarSubStatusPorStatus(Number(event.target.value))
                      )}
                      disabled={(travarCamposCadastro && camposCadastroTravados?.status_oportunidade && statusOportunidade) as boolean}
                    >
                      <option hidden >Selecione a Etapa</option>
                      {
                        arrayStatusOpv.length > 0 ?
                          arrayStatusOpv.map((status: StatusOportunidadeType, i) => (
                              <option value={status.id_status_oportunidade} key={i}>{status.nome}</option>
                          )) : <></>
                      }
                    </Form.Control>
                  </Form.Group>
                  {
                    arraySubStatus.length > 0 ?
                    <Form.Group
                      md={6}
                      as={Col}
                      controlId="formNome"
                      className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.substatus_oportunidade && statusOportunidade ? 'campo-travados-oculto' : ""}`}
                    >
                      <Form.Label>
                        Status da Oportunidade:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={subStatus}
                        custom
                        onChange={(event) => (
                          setSubStatus(Number(event.target.value))
                        )}
                        disabled={(travarCamposCadastro && camposCadastroTravados?.substatus_oportunidade && statusOportunidade) as boolean}
                      >
                        <option hidden >Selecione o Status</option>
                        {
                          arraySubStatus.length > 0 ?
                          arraySubStatus.map((substatus, i) => (
                                <option value={substatus.id_substatus_oportunidade} key={i}>{substatus.nome}</option>
                            )) : <></>
                        }
                      </Form.Control>
                    </Form.Group> : <></>
                  }
                </Row>
              </>
              : <></>
            ))
          }
          {
              arrayNivelInteresse && arrayNivelInteresse.length > 0 ?
              <Row>
                  <Form.Group md={12} as={Col} controlId="formInteresse" className={`${camposCadastroTravadosOculto && travarCamposCadastro && camposCadastroTravados?.nivel_interesse && nivelInteresse ? 'campo-travados-oculto' : ""}`}>
                        <Form.Label>Nível de Interesse</Form.Label>
                        <Form.Control
                            as="select"
                            value={nivelInteresse}
                            custom
                            onChange={(event) => (
                                setNivelInteresse(Number(event.target.value))
                            )}
                            disabled={(travarCamposCadastro && camposCadastroTravados?.nivel_interesse && nivelInteresse) as boolean}
                        >
                          <option value="" >Selecione o Nível de Interesse</option>
                            {
                                  arrayNivelInteresse.length > 0 ?
                                      arrayNivelInteresse.map((nivInteresse:NivelInteresseType, index:number) => (
                                          <option value={nivInteresse.id_nivel_interesse} key={index}>
                                                {nivInteresse.descricao}
                                          </option>
                                      ))
                                  : <></>
                            }
                        </Form.Control>
                  </Form.Group>
              </Row>
              : <></>
          }
          <Row>
            <Form.Group as={Col} md="8" controlId="formNome">
              <Form.Label>
                Nome: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                value={nome}
                onChange={(event: any) => setNome(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>CPF:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CPF"
                value={cpfMask(cpf)}
                onChange={(event: any) => setCpf(cpfMask(event.target.value))}
                onBlur={() => buscaDados("CPF")}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="8" controlId="formNome">
              <Form.Label>
                E-mail: <DommusOptionalSpan />
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite o e-mail"
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                onBlur={() => validaEmail("E-MAIL")}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formContatoDestinatario">
              <Form.Label>Telefone: <DommusOptionalSpan /></Form.Label>
              <Form.Control type="text" ref={(refInputTelefone)} value={telefone} onChange={(e:any) => {setTelefone(celularMask(e.target.value))}} onBlur={() => buscaDados("CELULAR")}/>
            </Form.Group>
          </Row>
          {!celular && !email ? (
            <Alert variant="warning">
              Deve ser informado ao menos o endereço de e-mail ou o número do
              celular para cadastrar o lead.
            </Alert>
          ) : (
            <></>
          )}

          {travaCampo ? (
            <Alert variant="warning">
              Já existe{" "}
              {(idProcesso != "" && idProcesso != null)  && idOportunidade != ""
                ? "um processo e uma oportunidade cadastrados"
                : (idProcesso != "" && idProcesso != null) && idLead != ""
                ? "um processo e um lead cadastrados"
                : idProcesso != ""  && idProcesso != null
                ? "um processo cadastrado"
                : idLead != "" && idOportunidade == ""
                ? "um lead cadastrado"
                : "uma oportunidade cadastrada"}{" "}
              utilizando o <strong>{campoTravado}</strong> digitado.
            </Alert>
          ) : (
            <></>
          )}
        </DommusToggle>
        {!travaCampo ? (
          <>
            <DommusToggle title="Endereço" toggle={false}>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>CEP:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o cep"
                    value={cepMask(cep)}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => buscaCep(event.target.value)}
                    onChange={(event: any) => setCep(event.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="8" controlId="formNome">
                  <Form.Label>Endereço:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o endereço"
                    value={endereco}
                    onChange={(event: any) => setEndereco(event.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="lastRow">
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Bairro:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o bairro"
                    value={bairro}
                    onChange={(event: any) => setBairro(event.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Cidade:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite a cidade"
                    value={cidade}
                    onChange={(event: any) => setCidade(event.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Estado:</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={estado}
                    onChange={(event) => setEstado(event.target.value)}
                  >
                    <option value="">Selecione o estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="EX">Estrangeiro</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Número:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Digite o número da residencia"
                    value={numero}
                    onChange={(event: any) => setNumero(event.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Complemento:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite a complemento"
                    value={complemento}
                    onChange={(event: any) => setComplemento(event.target.value)}
                  />
                </Form.Group>
              </Row>
            </DommusToggle>
            <FormDistribuicao
              oportunidade={null}
              mostrarBtnSalvar={false}
              parentGetDataDistribuicao={handleGetDataDistribuicao}
              dadosDefaulDistribuicao={camposCadastroTravados?.distribuicao}
              travarCamposCadastro={travarCamposCadastro}
              camposTravadosOcultos={camposCadastroTravadosOculto}
            />
          </>
        ) : (
          <DommusToggle title="Registros encontrados" toggle={true}>
            <Row className="lastRow">
              <Form.Group className="d-flex flex-row w-100 px-3">
                <Form.Control
                  className="w-100 mr-2"
                  as="select"
                  custom
                  value={idClone}
                  onChange={(event) => {
                    let value = event.target.value;
                    setIdClone(value);
                    if([Number(idOportunidade), Number(idProcesso)].includes(Number(value))){
                      setHideBotaoAcessar(false);
                    }else{
                      setHideBotaoAcessar(true);
                    }
                  }}
                >
                  <option value="">Selecione</option>
                  {((!idOportunidade) && idLead) ? (
                    <option value={idLead}>
                      {" "}
                      Lead {formatDommus.zeroPad(Number(idLead), 5)}
                    </option>
                  ) : (
                    <></>
                  )}
                  {idOportunidade ? (
                    <option
                      value={idOportunidade}
                    >
                      OPV-{formatDommus.zeroPad(Number(idOportunidade), 5)}
                    </option>
                  ) : (
                    <></>
                  )}
                  {idProcesso ? (
                    <option
                      value={idProcesso}
                      onClick={() => {
                        alertProcesso();
                      }}
                    >
                      Processo {formatDommus.zeroPad(Number(idProcesso), 7)}
                    </option>
                  ) : (
                    <></>
                  )}
                </Form.Control>
                {idClone && !hideBotaoAcessar &&
                    <button type="button" style={{height: "38px", backgroundColor: "#183b64"}}
                            className="btn btn-secondary btn-sm"
                            onClick={handleAccess}>Acessar</button>
                }
              </Form.Group>
            </Row>
          </DommusToggle>
        )}
        {ativarCriacaoVisita ?
          <DommusToggle title="Visita" toggle={true}>
            <FormProvider {...methodsAtendimentoVisita}>
              <FormAtendimentoCadastroOpv
                idTipoAtendimento={4}
                dadosDistribuicao={dataDistribuicao}
              />
            </FormProvider>
          </DommusToggle>
          :<></>
        }
        {ativarCriacaoTarefa ?
          <DommusToggle title="Tarefa" toggle={false}>
            <FormProvider {...methodsAtendimentoTarefa}>
              <FormAtendimentoCadastroOpv
                idTipoAtendimento={5}
                dadosDistribuicao={dataDistribuicao}
              />
            </FormProvider>
          </DommusToggle>
          :<></>
        }
        {ativarCriacaoComentario ?
          <DommusToggle title="Comentário" toggle={false}>
            <FormProvider {...methodsAtendimentoComentario}>
              <FormAtendimentoCadastroOpv
                idTipoAtendimento={7}
                dadosDistribuicao={dataDistribuicao}
              />
            </FormProvider>
          </DommusToggle>
          :<></>
        }
        {travaCampo ? (
          <DommusToggle title="Solicitar clonagem" toggle={true}>
            <Row>
              <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                  Descrição: <DommusRequiredSpan />
                </Form.Label>
                <Form.Control
                  type="textarea"
                  as="textarea"
                  rows={5}
                  placeholder="Digite a decrição"
                  onChange={(event) => setDescricao(event.target.value)}
                />
              </Form.Group>
            </Row>
          </DommusToggle>
        ) : (
          <></>
        )}
        {!travaCampo || permitirDuplicidadeOpv ? (
          <Row className="formFooter">
            <Col lg={9} md={12} className="px-3 items-campos-travados-section">
                {!camposCadastroTravadosOculto &&
                  <div className="d-flex agrupar-opv">
                    <div className="border rounded">
                      <DommusSwitch
                        checked={travarCamposCadastro}
                        clickEvent={() => {
                          handleTravarCamposCadastro();
                        }}
                        tooltip={travarCamposCadastro ? "Destravar" : "Travar"}
                        labelDesativado={`${travarCamposCadastro ? "Destravar" : "Travar"} campos cadastro`}
                      />
                    </div>
                  </div>
                }
                {travarCamposCadastro &&
                  <div className="border rounded checkbox-campos-travados">
                      <Form.Check
                        type="checkbox"
                        label="Ocultar campos travados"
                        checked={Boolean(camposCadastroTravadosOculto)}
                        onChange={(e)=>{
                          handleCamposTravadosVisualizacao(Number(e.target.checked));
                        }}
                        id="ocultar-campos-travados"
                      />
                  </div>
                }
              </Col>
            <Col lg={3} md={12} className="justify-content-end">
              <button
                type="button"
                onClick={() => salvarOportunidade()}
                className="btn btn-success"
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ marginRight: "15px" }}
                />
                Salvar
              </button>
            </Col>
          </Row>
        ) : (
          <Row className="formFooter">
            <Col>
            <button
                type="button"
                // disabled={true}
                onClick={(e) => {
                  e.preventDefault();
                  clonarOportunidade();
                }}
                style={{ marginRight: "15px" }}
                className="btn btn-primary"
                disabled={idClone ? false : true}
              >
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  style={{ marginRight: "15px" }}
                />
                Solicitar Clonagem
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  TransferirOportunidade()
                }}
                className="btn btn-success"
                disabled={idClone && idClone != idProcesso ? false : true}
              >
                <FontAwesomeIcon
                  icon={faRetweet}
                  style={{ marginRight: "15px" }}
                />
                Solicitar Transferência
              </button>
            </Col>
          </Row>
        )}
      </Form>

      <ModalDommus
        open={openModal}
        close={() => setOpenModal(false)}

        titulo={"Aprovar solicitação de " + (item.tipo_solicitacao == 'T' ? "Transferência" : "Clonagem")}
        setRefresh={setRefresh}
        refresh={refresh}
      >
        <Solicitacoes
          setRefresh={setRefresh}
          refresh={refresh}
          close={() => setOpenModal(false)}
          closeFather={closeModal}
          item={item}
          dadosOportunidade={dadosOportunidade}
        />
      </ModalDommus>

      {openModalNovoAtendimento &&
        <ModalDommus
          open={openModalNovoAtendimento}
          close={() => setOpenModalNovoAtendimento(false)}
          titulo="Nova Atividade"
          nomeCliente={novoAtendimento.lead_nome}
          opv={novoAtendimento.id_oportunidade}
        >
          <NovoAtendimento
            close={() => setOpenModalNovoAtendimento(false)}
            oportunidade={novoAtendimento?.id_oportunidade}
            atendimento={""}
            inserirLista={callbackNovoAtendimento}
            index={""}
            atualizaLista={""}
            setTabIndex={undefined}
          />
        </ModalDommus>

      }
    </>
  );
}
