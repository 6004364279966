import { faDownload, faEraser, faExchangeAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from "js-file-download";
import React, { SetStateAction, useContext, useEffect } from "react";
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import equipeService from "../../../services/EquipeService";
import oportunidadeService from "../../../services/OportunidadeService";
import ModalDommus from "../../Modal";
import "./index.css"
import ListaAtendimentos from "./ListaAtendimentos";
import { AsyncPaginate } from "react-select-async-paginate";
import EquipesPrePdv from "../../../pages/EquipesPrePdvSelectIdComponent";
import { DommusPhoneInput } from "../../DommusPhoneInput";
import { TipoAtendimentoType } from "../../../pages/DetalheOportunidade/Cadastro/types";
import { FowardRefFilhoType } from "../types";
import { EmpreendimentoContext, OportunidadeContext } from "../../GlobalContext/globalContext";
import { OperadorType } from "../../../pages/EquipesPrePdvSelectIdComponent/types";
import { MultiSelect, Option } from "react-multi-select-component";
import formatDommus from "../../../helpers/format";
import relatorioService from "../../../services/RelatorioService";
import { cachearContexto } from "../../../helpers/cachearContexto";
import { FiltroGlobalContext } from "../../../contexts/FiltroGlobalContext";

interface FiltroAtendimentoProps {
    optionsCampanhas: () => Option[]
    optionsEmpreendimentos: () => Option[]
    optionsMotivosDesistencia: () => Option[]
    optionsMidia: () => Option[]
    optionsStatusOportunidade: () => Option[]
    montaOptionsSubstatus: (evento: Option[], setState: React.Dispatch<SetStateAction<Option[]>>) => void
    exportFlag?: boolean
    list?: boolean
    handleSubmitParent?: (filtros: SubmitParentProps) => Promise<any>
    events?: boolean
}

interface SubmitParentProps {
    id_tipo_atendimento: string
    contato: string
    criado_por: Array<number>
    concluido_por: Array<number>
    tarefa_realizada: string
    criado_em: {
        inicio: string
        fim: string
    },
    atualizado_em: {
        inicio: string
        fim: string
    },
    data: {
        inicio: string
        fim: string
    },
    data_final: {
        inicio: string
        fim: string
    },
    id_campanha: number[]
    id_empreendimento: string[]
    id_motivo_desistencia: number[]
    id_equipe_pdv: string|string[]
    id_gerente_pdv: string|string[]
    id_usuario_atendimento: string|string[]
    id_equipe_pre_atendimento: string|string[]
    id_gerente_pre_atendimento: string|string[]
    id_usuario_pre_atendimento: string|string[]
    id_midia: number[]
    id_status_oportunidade: number[]
    id_substatus_oportunidade: number[]
}

export default function FiltroAtendimentos({
    optionsCampanhas,
    optionsEmpreendimentos,
    optionsMotivosDesistencia,
    optionsMidia,
    optionsStatusOportunidade,
    exportFlag,
    list,
    handleSubmitParent,
    events,
    montaOptionsSubstatus
}: FiltroAtendimentoProps) {
    const {
        optionsTiposAtendimentos, setOptionsTiposAtendimentos,
        tipoAtendimento, setTipoAtendimento,
        telefoneAtendimento, setTelefoneAtendimento,
        emailAtendimento, setEmailAtendimento,
        criadoPorAtendimento, setCriadoPorAtendimento,
        concluidodoPorAtendimento, setConcluidoPorAtendimento,
        tarefaRealizada, setTarefaRealizada,
        inicioCriacaoAtendimento, setInicioCriacaoAtendimento, 
        fimCriacaoAtendimento, setFimCriacaoAtendimento,
        inicioAtualizacaoAtendimento, setInicioAtualizacaoAtendimento, 
        fimAtualizacaoAtendimento, setFimAtualizacaoAtendimento,
        inicioConclusaoAtendimento, setInicioConclusaoAtendimento, 
        fimConclusaoAtendimento, setFimConclusaoAtendimento,
        inicioAgendamentoAtendimento, setInicioAgendamentoAtendimento, 
        fimAgendamentoAtendimento, setFimAgendamentoAtendimento,
        exportar, setExportar,
        openModal, setOpenModal,
        atendimentos, setAtendimentos, 
        limiteAtendimentos, setLimiteAtendimentos,
        paginaAtendimentos, setPaginaAtendimentos,
        totalAtendimentos, setTotalAtendimentos,
        filtroPeriodo, setFiltroPeriodo,
        tipoFiltroUsuario, setTipoFiltroUsuario,
        campanhaAtendimento, setCampanhaAtendimento,
        motivoDesistenciaAtendimento, setMotivoDesistenciaAtendimento,
        empreendimentoAtendimento, setEmpreendimentoAtendimento,
        equipePdvSelecionadaAtendimento, setEquipePdvSelecionadaAtendimento,
        gerentePdvSelecionadoAtendimento, setGerentePdvSelecionadoAtendimento,
        usuarioPdvSelecionadoAtendimento, setUsuarioPdvSelecionadoAtendimento,
        equipePreAtendimentoSelecionadaAtendimento, setEquipePreAtendimentoSelecionadaAtendimento,
        gerentePreAtendimentoSelecionadoAtendimento, setGerentePreAtendimentoSelecionadoAtendimento,
        usuarioPreAtendimentoSelecionadoAtendimento, setUsuarioPreAtendimentoSelecionadoAtendimento,
        midiaAtendimento, setMidiaAtendimento,
        statusOportunidadeAtendimento, setStatusOportunidadeAtendimento,
        substatusAtendimento, setSubstatusAtendimento,
        responsavelAtendimento, setResponsavelAtendimento,
        filtroContatoAtendimento, setFiltroContatoAtendimento,
        optionsSubstatus, setOptionsSubstatus,
        idTipoTarefa, setIdTipoTarefa,
        listaTarefas, setListaTarefas,
        limparFiltros
    } = useContext(FiltroGlobalContext)
    const { tiposAtendimento, setTiposAtendimento, tiposTarefa, setTiposTarefa } = useContext(OportunidadeContext)

    const contextoGlobalEmpreendimentos = useContext(EmpreendimentoContext)
    const configsMultiSelect = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	}
    let EquipesPrePdvFilho: FowardRefFilhoType<typeof EquipesPrePdv>

    useEffect(() => {
        cachearContexto(tiposAtendimento, setTiposAtendimento, oportunidadeService.buscarTiposAtendimentos)
        cachearContexto(tiposTarefa, setTiposTarefa, oportunidadeService.buscarTiposTarefa)
    }, [])

    useEffect(() => {
        if(tiposAtendimento.length) {
            montarOpcoesTiposAtendimento()
        }
    }, [tiposAtendimento])

    useEffect(() => {
        if(tiposTarefa.length) {
            montarOpcoesTiposTarefa()
        }
    }, [tiposTarefa])

    useEffect(() => {
        EquipesPrePdvFilho.setStateEquipes(contextoGlobalEmpreendimentos.equipes)
    }, [contextoGlobalEmpreendimentos.equipes])

    useEffect(() => {
        EquipesPrePdvFilho.setStateGerentes(contextoGlobalEmpreendimentos.gerentes)
    }, [contextoGlobalEmpreendimentos.gerentes])

    useEffect(() => {
        EquipesPrePdvFilho.setStateOperadores(contextoGlobalEmpreendimentos.operadores)
    }, [contextoGlobalEmpreendimentos.operadores])

    function montarOpcoesTiposAtendimento() {
        const arrPropsOptions = events ? tiposAtendimento.filter((tipo: TipoAtendimentoType) => [4,5].includes(tipo.id_tipo_atendimento)) : tiposAtendimento
        const options = arrPropsOptions.map((tipo: TipoAtendimentoType) => (
                <option key={tipo.id_tipo_atendimento} value={tipo.id_tipo_atendimento}>
                    {tipo.nome}
                </option>
            )
        )
        setOptionsTiposAtendimentos(options)
    }

    function montarOpcoesTiposTarefa() {
        const options = tiposTarefa.map((tipo: any) => {
            return (
                <option key={tipo.id} value={tipo.id}>
                    {tipo.nome}
                </option>
            )
        })
        setListaTarefas(options);
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        submitForm()
    }

    function submitForm(limit=0, page=0) {
        let limite = limit
        let pagina = page
        const json = {
            id_tipo_atendimento: tipoAtendimento,
            id_tipo_tarefa: idTipoTarefa,
            contato: telefoneAtendimento ? String(telefoneAtendimento).replace('+55', '') : emailAtendimento,
            criado_por: criadoPorAtendimento.map(operador => Number(operador.value)),
            concluido_por: concluidodoPorAtendimento.map(operador => Number(operador.value)),
            tarefa_realizada: tarefaRealizada,
            criado_em: {
                inicio: inicioCriacaoAtendimento,
                fim: fimCriacaoAtendimento
            },
            atualizado_em: {
                inicio: inicioAtualizacaoAtendimento,
                fim: fimAtualizacaoAtendimento
            },
            data: {
                inicio: inicioAgendamentoAtendimento,
                fim: fimAgendamentoAtendimento
            },
            data_final: {
                inicio: inicioConclusaoAtendimento,
                fim: fimConclusaoAtendimento
            },
            id_campanha: formatDommus.formataValoresMultiSelect(campanhaAtendimento),
            id_empreendimento: formatDommus.formataValoresMultiSelect(empreendimentoAtendimento),
            id_motivo_desistencia: formatDommus.formataValoresMultiSelect(motivoDesistenciaAtendimento),
            id_equipe_pdv: formatDommus.formataValoresMultiSelect(equipePdvSelecionadaAtendimento),
            id_gerente_pdv: formatDommus.formataValoresMultiSelect(gerentePdvSelecionadoAtendimento),
            id_usuario_atendimento: formatDommus.formataValoresMultiSelect(usuarioPdvSelecionadoAtendimento),
            id_equipe_pre_atendimento: formatDommus.formataValoresMultiSelect(equipePreAtendimentoSelecionadaAtendimento),
            id_gerente_pre_atendimento: formatDommus.formataValoresMultiSelect(gerentePreAtendimentoSelecionadoAtendimento),
            id_usuario_pre_atendimento: formatDommus.formataValoresMultiSelect(usuarioPreAtendimentoSelecionadoAtendimento),
            id_midia: formatDommus.formataValoresMultiSelect(midiaAtendimento),
            id_status_oportunidade: formatDommus.formataValoresMultiSelect(statusOportunidadeAtendimento),
            id_substatus_oportunidade: formatDommus.formataValoresMultiSelect(substatusAtendimento),
            responsavel: responsavelAtendimento.map(operador => Number(operador.value)),
        } as SubmitParentProps

        if(limite == 0 && pagina == 0) {
            limite = limiteAtendimentos
            pagina = paginaAtendimentos
        }
        if(list) {
            submitDefaultForm(json, limite, pagina)
        } else if(handleSubmitParent) {
            handleSubmitParent(json)
        }
    }

    function submitDefaultForm(json: SubmitParentProps, limite: number, pagina: number) {
        if ( exportar && !empreendimentoAtendimento ) {
            Swal.fire({
                position: 'center',
                icon: "warning",
                title: 'Selecione um empreendimento para exportar o relatório',
                showConfirmButton: true,
            })
            return;
        }
        trackPromise(oportunidadeService.filtrarAtendimentos(JSON.stringify(json), exportar, limite, pagina))
            .then(res => {
                if(res.status == 202) {
                    Swal.fire({
                        titleText: 'Relatório de Base Completa',
                        text: res.data.message,
                        icon: 'info',
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: 'dommus-button'
                        }
                    })
                    return
                }
                if(exportar) {
                    fileDownload(res.data, "relatorio-atendimentos" + new Date().toISOString() + ".csv");
                    return 1
                }
                setAtendimentos(res.data.atendimentos)
                setTotalAtendimentos(Number(res.data.total))
                setOpenModal(true)
            }).catch((error) => {
                if(error.response.status == 422) {
                    Swal.fire({
                        titleText: 'Relatório de Base Completa',
                        text: error.response.data.message,
                        icon: 'info',
                        confirmButtonText: "OK",
                        denyButtonText: 'Baixar Relatório Existente',
                        showDenyButton: true,
                        denyButtonColor: 'var(--dommus-color-2)',
                        customClass: {
                          confirmButton: 'dommus-button'
                        }
                    }).then(result => {
                        if(result.isDenied) {
                            relatorioService.baixarRelatorioOportunidadesDiario('atendimentos' as any)
                        }
                    })
                    return
                }
                console.log(error)
                console.log(error.response)
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao filtrar os atendimentos.",
                    icon: 'error'
                });
            })
    }

    function handleExport(val: number) {
        setExportar(val)
        if(val == 0) {
            setPaginaAtendimentos(1)
            setLimiteAtendimentos(8)
        } else {
            setPaginaAtendimentos(0)
            setLimiteAtendimentos(0)
        }
    }

    function handleSetLimiteAtendimentos(limite: number) {
        setLimiteAtendimentos(limite)
        submitForm(limite, paginaAtendimentos)
    }

    function handleSetPaginaAtendimentos(pagina: number) {
        setPaginaAtendimentos(pagina)
        submitForm(limiteAtendimentos, pagina)
    }

    async function loadOptions(search: string) {
        let options = [] as Array<Option>

        await trackPromise(equipeService.buscarOperadoresComFiltro(search))
            .then(res => {
                options = Object.values<OperadorType>(res.data).map(operador => {
                    return {
                        value: operador.id_operador,
                        label: operador.nome_operador
                    }
                })
            }).catch((error) => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar os operadores: " + error,
                    icon: 'error'
                });
            })
        if('não informado'.includes(search.toLowerCase()) || 'nao informado'.includes(search.toLowerCase())) {
            options.push({value: null, label: 'Não Informado'})
        }

        return {
            options: options,
            hasMore: false
        }
    }

    function handleSetFiltroPeriodo() {
        if(filtroPeriodo < 4) {
            setFiltroPeriodo(val => val + 1)
            return 1
        }
        setFiltroPeriodo(1)
        return 1
    }

    function handleTipoFiltroUsuario() {
        let value = tipoFiltroUsuario;
        value = value+1;
        if(tipoFiltroUsuario === 2){
            value = 0;
        }
        setTipoFiltroUsuario(value)

    }

    function mudarEmpreendimentoSelecionado(value: string){
        EquipesPrePdvFilho?.mudarEmpreendimentoSelecionado(value)
    }

    function alterarEstadoFiltroEtapa(evento: Option[]) {
        setStatusOportunidadeAtendimento(evento)
        montaOptionsSubstatus(evento, setOptionsSubstatus)
    }

    // function buscaTarefaPorId(id: string) {
    //     let tarefaPorId = [] as any;
    //     listaTarefas.map((item: any) => {
    //         if (parseInt(item.value) === parseInt(id)) {
    //             tarefaPorId.push(item);
    //         }
    //     });
    //     return tarefaPorId;
    // }

    return (
        <>
            <Container fluid>
                <Form className="formCadastro" onSubmit={(e: any) => handleSubmit(e)}>
                    <Row>
                        <Form.Group as={Col} lg="4" controlId="formAtendimento">
                            <Form.Label>Tarefa Realizada:</Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control"
                                custom
                                onChange={(event) => setTarefaRealizada(event.target.value)}
                                defaultValue={tarefaRealizada}
                                value={tarefaRealizada}
                            >
                                <option value="">Selecione uma opção</option>
                                <option value={1}>Apenas concluídas</option>
                                <option value={0}>Apenas não concluídas</option>
                            </Form.Control>
                        </Form.Group>                                       
                    </Row>
                    <Row className="form-first-row">
                        <Form.Group as={Col} lg="4" controlId="formAtendimento">
                            <Form.Label>Tipo do Atendimento</Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control"
                                custom
                                onChange={(event) => setTipoAtendimento(event.target.value)}
                                defaultValue={tipoAtendimento}
                                value={tipoAtendimento}
                            >
                                <option value="">Selecione Um Tipo</option>
                                {optionsTiposAtendimentos}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} lg="4" controlId="formTipo">
                            <Form.Label className="inputLabel">
                                Tipo de tarefa:
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control"
                                custom
                                onChange={(event) => { setIdTipoTarefa(event.target.value) }}
                                defaultValue={idTipoTarefa}
                                value={idTipoTarefa}
                            >
                                <option value="">Selecione Um Tipo</option>
                                {listaTarefas}
                            </Form.Control>
                        </Form.Group>
                        {filtroContatoAtendimento ? (
                            <Form.Group as={Col} lg={4} md={12} controlId="formAtendimento">
                                <Form.Label>Telefone</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap"}}>
                                    <DommusPhoneInput value={telefoneAtendimento} onChange={setTelefoneAtendimento}/>
                                    <InputGroup.Append style={{ marginLeft: -3 }}>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => setFiltroContatoAtendimento(!filtroContatoAtendimento)}
                                            data-tip="Telefone"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>                                    
                                </InputGroup>
                            </Form.Group>
                        ) : (
                            <Form.Group as={Col} lg={4} md={12} controlId="formAtendimento">
                                <Form.Label>E-mail</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap"}}>
                                    <FormControl
                                        type="text"
                                        placeholder="Digite o e-mail"
                                        value={emailAtendimento}
                                        onChange={(e: any) => {
                                            setEmailAtendimento(e.target.value)
                                            setTelefoneAtendimento('')
                                        }}
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3 }}>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => setFiltroContatoAtendimento(!filtroContatoAtendimento)}
                                            data-tip="E-mail"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>                                    
                                </InputGroup>
                            </Form.Group>
                        )}
                    </Row>

                    <Row>
                        {tipoFiltroUsuario === 0 ? (
                            <Form.Group as={Col} lg={6} md={12} controlId="formAtendimento">
                                <Form.Label>Responsável:</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap"}}>
                                    <AsyncPaginate
                                        value={responsavelAtendimento}
                                        loadOptions={loadOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={setResponsavelAtendimento as any}
                                        placeholder="Selecione os Operadores"
                                        className="w-100"
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3 }}>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleTipoFiltroUsuario()}
                                            data-tip="Responsável"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>   
                                    </InputGroup.Append>                                    
                                </InputGroup>
                            </Form.Group>
                        ) : tipoFiltroUsuario === 1 ? (
                            <Form.Group as={Col} lg={6} md={12} controlId="formAtendimento">
                                <Form.Label>Criado Por:</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap"}}>
                                    <AsyncPaginate
                                        value={criadoPorAtendimento}
                                        loadOptions={loadOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={setCriadoPorAtendimento as any}
                                        placeholder="Selecione os Operadores"
                                        className="w-100"
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3 }}>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleTipoFiltroUsuario()}
                                            data-tip="Criado Por"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>                                    
                                </InputGroup>
                            </Form.Group>                            
                        ) :
                        (
                            <Form.Group as={Col} lg={6} md={12} controlId="formAtendimento">
                                <Form.Label>Concluído Por:</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap"}}>
                                    <AsyncPaginate
                                        value={concluidodoPorAtendimento}
                                        loadOptions={loadOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={setConcluidoPorAtendimento as any}
                                        placeholder="Selecione os Operadores"
                                        className="w-100"
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3 }}>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleTipoFiltroUsuario()}
                                            data-tip="Concluído Por"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>                                    
                                </InputGroup>
                            </Form.Group>                             
                        )}

                        {filtroPeriodo == 1 ? (
                            <Form.Group as={Col} lg="6">
                                <Form.Label>Período: Criação do Atendimento</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap" }}>
                                    <Form.Control
                                        type="date"
                                        value={inicioCriacaoAtendimento}
                                        onChange={(event: any) => setInicioCriacaoAtendimento(event.target.value)}
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                                        <Form.Control
                                            type="date"
                                            value={fimCriacaoAtendimento}
                                            onChange={(event: any) => setFimCriacaoAtendimento(event.target.value)}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleSetFiltroPeriodo()}
                                            data-tip="Criado Em"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        ) : filtroPeriodo == 2 ? (
                            <Form.Group as={Col} lg="6">
                                <Form.Label>Período: Última Atualização do Atendimento</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap" }}>
                                    <Form.Control
                                        type="date"
                                        value={inicioAtualizacaoAtendimento}
                                        onChange={(event: any) => setInicioAtualizacaoAtendimento(event.target.value)}
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                                        <Form.Control
                                            type="date"
                                            value={fimAtualizacaoAtendimento}
                                            onChange={(event: any) => setFimAtualizacaoAtendimento(event.target.value)}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleSetFiltroPeriodo()}
                                            data-tip="Atualizado Em"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        ) : filtroPeriodo == 3 ? (
                            <Form.Group as={Col} lg="6">
                                <Form.Label>Período: Agendamento da Visita ou Tarefa</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap" }}>
                                    <Form.Control
                                        type="date"
                                        value={inicioAgendamentoAtendimento}
                                        onChange={(event: any) => setInicioAgendamentoAtendimento(event.target.value)}
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                                        <Form.Control
                                            type="date"
                                            value={fimAgendamentoAtendimento}
                                            onChange={(event: any) => setFimAgendamentoAtendimento(event.target.value)}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleSetFiltroPeriodo()}
                                            data-tip="Agendado Em"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        ) :  (
                            <Form.Group as={Col} lg="6">
                                <Form.Label>Período: Conclusão do Atendimento</Form.Label>
                                <InputGroup style={{ flexWrap: "nowrap" }}>
                                    <Form.Control
                                        type="date"
                                        value={inicioConclusaoAtendimento}
                                        onChange={(event: any) => setInicioConclusaoAtendimento(event.target.value)}
                                    />
                                    <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                                        <Form.Control
                                            type="date"
                                            value={fimConclusaoAtendimento}
                                            onChange={(event: any) => setFimConclusaoAtendimento(event.target.value)}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button
                                            className="colorBtn"
                                            onClick={() => handleSetFiltroPeriodo()}
                                            data-tip="Concluído Em"
                                        >
                                            <FontAwesomeIcon icon={faExchangeAlt} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        )}
                    </Row>

                    <Row>
                        <Form.Group as={Col} lg="4" controlId="formAtendimento">
                            <Form.Label id="label-empreendimento-atendimento">Empreendimento(s):</Form.Label>
                            <MultiSelect
                                options={optionsEmpreendimentos()}
                                value={empreendimentoAtendimento}
                                labelledBy="label-empreendimento-atendimento"
                                overrideStrings={configsMultiSelect}
                                onChange={setEmpreendimentoAtendimento}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg="4" controlId="formAtendimento">
                            <Form.Label id="label-campanha-atendimento">Campanha:</Form.Label>
                            <MultiSelect
                                options={optionsCampanhas()}
                                value={campanhaAtendimento}
                                labelledBy="label-campanha-atendimento"
                                overrideStrings={configsMultiSelect}
                                onChange={setCampanhaAtendimento}
                            />
                        </Form.Group>

                         <Form.Group as={Col} lg="4" controlId="formMidia">
                            <Form.Label>Midia:</Form.Label>
                            <MultiSelect
                                options={optionsMidia()}
                                value={midiaAtendimento}
                                labelledBy="label-midia"
                                overrideStrings={configsMultiSelect}
                                onChange={setMidiaAtendimento}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} lg="4" controlId="formNome">
                            <Form.Label id="label-etapa-atendimento">Etapa da Oportunidade:</Form.Label>
                            <MultiSelect
                                options={optionsStatusOportunidade()}
                                value={statusOportunidadeAtendimento}
                                labelledBy="label-etapa-atendimento"
                                overrideStrings={configsMultiSelect}
                                onChange={(event: any) => alterarEstadoFiltroEtapa(event)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg="4" controlId="formNome">
                            <Form.Label id="label-substatus-atendimento">Status da Oportunidade:</Form.Label>
                            <MultiSelect
                                options={optionsSubstatus}
                                value={substatusAtendimento}
                                labelledBy="label-substatus-atendimento"
                                overrideStrings={configsMultiSelect}
                                onChange={setSubstatusAtendimento}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg="4" controlId="formAtendimento">
                            <Form.Label id="label-desistencia-atendimento">Motivo de desistência:</Form.Label>
                            <MultiSelect
                                options={optionsMotivosDesistencia()}
                                value={motivoDesistenciaAtendimento}
                                labelledBy="label-desistencia-atendimento"
                                overrideStrings={configsMultiSelect}
                                onChange={setMotivoDesistenciaAtendimento}
                            />
                        </Form.Group>
                    </Row>

                    <EquipesPrePdv
                        ref={c => EquipesPrePdvFilho = c}
                        equipePdv={equipePdvSelecionadaAtendimento}
                        setequipePdv={setEquipePdvSelecionadaAtendimento as any}
                        empreendimento=""
                        gerentePvd={gerentePdvSelecionadoAtendimento}
                        setgerentePvd={setGerentePdvSelecionadoAtendimento as any}
                        setusuarioPdv={setUsuarioPdvSelecionadoAtendimento as any}
                        equipePre={equipePreAtendimentoSelecionadaAtendimento}
                        setequipePre={setEquipePreAtendimentoSelecionadaAtendimento as any}
                        gestorPre={gerentePreAtendimentoSelecionadoAtendimento}
                        setgestorPre={setGerentePreAtendimentoSelecionadoAtendimento as any}
                        setusuarioPre={setUsuarioPreAtendimentoSelecionadoAtendimento as any}
                        filtro={true}
                        equipesPadroes={true}
                        gerentesPadroes={true}
                        operadoresPadroes={true}
                        usuariosPdvMultiSelect={usuarioPdvSelecionadoAtendimento}
                        usuariosPreMultiSelect={usuarioPreAtendimentoSelecionadoAtendimento}
                    />

                    <div className="footer-form-atendimento">
                        <Button type="button" className="btnFiltroWrapper mr-3 dommus-button" onClick={() => limparFiltros('atendimentos')}>
                            <FontAwesomeIcon icon={faEraser} /> Limpar
                        </Button>
                        {exportFlag && (
                            <Button variant="success" type="submit" className="btnFiltroWrapper mr-3" onClick={() => handleExport(1)}>
                                <FontAwesomeIcon icon={faDownload}/> Exportar
                            </Button>
                        )}

                        <Button variant="success" type="submit" className="btnFiltroWrapper mr-3" onClick={() => handleExport(0)}>
                            <FontAwesomeIcon icon={faFilter} /> Filtrar
                        </Button>
                    </div>
                </Form>
            </Container>

            <ModalDommus
                open={openModal}
                close={() => setOpenModal(false)}
                children={
                    <ListaAtendimentos
                        atendimentos={atendimentos}
                        setLimit={handleSetLimiteAtendimentos}
                        limit={limiteAtendimentos}
                        page={paginaAtendimentos}
                        total={totalAtendimentos}
                        setPage={handleSetPaginaAtendimentos}
                    />
                }
                titulo="Filtro de Atendimentos"
            />
        </>
    )
}
