import crm from './crm';

const perfilUsuarioService = {
	buscarPerfisUsuarios: (data=null) => {
		let uri = data ? `/perfil-usuario/publico?ids_usuarios=${JSON.stringify(data)}` : '/perfil-usuario/publico'
		return crm.get(uri);
    },
	buscarConfig: (config) => {
		return crm.get(`/perfil-usuario/config/${config}`);
	},
	buscarNotificacoesTipo: () => {
		return crm.get('/notificacoes-tipo');
	},
	atualizarConfiguracao: (req) => {
		return crm.put('perfil-usuario/alterar-configuracao', req);
	},
	buscaDadosUsuario: (id_usuario) => {
		return crm.get(`perfil-usuario/publico/${id_usuario}`);
	},
	alterarIntervaloCalendario: (intervalo) => {
		return crm.put('perfil-usuario/alterar-intervalo-calendario/'+intervalo, {});
	},
	configuracoesAgenda: () => {
		return crm.get('agenda/configuracoes');
	},
	atualizarConfiguracoesAgenda: (chave, valor) => {
		return crm.put(`agenda/configuracoes/${chave}`, {valor: valor});
	},
	atualizar: (idUsuario, dados) => {
		return crm.put(`perfil-usuario/${idUsuario}`, { perfilusuario: dados });
	}
};

export default perfilUsuarioService;
