import React, { useContext, useEffect } from "react";
import { OportunidadeType } from "../Cadastro/types";
import { Alert } from "react-bootstrap";
import { QuestionarioOportunidadadeItem } from "./QuestionarioOportunidadeItem";
import { CamposDinamicosContext } from "../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { QuestionarioContext } from "../../../contexts/QuestionarioContext";

interface QuestionariosOportunidadeProps {
  oportunidade: OportunidadeType;
}

export function QuestionariosOportunidade({
  oportunidade,
}: QuestionariosOportunidadeProps) {
  const {listaQuestionariosPublicos, buscarQuestionariosOportunidade, buscarPermissoesQuestionarioOportunidade} = useContext(QuestionarioContext);
  const {recarregarComoConheceu, recarregarLocalidades, recarregarTipologias} = useContext(CamposDinamicosContext);

  useEffect(()=>{
    recarregarComoConheceu();
    recarregarLocalidades();
    recarregarTipologias();
  },[])

  useEffect(()=>{
    buscarQuestionariosOportunidade(oportunidade.id_oportunidade);
    buscarPermissoesQuestionarioOportunidade();
  },[])


  return listaQuestionariosPublicos.length ? (
    listaQuestionariosPublicos.map((questionario, index) => {
      return (
        <QuestionarioOportunidadadeItem
          key={index}
          oportunidade={oportunidade}
          questionario={questionario}
        />
      );
    })
  ) : (
    <Alert variant="warning">
      Não possui nenhum questionário vinculado a esse empreendimento!
    </Alert>
  );
}
