import React, { createContext, ReactElement, useState } from "react";
import { LocaisAtendimentoType, TipoAtendimentoType, TipoTarefaType } from "../../pages/DetalheOportunidade/Cadastro/types";
import oportunidadeService from "../../services/OportunidadeService";
import LocalAtendimentoService from "../../services/LocalAtendimentoService";
import { TipoVisitaService } from "../../services/TipoVisitaService";
import { TipoOportunidadeAtendimentoVisitaType } from "../../pages/DetalheOportunidade/Atendimento/MotivoVisita";

interface AtendimentoContextProps {
  listaAtendimentosTipos: TipoAtendimentoType[];
  listaTarefaTipo: TipoTarefaType[];
  listaLocaisAtendimento: LocaisAtendimentoType[];
  listaTipoVisitas: TipoOportunidadeAtendimentoVisitaType[];
  recarregarTiposAtendimento: () => void;
  recarregarTipoTarefa: () => void;
  recarregarLocaisAtendimento: () => void;
  recarregarTiposVisitas: () => void;
}

export const AtendimentoContext = createContext({} as AtendimentoContextProps );

export function AtendimentoProvider({ children }: { children: ReactElement }) {
  const [listaAtendimentosTipos, setListaAtendimentosTipos] = useState<TipoAtendimentoType[]>([]);
  const [listaTarefaTipo, setListaTarefaTipo] = useState<TipoTarefaType[]>([]);
  const [listaLocaisAtendimento, setListaLocaisAtendimento] = useState<LocaisAtendimentoType[]>([])
  const [listaTipoVisitas, setListaTipoVisita] = useState<TipoOportunidadeAtendimentoVisitaType[]>([])

  const recarregarTiposAtendimento = () => {
    oportunidadeService.buscarTiposAtendimentos().then((response: any) => {
      setListaAtendimentosTipos(response.data);
    });
  };

  const recarregarTipoTarefa = () => {
    oportunidadeService.buscarTiposTarefa().then((response:any)=>{
        setListaTarefaTipo(response.data);
    })
  }
  
  const recarregarLocaisAtendimento = () => {
    LocalAtendimentoService.buscarLocaisAtendimentoSemOcultos().then((response:any)=>{
        setListaLocaisAtendimento(response.data);
    })
  }

  const recarregarTiposVisitas = () => {
    TipoVisitaService.buscar().then((response:any)=>{
        setListaTipoVisita(response.data);
    })
  }

//   const recarregarOperadores = () => {
//     oportunidadeService.searchOperatorsInfo.().then((response:any)=>{
//         setListaTipoVisita(response.data);
//     })
//   }

  return (
    <AtendimentoContext.Provider
      value={{
        listaAtendimentosTipos,
        listaTarefaTipo,
        listaLocaisAtendimento,
        listaTipoVisitas,
        recarregarTiposAtendimento,    
        recarregarTipoTarefa,
        recarregarLocaisAtendimento,
        recarregarTiposVisitas,
      }}
    >
      {children}
    </AtendimentoContext.Provider>
  );
}
