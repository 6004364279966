import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import DommusRequiredSpan from '../../../../components/DommusRequiredSpan';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { trackPromise } from 'react-promise-tracker';
import { OportunidadeAtendimentoVisitaService } from '../../../../services/OportunidadeAtendimentoVisitaService';

interface MotivoVisitaProps {
    closeModal: () => void
    visitaRealizada: boolean
    id_oportunidade_atendimento?: number
    changeStatus?: (id_oportunidade_atendimento: number, status: boolean, dadosVisita: object) => void
    abrirModalNovoAtendimento: () => void
    setAtendimentoSelecionado?: () => void
}

export type OportunidadeAtendimentoVisitaType = {
    id_oportunidade_atendimento_visita: number
    id_oportunidade_atendimento: number
    id_tipo_oportunidade_atendimento_visita: number | undefined
    descricao: string
    visita_realizada: number
    oculto: number
    criado_por: number
    atualizado_por: number
    ativo: string | undefined
    criado_em: string | undefined
    atualizado_em: string | undefined
    tipoVisita: TipoOportunidadeAtendimentoVisitaType
    atendimento: OportunidadeAtendimentoType
}

export type OportunidadeAtendimentoTarefaType = {
    id_oportunidade_atendimento_visita: number
    id_oportunidade_atendimento: number
    id_tipo_oportunidade_atendimento_visita: number | undefined
    descricao: string
    visita_realizada: number
    oculto: number
    criado_por: number
    atualizado_por: number
    ativo: string | undefined
    criado_em: string | undefined
    atualizado_em: string | undefined
    tipoVisita: TipoOportunidadeAtendimentoVisitaType
    atendimento: OportunidadeAtendimentoType
}

export type TipoOportunidadeAtendimentoVisitaType = {
    id_tipo_oportunidade_atendimento_visita: number
    id_tipo_visita?: number
    nome: string
    oculto: number
    criado_em: string | undefined
    atualizado_em: string | undefined
    ativo: string | undefined
}

export type OportunidadeAtendimentoType = {
    id_oportunidade_atendimento: number
    id_local: number | undefined
    tipo: string
    id_tipo_atendimento: number | undefined
    titulo: string | undefined
    contato: string | undefined
    observacao: string | undefined
    data: string | undefined
    hora: string|undefined
    feedback: string | undefined
    local: string | undefined
    tarefa_realizada: number | undefined
    id_tipo_tarefa: number | undefined
    id_oportunidade: number
    data_inicial: string | undefined
    data_final: string | undefined
    concluido_por: number | undefined
    criado_em: string | undefined
    criado_por: number | undefined
    atualizado_em: string | undefined
    ativo: string | undefined
    tipo_atendimento: TipoOportunidadeAtendimentoType | null
    nome_criado_por: string | undefined
    visita: OportunidadeAtendimentoVisitaType | null
    tarefa_lembrete: OportunidadeAtendimentoTarefaType | null
}


export type TipoOportunidadeAtendimentoType = {
    id_tipo_atendimento: number
    nome: string
    evoluir_para: number | undefined
    criado_em: string | undefined
    atualizado_em: string | undefined
    ativo: string | undefined
}

export function MotivoVisita({ closeModal, visitaRealizada, id_oportunidade_atendimento, changeStatus, abrirModalNovoAtendimento, setAtendimentoSelecionado }: MotivoVisitaProps) {
    const [motivoVisitaNaoRealizada, setMotivoVisitaNaoRealizada] = useState("");
    const [motivosVisitasNaoRealizadas, setMotivosVisitasNaoRealizadas] = useState([] as Array<any>)
    const [descricao, setDescricao] = useState("");

    useEffect(() => {
        if(!visitaRealizada) {
            buscarDadosMotivosVisita();
        } 
    }, [])

    function isInvalid(param : string | number | null) {
        return param === "Selecione" || param === "" || param === null;
    }

    function salvarMotivoVisita() {
        let dados = {
            id_tipo_oportunidade_atendimento_visita: motivoVisitaNaoRealizada,
            descricao: descricao,
            visita_realizada: visitaRealizada,
            id_oportunidade_atendimento: id_oportunidade_atendimento
        }

        if(!visitaRealizada) {
            if(isInvalid(motivoVisitaNaoRealizada) ||  isInvalid(descricao)) {
                Swal.fire({
                    titleText: "Erro!",
                    text: "Preencha todos os campos obrigatórios",
                    icon: "error"
                })
                return;    
            } else { 
                trackPromise(
                    OportunidadeAtendimentoVisitaService.salvar(dados).then(
                        (response) => {
                            if(changeStatus && id_oportunidade_atendimento) {
                                changeStatus(id_oportunidade_atendimento, true, dados)
                            }
                            closeModal()
                            Swal.fire({
                                titleText: "Sucesso!",
                                text: "Motivo registrado com sucesso.",
                                icon: "success",
                            }).then(() => {
                                Swal.fire({
                                    titleText: "Deseja informar quem esteve presente na visita?",
                                    icon: "question",
                                    showCloseButton: false,
                                    showCancelButton: true,
                                    focusConfirm: false,
                                    confirmButtonText: "Sim, abrir!",
                                    cancelButtonText: "Não, obrigado.",
                                    customClass: {
                                        confirmButton: "btn btn-primary mr-3",
                                        cancelButton: "btn btn-danger",
                                    },
                                    buttonsStyling: false,
                                }).then((result) => {
                                    if(result.isConfirmed) {
                                        abrirModalNovoAtendimento();
                                    }else{
                                        Swal.fire({
                                            titleText: "Deseja abrir um novo atendimento para esta oportunidade?",
                                            icon: "question",
                                            showCloseButton: false,
                                            showCancelButton: true,
                                            focusConfirm: false,
                                            confirmButtonText: "Sim, abrir!",
                                            cancelButtonText: "Não, obrigado.",
                                            customClass: {
                                                confirmButton: "btn btn-primary mr-3",
                                                cancelButton: "btn btn-danger",
                                            },
                                            buttonsStyling: false,
                                        }).then((result) => {
                                            if(result.isConfirmed) {
                                                if(setAtendimentoSelecionado) {
                                                    setAtendimentoSelecionado()
                                                }
                                                abrirModalNovoAtendimento()
                                            }
                                        })
                                    }
                                })
                            });
                        },
                        (error) => {
                            Swal.fire({
                                titleText: "Ooops...",
                                text: "Ocorreu um erro ao salvar o motivo : " + error,
                                icon: "error",
                            });
                        }
                    )
                );
            }
        } else {
            if(isInvalid(descricao))
            {
                Swal.fire({
                    titleText: "Erro!",
                    text: "Preencha todos os campos obrigatórios",
                    icon: "error"
                })
                return;    
            }
            else
            {
                trackPromise(
                    OportunidadeAtendimentoVisitaService.salvar(dados).then(
                        (response) => {
                            if(changeStatus && id_oportunidade_atendimento) {
                                changeStatus(id_oportunidade_atendimento, true, {visita_realizada: 1, descricao, id_tipo_oportunidade_atendimento_visita: ""})
                            }
                            closeModal()
                            Swal.fire({
                                titleText: "Sucesso!",
                                text: "Motivo registrado com sucesso.",
                                icon: "success",
                            }).then(() => {
                                Swal.fire({
                                    titleText: "Deseja informar quem esteve presente na visita?",
                                    icon: "question",
                                    showCloseButton: false,
                                    showCancelButton: true,
                                    focusConfirm: false,
                                    confirmButtonText: "Sim, abrir!",
                                    cancelButtonText: "Não, obrigado.",
                                    customClass: {
                                        confirmButton: "btn btn-primary mr-3",
                                        cancelButton: "btn btn-danger",
                                    },
                                    buttonsStyling: false,
                                }).then((result) => {
                                    if(result.isConfirmed) {
                                        abrirModalNovoAtendimento();
                                    }else{
                                        Swal.fire({
                                            titleText: "Deseja abrir um novo atendimento para esta oportunidade?",
                                            icon: "question",
                                            showCloseButton: false,
                                            showCancelButton: true,
                                            focusConfirm: false,
                                            confirmButtonText: "Sim, abrir!",
                                            cancelButtonText: "Não, obrigado.",
                                            customClass: {
                                                confirmButton: "btn btn-primary mr-3",
                                                cancelButton: "btn btn-danger",
                                            },
                                            buttonsStyling: false,
                                        }).then((result) => {
                                            if(result.isConfirmed) {
                                                if(setAtendimentoSelecionado) {
                                                    setAtendimentoSelecionado()
                                                }
                                                abrirModalNovoAtendimento()
                                            }
                                        })
                                    }
                                })                                
                            })
                        },
                        (error) => {
                            Swal.fire({
                                titleText: "Ooops...",
                                text: "Ocorreu um erro ao salvar o motivo : " + error,
                                icon: "error",
                            });
                        }
                    )
                );
            }
        }

    }
    
    async function buscarDadosMotivosVisita() {
        await trackPromise(
            OportunidadeAtendimentoVisitaService.buscarTipos().then((response) => {
                if ( response.data !== undefined )
                {
                        let motivosVisitas = response.data;
                        setMotivosVisitasNaoRealizadas(motivosVisitas);
                }
            }).catch((error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os motivos visita: " + error,
                icon: "error"                
            })))
        )
    }

    return (
        <>
            <Container fluid>
                <Form className="formMotivoVisita">
                    { !visitaRealizada ? (
                        <Row>
                            <Form.Group as={Col} controlId="formMotivoVisita">
                                <Form.Label>
                                    Motivo: <DommusRequiredSpan />
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={motivoVisitaNaoRealizada}
                                    defaultValue="Selecione"
                                    onChange={(event) => {
                                        setMotivoVisitaNaoRealizada(event.target.value);
                                    }}
                                >
                                    <option>Selecione</option>
                                    {motivosVisitasNaoRealizadas && motivosVisitasNaoRealizadas.length != 0 ? (
                                        motivosVisitasNaoRealizadas.map((item) => (
                                            <option value={item.id_tipo_oportunidade_atendimento_visita} key={item.id_tipo_oportunidade_atendimento_visita}>
                                                {item.nome}
                                            </option>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </Form.Control>    
                            </Form.Group>                        
                        </Row>
                        )
                        : 
                        (<></>)
                    }                    
                    <Row>
                        <Form.Group as={Col} controlId="formDescricao">
                            <Form.Label>
                                Descrição: <DommusRequiredSpan />
                            </Form.Label>
                            <Form.Control
                                value={descricao}
                                type="textarea"
                                as="textarea"
                                rows={3}
                                onChange={(event) => setDescricao(event.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="formFooter">
                        <Col>
                            <button
                                type="button"
                                onClick={() => salvarMotivoVisita()}
                                className="btn btn-success"
                            >
                                <FontAwesomeIcon
                                    icon={faSave}
                                    style={{ marginRight: "15px" }}
                                />
                                Salvar
                            </button>
                        </Col>
                    </Row>
                </Form>                
            </Container>
        </>
    )
}
