import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { faEdit, faTrash, faUserCircle,faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import Swal from "sweetalert2";
import crm from "../../services/crm";
import { celularMask, cpfMask } from "../InputTextMask";
import moment from "moment";
import stringHelper from "../../helpers/StringHelper";
import { verifyIfUserHasConectaToWpp } from "../../function/WhatsAppFunc/go";
import { MeiosComunicacaoContext } from "../../contexts/MeiosComunicacaoContext";
import { PhoneHelper } from "../../helpers/PhoneHelper";

export default function DommusCarouselInteresse({ clientes, editEnvolvido, atualizarEnvolvidos, cadastrarAtendimento, idOportunidade, permissaoEditar = true }) {  
    
    const {verificarConfiguracaoMeioComunicacao} = useContext(MeiosComunicacaoContext);
    
    const removeEnvolvido = useCallback((envolvido) => {
        Swal.fire({
          titleText: "Remover envolvido",
          text: "Tem certeza que deseja remover o envolvido da Oportunidade?",
          icon: "question",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-primary mr-3",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        }).then((res) => {
          if (res.value) {
            crm.delete(`/lead/envolvido/${envolvido.id_envolvido}`).then(() => {
              atualizarEnvolvidos();
            });
          }
        });
      }, []);


    const handleAbrirAtendimentoWhatsapp = async (item) => {
      let telefoneComPrefixo = item.celular;
      if(!PhoneHelper.verificarDDIBrasileiro(item.celular)){
        telefoneComPrefixo = "+55" + item.celular;
      }
      let retorno = await verifyIfUserHasConectaToWpp(verificarConfiguracaoMeioComunicacao,`https://wa.me/${telefoneComPrefixo}`,item.celular, 'whatsapp', idOportunidade)
      if(retorno){
        cadastrarAtendimento(String(item.celular))
      }
    }

    return (
        <Carousel itemsToShow={1} renderArrow={() => <></>}>
        {clientes.map((item, index) => (
          <div className="item-slide" key={item.id_envolvido}>
            <div className="pessoa">
              {permissaoEditar && 
                <div className="editar d-flex justify-content-between">
                <OverlayTrigger
                  key="editEnvolvido"
                  placement="top"
                  overlay={
                    <Tooltip id='editEnvolvido'>
                      Editar Envolvido
                    </Tooltip>
                  }
                >
                  <div
                    className="buttonEditar mr-1"
                    onClick={() => editEnvolvido(item)}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#12a2b8"
                    />
                  </div>
                </OverlayTrigger>
                  {index > 0 && (
                    <div
                      className="buttonEditar"
                      onClick={() => removeEnvolvido(item)}
                      data-tip="Remover Envolvido"
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="#f0b941"
                      />
                    </div>
                  )}
                </div>
              }
              <div className="infos">
                <div className="dados">
                  <header>
                    <span>{index + 1}º Cliente</span>
                  </header>
                  <div className="data">
                    <div className="item">
                      <span className="nome-pessoa">
                        <strong id="textnome">
                          {item.nome}
                        </strong>
                      </span>
                      {item.cpf && String(item.cpf).length >= 11 && (
                        <span className="cpf">
                          <strong>CPF:</strong> {cpfMask(String(item.cpf))}
                        </span>
                      )}
                      {item.rg && (
                        <span className="rg">
                          <strong>RG:</strong> {item.rg}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="foto">
                  <div className="placeholder">
                    <div className=" fotoEnvolvido">
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        color="#444444"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-contato">
                <div className="dados">
                  <header>Informações de Contato</header>
                  <div className="data">
                    <div className="item">
                      <span className="celular">
                        <strong>Celular:</strong>{" "}
                        {
                          String(item.celular) == "" || item.celular == null ?
                          <></> :
                          <OverlayTrigger
                            key="iconCopy"
                            placement="top"
                            overlay={
                            <Tooltip id={String(item.id_envolvido)}>
                              Contactar pelo WhatsApp
                            </Tooltip>
                            }
                          >
                            <>
                              <span onClick={() => {
                                handleAbrirAtendimentoWhatsapp(item);
                              }} style={{color: '#25D366', padding: 0,cursor:'pointer'}} target="_blank"
                            
                              >
                                {String(item.celular) == '***********' ? item.celular : celularMask(item.celular)}
                                <FontAwesomeIcon style={{marginLeft: '5px'}} size='1x' icon={faWhatsappSquare} />
                              </span>
                              {item?.celular &&
                                <OverlayTrigger
                                    key="iconCopy"
                                    placement="top"
                                    overlay={
                                    <Tooltip id={String(item.id_envolvido)}>
                                      <>Copiar</>
                                    </Tooltip>
                                    }
                                >
                                  <FontAwesomeIcon color={'#666'} style={{marginLeft: '10px', marginTop: '3px', cursor:"pointer"}} 
                                    onClick={() => { 
                                      if(item.celular) {
                                        stringHelper.copyBoard(celularMask(item?.celular))
                                      }
                                    }} icon={faCopy} />
                                </OverlayTrigger>
                              }                              
                            </>
                          </OverlayTrigger>
                        }
                      </span>
                      <span className="telres">
                        <strong>Tel residencial:</strong>{" "}
                        {celularMask(item?.telefone)}
                        {item?.telefone &&
                          <OverlayTrigger
                              key="iconCopy"
                              placement="top"
                              overlay={
                              <Tooltip id={String(item?.id_envolvido)}>
                                <>Copiar</>
                              </Tooltip>
                              }
                          >
                            <FontAwesomeIcon color={'#666'} style={{marginLeft: '10px', marginTop: '3px', cursor:"pointer"}} 
                              onClick={() => { 
                                if(item.telefone) {
                                  stringHelper.copyBoard(celularMask(item?.telefone))
                                }
                              }} icon={faCopy} />
                          </OverlayTrigger>
                        }
                      </span>
                      <span style={{display: 'flex'}} className="email">
                        <strong className="mr-2">Email: </strong> {item?.email}
                        {item?.email &&
                          <OverlayTrigger
                              key="iconCopy"
                              placement="top"
                              overlay={
                              <Tooltip id={String(item?.id_envolvido)}>
                                <>Copiar</>
                              </Tooltip>
                              }
                          >
                            <FontAwesomeIcon color={'#666'} style={{marginLeft: '10px', marginTop: '3px', cursor:"pointer"}} 
                              onClick={() => { 
                                if(item.email) {
                                  stringHelper.copyBoard(item?.email)
                                }
                              }} icon={faCopy} />
                          </OverlayTrigger>
                        }
                      </span>
                      <span className="email">
                        <strong>Data de Nascimento:</strong>{" "}
                        {item.data_nascimento == '**********' 
                            ? '**********'  : item.data_nascimento 
                          ? moment(item.data_nascimento).format(
                              "DD/MM/YYYY"
                            )
                          : ""}
                      </span>
                      <span className="email">
                        <strong>Idade:</strong>{" "}
                        {item.data_nascimento == '**********' 
                          ? '** *****' : item.data_nascimento == '0000-00-00'
                          ? 'Não Informado' : item.data_nascimento
                          ? moment().diff(
                              item.data_nascimento,
                              "years"
                            ) + " anos"
                          : ""}
                      </span>
                      <span className="email">
                        <strong>Gênero:</strong> {item.genero}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-endereco">
                <div className="dados">
                  <header>Endereço</header>
                  <div className="data">
                    <div className="item">
                      <span className="logradouro">
                        <strong>Logradouro:</strong>{" "}
                        {item.endereco}
                      </span>
                      <span className="bairro">
                        <strong>Bairro:</strong> {item.bairro}
                      </span>
                      <span className="cep">
                        {" "}
                        <strong>Cidade:</strong> {item.cidade} |{" "}
                        <strong>CEP:</strong> {item.cep}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    )
}