import React, {useState, useEffect, useContext} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {trackPromise} from 'react-promise-tracker';
import Swal from 'sweetalert2';
import Select from "react-select";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import IconesService from '../../services/IconesService';
import SelectAsyncPaginate from './SelectAsyncPaginate';
import PropTypes from "prop-types";
import { EmpreendimentoContext } from '../GlobalContext/globalContext.tsx'
import './index.css'
import { useForm } from 'react-hook-form';

import useInfiniteScroll from 'react-infinite-scroll-hook';

var iconesFa = []
var iconesFab = []

const ico = Object
.keys(Icons)
.filter(key => key !== "fas" && key !== "prefix" )
.map(icon => (
    iconesFa.push(icon),
    Icons[icon]
))

const brands = Object
.keys(IconsBrands)
.filter(key => key !== "fab" && key !== "prefix" )
.map(icon => (
    iconesFab.push(icon),
    IconsBrands[icon]
))

 library.add(ico, brands)

function BibliotecaIcones({optionsSelectComIcones, setIdSelect, chaveLabelSelect, chaveIdIconeSelecionado, tipoExibicao, chaveIcone, sizeIcone, colorIcone, value, setValue, estilo, iconeSelecionado}) {

    const global = useContext(EmpreendimentoContext)

    const [nomeIcone, setNomeIcone] = useState("")

    const { register, handleSubmit, errors } = useForm();

    const [listaIcones, setListaIcones] = useState([])
    const [listaIconesFiltrados, setListaIconesFiltrados] = useState([])
    const [exibeFiltro, setExibeFiltro] = useState(false)

    const [loading, setLoading] = useState(false)
    const [isLoad, setIsLoad] = useState(true);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [totalPages, setTotalPages] = useState(0)
    const [limit, setLimit] = useState(80)

    useEffect(() => {
        handleLoadMore()
    }, []);

    const infiniteIconesRef = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: handleLoadMore,
        scrollContainer: "parent",
    });

    function filterSelectedData(data){
        if(setIdSelect){
            setIdSelect(data.id)
        }
        setValue(data)
    }

    function selecionaIcone(icon){
        if(iconeSelecionado){
            iconeSelecionado(icon)
        }
    }

    function buscaTodosIcones(){

     IconesService.buscarIcones().then(response => {
           console.log(response)
        }).catch(error => {
            Swal.fire(
                'Ooops... Ocorreu um erro ao carregar os icones.',
                '',
                'error'
            )
        }) 
        
    }

    const listaOptiosIcones = Array.isArray(optionsSelectComIcones) ?
        optionsSelectComIcones.map((item, i) => ({
        label: (
            <React.Fragment key={i}>
                <span style={{ paddingRight: "5px" }}>{item[chaveLabelSelect]}</span>
                {
                    iconesFa.map((icone, i) => (
                        icone === item[chaveIcone] ?
                        <FontAwesomeIcon style={estilo} size={sizeIcone || ''} color={colorIcone} icon={Icons[`${item[chaveIcone]}`]}
                        title="" /> :
                        <></>
                    ))
                }
                {
                    iconesFab.map((icone, i) => (
                        icone === item[chaveIcone] ?
                        <FontAwesomeIcon style={estilo} size={sizeIcone || ''} color={colorIcone} icon={IconsBrands[`${item[chaveIcone]}`]}
                        title="" /> :
                        <></>
                    ))
                }
            </React.Fragment>   
        ),
        value: item[chaveIcone],
        id: item[chaveIdIconeSelecionado]
    })) : []

    function handleLoadMore(){

        setLoading(true);

        if(tipoExibicao == 'lista-icone'){
            trackPromise( IconesService.buscarIcones(page, limit).then(response => {
                const novaLista = response.data.lista;
                setListaIcones([...listaIcones, ...novaLista])
                setTotalPages(response.data.pagination.count)
                const totalP = response.data.pagination.count
                if (page > totalP) {
                    setHasNextPage(false);
                } else {
                    setHasNextPage(true);
                }
            }).catch(error => {
                setHasNextPage(false);
                Swal.fire(
                    'Ooops... Ocorreu um erro ao carregar os icones.',
                    '',
                    'error'
                )
            }) .finally(() => {
                setLoading(false);
            }), `lista-area`);
        }

        setPage(page + 1)
    }

    const linhas =
    listaIconesFiltrados.length > 0 ?
    listaIconesFiltrados.map((item, i) => (
        <div
            onClick={() => selecionaIcone(item.chave)} key={i} className='icone'>
            <>
            {
                iconesFa.map((icone, i) => (
                    icone === item.chave ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={Icons[`${item.chave}`]} style={estilo}/> :
                    <></>
                ))
            }
            {
                iconesFab.map((icone, i) => (
                    icone === item.chave ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={IconsBrands[`${item.chave}`]} style={estilo} /> :
                    <></>
                ))
            }
            </>
        </div>
    ))
    :
    listaIcones.map((item, i) => (
        <div
            onClick={() => selecionaIcone(item.chave)} key={i} className='icone'>
            <>
            {
                iconesFa.map((icone, i) => (
                    icone === item.chave ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={Icons[`${item.chave}`]} style={estilo}/> :
                    <></>
                ))
            }
            {
                iconesFab.map((icone, i) => (
                    icone === item.chave ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={IconsBrands[`${item.chave}`]} style={estilo} /> :
                    <></>
                ))
            }
            </>
        </div>
    ))


    function buscaLikeIcones(){
        setExibeFiltro(true);
        let filtered = listaIcones.filter(icons => icons.chave.toLowerCase().includes(nomeIcone));

        setListaIconesFiltrados(filtered)

        setTimeout(() => {
            setExibeFiltro(false)
        }, 1000);
    }

    return (
        tipoExibicao =='select' ?
        <>
            <Select
                value={value}
                name='icone'
                onChange={e => (filterSelectedData(e))}
                options={
                    listaOptiosIcones
                }
                placeholder="Selecione o ícone"
            />
        </> :
        tipoExibicao =='select-paginate' ?
        <>
            <SelectAsyncPaginate
                value={value}
                onChange={e => (filterSelectedData(e))}
            />
        </>
        :
        tipoExibicao == 'icone' ?
        <>
            {
                iconesFa.map((icone, i) => (
                    icone === chaveIcone ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={Icons[`${chaveIcone}`]} style={estilo}/> :
                    <></>
                ))
            }
            {
                iconesFab.map((icone, i) => (
                    icone === chaveIcone ?
                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                        icon={IconsBrands[`${chaveIcone}`]} style={estilo} /> :
                    <></>
                ))
            }
        </>
        : tipoExibicao == 'lista-icone' ?
        <div
        className='div-sobre-icone'>
                {
                    listaIcones.map((item, i) => (
                        <div ref={infiniteIconesRef}
                            onClick={() => selecionaIcone(item.chave)} key={i} className='icone'>
                            <>
                            {
                                iconesFa.map((icone, i) => (
                                    icone === item.chave ?
                                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                                        icon={Icons[`${item.chave}`]} style={estilo}/> :
                                    <></>
                                ))
                            }
                            {
                                iconesFab.map((icone, i) => (
                                    icone === item.chave ?
                                    <FontAwesomeIcon color={colorIcone} size={sizeIcone || ''}
                                        icon={IconsBrands[`${item.chave}`]} style={estilo} /> :
                                    <></>
                                ))
                            }
                            </>
                        </div>
                    ))
                }
                {loading && <h6>Carregando...</h6>}
        </div>
        :
        <></>
    )
};

BibliotecaIcones.propTypes = {
    tipoExibicao: PropTypes.string.isRequired,
};

export default BibliotecaIcones;
