import React, { createContext, ReactElement, useState } from "react";
import { swalErro } from "../../helpers/DommusSwal";
import ConfiguracoesGestaoService from "../../services/ConfiguracoesGestaoService";
import { EnumConfiguracaoMeioComunicacao } from "./types";
import Swal from "sweetalert2";
import oportunidadeService from "../../services/OportunidadeService";
import guService from "../../services/GuService";

interface MeiosComunicacaoContextProps {
  configuracoesMeioComunicacao: any;
  buscarConfiguracaoMeiosComunicacao: () => void;
  verificarConfiguracaoMeioComunicacao: (config:string, idOportunidade:number) => void;
}

export const MeiosComunicacaoContext = createContext(
  {} as MeiosComunicacaoContextProps
);

export function MeiosComunicacaoProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [configuracoesMeioComunicacao, setConfiguracoesMeioComunicacao] =
    useState<any>({
      email: EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_DESATIVADO,
      voip: EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_DESATIVADO,
    });

  function buscarConfiguracaoMeiosComunicacao() {
    ConfiguracoesGestaoService.buscarConfiguracao(
      "meios_de_comunicacao_atendimento"
    )
      .then((resposta) => setConfiguracoesMeioComunicacao(resposta.data))
      .catch((erro) =>
        swalErro(
          `Houve um erro ao buscar as configurações de meio comunicação. Mensagem: ${erro.response?.data?.message}, Erro: ${erro}`
        )
      );
  }

  function  verificarConfiguracaoMeioComunicacao (config:string, idOportunidade:number) {
    switch(configuracoesMeioComunicacao[config]) {
      case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_DESATIVADO:
        Swal.fire({
          titleText: "Central de Atendimento não ativada",
          text: "Entre em contato com o departamento comercial da Dommus e solicite, agora mesmo, sua ativação.",
          icon: "warning",
        });
        break;
      case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_CONECTA:
        Swal.fire({
          titleText: "Abrir atendimento pelo Dommus Conecta?",
          text: `Deseja confirmar a criação de um novo atendimento pelo Dommus Conecta via ${config}?`,
          icon: "question",
          showCancelButton: true,
          cancelButtonText: 'Não, cancelar.',
          confirmButtonText: 'Sim, confirmar.'
        }).then(result => {
          if(result.isConfirmed) {
            oportunidadeService.criarAtendimentoConecta(config, idOportunidade)
              .then(res => {
                guService.buscarTokenAcessoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO).then(resposta => {
                  window.open(`${process.env.REACT_APP_CONECTA_FRONT}/gu-login/${resposta.data.token}:${res.data.id || res.data.conteudo.id}`, '_blank')
                })
              }).catch(erro => {
                swalErro(`${erro.response?.data?.mensagem}`)
              })              
          }
        });
        break;
      case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_PROSPECTA:
        //TODO: Implementar ação através dos widgets.
        break;
    }
  }

  return (
    <MeiosComunicacaoContext.Provider
      value={{
        configuracoesMeioComunicacao,
        buscarConfiguracaoMeiosComunicacao,
        verificarConfiguracaoMeioComunicacao,
      }}
    >
      {children}
    </MeiosComunicacaoContext.Provider>
  );
}
