import React, { Dispatch, SetStateAction, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DommusRequiredSpan from "../DommusRequiredSpan";
import { trackPromise } from "react-promise-tracker";
import oportunidadeService from "../../services/OportunidadeService";
import { toastErro, toastInfo } from "../../helpers/DommusSwal";
import Swal from "sweetalert2";

interface VendaParceiroProps {
  idOportunidade: string
  setIdOportunidade: Dispatch<SetStateAction<string>>
}

export function VendaParceiro({ idOportunidade, setIdOportunidade }: VendaParceiroProps) {
  const campoCodigoOpv = useRef<any>(null)

  function validarOpv(idOportunidade: string) {
    if(!idOportunidade) {
      toastInfo('É obrigatório preencher o código da oportunidade para registrar este tipo de atendimento.')
      campoCodigoOpv?.current?.focus()

      return
    }
    trackPromise(oportunidadeService.buscarOportunidade(idOportunidade, { semEscopos: true } as any))
      .then(response => {
        if(response?.data?.status?.tipo == 'G') {
          setIdOportunidade(idOportunidade)
        } else {
          toastInfo('A oportunidade digitada deve estar em uma etapa do tipo Venda.')
          campoCodigoOpv?.current?.focus()
          setIdOportunidade('')
        }
      })
      .catch(error => {
        setIdOportunidade('')
        console.log(error)
        console.log(error?.response)
        toastErro('Houve um erro ao tentar validar a oportunidade')
      })
  }

  return <Col>
    <Row>
      <Form.Label>Código da Oportunidade: <DommusRequiredSpan /></Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>OPV - </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control 
          ref={campoCodigoOpv} 
          value={idOportunidade} 
          min="1" 
          type="number" 
          onChange={(event) => setIdOportunidade(event.target.value)} 
          onBlur={(event: any) => validarOpv(event.target.value)}
        />
      </InputGroup>
    </Row>
  </Col>
}