import React, { useState, useEffect, useRef, useCallback } from "react";
import "./index.css";

import CSVReader1 from "./upload";
import { useForm, useFieldArray } from "react-hook-form";
import DommusToggle from "../../../src/components/DommusToggle";
import { Button, Form, Row, Col, ProgressBar } from "react-bootstrap";
import CSVService from "../../services/CSVService";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faFileExport } from "@fortawesome/free-solid-svg-icons";
import crm from "../../services/crm";
import ModalDommus from "../../components/Modal";

export default function Importacao({ setIdsLeadsImportados, options = {} }) {
  const [selectedFormato, setselectedFormato] = useState("Selecione");
  const [selectedCorrespondencia, setselectedCorrespondencia] =
    useState("Selecione");
  const [formato, setformato] = useState([]);
  const [header, setheader] = useState([]);
  const [dadoscsv, setdadoscsv] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [ultimoValor, setUltimoValor] = useState("");
  const formRef = useRef(null);
  const [resetar, setresetar] = useState(false);
  const [dadosImportar, setDadosImportar] = useState([]);
  const [progresso, setProgresso] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [leads, setLeads] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const [formCampanha, setFormCampanha] = useState("");
  const [formMidia, setFormMidia] = useState("");

  const [listaCampanha, setListaCampanha] = useState([]);
  const [listaMidia, setListaMidia] = useState([]);

  useEffect(() => {
    trackPromise(
      crm.get("campanha").then(
        (response) => {
          setListaCampanha(response.data);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar as origens: " + error,
            icon: "error",
          });
        }
      )
    );
    trackPromise(
      crm.get("midia").then(
        (response) => {
          setListaMidia(response.data);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar as origens: " + error,
            icon: "error",
          });
        }
      )
    );
  }, []);

  const correspondencia = [
    { name: "", formato: "" },
    { name: "Nome", formato: ["Texto"] },
    { name: "Telefone", formato: ["Número"] },
    { name: "Email", formato: ["Email"] },
    { name: "Campanha", formato: ["Texto"] },
    { name: "Midia", formato: ["Texto"] },
    { name: "Empreendimento", formato: ["Texto"] },
    { name: "ID Externo", formato: ["Número"] },
  ];

  const { register, control, handleSubmit, reset } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "headers",
  });

  function recebedata(content) {
    setheader(content[0]);
    setdadoscsv(content);
  }

  function cleardata(data) {
    setheader([]);
    setformato([]);
    setTasks([]);
    setdadoscsv(data);
    remove();
    setresetar(false);
  }

  const importarLeads = useCallback(
    (data) => {
      //validação de campos prenchidos
      const validate = [];
      data.headers.map((header) => {
        validate[header.correspondente] = true;
      });

      if (
        !validate["Nome"] ||
        (!validate["Email"] && !validate["Telefone"] ) ||
        (!validate["Campanha"] &&
          !validate["Midia"] &&
          !formCampanha &&
          !formMidia)
      ) {
        Swal.fire({
          titleText: "Atenção",
          text: "Selecione os campos Correspondentes a Nome, Email, Telefone, Campanha e Mídia",
          icon: "info",
        });
        return;
      }

      //envio do csv para o backend validar dados e retornar informações
      trackPromise(
        CSVService.importar(data, dadoscsv, options)
          .then((res) => {
            Swal.fire({
              titleText: "Deseja confirmar a importação de leads?",
              text: `O arquivo de leads adicionado, importará ${res.data.total_validos} de um total de ${res.data.total_enviados} linhas`,
              icon: "question",
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: "Sim, Importar!",
              cancelButtonText: "Não, cancelar.",
              customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            }).then((modalRes) => {
              if (modalRes.isConfirmed) {
                setOpenModal(true);
                setLeads(res.data.leads);

                // confirmarImportacao(res.data.leads);
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um erro ao enviar os dados: " + error,
              icon: "error",
            });
          })
      );
    },
    [formCampanha, formMidia]
  );

  function enviar2000(leads) {
    let max = 2000;
    let begin = 0;
    let leadsLength = Object.values(leads).length;
    let total = leadsLength / max;
    let leadList = Object.values(leads);
    let leads_countMax;
    let vezes = 1;
    let progresso = 0;
    let leadsEnviar = [];
    let id_importacaoEnviar = [];

    setDadosImportar(leads);
    options.campanha_padrao = formCampanha;
    options.midia_padrao = formMidia;
     
    trackPromise(
      CSVService.confirmar(leads, options)
        .then((response) => {
          if (setIdsLeadsImportados !== undefined) {
            const { leads, id_importacao } = response.data;
            leads_countMax = leadList.slice(begin, max);
            options.campanha_padrao = formCampanha;
            options.midia_padrao = formMidia;
            leadsEnviar.push(leads);
            id_importacaoEnviar.push(id_importacao);
            setIdsLeadsImportados(leads, id_importacao);
            setOpenModal(false);
          }
          Swal.fire({
            titleText: "Sucesso!",
            text: "Dados enviados com sucesso.",
            icon: "success",
          });
          cleardata([]);
        })
        .catch((error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao enviar os dados: " + error,
            icon: "error",
          });
        })
    );

    begin = begin + 2000;
    max = max + 2000;
    progresso = (vezes * 100) / Math.ceil(total);
    vezes = vezes + 1;
    setProgresso(progresso);

    setIdsLeadsImportados(leadsEnviar, id_importacaoEnviar);
  }

  const confirmarImportacao = useCallback(
    (leads, id_importacao) => {
      options.campanha_padrao = formCampanha;
      options.midia_padrao = formMidia;

      trackPromise(
        CSVService.confirmar(leads, options)
          .then((response) => {
            if (setIdsLeadsImportados !== undefined) {
              const { leads, id_importacao } = response.data;
              setIdsLeadsImportados(leads, id_importacao);
            }
            Swal.fire({
              titleText: "Sucesso!",
              text: "Dados enviados com sucesso.",
              icon: "success",
            });
            cleardata([]);
          })
          .catch((error) => {
            Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um erro ao enviar os dados: " + error,
              icon: "error",
            });
          })
      );
    },
    [formCampanha, formMidia, options]
  );

  function changeCorrespondencia(event, index) {
    event.preventDefault();
    const arrayAtual = tasks.slice();
    setselectedCorrespondencia(event.target.value);
    arrayAtual.push(event.target.value);
   
   
    setTasks(arrayAtual);
    let novoformato = formato.slice();
    novoformato[index] = correspondencia.find(
      (corresp) => corresp.name === event.target.value
    ).formato;
    setformato(novoformato);
  }

  function changeState(event) {
    event.preventDefault();
    setselectedFormato(event.target.value);
  }

  useEffect(() => {
    if (Array.isArray(header.data)) {
      let arrayAppend = header.data.slice();
      append(arrayAppend);
    }
  }, [header]);

  return (
    <div className="csvPagina">
      <div className="tituloPagina">Importação de CSV</div>

      <Form.Control as="select" ref={register()} name={"modelo"}>
        <option value="lead"> Adicionar Lead</option>
      </Form.Control>

      <div className="csvReader">
        <CSVReader1
          cleardata={cleardata.bind(this)}
          recebedata={recebedata.bind(this)}
          resetar={resetar}
        />
      </div>
      <DommusToggle title="Campanha e Mídia Padrão" toggle>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Mídia:</Form.Label>
            <Form.Control
              as="select"
              custom
              value={formMidia}
              onChange={(event) => setFormMidia(event.target.value)}
            >
              <option value="">Selecione a mídia</option>
              {listaMidia.map((item) => (
                <option value={item.id_midia} key={item.id_midia}>
                  {item.descricao}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Campanha:</Form.Label>
            <Form.Control
              as="select"
              custom
              value={formCampanha}
              onChange={(event) => setFormCampanha(event.target.value)}
            >
              <option value="">Selecione a campanha</option>
              {listaCampanha.map((item) => (
                <option value={item.id_campanha} key={item.id_campanha}>
                  {item.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
      </DommusToggle>
      <div className="DommusToggle">
        <DommusToggle title="Conversão do Lead" toggle={true}>
          <Form
            ref={formRef}
            onSubmit={handleSubmit((data) => importarLeads(data))}
          >
            <div className="cabecalho">
              <Row>
                <Col>
                  <Form.Label>Header</Form.Label>
                </Col>
                <Col>
                  <Form.Label>Correspondência</Form.Label>
                </Col>
                <Col>
                  <Form.Label>Formato</Form.Label>
                </Col>
              </Row>
            </div>
            {fields.length > 0 ? (
              fields.map(({ id }, index) => {
                return (
                  <div key={id}>
                    <Row>
                      <Form.Control
                        className="hidden"
                        ref={register({ validate: (value) => value !== "" })}
                        name={`headers[${index}].index`}
                        plaintext
                        readOnly
                        defaultValue={index}
                      />
                      <Col>
                        <Form.Label>
                          <div>
                            {header.data != undefined ? header.data[index] : ""}
                          </div>
                        </Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          as="select"
                          ref={register()}
                          name={`headers[${index}].correspondente`}
                          onChange={(e) => {
                            changeCorrespondencia(e, index);
                          }}
                        >
                          <option value="">Selecionar</option>
                          {correspondencia.map((e, i) => {
                            if (
                              tasks.indexOf(e.name) ===
                              ""
                            ) {
                              return (
                                <option
                                  disabled="disabled"
                                  value={e.name}
                                  key={i}
                                >
                                  {e.name}
                                </option>
                              );
                            } else if (e.name !== "") {
                              return (
                                <option value={e.name} key={i}>
                                  {e.name}
                                </option>
                              );
                            }
                          })}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          as="select"
                          required={
                            formato[index] === undefined ||
                            formato[index] === ""
                              ? false
                              : true
                          }
                          ref={register()}
                          name={`headers[${index}].formato`}
                          placeholder="Formato"
                          onChange={(e) => {
                            changeState(e);
                          }}
                        >
                          <option value="">Selecionar</option>
                          {formato[index] &&
                            formato[index].map((e, i) => {
                              return (
                                <option value={e} key={i}>
                                  {e}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <Row className="d-flex justify-content-end">
              <div className="enviar">
                <Button
                  variant="success"
                  type="submit"
                  style={{ display: header.data ? "block" : "none" }}
                >
                  Enviar
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ marginLeft: "5px" }}
                  />
                </Button>
              </div>
            </Row>
          </Form>
        </DommusToggle>
      </div>
      <ModalDommus
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
        titulo="Progresso"
        size={"md"}
      >
        <>
          <ProgressBar now={progresso} label={`${progresso}%`} />
          { progresso === 0 &&
            <div className="text-right mt-4">
              <Button
                variant="info"
                onClick={() => {
                  enviar2000(leads);
                }}
              >
                {`Iniciar importação`}
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginLeft: "5px" }}
                />
              </Button>
            </div>
          }
        </>
      </ModalDommus>
    </div>
  );
}
