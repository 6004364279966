import crm from './crm';

const ContatoNotificacaoService = {
	buscar: () => {
        let uri = '/contato-notificacao-erro';
        return crm['get'].call(crm, uri);
    },
	store: (dados) => {
        let uri = '/contato-notificacao-erro';
        return crm['post'].call(crm, uri, dados);
    },
	update: (id, dados) => {
        let uri = `/contato-notificacao-erro/${id}`;
        return crm['put'].call(crm, uri, dados);
    },
	delete: (id) => {
        let uri = `/contato-notificacao-erro/${id}`;
        return crm['delete'].call(crm, uri);
    },
};

export default ContatoNotificacaoService;
