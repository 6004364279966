import { trackPromise } from 'react-promise-tracker';
import api from './api';
import crm from './crm';
const setLocalState = (name, value) => {
    localStorage.setItem('@DommusCRM-'+name, value);
};
const getLocalState = name => {
    const value = localStorage.getItem('@DommusCRM-' + name);
    return value;
};
const guService = {
    buscarAmbientes: () => {
        return api.get('/clientes/modulo/1');
    },
    loginGU: token => {
        return api.get(`/gu-api/login/${token}`);
    },
    validaToken: token => {
        return api.post('/gu-api/valida-token', {token});
    },

    logout: () => {
        localStorage.clear();
        if(window.location.pathname !== '/login') {
            window.location = process.env.REACT_APP_GU_FRONT+'/modulo/logout';
        }
    },

    redirectGU: () => {
        window.location = process.env.REACT_APP_GU_FRONT
    },
    setLocalState,
    getLocalState,

    setLoginInfo: data => {
        setLocalState('token', data.access_token);
        setLocalState('nomeUsuario', data.nome_usuario);
        setLocalState('idUsuario', data.id_usuario);
        setLocalState('tipoUsuario', data.id_usuario_tipo);
        setLocalState('linkVendas', data.link_vendas);
        setLocalState('tokenCliente', data.token_cliente);
        setLocalState('idInquilino', data.id_cliente);
        setLocalState('usaWidgetsAtendimento', JSON.stringify(data.usa_widgets_atendimento || false));
        setLocalState('abrirOportunidadeModal', data.abrir_oportunidade_modal  ?? 'N');
        setLocalState('idsModulos', data.modulos && data.modulos.length ? data.modulos.map(modulo => modulo.id_modulo) : '')
        setLocalState('cadastroExibeDialogoAtendimento', JSON.stringify(data.cadastro_exibe_dialogo_atendimento ||false))
        setLocalState('exibirCanalOpv', JSON.stringify(data.exibir_canal_opv ||false))

    },

    validaPermissaoModulo: (id_modulo) => {
        const permissao_modulos = getLocalState('idsModulos')

        return permissao_modulos && permissao_modulos.split(',').map(id_modulo => Number(id_modulo)).includes(id_modulo)
    },

    ID_MODULO_CENTRAL_ATENDIMENTO: 6,

    buscarTokenAcessoModulo: id_modulo => {
        return trackPromise(crm.get(`buscar-token-modulo-usuario/${guService.getLocalState('guToken')}/${id_modulo}`))
    }
};

export default guService;
