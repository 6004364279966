import React, { createContext, ReactElement, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { swalErro } from "../../helpers/DommusSwal";
import { AlertaDestinatarioType, AlertaTipoRegraType, AlertaType } from "./types";
import AlertaService from "../../services/AlertaService";

interface AlertaContextProps {
    listaAlertas: AlertaType[];
    alertaSelecionado: AlertaType;
    listaAlertaTiposRegra: AlertaTipoRegraType[];
    listaDestinatarios: AlertaDestinatarioType[];
    listaTemplates: any[];
    setAlertaSelecionado: React.Dispatch<React.SetStateAction<AlertaType>>;
    recarregarAlertas: () => void;
    recarregarTiposRegra: () => void;
    adicionarAlerta: (data: AlertaType) => void;
    atualizarAlerta: (data: AlertaType) => void;
    removerAlerta: (idAlerta: number) => void;
    removerRegra: (idAlerta: number, idRegra: number) => void;
    buscarDestinatariosPorMeioDisparo: (meioDisparo:string) => void;
    buscarTemplatesPorMeioComunicacao: (meioComunicacao:string) => void;
}

export const AlertaContext = createContext({} as AlertaContextProps);

export function AlertaProvider({ children }: { children: ReactElement }) {
    const [listaAlertas, setListaAlertas] = useState<AlertaType[]>([] as AlertaType[]);
    const [listaAlertaTiposRegra, setListaAlertaTiposRegra] = useState<AlertaTipoRegraType[]>([]);
    const [alertaSelecionado, setAlertaSelecionado] = useState<AlertaType>({} as AlertaType);
    const [listaDestinatarios, setListaDestinatarios] = useState<AlertaDestinatarioType[]>([] as AlertaDestinatarioType[]);
    const [listaTemplates, setListaTemplates] = useState<any>([]);


    const recarregarTiposRegra = () => {
        AlertaService.buscarTiposRegra().then((response: any) => {
            setListaAlertaTiposRegra(response.data);
        });
    };

    const buscarDestinatariosPorMeioDisparo = (meioDisparo:string)=>{
        let sigla = "";
        switch (meioDisparo) {
            case 'sms':
            case 'whatsapp':
                sigla = 'T';
                break;        
            case 'email':
                sigla='E'
            default:
                break;
        }

        AlertaService.buscarDestinatariosPorMeioDisparo(sigla).then((response: any) => {
            setListaDestinatarios(response.data);
        });
    }

    const recarregarAlertas = () => {
        trackPromise(AlertaService.buscarAlertas())
            .then((response) => {
                setListaAlertas(response.data);
            })
            .catch((error) => {
                swalErro(
                    `Houve um erro ao carregar os alertas: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
                );
            });
    };

    const buscarTemplatesPorMeioComunicacao = (meioComunicacao:string) => {
        trackPromise(AlertaService.buscarTemplatesPorMeioComunicacao(meioComunicacao))
            .then((response) => {
                setListaTemplates(response.data);
            })
            .catch((error) => {
                swalErro(
                    `Houve um erro ao carregar os templates: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
                );
            });
    }

    const adicionarAlerta = (data: AlertaType) => {
        setListaAlertas((current) => {
            return [data, ...current];
        });
    };

    const atualizarAlerta = (data: AlertaType) => {
        setListaAlertas((current) => {
            return current.map((alerta) => {
                if (alerta.id_alerta === data.id_alerta) {
                    return data;
                }
                return alerta;
            });
        });
    };

    const removerAlerta = (idAlerta: number) => {
        setListaAlertas((current) => {
            return current.filter(
                (alerta) => alerta.id_alerta !== idAlerta
            );
        });
    };

    const removerRegra = (idAlerta: number, idRegra: number) => {
        setListaAlertas((current) => {
            return current.map((alerta) => {
                if (alerta.id_alerta == idAlerta) {
                    alerta.regras = alerta.regras?.filter(
                        (regra) => regra.id_alerta_regra !== idRegra
                    );
                }
                return alerta;
            });
        });
    };

    return (
        <AlertaContext.Provider
            value={{
                listaAlertas,
                listaAlertaTiposRegra,
                listaDestinatarios,
                alertaSelecionado,
                recarregarAlertas,
                recarregarTiposRegra,
                adicionarAlerta,
                atualizarAlerta,
                removerAlerta,
                removerRegra,
                setAlertaSelecionado,
                buscarDestinatariosPorMeioDisparo,
                buscarTemplatesPorMeioComunicacao,
                listaTemplates,
            }}
        >
            {children}
        </AlertaContext.Provider>
    );
}
