import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, RefObject, useEffect, useRef } from "react";

interface ISubmenu {
  title: string;
  open: boolean;
  callback: (value: boolean) => void;
  children: ReactElement;
  refParent: Array<RefObject<HTMLInputElement>>;
}

export function Submenu({ title, open, callback, children, refParent=[]}: ISubmenu) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {        
    const handleClickOutside = (event: any) => {      
      if (ref.current && !ref.current.contains(event.target) && 
          (!refParent?.map((item)=> {return (item.current?.contains(event.target))}).includes(true))) {
        callback(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

 return (
    <>
      <div
        className={`sidenav-overlay-menu suporte is-menu ${open ? "open" : ""}`}
      ></div>
      <section ref={ref} className={`suporte is-menu ${open ? "open" : ""}`}>
        <header>
          {title}
          <div
            className="close-pane"
            onClick={() => {
              callback(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
          </div>
        </header>
        {children}
      </section>
    </>
  );
}
