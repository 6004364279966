import React, { useContext, useMemo } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { format, parseISO } from "date-fns";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stringHelper from "../../helpers/StringHelper";
import { EmpreendimentoContext } from "../GlobalContext/globalContext";

export function DommusCardHistorico({chave, funcaoClique, data, descricao, detalhes,
  criado_por, icone, cor_icone, icone_descricao, complemento_descricao,
  tipoHistorico, id_atendimento_prospecta}) {
    const {resolverNomeOperador, resolverNomeGerente, resolverNomeEquipe} = useContext(EmpreendimentoContext);

    var dadosDetalhes = useMemo(()=>{
        if(detalhes && stringHelper.isJson(detalhes)){
            let detalhesJson = JSON.parse(detalhes);
            return detalhesJson &&
            (detalhesJson?.id_equipe || detalhesJson?.id_gerente || detalhesJson?.id_atendente) ? (<>
                <Row>
                    <div style={{padding: "0px 15px"}}>
                        { detalhesJson?.id_equipe &&
                          <div><strong>Equipe: </strong>
                          {resolverNomeEquipe(detalhesJson.id_equipe)}
                          </div>
                        }
                        { detalhesJson?.id_gerente &&
                          <div><strong>Gerente: </strong>
                          {resolverNomeGerente(detalhesJson.id_gerente)}
                          </div>
                        }
                        { detalhesJson?.id_atendente &&
                          <div><strong>Operador: </strong>
                          {resolverNomeOperador(detalhesJson.id_atendente)}
                          </div>
                        }
                    </div>
                </Row>
            </>) : <>{!(id_atendimento_prospecta) && "Dados indisponíveis"}</>

        }else if(detalhes){
            return detalhes;
        }else{
            return (!(id_atendimento_prospecta) && 'Detalhes indisponíveis') || "";
        }
    },[detalhes])

    return (
        <VerticalTimelineElement
            key={`card-historico-${chave}`}
            className="vertical-timeline-element--work"
            onTimelineElementClick={() => funcaoClique()}
            contentStyle={{
                background: "#f8f8f8",
                color: "#000",
                boxShadow: '0 3px 0 #ddd',
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                cursor: "pointer"
            }}
            contentArrowStyle={{ borderLeft: "7px solid  #f8f8f8" }}
            iconStyle={{
                background: '#f8f8f8',
                color: "#f7941d",
            }}
            icon={
                <OverlayTrigger
                    key="iconEvento"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-evento`}>
                            {icone_descricao}
                        </Tooltip>
                    }
                >
                    <FontAwesomeIcon icon={icone} color={cor_icone}/>
                </OverlayTrigger>
            }
        >
            <span className="vertical-timeline-element-data">{format(parseISO(data), "dd/MM/yyyy' às ' HH:mm")}</span>
            <h5 className="vertical-timeline-element-title">{descricao}</h5>
            <span className="vertical-timeline-element-body" >
            {dadosDetalhes || (id_atendimento_prospecta && <i>com Dommus Conecta</i> ) || ""}
            </span>
            <h6 className="vertical-timeline-element-subtitle">
                <span className="criadoTimeline">Ação realizada por:</span>
                <br />
                {criado_por?.length > 0 ? criado_por : 'Administrador do Sistema'}
                <br/>
                {complemento_descricao}
            </h6>
        </VerticalTimelineElement>
    )
}
