import React, { useCallback, useContext } from "react";
import { EmpreendimentoContext } from "../../../../../components/GlobalContext/globalContext";
import { CamposDinamicosContext } from "../../../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { Badge, Col, Row } from "react-bootstrap";
import { TipoCampoDinamico } from "../../../../../contexts/QuestionarioContext/types";
import { PerguntaType } from "../../../../../contexts/PerguntaContext/types";

interface PerguntaItemVisualizacaoProps {
    pergunta: PerguntaType;
}

export function PerguntaItemVisualizacao({ pergunta }: PerguntaItemVisualizacaoProps) {
    const { empreendimentos } = useContext(EmpreendimentoContext);
    const {
        listaComoConheceu,
        listaLocalidades,
        listaTipologia,
    } = useContext(CamposDinamicosContext);

    const handleMontarResposta = useCallback(
        (options: any) => {
            if (Array.isArray(options)) {
                return options.map((option: any, index: number) => {
                    return (
                        <Badge key={index} variant="info">
                            {option?.label}
                        </Badge>
                    );
                });
            } else if (options !== "" || !options.length) {
                switch (parseInt(options)) {
                    case TipoCampoDinamico.EMPREENDIMENTO:
                        return empreendimentos.map((empreendimento, index) => {
                            return (
                                <Badge key={index} variant="info">
                                    {empreendimento.nome}
                                </Badge>
                            );
                        });
                    case TipoCampoDinamico.TIPOLOGIA:
                        return listaTipologia.map((tipologia, index) => {
                            return <Badge key={index} variant="info">{tipologia.descricao}</Badge>
                        });
                    case TipoCampoDinamico.LOCALIZACAO:
                        return listaLocalidades.map((localidade, index) => {
                            return <Badge key={index} variant="info">{localidade}</Badge>
                        })
                    case TipoCampoDinamico.COMO_CONHECEU:
                        return listaComoConheceu.map((comoConheceu, index) => {
                            return <Badge key={index} variant="info">{comoConheceu.descricao}</Badge>
                        })
                    default:
                        return [];
                }
            }
        },
        [pergunta]
    );

    return (
        <Row className="pergunta-card">
            <Col md="8" className="info">
                <div className="enunciado-section">
                    <span style={{ color: "#999", fontSize: "14px" }}>Enunciado</span>
                    {pergunta?.enunciado}
                </div>
                <span className="tipo-resposta">
                    Tipo Respostas: {pergunta?.tipo_resposta_pergunta?.nome}
                </span>
                <div className="respostas-section">
                    {handleMontarResposta(pergunta?.options)}
                </div>
            </Col>            
        </Row>
    );
}