import React, { createContext, Dispatch, ReactElement, useState } from "react";
import TagOportunidadeService from "../../services/TagOportunidadeService";
import { swalErro } from "../../helpers/DommusSwal";
import { TagOportunidadeType } from "./types";

interface TagOportunidadeProps {
    listaTagsOportunidade: TagOportunidadeType[];
    setListaTagsOportunidade: Dispatch<React.SetStateAction<TagOportunidadeType[]>>;
    recarregarTagsOportunidade: () => void;
}

export const TagOportunidadeContext = createContext({} as TagOportunidadeProps);

export function TagOportunidadeProvider({ children}: { children: ReactElement }) {
    const [listaTagsOportunidade, setListaTagsOportunidade] = useState<TagOportunidadeType[]>([]);
    
    const recarregarTagsOportunidade = () => {
        TagOportunidadeService.buscarTags()
        .then((response) => {
            setListaTagsOportunidade(response.data);
        })
        .catch((erro) =>
            swalErro(
            `Houve um erro ao tentar carregar tags. Status: ${erro?.response?.status}, Erro: ${erro?.response?.data?.message}, ${erro}`
            )
        );
    };

    return (
        <TagOportunidadeContext.Provider 
            value={{ 
                listaTagsOportunidade, 
                setListaTagsOportunidade,
                recarregarTagsOportunidade 
            }}>
        {children}
        </TagOportunidadeContext.Provider>
    );
}