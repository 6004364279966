import React, { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import { PermissaoValidaComReferencia, PermissoesUsuarioType } from "./types";

interface UsuarioProps {
  permissoes: PermissoesUsuarioType
  setPermissoes: Dispatch<SetStateAction<PermissoesUsuarioType>>
  validarPermissaoPorTelaAreaAcao: (tela: string, area: string, acao: string) => boolean
  validarPermissaoAcessoTela: (tela: string) => boolean
  validarPermissaoPorTelaAreaAcaoComReferencia: (tela: string, area: string, acao: string) => PermissaoValidaComReferencia
}

export const UsuarioContext = createContext({} as UsuarioProps);

export function UsuarioContextProvider({ children}: { children: ReactElement }) {
  const [permissoes, setPermissoes] = useState<PermissoesUsuarioType>({} as PermissoesUsuarioType)

  function validarPermissaoPorTelaAreaAcao(tela: string, area: string, acao: string) {
    const permissaoAcao = permissoes?.[tela]?.[area]
    if(!permissaoAcao) {
      return false
    } 
    return Object.keys(permissaoAcao).includes(acao) || false
  }

  function validarPermissaoAcessoTela(tela: string) {
    const permissaoAcao = permissoes?.[tela]?.geral
    if(!permissaoAcao) {
      return false
    } 
    return Object.keys(permissaoAcao).includes('visualizar') || false
  }

  function validarPermissaoPorTelaAreaAcaoComReferencia(tela: string, area: string, acao: string) {
    const permissaoValida = validarPermissaoPorTelaAreaAcao(tela, area, acao)
    const referencia = permissoes?.[tela]?.[area]?.[acao]
    
    return { permissaoValida, referencia }
  }

  return (
    <UsuarioContext.Provider value={{ permissoes, setPermissoes, validarPermissaoPorTelaAreaAcao, validarPermissaoAcessoTela, validarPermissaoPorTelaAreaAcaoComReferencia }}>
      {children}
    </UsuarioContext.Provider>
  );
}