import React, { SetStateAction, useMemo } from "react";
import "./index.css";
import ModalDommus from "../../../../components/Modal";
import { CardAlteracaoMassa } from "./CardAlteracaoMassa";
import DommusTabelaPaginada from "../../../../components/DommusTabelaPaginada";
import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { IRetornoOpvs } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IModalVisualizarAlteracaoMassa {
  open: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  opvsRetorno: IRetornoOpvs;
}

export function ModalVisualizarAlteracaoMassa({
  opvsRetorno,
  open,
  closeModal,
}: IModalVisualizarAlteracaoMassa) {
  const thead = (
    <tr>
      <th>Lead</th>
      <th className="text-center" style={{ width: "15%" }}>
        Status
      </th>
    </tr>
  );

  const linhas = useMemo(() => {
    var linhasTemp = [];
    // ALTERADOS
    linhasTemp = opvsRetorno.opvs.map((opv) => {
      return (
        <tr
          key={opv.id_oportunidade}
          onClick={() => {
            window.open(
              `${window.location.origin}/oportunidade/${opv.id_oportunidade}`,
              "_blank"
            );
          }}
        >
          <td>
            <CardAlteracaoMassa
              opv={opv}
              dadosAlterado={opvsRetorno.dadosAlterados}
              etapaOpvAlterado={opvsRetorno.etapaOpvAlterada}
            />
          </td>
          <td className="text-center">
            <OverlayTrigger
              key="tooltip-redistribuido"
              placement="top"
              overlay={
                <Tooltip id="tooltipRedistribuido">
                  {"Alterado com sucesso!"}
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                style={{ fontSize: "38px" }}
              />
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
    // NÃO ENVIADOS
    let naoEnviados = opvsRetorno.opvsNaoEnviadas.map((opv) => {
      return (
        <tr key={opv.id_oportunidade}>
          <td>
            <CardAlteracaoMassa opv={opv} />
          </td>
          <td className="text-center">
            <OverlayTrigger
              key="tooltip-redistribuido"
              placement="top"
              overlay={
                <Tooltip id="tooltipRedistribuido">
                  {"Oportunidade não alterada!"}
                </Tooltip>
              }
            >
              <div className="icon-nao-enviado-card">
                <span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="white"
                    style={{ fontSize: "36px" }}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });

    linhasTemp = [...linhasTemp, ...naoEnviados];

    return linhasTemp;
  }, [opvsRetorno.opvs, opvsRetorno.opvsNaoEnviadas]);

  return (
    <ModalDommus titulo="Alteração em Massa" open={open} close={closeModal}>
      <Alert variant="success">
        <b style={{ color: "green" }}>
          {opvsRetorno.opvs.length ? (opvsRetorno.opvs.length).toString().padStart(2, "0") : "0"}
        </b>
        {" OPV's foram alteradas e "}
        <b style={{ color: "red" }}>
          {opvsRetorno.opvsNaoEnviadas.length? (opvsRetorno.opvsNaoEnviadas.length).toString().padStart(2, "0") : "0"}
        </b>
        {" OPV's não foram alteradas. "}
        <b>
          {"Total: "}
          {(opvsRetorno.opvs.length + opvsRetorno.opvsNaoEnviadas.length).toString().padStart(2, "0")}
          {" OPV's"}
        </b>
      </Alert>
      <div className="table-section px-2">
        <DommusTabelaPaginada
          linhasCabecalho={thead}
          optionsTabela={{ bordered: false }}
          linhasCorpo={linhas}
        />
      </div>
      <div className="mt-3 w-100 d-flex justify-content-end align-items-center">
        <Button
          onClick={() => {
            closeModal(false);
          }}
          variant={"danger"}
          type={"button"}
        >
          <FontAwesomeIcon icon={faTimes} style={{ marginRight: "15px" }} />
          Fechar
        </Button>
      </div>
    </ModalDommus>
  );
}
