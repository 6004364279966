const urlHelper = {
  download: (link) => {
    let url;
    let linkOrigem;
    url = link.split("/");
    linkOrigem = url[2].split(".");
    if (linkOrigem[1] === "dommusdriver" || linkOrigem[1] === "dommus" ) {
      window.open(link);
    } else {
      let fileName;
      fileName = link.split("/").slice(-1)[0];

      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", `https://arquivos.dommus.com.br/${fileName}`);
      form.setAttribute("target", "_blank");
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = "file";
      input.value = link;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    }
  },
  imageUrl: (link) => {
    let url = link.split("/");
    let linkOrigem = url[2].split(".");

    if ( linkOrigem[1] === "dommusdriver" )
    {
          return link;
    }

    let fileName = link.split("/").slice(-1)[0];
    return `https://arquivos.dommus.com.br/${fileName}`;

  }
};

export default urlHelper;
