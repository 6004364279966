import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./index.css";
import { Col, Form, Row } from "react-bootstrap";
import { CamposDinamicosContext } from "../../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  celularMask,
  cepMask,
  cpfMask,
  realMoeda,
} from "../../../InputTextMask";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { EmpreendimentoContext } from "../../../GlobalContext/globalContext";

interface BlocoCamposDinamicosProps {
  register: any;
  names: string[];
  control:any;
  setValue: any;
  index: number;
  removerBloco: (index: number) => void;
}

export enum EnumTiposCampos {
  TEXTO = 1,
  DATA = 2,
  CPF = 3,
  CEP = 4,
  TELEFONE = 5,
  VALOR = 6,
  SIMPLES_SELECAO = 7,
  MULTIPLA_SELECAO = 8,
  SIMPLES_SELECAO_COM_DADOS_DINAMICOS = 9,
  MULTIPLA_SELECAO_COM_DADOS_DINAMICOS = 10,
  CHECKBOX = 11,
}

export function BlocoCamposDinamicos({
  index,
  register,
  removerBloco,
  control,
  setValue,
  names,
}: BlocoCamposDinamicosProps) {
  const { listaCamposDinamicos, listaComoConheceu, listaLocalidades, listaTipologia } = useContext(CamposDinamicosContext);
  const {empreendimentos} = useContext(EmpreendimentoContext);
  const [optionsRelacao, setOptionsRelacao] = useState<Array<ReactElement>>([]);
  const [optionsBusca, setOptionsBusca] = useState<Array<any>>([]);
  const [relacaoTipo, setRelacaoTipo] = useState<string>("");
  const [tipoCampoBusca, setTipoCampoBusca] = useState({
    campo: "text",
    tipo: 'text',
    mascara: "",
  });

  const optionsLocalidade = useMemo(()=>{
    return listaLocalidades.map((localidade)=>{
      return {
        label: localidade,
        value: localidade,
      }
    });
  },[listaLocalidades]);

  const optionsEmpreendimentos = useMemo(()=>{
    return empreendimentos.map((empreendimento)=>{
      return {
        label: empreendimento.nome,
        value: empreendimento.nome,
      };
    })
  },[empreendimentos]);

  const optionsTipologia = useMemo(()=>{
    return listaTipologia.map((tipologia)=>{
      return {
        label: tipologia.descricao,
        value: tipologia.descricao,
      }
    });
  },[listaTipologia]);

  const optionsComoConheceu = useMemo(()=>{
    return listaComoConheceu.map((comoConheceu)=>{
      return {
        label: comoConheceu.descricao,
        value: comoConheceu.descricao,
      }
    });
  },[listaComoConheceu]);

  const optionsRelacoesObjeto = useMemo(() => {
    return {
      igual: (
        <option value="igual" key="igual">
          Igual
        </option>
      ),
      diferente: (
        <option value="diferente" key="diferente">
          Diferente
        </option>
      ),
      existe: (
        <option value="existe" key="existe">
          Existe
        </option>
      ),
      naoExiste: (
        <option value="naoExiste" key="naoExiste">
          Não Existe
        </option>
      ),
      contem: (
        <option value="contem" key="contem">
          Contem
        </option>
      ),
      naoContem: (
        <option value="naoContem" key="naoContem">
          Não Contem
        </option>
      ),
      vazio: (
        <option value="vazio" key="vazio">
          Vazio
        </option>
      ),
      naoVazio: (
        <option value="naoVazio" key="naoVazio">
          Não Vazio
        </option>
      ),
      maiorQue: (
        <option value="maiorQue" key="maiorQue">
          Maior Que
        </option>
      ),
      menorQue: (
        <option value="menorQue" key="menorQue">
          Menor Que
        </option>
      ),
      entre: (
        <option value="entre" key="entre">
          Entre
        </option>
      ),
    };
  }, []);

  const optionsCamposDinamicos = useMemo(() => {
    let orden = listaCamposDinamicos.sort((a, b)=>{
      return a.label > b.label ? 1 : -1;
    })
    return orden.map((campo, index) => {
      return (
        <option value={campo.id_campos_dinamicos} key={index}>
          {`${campo.label} ${campo.oculto ? " - (INATIVO)" : " "}` }
        </option>
      );
    });
  }, [listaCamposDinamicos]);

  const getOptionsCamposDinamicosMultiplaSelecao = useCallback((label:string)=>{
    let labelTrim = label.replaceAll(/\s/g,'');
    switch(labelTrim){
      case 'Localização':
        return optionsLocalidade;
      case 'Empreendimento':
          return optionsEmpreendimentos;
      case 'Tipologia':
        return optionsTipologia;
      case 'ComoConheceu':
          return optionsComoConheceu;
      default:
        return [];
    }
  },[tipoCampoBusca]);

  const getTipoCampo = useCallback((chave: number) => {
    let item = listaCamposDinamicos.filter((campo) => campo.id_campos_dinamicos === chave)[0];

    return item?.id_tipo_campos_dinamicos || 0;
  }, [listaCamposDinamicos]);

  const getCampoDinamico = useCallback((chave:number)=>{
    return listaCamposDinamicos.find((campo)=>campo.id_campos_dinamicos === chave);
  },[listaCamposDinamicos])

  const montarOptionsRelacao = (chave: number) => {
      let opcoes = [] as Array<ReactElement>;
      let tipoCampo = getTipoCampo(chave);

      switch (tipoCampo) {
        case EnumTiposCampos.TEXTO:
        case EnumTiposCampos.CPF:
        case EnumTiposCampos.CEP:
        case EnumTiposCampos.TELEFONE:
          opcoes = [
            optionsRelacoesObjeto.igual,
            optionsRelacoesObjeto.diferente,
            optionsRelacoesObjeto.existe,
            optionsRelacoesObjeto.naoExiste,
            optionsRelacoesObjeto.contem,
            optionsRelacoesObjeto.naoContem,
            optionsRelacoesObjeto.vazio,
            optionsRelacoesObjeto.naoVazio,
          ];
          break;
        case EnumTiposCampos.VALOR:
          opcoes = [
            optionsRelacoesObjeto.igual,
            optionsRelacoesObjeto.diferente,
            optionsRelacoesObjeto.existe,
            optionsRelacoesObjeto.naoExiste,
            optionsRelacoesObjeto.vazio,
            optionsRelacoesObjeto.naoVazio,
            optionsRelacoesObjeto.maiorQue,
            optionsRelacoesObjeto.menorQue,
            optionsRelacoesObjeto.entre,
          ];
          break;
        case EnumTiposCampos.DATA:
          opcoes = [
            optionsRelacoesObjeto.igual,
            optionsRelacoesObjeto.diferente,
            optionsRelacoesObjeto.vazio,
            optionsRelacoesObjeto.naoVazio,
            optionsRelacoesObjeto.maiorQue,
            optionsRelacoesObjeto.menorQue,
            optionsRelacoesObjeto.entre,
          ];
          break;
        case EnumTiposCampos.SIMPLES_SELECAO_COM_DADOS_DINAMICOS:          
        case EnumTiposCampos.MULTIPLA_SELECAO_COM_DADOS_DINAMICOS:
          let campoDinamico = getCampoDinamico(chave);
          let options = getOptionsCamposDinamicosMultiplaSelecao(campoDinamico?.label || "");
          setOptionsBusca(options ?? []);
          opcoes = [
            optionsRelacoesObjeto.existe,
            optionsRelacoesObjeto.naoExiste,
            optionsRelacoesObjeto.vazio,
            optionsRelacoesObjeto.naoVazio,
          ];
          break;
        case EnumTiposCampos.SIMPLES_SELECAO:          
        case EnumTiposCampos.MULTIPLA_SELECAO:
        case EnumTiposCampos.CHECKBOX:
          let campo = getCampoDinamico(chave);                   
          setOptionsBusca(JSON.parse(campo?.options || ""));          
          opcoes = [
            optionsRelacoesObjeto.existe,
            optionsRelacoesObjeto.naoExiste,
            optionsRelacoesObjeto.vazio,
            optionsRelacoesObjeto.naoVazio,
          ];
          break;
        default:
          break;
      } 
      setValue(names[1], "");
      setValue(names[2], "");
      setValue(names[3], "");
      setValue(names[4], "");
      setValue(names[5], "");
      setOptionsRelacao(opcoes);
      handleMudarCampoBusca(tipoCampo);
      setRelacaoTipo("");
  }
    

  const handleMudarCampoBusca = useCallback((chave: number) => {
    switch (chave) {
      case EnumTiposCampos.CPF:
        setTipoCampoBusca({
          campo: "text",
          tipo: "comum",
          mascara: "",
        });
        break;
      case EnumTiposCampos.CEP:
        setTipoCampoBusca({
          campo: "text",
          tipo: "comum",
          mascara: "",
        });
        break;
      case EnumTiposCampos.TELEFONE:
        setTipoCampoBusca({
          campo: "text",
          tipo: "comum",
          // mascara: "telefone",
          mascara: "",
        });
        break;
      case EnumTiposCampos.VALOR:
        setTipoCampoBusca({
          campo: "text",
          tipo: "valor",
          mascara: "valor",
        });
        break;
      case EnumTiposCampos.DATA:
        setTipoCampoBusca({
          campo: "date",
          tipo: "comum",
          mascara: "",
        });
        break;
      case EnumTiposCampos.CHECKBOX:
      case EnumTiposCampos.MULTIPLA_SELECAO:
      case EnumTiposCampos.MULTIPLA_SELECAO_COM_DADOS_DINAMICOS:        
      case EnumTiposCampos.SIMPLES_SELECAO:
      case EnumTiposCampos.SIMPLES_SELECAO_COM_DADOS_DINAMICOS:
        setTipoCampoBusca({
          campo: "",
          tipo: "multiselect",
          mascara: "",
        });
        break;        
      default:
        setTipoCampoBusca({
          campo: "text",
          tipo: "comum",
          mascara: "",
        });
        break;
    }
  }, []);

  const handleSetMascara = useCallback((chave: string, valor: any) => {
    switch (chave) {
      case "cpf":
        return cpfMask(valor);
      case "cep":
        return cepMask(valor);
      case "telefone":
        return celularMask(valor);
      case "valor":
        return `R$ ${realMoeda(valor)}`;
      default:
        break;
    }
  }, []);

  const handleCampoBusca = (tipo:string) => {
      let isDisabled = ['vazio', 'naoVazio', ''].includes(relacaoTipo);
      if(isDisabled){
        setValue(names[2], '');
        setValue(names[3], '');
        setValue(names[4], '');
        setValue(names[5], '');
      }
      switch (tipo) {                      
        case "multiselect":
          return (   
            <Controller
              as={Select}
              name={names[5]}
              options={optionsBusca.map((option) => {
                        return {
                          label: option?.label,
                          value: option?.label,
                        };
                      })}
              isMulti    
              menuPlacement="top"
              control={control}
              isDisabled={isDisabled}
            />                 
          )
        default:
          return (            
            <Form.Control
            className="multiselect-busca"
              type={tipoCampoBusca.campo}
              ref={register()}
              name={names[2]}
              disabled={isDisabled}
              onChange={(e) => {
                if (tipoCampoBusca.mascara) {
                  let valor = handleSetMascara(tipoCampoBusca.mascara, e.target.value);
                  setValue(
                    names[2],
                    valor
                  );
                }
              }}
            />
          );
      }
    
  }

  useEffect(() => {
    if(listaCamposDinamicos.length && control?.defaultValuesRef?.current?.qualificacaoFiltro?.length) {
      montarOptionsRelacao(parseInt(control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.campoDinamicoId))
    }
  }, [listaCamposDinamicos])

  useEffect(() => {
    if(optionsRelacao.length && control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.relacao) {
      const valorRelacaoPadrao = control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.relacao ||''
      setValue(names[1], valorRelacaoPadrao)
      setRelacaoTipo(valorRelacaoPadrao)
      setValue(names[2], control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.buscaTexto ||'')
      setValue(names[5], control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.multiselect ||'')
    }
  }, [optionsRelacao])

  useEffect(() => {
    if(relacaoTipo == 'entre' && (control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.inicio || control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.fim)) {
      setValue(names[3], control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.inicio ||'')
      setValue(names[4], control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.fim ||'')
    }
  }, [relacaoTipo])

  return (
    <Row className="form-first-row">
      <Form.Group as={Col} lg="4">
        <Form.Label>Nome do Campo:</Form.Label>
        <Form.Control
          as="select"
          className="form-control"
          custom
          ref={register()}
          name={names[0]}
          onChange={(e) => {
            montarOptionsRelacao(parseInt(e.target.value));
          }}
          defaultValue={control?.defaultValuesRef?.current?.qualificacaoFiltro[index]?.campoDinamicoId ?? ''}
        >
          <option value="" hidden>
            Selecione um campo
          </option>
          {optionsCamposDinamicos}
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} lg="3">
        <Form.Label>Relação:</Form.Label>
        <Form.Control
          as="select"
          className="form-control"
          custom
          ref={register()}
          name={names[1]}
          onChange={(e) => {            
              setRelacaoTipo(e.target.value);            
          }}
        >
          <option value="" hidden>
            Selecione uma relação
          </option>
          {optionsRelacao.map((item) => item)}
        </Form.Control>
      </Form.Group>
      {relacaoTipo && relacaoTipo === "entre" ? (
        <>
          <Form.Group as={Col} lg="2">
            <Form.Label>Inicio:</Form.Label>
            <Form.Control
              type={tipoCampoBusca.campo}
              ref={register()}
              name={names[3]}
              onChange={(e) => {
                if (tipoCampoBusca.mascara) {
                  setValue(
                    names[3],
                    handleSetMascara(tipoCampoBusca.mascara, e.target.value)
                  );
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <Form.Label>Fim:</Form.Label>
            <Form.Control
              type={tipoCampoBusca.campo}
              ref={register()}
              name={names[4]}
              onChange={(e) => {
                if (tipoCampoBusca.mascara) {
                  setValue(
                    names[4],
                    handleSetMascara(tipoCampoBusca.mascara, e.target.value)
                  );
                }
              }}
            />
          </Form.Group>
        </>
      ) : (
        <Form.Group as={Col} lg="4">
          <Form.Label>Busca:</Form.Label>
          {handleCampoBusca(tipoCampoBusca.tipo)}
        </Form.Group>
      )}
      <Col lg={1} className="btn-remove-section">
        <button
          type="button"
          className="remover-bloco"
          onClick={() => {
            removerBloco(index);
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </Col>
    </Row>
  );
}
