import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import DommusLoading from "./DommusLoading";

interface DommusLoadingAreaProps {
	area: string
	fundo: string
}

export default function DommusLoadingArea({area, fundo}: DommusLoadingAreaProps) {
	const {promiseInProgress} = usePromiseTracker({area: area});

	return promiseInProgress ? <DommusLoading loadWrapperPosition={"relative"} fundo={fundo}/> : <></>
}