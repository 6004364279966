import React, { useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import OfertaAtivaService from "../../../../../services/OfertaAtivaService";
import Swal from "sweetalert2";
import { MultiSelect, Option } from "react-multi-select-component";
import { CampanhaType, LeadType, MidiaType } from '../../../../DetalheOportunidade/Cadastro/types';

interface NaoDistribuidosProps {
    setListaLeads: (leads: Array<LeadType>) => void
    setFiltroSubmetido: (filters: LeadsNaoDistruibuidosFiltersType) => void
    leadsSelecionadosAlterados: boolean
    setToggleFiltro: (value: boolean) => void
    setToggleLista: (value: boolean) => void
    setBtnFiltrar: (element: JSX.Element) => void
    setOptionsImportacao: (importOprtions: ImportOptionsType) => void
}

export type ImportOptionsType = {
    origem: string | null
}

type LeadsNaoDistruibuidosFiltersType = {
    midias: Array<number> | null
    campanhas: Array<number> | null
    limit: number | null
    tipo_importacao: string | null
    tipo_filtro: string | null
}

export default function NaoDistribuidos({
    setListaLeads,
    setFiltroSubmetido,
    leadsSelecionadosAlterados,
    setToggleFiltro,
    setToggleLista,
    setBtnFiltrar,
    setOptionsImportacao
}: NaoDistribuidosProps) {
    const [listaMidia, setListaMidia] = useState([] as Option[]);
    const [listaCampanha, setListaCampanha] = useState([]);
    const [selectedMidias, setSelectedMidias] = useState([]);
    const [selectedCampanhas, setSelectedCampanhas] = useState([]);
    const configs = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	};
    const handleFiltrarLeads = function () {
        if (leadsSelecionadosAlterados) {
            Swal.fire({
                titleText: "Leads selecionados não Importados",
                text: "Tem certeza que deseja realizar uma nova busca antes de importar os leads selecionados?",
                icon: 'question',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Não, mantenha.',
                customClass: {
                    confirmButton: 'btn btn-primary mr-3',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false

            }).then(
                (result) => {
                    if (!result.value) {
                        return;
                    } else {
                        handleSubmitFiltro()
                    }
                })
        } else {
            handleSubmitFiltro()
        }
    }
    const handleSubmitFiltro = useCallback(() => {

        if(selectedMidias.length <= 0 &&
            selectedCampanhas.length <= 0) {
            Swal.fire({
                titleText: "Atenção",
                text: "Preencha algum dos campos para continuar",
                icon: 'info'
            });
            return;
        }

        let valueCampanhas = [] as Array<number>
        let valueMidias = [] as Array<number>

        selectedCampanhas.map((item: Option) => {
            if(item.value) {
                valueCampanhas.push(item.value);
            }
        })
        selectedMidias.map((item: Option) => {
            if(item.value) {
                valueMidias.push(item.value);
            }
        })

        const formData = {
            midias: valueMidias,
            campanhas: valueCampanhas,
            limit: 0,
            tipo_importacao: 'F',
            tipo_filtro: 'nao-distribuido'
        }

        trackPromise(OfertaAtivaService.filtrar.nao_distribuido(formData)
            .then(res => {
                setListaLeads(res.data);
                setOptionsImportacao({
                    origem: 'filtro'
                })
                setFiltroSubmetido(formData);
            })
            .catch(e => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao realizar a filtragem: " + e.message,
                    icon: 'error'
                });
            }))
        setToggleFiltro(false)
        setToggleLista(true)
    }, [selectedCampanhas, selectedMidias]);

    useEffect(() => {
        trackPromise(OfertaAtivaService.midia.buscar().then(res => {
            const midiaOptions = res.data.map((midia: MidiaType) => {
                return {
                    label: midia.descricao,
                    value: midia.id_midia
                }
            });
            setListaMidia(midiaOptions);
        }))

        trackPromise(OfertaAtivaService.campanha.buscar().then(res => {
            const campanhasOptions = res.data.map((campanha: CampanhaType) => {
                return {
                    label: campanha.nome,
                    value: campanha.id_campanha
                }

            });
            setListaCampanha(campanhasOptions)
        }))
    }, []);

    useEffect(() => {
        setBtnFiltrar(<Button onClick={handleFiltrarLeads} className="btn-block">Filtrar Leads</Button>);
    }, [handleSubmitFiltro])

    return (
        <Form>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label id="select-midia">Mídia:</Form.Label>
                    {listaMidia.length > 0 &&
                        <>
                            <MultiSelect options={listaMidia}
                                onChange={setSelectedMidias}
                                value={selectedMidias}
                                overrideStrings={configs}
                                labelledBy={"select-midia"}
                            />
                        </>
                    }
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label id="select-campanha">Campanha:</Form.Label>
                    {listaCampanha.length > 0 &&
                        <>
                            <MultiSelect options={listaCampanha}
                                onChange={setSelectedCampanhas}
                                value={selectedCampanhas}
                                overrideStrings={configs}
                                labelledBy={"select-campanha"}
                            />
                        </>
                    }
                </Form.Group>
            </Row>
        </Form>
    );
}
