import React, { createContext, ReactElement, SetStateAction, useState } from "react";
import { swalErro } from "../../helpers/DommusSwal";
import { StatusOportunidadeType } from "../../pages/Configuracoes/FunilDeVendas/Funil/types";
import StatusOportunidadeService from "../../services/StatusOportunidadeService";

interface StatusOportunidadeContextProps {
  listaStatusOportunidade: StatusOportunidadeType[]
  setListaStatusOportunidade: React.Dispatch<SetStateAction<StatusOportunidadeType[]>>
  recarregarStatusOportunidade: () => void
}

export const StatusOportunidadeContext = createContext({} as StatusOportunidadeContextProps);

export function StatusOportunidadeProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [listaStatusOportunidade, setListaStatusOportunidade] = useState<Array<StatusOportunidadeType>>([]);

  const recarregarStatusOportunidade = () => {
    StatusOportunidadeService.listaTodasEtapasFunis()
      .then((response) => {
        setListaStatusOportunidade(response.data);
      })
      .catch((erro) =>
        swalErro(
          `Houve um erro ao tentar carregar etapas. Status: ${erro?.response?.status}, Erro: ${erro?.response?.data?.message}, ${erro}`
        )
      );
  };

  return (
    <StatusOportunidadeContext.Provider value={{
        listaStatusOportunidade,
        setListaStatusOportunidade,
        recarregarStatusOportunidade,
    }}>
      {children}
    </StatusOportunidadeContext.Provider>
  );
}
