import React, { useEffect, useState } from "react";
import formatDommus from "../../../helpers/format";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import oportunidadeService from "../../../services/OportunidadeService";
import equipeService from "../../../services/EquipeService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import DommusToggle from "../../../components/DommusToggle";
import FormDistribuicao from "../../../components/FormDistribuicao";
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import StatusOportunidadeService from "../../../services/StatusOportunidadeService";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { cpfMask } from "../../../components/InputTextMask";
import { DommusPhoneInput } from "../../../components/DommusPhoneInput";
import DommusOptionalSpan from "../../../components/DommusOptionalSpan";
import stringHelper from "../../../helpers/StringHelper";

export default function Solicitacoes({ item, setRefresh, refresh, close, closeFather, dadosOportunidade }) {
  const [observacao, setObservacao] = useState("");

  const [gerentes, setGerentes] = useState([]);
  const [operadores, setOperadores] = useState([]);

  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] = useState(
    item.oportunidade
      ? item.oportunidade.id_empreendimento
      : ""
  );
  const [
    usuarioPreAtendimentoSelecionado,
    setUsuarioPreAtendimentoSelecionado,
  ] = useState(
    item.oportunidade
      ? item.oportunidade.id_usuario_pre_atendimento
      : ""
  );
  const [equipePdvSelecionada, setEquipePdvSelecionada] = useState(
    item.oportunidade ? item.oportunidade.id_equipe_pdv : ""
  );
  const [equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada] =
    useState(
      item.oportunidade
        ? item.oportunidade.id_equipe_pre_atendimento
        : ""
    );
  const [gerentePdvSelecionado, setGerentePdvSelecionado] = useState(
    item.oportunidade ? item.oportunidade.id_gerente_pdv : ""
  );
  const [
    gerentePreAtendimentoSelecionado,
    setGerentePreAtendimentoSelecionado,
  ] = useState(
    item.oportunidade
      ? item.oportunidade.id_gerente_pre_atendimento
      : ""
  );
  const [usuarioPdvSelecionado, setUsuarioPdvSelecionado] = useState(
    item.oportunidade
      ? item.oportunidade.id_usuario_atendimento
      : ""
  );
  const [statusOpvSelecionado, setstatusOpvSelecionado] = useState(
    item.oportunidade
      ? item.oportunidade.id_status_oportunidade
      : ""
  );
  const [dadosDistribuicao, setDadosDistribuicao] = useState([])
  const [listaStatusOportunidade, setListaStatusOportunidade] = useState([]);
  const [validacampos, setValidacampos] = useState(true)
  const [nome, setNome] = useState(item.processo ? item.processo.proponente_encontrado_nome : "");
  const [cpf, setCpf] = useState(item.processo ? item.processo.proponente_encontrado_cpf : "");
  const [celular, setCelular] = useState(item.processo ? item.processo.telefone : "");
  const [email, setEmail] = useState(item.processo ? item.processo.email : "");

  useEffect(() => {
    trackPromise(
      Promise.all([
        equipeService.buscarGerentes(),
        equipeService.buscarOperadores(),
        StatusOportunidadeService.listaEtapas()
      ]).then((res) => {
        setGerentes(res[0].data);
        setOperadores(res[1].data);
        if (res[2].data !== "") {
          setListaStatusOportunidade(res[2].data);
        }
      })
    );
  }, []);

  function validaEmail(campo) {
    if (!stringHelper.IsEmail(email) && email.length > 0) {
      Swal.fire({
        titleText: "E-mail inválido",
        text: "Preencha um e-mail válido prosseguir",
        icon: "error",
      });
      return;
    }
  }

  function salvaDados(idAprovacao, estado) {

    let solicitacao = {
      solicitacao: {
        id_solicitacao_clonagem: idAprovacao,
        observacao_interacao: observacao,
        aprovado: estado,
      },
      tipo_solicitacao: item.tipo_solicitacao,
      oportunidade: {
        id_empreendimento: empreendimentoSelecionado,
        id_equipe_pre_atendimento: equipePreAtendimentoSelecionada,
        id_gerente_pre_atendimento: gerentePreAtendimentoSelecionado,
        id_usuario_pre_atendimento: usuarioPreAtendimentoSelecionado,
        id_gerente_pdv: gerentePdvSelecionado,
        id_equipe_pdv: equipePdvSelecionada,
        id_usuario_atendimento: usuarioPdvSelecionado,
        id_status_oportunidade: statusOpvSelecionado,
      },
      lead: {
        nome: nome,
        email: email,
        telefone: celular,
        cpf: cpf,
        id_campanha: dadosOportunidade?.id_campanha,
        id_midia: dadosOportunidade?.id_midia,
        id_origem: dadosOportunidade?.id_origem
      }
    };

    let oportunidade = {
      oportunidade: dadosDistribuicao.oportunidade
    }

    if(observacao == "" || statusOpvSelecionado == "" || statusOpvSelecionado == undefined || oportunidade.oportunidade.id_empreendimento == ""){
      setValidacampos(false)
      // Swal.fire(
      //   'Preencha os campos para prosseguir',
      //   '',
      //   'error'
      // )
      return;
    }
    Swal.fire({
      titleText: "Atenção!!!",
      text: "Tem certeza que deseja aprovar ou reprovar a solicitação?",
      icon: "warning",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Agora não",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {

      oportunidade.oportunidade['id_oportunidade'] = item.oportunidade ? item.oportunidade.id_oportunidade : null
      oportunidade.oportunidade['id_status_oportunidade'] = statusOpvSelecionado

      if (result.isConfirmed) {
        trackPromise(
          oportunidadeService.aprovarClonagem(idAprovacao, solicitacao, oportunidade).then(
            (res) => {
              if (estado === "A") {
                Swal.fire({
                  titleText: "Solicitação aprovada!",
                  icon: "success",
                });
              } else {
                Swal.fire({
                  titleText: "Solicitação reprovada!",
                  icon: "success",
                });
              }
              setRefresh(!refresh);
              close();
              if(closeFather) {
                closeFather()
              }
            },
            (error) => {
              Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao aprovar a solicitação: " + error,
                icon: "error",
              });
            }
          )
        );
      } else {
        return;
      }
    });
  }
  return (
    <Container fluid>
      <DommusToggle title="Dados da solicitação" toggle={true}>
        <Col>
          <Row>
            <div>
              {item.oportunidade ? (
                <div className="idOpv">
                  <a className="btn btn-primary btn-sm mb-2" href={`${window.location.href.split('/').slice(0, 3).join('/')}/oportunidade/${item.oportunidade.id_oportunidade}`} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faEye} className="mr-1" /> N°: OPV- {formatDommus.zeroPad(item.oportunidade.id_oportunidade, 5)}
                  </a>
                </div>
              ) : (
                <></>
              )}
              {item.lead ? (
                <div className="nomeLead">Nome: {item.lead.nome}</div>
              ) : (
                <></>
              )}
            </div>
          </Row>
          <Row>
            <div>Descrição: {item.descricao}</div>
          </Row>
        </Col>
      </DommusToggle>
      {item.processo ?
      (
        <DommusToggle title="Dados do Processo" toggle={true}>
          <Col>
            <Row>
              <Form.Group as={Col} md="8" controlId="formNome">
                <Form.Label>
                  Nome: <DommusRequiredSpan />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={nome}
                  placeholder="Digite o nome"
                  onChange={(event) => setNome(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formNome">
                <Form.Label>CPF:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o CPF"
                  value={cpfMask(cpf)}
                  onChange={(event) => setCpf(cpfMask(event.target.value))}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="8" controlId="formNome">
                <Form.Label>
                  E-mail: <DommusOptionalSpan />
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="Digite o e-mail"
                  onChange={(event) => setEmail(event.target.value)}
                  onBlur={() => validaEmail("E-MAIL")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                  Celular: <DommusOptionalSpan />
                </Form.Label>
                <DommusPhoneInput
                  className="form-control"
                  value={celular}
                  placeholder="Digite o celular"
                  onChange={setCelular}
                />
              </Form.Group>
            </Row>
          </Col>
        </DommusToggle>
      ) : (
        <></>
      )}
      <FormDistribuicao
        oportunidade={null}
        mostrarBtnSalvar={false}
        parentGetDataDistribuicao={setDadosDistribuicao}
      />
      <Col>
        <Row>
          <DommusToggle title="Status Oportunidade" toggle={true}>
            <Form.Group as={Col}>
              <Form.Label className="labelForm">Status Oportunidade: <DommusRequiredSpan /></Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                custom
                value={statusOpvSelecionado}
                onChange={(event) => setstatusOpvSelecionado(event.target.value)}
              >
                <option hidden value="">
                  Selecione
                </option>
                {listaStatusOportunidade.map((status) => (
                  <option
                    key={status.id_status_oportunidade}
                    value={status.id_status_oportunidade}
                  >
                    {status.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </DommusToggle>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Form.Label className="labelForm">Observação: <DommusRequiredSpan /></Form.Label>
          <Form.Control
            as={"textarea"}
            disabled={item.aprovado !== "P"}
            multiple
            rows="5"
            onChange={(event) => {
              setObservacao(event.target.value);
            }}
            value={observacao}
          />
          {
            !validacampos ?
            <span className="help-text">Campos obrigatórios não preenchidos</span> : <></>
          }
        </Row>
      </Col>
      <Row>
        <Col
          style={{
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {item.aprovado === "P" ? (
            <Button
              onClick={() => salvaDados(item.id_solicitacao_clonagem, "A")}
              className="botaoCadastroProcesso"
              style={{ backgroundColor: "green", marginRight: 10 }}
            >
              Aprovar
            </Button>
          ) : (
            <></>
          )}
          {item.aprovado === "P" ? (
            <Button
              onClick={() => salvaDados(item.id_solicitacao_clonagem, "R")}
              className="botaoCadastroProcesso"
              style={{ backgroundColor: "red" }}
            >
              Recusar
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
}
