import crm from './crm';

const empreendimentoService = {

	showCidadeUf:()=>{
		return crm.get('/portal-do-corretor/empreedimentos/cidade-estado')
	},

    showEmpreendimento:(id)=>{
		return crm.get('/portal-do-corretor/empreedimentos/' + id);
	},
	buscarEmpreendimentosPorUsuario:()=>{
		return crm.get('/portal-do-corretor/empreedimentos');
	},
	editEmpreendimento: (dados, id) => {
        let uri = '/portal-do-corretor/empreedimentos/' + id;
        return crm['put'].call(crm, uri, dados);
    },
	buscarEmpreendimentos:()=>{
		return crm.get('/empreendimento');
	},
	buscarEmpreendimentosVisiveisUsuarioAtual:()=>{
		return crm.get('/empreendimento/visiveis-usuario-atual');
	},
	buscarLocalidades:()=>{
		return crm.get('/empreendimento/localidades');
	},
	buscarEquipes:(idEmpreendimento) =>{
		let promisseEquipesPdv = crm.get('/empreendimento/' + idEmpreendimento + '/equipe/P');
		let promisseEquipesAtendimento = crm.get('/empreendimento/' + idEmpreendimento + '/equipe/A');

		return Promise.all([promisseEquipesPdv, promisseEquipesAtendimento])
	},

	buscarUnidadesDisponiveis:(idEmpreendimento)=>{
		return crm.get('/empreendimento/'+idEmpreendimento+'/unidades-disponiveis');
	},

	filtroEmpreendimentosPortal: (filtros) => {
		return crm.get('/portal-do-corretor/empreedimentos/por-filtro', {params: filtros} );
	},

	checarEmpreendimentosIguais: (dados) => {
		return crm.get('/empreendimento/iguais', {params: dados} );
	}
};

export default empreendimentoService;
