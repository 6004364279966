import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../../components/Modal";
import {Alert, ListGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService";

export default function InformacoesAdicionais({informacoesAdicionais}) {

    const [openModal, setOpenModal] = useState(false);
    const [exibirDadosMeta, setExibirDadosMeta] = useState();
    const [dadosInformacoesAdicionais, setDadosInformacoesAdicionais] = useState({});
    const [listaInfo, setListaInfo] = useState({});

    const formatarChave = (chave) => {
        let arrayPalavras = chave.toString().split('_');
        let retorno = '';
        arrayPalavras.forEach((palavra) => retorno = retorno.concat(palavra.charAt(0).toUpperCase() + palavra.slice(1), " "));
        return retorno;
    };

    useEffect(() => {
        trackPromise(
            ConfiguracoesGestaoService
              .buscarConfiguracao('exibir_dados_anuncios_meta')
              .then((response) => {
                if (response.data !== "") {
                    setExibirDadosMeta((response.data == 'true' || response.data == true || response.data == 1 || response.data == '1') ? true : false)
                }else{
                    setExibirDadosMeta(false)
                }
              })
          ); 
    }, []);
  
    useEffect(() => {
        if(exibirDadosMeta === false){
            let dados = informacoesAdicionais;
            delete dados.ANUNCIO;
            delete dados.PAGINA;
            delete dados.FORMULARIO;
            delete dados.GRUPO_DE_ANUNCIOS;
            setDadosInformacoesAdicionais(dados)
        }else{
            setDadosInformacoesAdicionais(informacoesAdicionais)
        }

     
    }, [exibirDadosMeta]);


    useEffect(() => {
        if(Object.keys(dadosInformacoesAdicionais).length){
            setListMethod();
        }
    },[dadosInformacoesAdicionais])


    const setListMethod = () => {

        let dados = dadosInformacoesAdicionais;

        if(typeof dados === 'string'){
            dados = JSON.parse(dados);
        }
        let listaInformacoes = (dados && Object.entries(dados).map(([key, value]) => {
            return <ListGroup.Item key={key}>
                <b>{formatarChave(key)}:</b> {value}
            </ListGroup.Item>;
        })) || [];

        setListaInfo(listaInformacoes)
    }



    return <>
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Informações Adicionais</Tooltip>}>
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => setOpenModal(true)} style={{cursor: 'pointer'}}/>
        </OverlayTrigger>
        <ModalDommus open={openModal} close={() => {
            setOpenModal(false)
        }} titulo={"Informações Adicionais"}>
           { Array.isArray(listaInfo) &&  <>

                {listaInfo.length > 0 
                ? (   <ListGroup>
                    {listaInfo}                
            </ListGroup>) : ( <Alert variant="info">Não há informações adicionais no momento</Alert>)
                 
                }
           
           </>}
        </ModalDommus>
    </>

}