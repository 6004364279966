import React, {} from "react";
import { actionReducerType } from "../types/actionReducerType";
import { filtroReducerType } from "./types";


export const filtroInitialState: filtroReducerType = {
    search: {},
    filtered: false
}



export const filtroReducer = (state: filtroReducerType,action:actionReducerType) => {

    switch(action.type){
        case 'FILTER':
            state.search =  action.payload.search;
            return state
        break;
        case 'CAN_OPEN_MODAL_FILTERED':
             state.filtered = action.payload.filtered;
             return state;
        break;
    }

    return state
} 