import crm from './crm';

const AlertaService = {
	buscarAlertas: () => {
        return crm.get('/alerta');
    },
	buscarAlertasTipos: () => {
        return crm.get('/alerta/tipo');
    },
	buscarDestinatariosPorMeioDisparo: (meioDisparo) => {
        return crm.get(`/alerta/destinatario/meio-disparo/${meioDisparo}`);
    },
	buscarTemplatesPorMeioComunicacao: (meioDisparo) => {
        return crm.get(`/alerta/template/meio-disparo/${meioDisparo}`);
    },
	editAlerta: (dados, id) => {
        let uri = `/alerta/${id}`;
        return crm['put'].call(crm, uri, dados);
    },    
	storeAlerta: (dados) => {
        let uri = '/alerta';
        return crm['post'].call(crm, uri, dados);
    },
    buscarTiposRegra: () => {
        let uri = '/alerta/tipo-regra';
        return crm['get'].call(crm, uri);
    },
    deleteAlerta: (id) =>{
        let uri = `/alerta/${id}`;
        return crm['delete'].call(crm, uri);
    },
    deleteRegra: (id) =>{
        let uri = `/alerta/regra/${id}`;
        return crm['delete'].call(crm, uri);
    },
};

export default AlertaService;
