import guService from "../../../services/GuService"
import Swal from "sweetalert2";
import { buscarConfiguracaoMeiosComunicacao } from "../../MeiosComunicacao/Conf/buscar";





type FuncWppType = (method: (meioComunicacao?:string, idOportunidade?:number) => void,linkWpp:string,phone:string, meioComunicacao:string, idOportunidade:number) => void;

export const verifyIfUserHasConectaToWpp:FuncWppType = async (method, linkWpp, phone, meioComunicacao, idOportunidade) => {
    let retorno = false;

    const hasConecta = guService.validaPermissaoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO);


    const hasMeiosComunicationConfig = await buscarConfiguracaoMeiosComunicacao();


    if(!hasConecta || !hasMeiosComunicationConfig) {
        window.open(linkWpp,'_blank')
        return
    } 

    await Swal.fire({
        title: 'Por onde deseja iniciar o Atendimento?',
        text: "Escolha uma das opções abaixo ",
        icon: 'question',
        showCloseButton: false,
        showCancelButton: false,
        showDenyButton: true,
        focusConfirm: false,
        confirmButtonText: "<div style='width: 200px; overflow-wrap: break-word;  word-wrap: break-word; word-break: break-word'>Central de Atendimento Dommus</div>",
        denyButtonText: "<div style='width: 170px; overflow-wrap: break-word;  word-wrap: break-word; word-break: break-word'>Link direto para App WhatsApp</div>",
        customClass: {
            confirmButton: 'btn btn-primary mr-3 ',
            denyButton: 'btn btn-danger',
        },
        buttonsStyling: false
    }).then(response => {
        if(response.isConfirmed){
            method(meioComunicacao, idOportunidade)
            retorno = false;
        }else if(response.isDenied){
            window.open(linkWpp,'_blank')
            retorno = true;
        }
    })
    return retorno;
}