import React from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ModalFullScreenOportunidade({ children, ...props }) {
    return (
        <div
            className={`modal-fullscreen-oportunidade ${
                props.open ? "show" : ""
            }`}
        >
            <div className="modal-header">
                {props.titulo}
                <FontAwesomeIcon
                    icon={faTimes}
                    className="icon-close"
                    onClick={() => {
                        props.close();
                    }}
                />
            </div>
            <div className="modal-conteudo">{children}</div>
        </div>
    );
}
