import React, { createContext, ReactElement, useState } from "react";
import { swalErro } from "../../helpers/DommusSwal";
import { FunilType } from "../../pages/Configuracoes/FunilDeVendas/Funil/types";
import { FunilService } from "../../services/FunilService";

interface FunilContextProps {
    listaFunis: FunilType[];
    setListaFunis: React.Dispatch<React.SetStateAction<FunilType[]>>;
    recarregarFunis: () => void;
}

export const FunilContext = createContext({} as FunilContextProps);

export function FunilProvider({children}: {children: ReactElement}) {
  const [listaFunis, setListaFunis] = useState<FunilType[]>([])

  const recarregarFunis = () => {
    FunilService.index()
      .then((response) => {
        setListaFunis(response.data);
      })
      .catch((erro) =>
        swalErro(
          `Houve um erro ao tentar carregar funis. Status: ${erro?.response?.status}, Erro: ${erro?.response?.data?.message}, ${erro}`
        )
      );
  };

  return (
    <FunilContext.Provider value={{
        listaFunis,
        setListaFunis,
        recarregarFunis
    }}>
      {children}
    </FunilContext.Provider>
  );
}
