import crm from './crm';

const newCockpitService = {
	totalOportunidades: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/oportunidades"+ tipoAgrupamento, { params: filtros });
	},

	totalConversoes: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/conversoes"+ tipoAgrupamento, { params: filtros });
	},

	totalDesistencias: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/desistencias"+ tipoAgrupamento, { params: filtros });
	},

	totalAgendamentos: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/agendamentos"+ tipoAgrupamento, { params: filtros });
	},

	totalTarefas: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/tarefas"+ tipoAgrupamento, { params: filtros });
	},

	totalTarefasAtrasadas: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/tarefas-atrasadas"+ tipoAgrupamento, { params: filtros });
	},

	totalRepresados: (filtros = null, agrupamento = "") => {
		let tipoAgrupamento = agrupamento != "" ? "/"+agrupamento : "";
		return crm.get("/new-cockpit/estatisticas/represados"+ tipoAgrupamento, { params: filtros });
	},
	
	totalTasksToPerform: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/tarefas-realizar${group ? `/${group}` : ''}`, { params: filters });
	},

	totalVisits: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/visits${group ? `/${group}` : ''}`, { params: filters });
	},

	totalVisitsToPerform: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/visits-perform${group ? `/${group}` : ''}`, { params: filters });
	},

	totalLateVisits: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/late-visits${group ? `/${group}` : ''}`, { params: filters });
	},

	totalAttendanceSlaTime: () => {
		return crm.get('/new-cockpit/estatisticas/attendance-sla-time');
	},

	totalPhoneCalls: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/phone-calls${group ? `/${group}` : ''}`, { params: filters });
	},

	totalEmails: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/emails${group ? `/${group}` : ''}`, { params: filters });
	},

	totalMessages: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/messages${group ? `/${group}` : ''}`, { params: filters });
	},

	totalProposals: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/proposals${group ? `/${group}` : ''}`, { params: filters });
	},

	totalComments: (filters=null, group='') => {
		return crm.get(`/new-cockpit/estatisticas/comments${group ? `/${group}` : ''}`, { params: filters });
	},
};

export default newCockpitService;