import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import "./index.css"
import OportunidadeRow from "./OportunidadeCard";
import usePesquisaGeral from "./UsePesquisaGeral/usePesquisaGeral";

function PesquisaPadrao(props) {

  const [pageNum, setPageNum] = useState(Number(props.numPage));
  const {
    isLoading,
    isError,
    error,
    results,
    hasNextPage
  } = usePesquisaGeral(pageNum, props.pesquisa, props.setCampoPesquisa);

    useEffect(() => {
      setPageNum(Number(props.numPage))
    }, [props.pesquisa])
    
    
    

    
    const intObserver = useRef()
    const lastPostRef = useCallback(oportunidade => {
      if (isLoading) return
      
      if (intObserver.current) intObserver.current.disconnect()
  
      intObserver.current = new IntersectionObserver(oportunidades => {
        if (oportunidades[0].isIntersecting && hasNextPage) {
          setPageNum(prev => prev + 1)
        }
      })
  
      if (oportunidade) intObserver.current.observe(oportunidade)
    }, [isLoading, hasNextPage])

    if (isError) return <p className='center'>Error: {error.message}</p>
      const content = results.map((oportunidade, i) => {
      if (results.length === i + 1) {
        return <OportunidadeRow ref={lastPostRef} key={oportunidade.id_oportunidade} oportunidade={oportunidade} pesquisa={props.pesquisa}/>
      }
      return <OportunidadeRow key={oportunidade.id_oportunidade} oportunidade={oportunidade} pesquisa={props.pesquisa}/>
    }) 

    return (
    <>
      <div>
          <div className="resultado-pesquisa-itens">
            {            
             results.length?
              content  
            :
            isLoading ? 
              <div className="spinner">
                <Spinner animation="border"/>
              </div>
            :
              <Alert variant={'primary'} >Nenhuma oportunidade encontrada </Alert>
            }
          </div>
          
      </div>
    </>
  );
}

export default PesquisaPadrao;
