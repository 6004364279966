import crm from "./crm";



export const HistoricoService = {


    getPaginateHistoricos: async (tamanhoPagina:number,page:number,search:Object) => {



        if(Object.keys(search).length > 0){
            let json = JSON.stringify(search)
            return crm.get(`/historico/todos?limit=${tamanhoPagina}&page=${page}&search=${json}`)
        }   
        
        //se Não existir filtro, busca todos os historicos de hoje
        
          return crm.get(`/historico/todos?limit=${tamanhoPagina}&page=${page}`)
    
    },
    getPaginateHistoricoDistribuicao: (tamanhoPagina:number, page:number, search:Object) => {
        let dados:any = {
            limit: tamanhoPagina,
            page: page,
        }
        
        if(Object.keys(search).length > 0){
            dados.search = search;            
        }   
        return crm.get(`/historico/distribuicao/todos`, {params: dados});
    },
    
    getTipos: async () => {
        return crm.get(`/tipohistoricolead`);
    },

    getHistoricoLeadById: (idHistoricoLead:number) => {
        return crm.get(`/historico/${idHistoricoLead}`);
    },

    getAllHistoricosLead: (idLead: number) => {
        return crm.get(`/historico/distribuicaoLead/${idLead}?limit=1`);
    },

    getHistoricoPorOportunidade: (idOportunidade:number, tamanhoPagina:number,page:number) => {
        return crm.get(`/historico/oportunidade/${idOportunidade}?limit=${tamanhoPagina}&page=${page}`);
    }

}