import React,{ createContext, useReducer} from 'react';
import { filtroReducer,filtroInitialState } from '../../hooks/reducers/filtroReducer';
import { mainReducerInitialStateType} from './types/mainReducerInitialStateType';
import { ContextType } from './types/ContextType';
import { actionReducerType } from '../../hooks/reducers/types/actionReducerType';
import { initialStateReguaNutricao, reguaNutricaoReducer } from '../../hooks/reducers/reguaNutricaoReducer';

const mainReducerInitialState : mainReducerInitialStateType = {
  reguaNutricao:initialStateReguaNutricao
}

export const AcompanhamentoReguaNutricaoContext = createContext<ContextType>({
  state: mainReducerInitialState,
  dispatch: () => null
})

const mainReducer = (state:mainReducerInitialStateType, action:actionReducerType) => {
  return ({
    reguaNutricao: reguaNutricaoReducer(state.reguaNutricao,action)
  })
}

export const ContextAcompanhamentoReguaNutricaoProvider = ({children}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(mainReducer, mainReducerInitialState);
  return(
    <AcompanhamentoReguaNutricaoContext.Provider value={{state, dispatch}}>
          {children}
    </AcompanhamentoReguaNutricaoContext.Provider>
  )
}