export const PhoneHelper = {

    verificarDDIBrasileiro: (celular: string) => {
        return celular[0] != '+' && celular[1] != '5' && celular[2] != '5';
    },
    tratarNumero9: (celular: string) => {
        let primeiroDigito = Number(celular[0])
        if ( ![2,3,4,5].includes(primeiroDigito) && celular.length == 8) {
            celular = "9" + celular;
        }
        return celular;
    }
};