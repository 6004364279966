import React, { useCallback, useMemo } from "react";
import guService from "../../services/GuService";

function NavegadorModulos() {
    const name = useMemo(() => {
        return "dommus-navegacao-modulo";
    }, []);
    const host = useMemo(() => {
        return "https://cdn.navegacao.dommus.com.br/";
    }, [])

    const element = (
            <div
                id="dommus-navegacao-modulo"
                data-gu-token={guService.getLocalState('guToken')}
                data-client={guService.getLocalState('idInquilino')}
                data-user={guService.getLocalState('idUsuario')}
                data-current-module={1}
            >
            </div>
        );

    const ref = useCallback((node: any) => {
        if (name && host && node) {
            const widgetsWrapper = node.childNodes[0] ?? null;
            const scriptId = `micro-frontend-script-${name}`;
            const linkId = `micro-frontend-styles-${name}`;
            const vendorScriptId = `micro-frontend-vendor-script-${name}`;
            const vendorLinkId = `micro-frontend-vendor-styles-${name}`;
            let shadow: any = null;
            if (widgetsWrapper) {
                if (!widgetsWrapper.shadowRoot) {
                    shadow = widgetsWrapper.attachShadow({ mode: 'open' });

                    const existingScript = shadow.getElementById(scriptId);
                    const existingLink = shadow.getElementById(linkId);
                    const existingVendorScript = shadow.getElementById(vendorScriptId);
                    const existingVendorLink = shadow.getElementById(vendorLinkId);

                    const children = [];
                    if (!existingVendorScript) {
                        const vendorScript = document.createElement('script');
                        vendorScript.src = `${host}/static/js/vendors.js`;
                        vendorScript.id = vendorScriptId;
                        children.push(vendorScript);
                    }
                    if (!existingVendorLink) {
                        const vendorLink = document.createElement('link');
                        vendorLink.href = `${host}/static/css/vendors.css`;
                        vendorLink.rel = "stylesheet";
                        vendorLink.id = vendorLinkId;
                        children.push(vendorLink);
                    }
                    if (!existingScript) {
                        const script = document.createElement('script');
                        script.src = `${host}/static/js/main.js`;
                        script.id = scriptId;
                        children.push(script);
                    }
                    if (!existingLink) {
                        const link = document.createElement('link');
                        link.href = `${host}/static/css/styles.css`;
                        link.rel = "stylesheet";
                        link.id = linkId;
                        children.push(link);
                    }

                    if (children.length) {
                        const documentFragment = document.createDocumentFragment();
                        children.forEach(c => documentFragment.appendChild(c));

                        const elementNavegador = document.createElement('div');
                        elementNavegador.id = 'dommus-navegacao-modulo';
                        elementNavegador.setAttribute('data-gu-token', guService.getLocalState('guToken') || "");
                        elementNavegador.setAttribute('data-client', guService.getLocalState('idInquilino') || "");
                        elementNavegador.setAttribute('data-user', guService.getLocalState('idUsuario') || "");
                        elementNavegador.setAttribute('data-current-module', '1');

                        shadow.appendChild(documentFragment);
                        shadow.appendChild(elementNavegador);
                    }
                }
            }
        }
    }, []);


    return (
        <div ref={ref}>
            <div id="dommus-navegacao-modulo-shadow">
            </div>
        </div>
    )
}

NavegadorModulos.defaultProps = {
    document,
    window,
};

export default NavegadorModulos;