import React, { createContext, ReactElement, useEffect, useState } from "react";

import { trackPromise } from "react-promise-tracker";
import { swalErro } from "../../helpers/DommusSwal";
import { GrupoQualificacaoType } from "./types";
import GrupoQualificacaoService from "../../services/GrupoQualificacaoService";

interface GrupoQualificacaoContextProps {
  listaGruposQualificacao: GrupoQualificacaoType[];
  listaGruposQualificacaoAtivas: GrupoQualificacaoType[];
  recarregarGruposQualificacao: () => void;
  adicionarGrupoQualificacao: (data: GrupoQualificacaoType) => void;
  atualizarGrupoQualificacao: (data: GrupoQualificacaoType) => void;
  removerGrupoQualificacao: (idGrupoQualificacao: number) => void;
  mapaGruposQualificacao:Map<number, GrupoQualificacaoType>;
}

export const GrupoQualificacaoContext = createContext({} as GrupoQualificacaoContextProps );

export function GrupoQualificacaoProvider({ children }: { children: ReactElement }) {
  const [listaGruposQualificacao, setListaGruposQualificacao] = useState<GrupoQualificacaoType[]>([]);
  const [listaGruposQualificacaoAtivas, setListaGruposQualificacaoAtivas] = useState<GrupoQualificacaoType[]>([]);
  const [mapaGruposQualificacao, setMapaGruposQualificacao] = useState<Map<number, GrupoQualificacaoType>>(new Map());

  useEffect(()=>{
    setListaGruposQualificacaoAtivas(listaGruposQualificacao.filter((grupo)=>grupo.oculto === 0));
  },[listaGruposQualificacao]);

  useEffect(()=>{
    const novoMapa = new Map();
    listaGruposQualificacao.forEach((grupo:GrupoQualificacaoType) => {
      novoMapa.set(Number(grupo.id_grupo_campos_dinamicos), grupo);
    });
    setMapaGruposQualificacao(novoMapa);
  }, [listaGruposQualificacao]);


  const recarregarGruposQualificacao = () => {
    if(listaGruposQualificacao.length) {
      return 0
    }
    GrupoQualificacaoService.buscar()
      .then((response:any) => {
        setListaGruposQualificacao(response.data);
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
        swalErro(`Houve um erro ao carregar as Automações`);

        return 0
      });
      return 1
  };

  const adicionarGrupoQualificacao = (data: GrupoQualificacaoType) => {
    setListaGruposQualificacao((current) => {
      return [data,...current];
    });
  };

  const atualizarGrupoQualificacao = (data: GrupoQualificacaoType) => {
    setListaGruposQualificacao((current) => {
      return current.map((grupoQualificacao) => {
        if (grupoQualificacao.id_grupo_campos_dinamicos === data.id_grupo_campos_dinamicos) {
          return data;
        }
        return grupoQualificacao;
      });
    });
  };

  const removerGrupoQualificacao = (idGrupoQualificacao: number) => {
    setListaGruposQualificacao((current) => {
      return current.filter(
        (grupoQualificacao) => grupoQualificacao.id_grupo_campos_dinamicos !== idGrupoQualificacao
      );
    });
  };

  return (
    <GrupoQualificacaoContext.Provider
      value={{
        listaGruposQualificacao,
        listaGruposQualificacaoAtivas,
        recarregarGruposQualificacao,
        adicionarGrupoQualificacao,
        atualizarGrupoQualificacao,
        removerGrupoQualificacao,
        mapaGruposQualificacao
      }}
    >
      {children}
    </GrupoQualificacaoContext.Provider>
  );
}
