import React, { SetStateAction } from "react"
import { AxiosResponse } from "axios"
import { trackPromise } from "react-promise-tracker"
import { swalErro } from "./DommusSwal"

export const cachearContexto = (chave: any[], setStateChave: React.Dispatch<SetStateAction<any>>, promise: () => Promise<AxiosResponse<any>>, loading=false, testeCacheContexto=null) => {
    if((testeCacheContexto == null && chave?.length) || testeCacheContexto) {
        return 0
    }
    if(loading) {
        trackPromise(promise()
            .then(response => setStateChave(response.data))
            .catch((error: any) => {
                console.log(error)
                console.log(error.response)
                swalErro('Houve um erro ao tentar carregar dependencias.')
                
                return false
            })
        )
    } else {
        promise()
            .then(response => setStateChave(response.data))
            .catch((error: any) => {
                console.log(error)
                console.log(error.response)
                swalErro('Houve um erro ao tentar carregar dependencias.')
                
                return false
            })
    }
    return 1
}