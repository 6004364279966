import crm from './crm';

const campanhaService = {
	buscarCampanhas:()=>{
		return crm.get('/campanha');
	},

	buscarTodasCampanhas:()=>{
		return crm.get('/campanha/todos');
	},

	showCampanha:(id)=>{
		return crm.get('/campanha/' + id);
	},

	buscarDependenciasCadastro:() =>{
		let promisseEmpreendimentos = crm.get('/empreendimento');
		let promisseTemplate = crm.get('/template');
		let promisseMidia = crm.get('/midia');

		return Promise.all([promisseEmpreendimentos, promisseMidia, promisseTemplate])
	},

	salvarCampanha:(campanha, arquivos_customizacao) => {
		let uri = '/campanha';
		let method = 'post';
		if(campanha.id_campanha || false){
			uri = '/campanha/' + campanha.id_campanha;
			method = 'put'
		}

		return crm[method].call(crm, uri, {campanha: campanha, arquivos_customizacao:arquivos_customizacao});
	},

	salvarCampanhaMidia: (dados, idcampanha, idmidia) => {
        let uri = '/campanha-midia/' + idcampanha + '/' + idmidia;
        return crm['put'].call(crm, uri, {campanha_midia: dados});
    },

	excluirCampanha:(campanha) => {
		let uri = '/campanha/' + campanha.id_campanha;
		let method = 'delete';

		return crm[method].call(crm, uri);
	},
	excluirMidiaCusto: (campanhaMidiaId) => {
		let uri = `/campanha-midia-custo/${campanhaMidiaId}`;
		let method = 'delete';

		return crm[method].call(crm, uri);
	}
};

export default campanhaService;