import { trackPromise } from "react-promise-tracker";
import newCockpitService from "../../../../services/NewCockpitService";

const cardsService = {
    handleSetDadosOportunidades: (setDadosOportunidades: (total: number) => void) => {
        trackPromise(newCockpitService.totalOportunidades()
            .then((response) => {
                setDadosOportunidades(response.data.total)
            },(error) => {
                setDadosOportunidades(0)
            }),'total-oportunidades-cockpit');
    },
    
    handleSetDadosOportunidadesDia: (setDadosOportunidadesDia: (total: number) => void) => {
        trackPromise(newCockpitService.totalOportunidades(null, 'dia')
        .then((response) => {
            setDadosOportunidadesDia(response.data.total)
        },(error) => {
            setDadosOportunidadesDia(0)
        }),'total-oportunidades-dia-cockpit');
    },

    handleSetDadosConversoes: (setDadosConversoes: (total: number) => void) => {
        trackPromise(newCockpitService.totalConversoes()
        .then((response) => {
            setDadosConversoes(response.data.total)
        },(error) => {
            setDadosConversoes(0)
        }),'total-conversoes-cockpit');
    },

    handleSetDadosConversoesDia: (setDadosConversoesDia: (total: number) => void) => {
        trackPromise(newCockpitService.totalConversoes(null, 'dia')
            .then((response) => {
                setDadosConversoesDia(response.data.total)
            },(error) => {
                setDadosConversoesDia(0)
            }),'total-conversoes-dia-cockpit');
    },

    handleSetDadosDesistencias: (setDadosDesistencias: (total: number) => void) => {
        trackPromise(newCockpitService.totalDesistencias()
            .then((response) => {
                setDadosDesistencias(response.data.total)
            },(error) => {
                setDadosDesistencias(0)
            }),'total-desistencia-cockpit');
    },

    handleSetDadosDesistenciasDia: (setDadosDesistenciasDia: (total: number) => void) => {
        trackPromise(newCockpitService.totalDesistencias(null, 'dia')
            .then((response) => {
                setDadosDesistenciasDia(response.data.total)
            },(error) => {
                setDadosDesistenciasDia(0)
            }),'total-desistencia-dia-cockpit');
    },

    handleSetDadosAgendamentos: (setDadosAgendamentos: (total: number) => void) => {
        trackPromise(newCockpitService.totalAgendamentos()
            .then((response) => {
                setDadosAgendamentos(response.data.total)
            },(error) => {
                setDadosAgendamentos(0)
            }),'total-agendamentos-cockpit');
    },

    handleSetDadosAgendamentosDia: (setDadosAgendamentosDia: (total: number) => void) => {
        trackPromise(newCockpitService.totalAgendamentos(null, 'dia')
            .then((response) => {
                setDadosAgendamentosDia(response.data.total)
            },(error) => {
                setDadosAgendamentosDia(0)
            }),'total-agendamentos-dia-cockpit');
    },

    handleSetDadosTarefas: (setDadosTarefas: (total: number) => void) => {
        trackPromise(newCockpitService.totalTarefas()
            .then((response) => {
                setDadosTarefas(response.data.total)
            },(error) => {
                setDadosTarefas(0)
            }),'total-tarefas-cockpit');
    },

    handleSetDadosTarefasAtrasada: (setDadosTarefasAtrasada: (total: number) => void) => {
        trackPromise(newCockpitService.totalTarefasAtrasadas()
            .then((response) => {
                setDadosTarefasAtrasada(response.data.total)
            },(error) => {
                setDadosTarefasAtrasada(0)
            }),'total-tarefas-atrasadas-cockpit');
    },

    handleSetDadosRepresados: (setDadosRepresados: (total: number) => void) => {
        trackPromise(newCockpitService.totalRepresados()
            .then((response) => {
                setDadosRepresados(response.data.total)
            },(error) => {
                setDadosRepresados(0)
            }),'total-represados-cockpit');
    },

    handleSetTasksToPerform: (setTotalTasksToPerform: (total: number) => void) => {
        trackPromise(newCockpitService.totalTasksToPerform(null, 'day')
            .then((res) => {
                setTotalTasksToPerform(res.data.total)
            },(error) => {
                setTotalTasksToPerform(0)
            }),'total-tasks-perform-cockpit');
    },

    handleSetTotalVisits: (setTotalVisits: (total: number) => void) => {
        trackPromise(newCockpitService.totalVisits()
            .then((res) => {
                setTotalVisits(res.data.total)
            },(error) => {
                setTotalVisits(0)
            }),'total-visits-cockpit');
    },

    handleSetTotalVisitsToPerform: (setTotalVisitsToPerform: (total: number) => void) => {
        trackPromise(newCockpitService.totalVisitsToPerform()
            .then((res) => {
                setTotalVisitsToPerform(res.data.total)
            },(error) => {
                setTotalVisitsToPerform(0)
            }),'total-visits-perform-cockpit');
    },

    handleSetTotalLateVisits: (setTotalLateVisits: (total: number) => void) => {
        trackPromise(newCockpitService.totalLateVisits()
            .then((res) => {
                setTotalLateVisits(res.data.total)
            },(error) => {
                setTotalLateVisits(0)
            }),'total-late-visits-cockpit');
    },

    handleSetTotalAttendanceSlaTime: (setTotalAttendanceSlaTime: (total: string) => void) => {
        trackPromise(newCockpitService.totalAttendanceSlaTime()
            .then((res) => {
                setTotalAttendanceSlaTime(res.data.total)
            },(error) => {
                setTotalAttendanceSlaTime("00:00:00")
            }),'total-average-attendance-sla-time-cockpit');
    },

    handleSetTotalPhones: (setTotalPhones: (total: number) => void) => {
        trackPromise(newCockpitService.totalPhoneCalls()
            .then((res) => {
                setTotalPhones(res.data.total)
            },(error) => {
                setTotalPhones(0)
            }),'total-phone-calls-cockpit');
    },

    handleSetTotalDayPhones: (setTotalDayPhones: (total: number) => void) => {
        trackPromise(newCockpitService.totalPhoneCalls(null, 'day')
            .then((res) => {
                setTotalDayPhones(res.data.total)
            },(error) => {
                setTotalDayPhones(0)
            }),'total-day-phone-calls-cockpit');
    },

    handleSetTotalEmails: (setTotalEmails: (total: number) => void) => {
        trackPromise(newCockpitService.totalEmails()
            .then((res) => {
                setTotalEmails(res.data.total)
            },(error) => {
                setTotalEmails(0)
            }),'total-emails-cockpit');
    },
    
    handleSetTotalDayEmails: (setTotalDayEmails: (total: number) => void) => {
        trackPromise(newCockpitService.totalEmails(null, 'day')
            .then((res) => {
                setTotalDayEmails(res.data.total)
            },(error) => {
                setTotalDayEmails(0)
            }),'total-day-emails-cockpit');
    },

    handleSetTotalMessages: (setTotalMessages: (total: number) => void) => {
        trackPromise(newCockpitService.totalMessages()
            .then((res) => {
                setTotalMessages(res.data.total)
            },(error) => {
                setTotalMessages(0)
            }),'total-day-messages-cockpit');
    },

    handleSetTotalDayMessages: (setTotalDayMessages: (total: number) => void) => {
        trackPromise(newCockpitService.totalMessages(null, 'day')
            .then((res) => {
                setTotalDayMessages(res.data.total)
            },(error) => {
                setTotalDayMessages(0)
            }),'total-day-messages-cockpit');
    },

    handleSetTotalProposals: (setTotalProposals: (total: number) => void) => {
        trackPromise(newCockpitService.totalProposals()
            .then((res) => {
                setTotalProposals(res.data.total)
            },(error) => {
                setTotalProposals(0)
            }),'total-day-proposals-cockpit');
    },
    
    handleSetTotalDayProposals: (setTotalDayProposals: (total: number) => void) => {
        trackPromise(newCockpitService.totalProposals(null, 'day')
            .then((res) => {
                setTotalDayProposals(res.data.total)
            },(error) => {
                setTotalDayProposals(0)
            }),'total-day-proposals-cockpit');
    },

    handleSetTotalComments: (setTotalComments: (total: number) => void) => {
        trackPromise(newCockpitService.totalComments()
            .then((res) => {
                setTotalComments(res.data.total)
            },(error) => {
                setTotalComments(0)
            }),'total-day-comments-cockpit');
    },

    handleSetTotalDayComments: (setTotalDayComments: (total: number) => void) => {
        trackPromise(newCockpitService.totalComments(null, 'day')
            .then((res) => {
                setTotalDayComments(res.data.total)
            },(error) => {
                setTotalDayComments(0)
            }),'total-day-comments-cockpit');
    },
}

export { cardsService }