import { trackPromise } from 'react-promise-tracker';
import crm from './crm';

const StatusOportunidadeService = {

	listaEtapas:(filtros=null)=>{
		return crm.get('/status-oportunidade', { params: filtros });
	},
	listaEtapaId:(id)=>{
		return crm.get('/status-oportunidade/' + id);
	},
	listaTodasEtapas:()=>{
		return crm.get('/status-oportunidade/todos');
	},
	listaTodasEtapasFunis:(filtros)=>{
		return crm.get('/status-oportunidade/todos-com-funis',{ params: filtros });
	},
	recepcaoNaoDistribuidos:()=>{
		return crm.get('/lead/recepcao-nao-distribuidos');
	},
	editStatusOportunidade: (payload, id) => {
        let uri = '/status-oportunidade/' + id;
        return crm['put'].call(crm, uri, payload);
    },
	mudarPosicaoStatusOportunidade: (dados) => {
        let uri = '/status-oportunidade/mudar-posicao';
        return crm['put'].call(crm, uri, dados);
    },
	mudarPosicaoEtapas: (dados) =>{
		let uri = '/status-oportunidade/mudar-posicao-etapas';
		return crm['put'].call(crm, uri, dados);
	},
	storeStatusOportunidade: (payload) => {
        let uri = '/status-oportunidade';
        return crm['post'].call(crm, uri, payload);
    },
	excluirStatusOportunide: (id) =>{
		let uri = '/status-oportunidade/' + id;
		return crm['delete'].call(crm, uri);
	},
	iconesMidia: () => {
		return crm.get('/midia/icones');
    },
	excluir: (idLead) => {
		return crm.delete('/lead/' + idLead);
	},
	reordenar: (id_etapa, payload) => {
		return trackPromise(crm.put(`/status-oportunidade/reordenar-substatus/${id_etapa}`, payload))
	}
};

export default StatusOportunidadeService;
