import React, { Dispatch, useContext, useMemo } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import ModalDommus from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { TagOportunidadeContext } from "../../contexts/TagOportunidadeContext";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
import { trackPromise } from "react-promise-tracker";
import TagOportunidadeService from "../../services/TagOportunidadeService";
import { toastSucesso } from "../../helpers/DommusSwal";
import Swal from "sweetalert2";
import { OportunidadePorStatusPaginadoType } from "../../pages/Oportunidades/Visualizacao/FunilOportunidades/types";
import { OportunidadeType } from "../../pages/DetalheOportunidade/Cadastro/types";

interface TagOportunidadeFormularioProps {
    oportunidade: OportunidadePorStatusPaginadoType | OportunidadeType;
    openModal: boolean;
    setOpenModal: Dispatch<React.SetStateAction<boolean>>
    atualizarOportunidade?: (oportunidade:(OportunidadePorStatusPaginadoType | OportunidadeType)) => void;
}

export function TagOportunidadeFormulario({ oportunidade, openModal, setOpenModal, atualizarOportunidade }: TagOportunidadeFormularioProps) {
    const { listaTagsOportunidade, recarregarTagsOportunidade } = useContext(TagOportunidadeContext);
    const { control, handleSubmit } = useForm()

    const handleOnSubmit = (data: any) => {
        let payload = {
            "tags": data.tags_oportunidade.map((tag: any) => {
                return {
                    "id_tag": tag.__isNew__ ? null : tag.value,
                    "nome": tag.label
                };
            }) as []
        }

        trackPromise(TagOportunidadeService.storeTagsOportunidade(oportunidade.id_oportunidade, payload)).then((response:any)=>{
            setOpenModal(false);
            if(atualizarOportunidade){
                oportunidade.tags_oportunidade = response.data;
                atualizarOportunidade(oportunidade);
                recarregarTagsOportunidade();
                toastSucesso("Etiquetas alteradas com sucesso!");
            }else{
                Swal.fire({
                    titleText: "Sucesso",
                    text: "Etiquetas alteradas com sucesso!",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    showConfirmButton: false,
                    focusConfirm: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: false,
                }).then(() => {                    
                    window.location.reload();
                })
            }   
        }).catch((error)=>{            
            setOpenModal(false);
            Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao alterar as etiquetas: " + error,
                icon: 'error'
            });
        })
        
    }

    const optionsTag = useMemo(() => {
        return listaTagsOportunidade.map((tag) => {
            return {
                label: tag.nome,
                value: tag.id_tag
            }
        })
    }, [listaTagsOportunidade])

    return (
        <ModalDommus
            open={openModal}
            size={"md"}
            close={() => setOpenModal(false)}
            titulo="Etiquetas da Oportunidade"
        >
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                    <Form.Label id="label-tags">Etiqueta(s):</Form.Label>
                    <Controller
                        name="tags_oportunidade"
                        control={control}
                        defaultValue={oportunidade?.tags_oportunidade?.map((tag) => {
                            return {
                                "label": tag.nome,
                                "value": tag.id_tag
                            }
                        })}
                        render={({ onChange, value }) =>
                            <CreatableSelect
                                isMulti
                                options={optionsTag}
                                formatCreateLabel={() => `Cadastrar`}
                                className="basic-multi-select"
                                placeholder="Selecionar ou  criar"
                                onChange={onChange}
                                value={value}
                                maxMenuHeight={190}
                            />
                        }
                    />
                </Form.Group>
                <Container fluid className="footer-filtro">
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btnFiltroWrapper"
                            variant={"success"}
                            type="submit"
                        >
                            <FontAwesomeIcon
                                icon={faSave}
                                style={{ marginRight: "15px" }}
                            />
                            Salvar
                        </Button>
                    </div>
                </Container>
            </Form>
        </ModalDommus>
    );
}
