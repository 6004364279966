import React, { useState } from "react";

import { trackPromise } from "react-promise-tracker";
import oportunidadeService from "../../../../../../services/OportunidadeService";

import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";
import { Button, Row, Container, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faBars,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";

export default function EditarInteresse({
  listaInteresses,
  selecionado,
  atualiza,
  oportunidade,
  setRefresh,
  refresh,
  close,
}) {
  const [interesseSelecionado, setInteresseSelecionado] = useState(selecionado);
  const [idOportunidadeInteresse, setIdOportunidadeInteresse] = useState();
  
  function salvaInteresse(id) {
    const dados = {
      id_oportunidade: oportunidade.id_oportunidade,
      informacoes: oportunidade.interesse
        ? oportunidade.interesse.informacoes
        : "",
      empreendimento: oportunidade.interesse
        ? oportunidade.interesse.empreendimento
          ? JSON.parse(oportunidade.interesse.empreendimento)
          : []
        : [],
      localizacao: oportunidade.interesse
        ? oportunidade.interesse.localizacao
          ? JSON.parse(oportunidade.interesse.localizacao)
          : []
        : [],
      tipologia: oportunidade.interesse
        ? oportunidade.interesse.tipologia
          ? JSON.parse(oportunidade.interesse.tipologia)
          : []
        : [],
      id_como_conheceu: oportunidade.interesse
        ? oportunidade.interesse.id_como_conheceu
        : "",
      id_nivel_interesse: interesseSelecionado,
      id_origem: oportunidade.interesse ? oportunidade.interesse.id_origem : "",
      saldo_fgts: oportunidade.interesse
        ? oportunidade.interesse.saldo_fgts
        : "",
      tempo_previsto_compra: oportunidade.interesse
        ? oportunidade.interesse.tempo_previsto_compra
        : "",
      possui_dependentes: oportunidade.interesse
        ? oportunidade.interesse.possui_dependentes
        : "",
      id_oportunidade_interesse: oportunidade.interesse
        ? oportunidade.interesse.id_oportunidade_interesse
        : "",
    };
    if (idOportunidadeInteresse !== undefined) {
      dados.id_oportunidade_interesse = idOportunidadeInteresse;
    }
    trackPromise(
      oportunidadeService.salvarInteresse(dados).then(
        (response) => {
          setIdOportunidadeInteresse(response.data.id_oportunidade_interesse);
          Swal.fire({
            titleText: "Atualizado!",
            text: "Qualificação salva com sucesso.",
            icon: "success",
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            focusConfirm: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o interesse: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  return (
    <Container fluid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        {listaInteresses.length > 0 ? (
          listaInteresses.map((item) => {
            return (
              <div
                style={{
                  marginBottom: 10,
                }}
                key={item.id_nivel_interesse}
              >
                {item.descricao === "Muito Frio" ? (
                  <div
                    onClick={() => {
                      setInteresseSelecionado(item.id_nivel_interesse);
                    }}
                    style={
                      interesseSelecionado === item.id_nivel_interesse
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            border: "1px solid var(--dommus-color)",
                            justifyContent: "space-between",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    {item.descricao}
                    <div>
                      {item.descricao === "Muito Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Normal" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FFAB00"
                            icon={faBars}
                          />
                        </div>
                      ) : item.descricao === "Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : item.descricao === "Muito Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : item.descricao === "Frio" ? (
                  <div
                    onClick={() => {
                      setInteresseSelecionado(item.id_nivel_interesse);
                    }}
                    style={
                      interesseSelecionado === item.id_nivel_interesse
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            border: "1px solid var(--dommus-color)",
                            justifyContent: "space-between",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    {item.descricao}
                    <div>
                      {item.descricao === "Muito Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Normal" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FFAB00"
                            icon={faBars}
                          />
                        </div>
                      ) : item.descricao === "Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : item.descricao === "Muito Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : item.descricao === "Normal" ? (
                  <div
                    onClick={() => {
                      setInteresseSelecionado(item.id_nivel_interesse);
                    }}
                    style={
                      interesseSelecionado === item.id_nivel_interesse
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            border: "1px solid var(--dommus-color)",
                            justifyContent: "space-between",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    {item.descricao}
                    <div>
                      {item.descricao === "Muito Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Normal" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FFAB00"
                            icon={faBars}
                          />
                        </div>
                      ) : item.descricao === "Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : item.descricao === "Muito Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : item.descricao === "Quente" ? (
                  <div
                    onClick={() => {
                      setInteresseSelecionado(item.id_nivel_interesse);
                    }}
                    style={
                      interesseSelecionado === item.id_nivel_interesse
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            border: "1px solid var(--dommus-color)",
                            justifyContent: "space-between",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    {item.descricao}
                    <div>
                      {item.descricao === "Muito Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Normal" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FFAB00"
                            icon={faBars}
                          />
                        </div>
                      ) : item.descricao === "Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : item.descricao === "Muito Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : item.descricao === "Muito Quente" ? (
                  <div
                    onClick={() => {
                      setInteresseSelecionado(item.id_nivel_interesse);
                    }}
                    style={
                      interesseSelecionado === item.id_nivel_interesse
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            border: "1px solid var(--dommus-color)",
                            justifyContent: "space-between",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            padding: 5,
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            borderRadius: 5,
                            width: 150,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    {item.descricao}
                    <div>
                      {item.descricao === "Muito Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Frio" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#3D56B2"
                            icon={faChevronDown}
                          />
                        </div>
                      ) : item.descricao === "Normal" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FFAB00"
                            icon={faBars}
                          />
                        </div>
                      ) : item.descricao === "Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : item.descricao === "Muito Quente" ? (
                        <div
                          className="DoubleArrow"
                          data-tip={"Interesse " + item.descricao}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                            className="iconDouble"
                          />
                          <FontAwesomeIcon
                            style={{ fontSize: 14 }}
                            color="#FF5C58"
                            icon={faChevronUp}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : (
          <Alert variant="warning">Nenhum interesse a ser exibido.</Alert>
        )}
      </div>
      <Row className="formFooter">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          {oportunidade.interesse ? (
            <Button
              onClick={() => {
                salvaInteresse(oportunidade.id_oportunidade_interesse);
              }}
              variant={"success"}
              type={"button"}
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
              Salvar
            </Button>
          ) : (
            <Button
              onClick={() => {
                salvaInteresse();
              }}
              variant={"success"}
              type={"button"}
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
              Salvar
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}
