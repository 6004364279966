import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import MidiaSocialIcone from "../../../../../components/MidiaSocialIcone";
import { format, parseISO } from "date-fns";
import { celularMask } from "../../../../../components/InputTextMask";
import { LeadType } from "../../../../DetalheOportunidade/Cadastro/types";

interface LinhaLeadType {
  lead: LeadType
  selecionado: boolean
  setSelecionado: (id_lead: number) => void
}

export default function LinhaLead({ lead, selecionado, setSelecionado }: LinhaLeadType) {

  return (
    <>
      <tr onClick={() => setSelecionado(lead.id_lead)}>
        <td style={{ cursor: "pointer" }}>
          <Row>
            <Form.Group

              as={Col}
              className="col-1 text-center mt-4"
              controlId="formNome"
            >
              <input
                type="checkbox"
                id={"lead" + lead.id_lead}
                value={1}
                checked={selecionado}
                onChange={() => setSelecionado(lead.id_lead)}
              />
            </Form.Group>

            <Col>
              <div className="conteudoNaoDistribuido">
                <div className="midia">
                  {lead.midia && (
                    <>
                      <MidiaSocialIcone midia={lead.midia} />
                    </>
                  )}
                </div>
                <div className="">
                  <div className="linha">
                    <div>
                      {lead.nome}
                      {lead.criado_em
                        ? format(
                            parseISO(lead.criado_em),
                            " | dd/MM/yyyy' às ' HH:mm:ss"
                          )
                        : ""}
                    </div>
                  </div>
                  <div className="linha">
                    <div>
                      {lead.email} | {celularMask(lead.telefone)}
                    </div>
                  </div>
                  <div className="linha">
                    {lead.campanha != null ? (
                      <div>Campanha: {lead.campanha.nome}</div>
                    ) : (
                      <div>Campanha: -</div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </td>
      </tr>
    </>
  );
}
