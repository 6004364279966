import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import FiltroOportunidade from "./Filtros/Oportunidade";
import  { OportunidadeInfoFiltersType } from "./Filtros/types"
import ListaLeads from "./ListaLeads";
import ListaLeadsPage from "./ListaLeadsPage";
import NaoDistribuidos from "./Filtros/NaoDistribuidos";
import ofertaAtiva from "../../../../services/OfertaAtivaService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import DommusToggle from "../../../../components/DommusToggle";
import Importacao from "../../../Importacao";
import DommusBarraTitulo from "../../../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../../../components/DommusBarraTitulo/ItemBarraTitulo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faFilter } from '@fortawesome/free-solid-svg-icons';
import ModalDommus from "../../../../components/Modal";
import FiltroOfertaAtiva, { FiltroOfertaAtivaType } from "../../FiltroOfertaAtiva";
import { LeadType } from "../../../DetalheOportunidade/Cadastro/types"

interface BaseDeLeadsProps {
  oferta: OfertaAtivaType
  ofertaAlteracao: (oferta_id: number, novaOferta: OfertaAtivaType) => void
}

export type OfertaAtivaType = {
  id_oferta_ativa: number
  titulo: string
  id_usuario: number | null
  data_inicial: string
  data_final: string
  objetivo: string
  tipo_importacao: string
  filtro: string
  acesso_fora_periodo: number
  resgate_fora_periodo: number
  criado_em: string
  atualizado_em: string | null
  ativo: string
  leads_count: number | null
  leads_descartados: number | null
  leads_resgatados: number | null
  leads_agendados: number | null
  leads_contactados: number | null
  leads_sem_contato: number | null
  oferta_usuarios: Array<OfertaAtivaUsuariosType> | null
}

export type OfertaAtivaUsuariosType = {
  id_oferta_ativa: number
  id_usuario: number
  permissao: string
  lead_ativo: number | null
  criado_em: string | null
  atualizado_em: string | null
  ativo: string | null
}

export default function BaseDeLeads({ oferta, ofertaAlteracao }: BaseDeLeadsProps) {
  const [tipoImportacao, setTipoImportacao] = useState('');
  const [tipoLead, setTipoLead] = useState('');
  const [listaLeads, setListaLeads] = useState([] as Array<LeadType>);
  const [filtroSubmetido, setFiltroSubmetido] = useState({});
  const [leadsSelecionadosAlterados, setLeadsSelecionadosAlterados] = useState(false);
  const [filtroInfo, setFiltroInfo] = useState({} as OportunidadeInfoFiltersType);
  const [toggleFiltro, setToggleFiltro] = useState(true);
  const [toggleLista, setToggleLista] = useState(false);
  const [btnFiltrar, setBtnFiltrar] = useState(<Button className="d-none"></Button>);
  const [optionsImportacao, setOptionsImportacao] = useState({});
  const [openModalFiltro, setopenModalFiltro] = useState(false)
  const [exibeFiltrados, setexibeFiltrados] = useState(true)
  const [filtersAlert, setFiltersAlert] = useState(false)

  useEffect(() => {
    if (oferta) {
      const filtro = JSON.parse(oferta.filtro);
      if (filtro) {
        setTipoImportacao(filtro.tipo_importacao);
        setTipoLead(filtro.tipo_filtro);
        setFiltroInfo(filtro);
      }
    }
  }, [oferta]);

  useEffect(() => {
    setLeadsSelecionadosAlterados(false);
  }, [filtroSubmetido]);

  const handleSubmitImportar = useCallback(
    (leadsSelecionados: Array<number>) => {
      const postInfo = {
        leadsSelecionados,
        id_oferta_ativa: oferta.id_oferta_ativa,
        filtroSubmetido,
        options: optionsImportacao,
      };

      trackPromise(
        ofertaAtiva.importarLeads(postInfo).then((res) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Leads importados com sucesso",
            icon: "success",
          }).then(() => {
            setLeadsSelecionadosAlterados(false);
            setListaLeads([]);
            setFiltroSubmetido({});
            ofertaAlteracao(oferta.id_oferta_ativa, res.data);
          });
        })
      );
    },
    [filtroSubmetido, optionsImportacao]
  );

  const handleGetLeadsCSV = useCallback((leadsCSV: Array<LeadType>, id_importacao: number) => {
    setListaLeads(leadsCSV);
    setToggleFiltro(false);
    setToggleLista(true);
    setOptionsImportacao({
      origem: "importacao",
      id_importacao: id_importacao,
    });
  }, []);

  const submiterFiltro = (filtro: FiltroOfertaAtivaType) => {
      trackPromise(ofertaAtiva.filtrarLeadsOA(oferta.id_oferta_ativa, filtro)
        .then((response) => {
          if (response.data !== undefined) {
            setListaLeads(response.data.leads)
          }
        }).catch(error => Swal.fire({
            titleText: "Ooops...",
            text: "Não foi possível fazer o filtro" + error,
            icon: "error"
        }))            
      )
  }

  function exibirLeadsFiltrados(){
    setexibeFiltrados(!exibeFiltrados)
  }

  return (
    <Container fluid>
      <DommusToggle
        title="Filtrar leads"
        toggle={toggleFiltro}
        setToggle={() => toggleFiltro}
      >
        <Row>
          <Col md="4">
            <Form className="formTipo">
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Tipo de importação: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={tipoImportacao || undefined}
                    onChange={(event) => setTipoImportacao(event.target.value)}
                  >
                    <option value="">Selecione o tipo de importação</option>
                    <option value="F">Filtro de leads</option>
                    <option value="C">CSV</option>
                  </Form.Control>
                </Form.Group>
              </Row>
            </Form>

            {tipoImportacao === "F" && (
              <>
                <Form className="formTipoLead">
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        Tipo de lead: <DommusRequiredSpan />
                      </Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        value={tipoLead || undefined}
                        onChange={(event) => setTipoLead(event.target.value)}
                      >
                        <option value="">Selecione o tipo de lead</option>
                        <option value="oportunidades">Oportunidades</option>
                        <option value="nao-distribuido">
                          Não distribuídos
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Row>
                </Form>
                {btnFiltrar}
              </>
            )}
          </Col>
          {tipoImportacao === "F" && (
            <Col md="8">
              {tipoLead === "oportunidades" && (
                <FiltroOportunidade
                  setListaLeads={setListaLeads}
                  setOptionsImportacao={setOptionsImportacao}
                  setFiltroSubmetido={setFiltroSubmetido}
                  leadsSelecionadosAlterados={leadsSelecionadosAlterados}
                  filtroInfo={filtroInfo}
                  setToggleFiltro={setToggleFiltro}
                  setToggleLista={setToggleLista}
                  setBtnFiltrar={setBtnFiltrar}
                />
              )}
              {tipoLead === "nao-distribuido" && (
                <NaoDistribuidos
                  setListaLeads={setListaLeads}
                  setOptionsImportacao={setOptionsImportacao}
                  setFiltroSubmetido={setFiltroSubmetido}
                  leadsSelecionadosAlterados={leadsSelecionadosAlterados}
                  setToggleFiltro={setToggleFiltro}
                  setToggleLista={setToggleLista}
                  setBtnFiltrar={setBtnFiltrar}
                />
              )}
            </Col>
          )}
          {tipoImportacao === "C" && (
            <Col>
              <Importacao
                options={{ "distribuir-leads": false }}
                setIdsLeadsImportados={handleGetLeadsCSV}
              />
            </Col>
          )}
        </Row>
      </DommusToggle>
      <DommusToggle
        title="Lista de Leads Filtrados"
        toggle={toggleLista}
        setToggle={() => toggleFiltro}
      >
        <Row>
          <Col>
            <DommusBarraTitulo>
                <ItemBarraTitulo classe="">
                    Pesquisa:
                    <span></span>
                </ItemBarraTitulo>
                <ItemBarraTitulo classe="" style={{cursor: 'pointer'}} onClick={() => {setopenModalFiltro(true); setexibeFiltrados(false)}}
                >
                  <FontAwesomeIcon icon={faFilter} color="#183b64" />
                </ItemBarraTitulo>
            </DommusBarraTitulo>
          </Col>
        </Row>
        <Row>
          <Col>
            {filtersAlert && listaLeads.length <= 0 && (
              <Alert variant="warning" key={"warning"}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Nenhum registro encontrado. tente uma combinação de filtros diferente.
              </Alert>
            )}
            {listaLeads.length > 0 && exibeFiltrados && (
              <ListaLeads
                leads={listaLeads}
                handleSubmitImportar={handleSubmitImportar}
                setLeadsSelecionadosAlterados={setLeadsSelecionadosAlterados}
                exibirLeadsFiltrados={exibirLeadsFiltrados}
                exibeFiltrados={exibeFiltrados}
              />
            )}
            {oferta != null && oferta.leads_count && oferta.leads_count > 0 && (
              <ListaLeadsPage oferta={oferta} />
            )}
          </Col>
        </Row>
        <ModalDommus 
          children={
            <FiltroOfertaAtiva
              closeModal={() => {
                setopenModalFiltro(false)
                setexibeFiltrados(!exibeFiltrados)
                setFiltersAlert(true)
              }}
              submiterFiltro={submiterFiltro}
            />
          }
          open={openModalFiltro}
          close={() => setopenModalFiltro(false) }
          titulo="Filtro da Oferta Ativa" 
        />
      </DommusToggle>
    </Container>
  );
}
