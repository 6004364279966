import crm from "./crm";

const TagOportunidadeService = {
    buscarTags: () => {
        return crm.get("/tag-oportunidade");
    },
    storeTagsOportunidade: (idOportunidade, payload) => {
        let uri = `/oportunidade/${idOportunidade}/tag-oportunidade`;
        return crm["post"].call(crm, uri, payload);
    },    
};

export default TagOportunidadeService;
