import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Row, Container, Col } from "react-bootstrap";
import "../NovoAtendimento/index.css";
import OportunidadeService from "../../../../services/OportunidadeService";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import { apenasNumeros, celularMask } from "../../../../components/InputTextMask";
import DommusToggle from "../../../../components/DommusToggle";
import {errorToast} from "../../../../components/DommusToast";
import { OportunidadeAtendimentoVisitaService } from "../../../../services/OportunidadeAtendimentoVisitaService"
import Select from "react-select";
import helper from "../../../../helpers/format";
import moment from "moment";
import LocalAtendimentoService from "../../../../services/LocalAtendimentoService";
import DommusCheckboxToggle from "../../../../components/DommusCheckboxToggle";
import guService from "../../../../services/GuService";
import { TipoVisitaService } from "../../../../services/TipoVisitaService";
import { swalErro } from "../../../../helpers/DommusSwal";
import { CamposDinamicosContext } from "../../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { cachearContexto } from "../../../../helpers/cachearContexto";
import { EmpreendimentoContext, OportunidadeContext } from "../../../../components/GlobalContext/globalContext";
import oportunidadeService from "../../../../services/OportunidadeService";
import { FechamentoParceria } from "../../../../components/FechamentoParceria";
import { VendaParceiro } from "../../../../components/VendaParceiro";
import { AtendimentoParticipantesVisita } from "./AtendimentoParticipantesVisita";
import { FormProvider, useForm } from "react-hook-form";
import { AtendimentoEnum } from "../../../Oportunidades/Visualizacao/OportunidadesListaPaginada/types/AtendimentoType";

export default function NovoAtendimento({
  oportunidade,
  inserirLista,
  atendimento,
  atualizaLista,
  setTabIndex,
  index,
  close
}) {
  const [tipo, setTipo] = useState(atendimento.id_tipo_atendimento);
  const [errorTitulo, setErroTitulo] = useState(false);
  const [erroTarefa, setErroTarefa] = useState(false);
  const [titulo, setTitulo] = useState(atendimento.titulo || "");
  const [idTipoTarefa, setIdTipoTarefa] = useState(atendimento.id_tipo_tarefa || "");
  const [listaTarefas, setListaTarefas] = useState([]);
  const [id_oportunidade, setOportunidade] = useState(oportunidade);
  const [contato, setContato] = useState(atendimento.contato);
  const [observacao, setObservacao] = useState(atendimento.observacao);
  const [data, setData] = useState(atendimento.data);
  const [dataInicial, setDataInicial] = useState(
    atendimento.data_inicial || ""
  );
  const [dataFim, setDataFim] = useState(atendimento.data_final || "");
  const [hora, setHora] = useState(atendimento.hora || "00:00");
  const [feedback, setFeedback] = useState(atendimento.feedback);
  const [local, setLocal] = useState(atendimento.local);
  const [id_oportunidade_atendimento, setAtendimento] = useState(
    atendimento.id_oportunidade_atendimento
  );
  const [localAtendimento, setLocalAtendimento] = useState(atendimento.id_local);
  const [telefonesDisponiveis, setTelefonesDisponiveis] = useState([]);
  const [emailsDisponiveis, setEmailsDisponiveis] = useState([]);
  const [locaisAtendimentos, setLocaisAtendimentos] = useState([]);
  const [quando, setQuando] = useState("");
  const [vezesNotificar, setVezesNotificar] = useState(1);
  const [dataHoraNotificacao, setDataHoraNotificacao] = useState(null);
  const [operatorsOptions, setOperatorsOptions] = useState(<></>)
  const [createdFor, setCreatedFor] = useState(atendimento.responsavel || "")
  const [visitaRealizada, setVisitaRealizada] = useState(atendimento.visita?.visita_realizada)
  const [tarefaRealizada, setTarefaRealizada] = useState(atendimento?.tarefa_realizada ?? false)
  const [descricaoVisita, setDescricaoVisita] = useState(atendimento.visita?.descricao)
  const [motivoVisita, setMotivoVisita] = useState(atendimento.visita?.id_tipo_oportunidade_atendimento_visita || "")
  const [motivosVisita, setMotivosVisita] = useState([])
  const [idTipoVisita, setIdTipoVisita] = useState(atendimento?.id_tipo_visita || 1)
  const [arquivo, setArquivo] = useState(null)
  const [vinculoOPV, setVinculoOPV] = useState('')
  const { camposTelaQualificacao, setCamposTelaQualificacao } = useContext(CamposDinamicosContext)
  const {
    atualizarOportunidade,
    oportunidadeSelecionada, setOportunidadeSelecionada,
    tiposAtendimento, setTiposAtendimento,
    tiposTarefa, setTiposTarefa,
    locaisAtendimento, setLocaisAtendimento,
    tiposVisita, setTiposVisita,
    novoTipoAtendimento, setNovoTipoAtendimento,
  } = useContext(OportunidadeContext)
  const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);
  const {coordenadores} = useContext(EmpreendimentoContext)
  const idUsuario = guService.getLocalState("idUsuario")
  const defatultValue = useMemo(()=>{
    let object = []

    if(atendimento.participantes && atendimento.participantes.length){
      atendimento.participantes.forEach((participante)=>{
        object.push(
          {
            id_oportunidade_atendimento_participante: participante?.id_oportunidade_atendimento_participante,
            id_oportunidade_atendimento: participante?.id_oportunidade_atendimento,
            id_participante_tipo: {
              label: participante?.participante_tipo?.descricao,
              value: participante?.participante_tipo?.id_participante_tipo
            },
            nome: participante?.nome,
            contato: celularMask(participante?.contato),    
            cidade: participante?.cidade
          }
        )
      })
    }else{
      object = []
    }
    return object;
  },[atendimento]);
  const methods = useForm({
    defaultValues: {
      "participantes": defatultValue
    }
  });

  function createOperatorsOptions(id) {
    OportunidadeService.searchOperatorsInfo(id)
      .then(res => {
        let options = []
        Object.keys(res.data).forEach(id => {
          options.push({
            "value": id,
            "label": res.data[id]
          })
        })
        
        coordenadores.forEach((coordenador)=>{
          options.push({
            "value": coordenador.id_coordenador,
            "label": coordenador.nome_coordenandor
          })
        })

        const optionUsuario = options.filter(option => option.value == idUsuario)
        if(optionUsuario.length) {
          setCreatedFor(idUsuario)
        }
        setOperatorsOptions(options)
      })
      .catch((error) => {
        Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar os atendentes e gestores da oportunidade selecionada: " + error,
            icon: 'error'
        });
      })
  }

  function buscaTarefaPorId(id) {
    let tarefaPorId = [];
    listaTarefas.map((item) => {
      if (parseInt(item.value) === parseInt(id)) {
        tarefaPorId.push(item);
      }
    });
    return tarefaPorId;
  }

  useEffect(() => {
    cachearContexto(camposTelaQualificacao, setCamposTelaQualificacao, OportunidadeService.buscarCamposDinamicosTelaQualificacao, false, camposTelaQualificacao.total)
    if(oportunidadeSelecionada?.id_oportunidade != oportunidade) {
      OportunidadeService.buscarOportunidade(oportunidade)
        .then(response => setOportunidadeSelecionada(response.data))
        .catch(error => {
          console.log(error)
          console.log(error.response)
          swalErro('Houve um erro ao tentar buscar a oportunidade.')
        })
    }
    cachearContexto(tiposAtendimento, setTiposAtendimento, oportunidadeService.buscarTiposAtendimentos)
    cachearContexto(tiposTarefa, setTiposTarefa, OportunidadeService.buscarTiposTarefa)
    cachearContexto(locaisAtendimento, setLocaisAtendimento, LocalAtendimentoService.buscarDadosLocaisAtendimento)
    if(atendimento.id_tipo_atendimento == 4 && atendimento.tarefa_realizada) {
      buscarMotivosVisita();
    }
    createOperatorsOptions(oportunidade)
    cachearContexto(tiposVisita, setTiposVisita, TipoVisitaService.buscarPorOpv.bind(this, oportunidade))
  }, []);

  useEffect(()=>{
    if(novoTipoAtendimento !== 0){
      setTipo(novoTipoAtendimento);
      setNovoTipoAtendimento(0);
    }
  },[])

  useEffect(() => {
    if(oportunidadeSelecionada != null) {
      montarTelefonesDisponiveis()
      montarEmailsDisponiveis()
    }
  }, [oportunidadeSelecionada])

  useEffect(() => {
    if(tiposTarefa.length) {
      montarOpcoesTiposTarefa()
    }
  }, [tiposTarefa])

  useEffect(() => {
    if(locaisAtendimento.length) {
      const locaisVisiveis = locaisAtendimento.filter(local => Number(local.ativo))
      setLocaisAtendimentos(locaisVisiveis)
    }
  }, [locaisAtendimento])

  function montarOpcoesTiposTarefa() {
    const opcoes = tiposTarefa.map(tipo => {
      return {
        value: tipo.id,
        label: tipo.nome
      }
    })
    setListaTarefas(opcoes)
  }

  function buscarMotivosVisita() {
    trackPromise(OportunidadeAtendimentoVisitaService.buscarTipos())
    .then(response => {
      const motivosVisitaCarregados = response.data
      setMotivosVisita(motivosVisitaCarregados)
    })
    .catch(erro => Swal.fire('Ooops...', `Houve um erro ao tentar buscar os tipos de visita. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`))
  }

  function montarTelefonesDisponiveis() {
    const envolvidosTelefones = oportunidadeSelecionada.envolvidos_oportunidade.map(envolvidoOportunidade => {
      return [...new Set([envolvidoOportunidade?.envolvido?.telefone, envolvidoOportunidade?.envolvido?.celular])].map(telefone => {
        return {
          nome: envolvidoOportunidade?.envolvido?.nome,
          telefone
        }
      })
    }).flat()
    setTelefonesDisponiveis(envolvidosTelefones)
  }

  function montarEmailsDisponiveis() {
    const envolvidosTelefones = oportunidadeSelecionada.envolvidos_oportunidade.map(envolvidoOportunidade => {
      return {
        nome: envolvidoOportunidade?.envolvido?.nome,
        email: envolvidoOportunidade?.envolvido?.email
      }
    }).flat()
    setEmailsDisponiveis(envolvidosTelefones)
  }

  function verificaError() {
    if (titulo === "" || titulo == null) {
      setErroTitulo(true);
    } else {
      setErroTitulo(false);
      if (idTipoTarefa === "" || idTipoTarefa == null) {
        setErroTarefa(true);
      } else {
        setErroTarefa(false);
        if (dataInicial === "" || dataInicial == null) {
        } else {
        }
      }
    }
  }

  const handleSetTipo = (event) => {
    let dadosDinamicos = oportunidadeSelecionada?.interesse ? JSON.parse(oportunidadeSelecionada?.interesse?.dados_dinamicos) : null;
    if(!dadosDinamicos) {
      setTipo(event)

      return
    }
    let validaCampos = [];
    let camposVazios = [];
    camposTelaQualificacao.campos.forEach(camposlista => {
      let campo = (camposlista?.etapas_vinculadas?.filter(campos => [campos].includes(oportunidadeSelecionada.status.id_status_oportunidade)))
      if (Array.isArray(campo) && campo.length) {
        validaCampos.push(camposlista);
      }
    })
    validaCampos.forEach(camposValidados => {
      if (dadosDinamicos[camposValidados.id_campos_dinamicos]) {
        camposVazios.push(camposValidados.label);
      }
    })

    // tiposAtendimentos[event - 1].evoluir_para

    let nomeCampos = camposVazios.toString();
    if (Array.isArray(camposVazios) && camposVazios.length ) {
      Swal.fire({
        title: 'Parece que há campos obrigatórios sem preencher, deseja preenche-los?',
        text: `Campos: ${nomeCampos}`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          setTabIndex(1);
          close();

        }
      })
    } else {
      setTipo(event)
    }

    let tipoTemp = tiposAtendimento.find((item) => Number(item?.id_tipo_atendimento) === Number(event));
    if(tipoTemp){
      setTipoAtendimentoSelecionado(tipoTemp);
    }

    if(Number(event) === AtendimentoEnum.VISITA){
      buscarMotivosVisita();
    }
  }

  function salvarAtendimento() {
    if(!(tipo)){
      errorToast.fire({text:"Campo obrigatório não preenchido."});
      return false;
    }
    var dados = {
      titulo: titulo,
      id_tipo_tarefa: idTipoTarefa ? idTipoTarefa : null,
      id_tipo_visita: idTipoVisita || null,
      id_tipo_atendimento: parseInt(tipo),
      id_local: parseInt(localAtendimento),
      tarefa_realizada: tarefaRealizada,
      contato: contato,
      observacao: observacao,
      feedback: feedback,
      local: local,
      id_oportunidade: id_oportunidade,
      responsavel: createdFor,
      visita: {
        visita_realizada: visitaRealizada,
        descricao: descricaoVisita,
        id_tipo_oportunidade_atendimento_visita: motivoVisita
      },
      anexo: arquivo || null,
      id_oportunidade_secundaria: vinculoOPV || null,
      data_hora_notificacao: dataHoraNotificacao ?? null
    };
    if (
      id_oportunidade_atendimento != undefined &&
      id_oportunidade_atendimento != null
    ) {
      dados.id_oportunidade_atendimento = id_oportunidade_atendimento;
    }
    if (
      dados.id_tipo_atendimento === 1 ||
      dados.id_tipo_atendimento === 2 ||
      dados.id_tipo_atendimento === 3
    ) {
      if (
        dados.contato == undefined ||
        dados.contato == "" ||
        dados.contato == null ||
        dados.contato == "Selecione"
      ) {
        Swal.fire({
          titleText: "Erro!",
          text: "Preencha todos os campos obrigatórios",
          icon: "error",
        });
        return;
      }
    }
    
    if ([AtendimentoEnum.VISITA, AtendimentoEnum.TAREFA, OportunidadeService.idsTiposAtendimento.FECHAMENTO_DE_PARCERIA].includes(dados.id_tipo_atendimento)) {
      dados.data = data ? data : new Date()
      dados.data_inicial = helper.formataMysqlDateHour(dataInicial)
      dados.data_final = helper.formataMysqlDateHour(dataFim)
      dados.hora = hora ? hora : moment(new Date()).format("HH:MM:ss")
      if (!dados.data || !dados.hora || !createdFor) {
        Swal.fire({
          titleText: "Erro!",
          text: "Preencha todos os campos obrigatórios",
          icon: "error",
        });
        return;
      }
      if ( dados.id_tipo_atendimento === 5 && !idTipoTarefa ) {
          Swal.fire({
            titleText: "Erro!",
            text: "Preencha todos os campos obrigatórios",
            icon: "error",
          });
          return;
      }
    }

    if ( dados.id_tipo_atendimento === AtendimentoEnum.VISITA ){
      let campoEmpty = false;

      let dadosParticipantes = methods.getValues()?.participantes;
      if(dadosParticipantes && dadosParticipantes.length){
        dados.participantes = dadosParticipantes.map((participante,index)=>{
          participante.contato = apenasNumeros(participante.contato);
  
          if(!participante.contato.length || participante.contato.length < 10){
            methods.setError(`participantes.${index}.contato`, { type: "custom", message: "Campo obrigatório!" });
            campoEmpty = true;
          }
  
          if(!participante.id_participante_tipo){
            methods.setError(`participantes.${index}.id_participante_tipo`, { type: "custom", message: "Campo obrigatório!" });
            campoEmpty = true;
          }else{
            participante.id_participante_tipo = participante.id_participante_tipo?.value;
          }
  
          if(!participante.nome){
            methods.setError(`participantes.${index}.nome`, { type: "custom", message: "Campo obrigatório!" });
            campoEmpty = true;
          }
  
          return participante
        })
      }
      
      if(campoEmpty || !dados.id_local || (Object.keys(atendimento).length && atendimento.tarefa_realizada && !dados.visita.visita_realizada && !dados.visita.id_tipo_oportunidade_atendimento_visita)) {
        Swal.fire({
          titleText: "Erro!",
          text: "Preencha todos os campos obrigatórios",
          icon: "error",
        });
        return;
      }

      if(dados.tarefa_realizada){
        if(dados.visita.visita_realizada && !dados.visita.descricao){
          Swal.fire({
            titleText: "Erro!",
            text: "Preencha todos os campos obrigatórios. Descrição da Visita",
            icon: "error",
          });
          return;
        }
  
        if(!dados.visita.visita_realizada && !dados.visita.id_tipo_oportunidade_atendimento_visita){
          Swal.fire({
            titleText: "Erro!",
            text: "Preencha todos os campos obrigatórios. Motivo da Visita",
            icon: "error",
          });
          return;
        }
      }
    }

    if(dados.id_tipo_atendimento == oportunidadeService.idsTiposAtendimento.VENDA_PARCEIRO && !vinculoOPV) {
      swalErro('Preencha todos os campos obrigatórios.')
      return
    }

    trackPromise(
      OportunidadeService.salvarAtendimento(dados).then(
        (response) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Atendimento registrado com sucesso.",
            icon: "success",
          }).then(() => {
            close()
            if (!(dados.id_oportunidade_atendimento || false)) {
              inserirLista(response.data);
            }else{
              atualizaLista(index, response.data)
            }
            atualizarOportunidade(id_oportunidade);
          });
        }
      ).catch(error => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o atendimento: " + error.response?.data?.message,
            icon: "error",
          });

      })
    );
  }

  function limpaCamposPreenchidos(){
    setContato("")
    setObservacao("")
    setData(moment().format('YYYY-MM-DD'))
    setHora("00:00")
    setFeedback("")
    setLocal("")
    setTitulo("")
    setIdTipoTarefa("")
    setVezesNotificar(1)
    setQuando("")
    setArquivo(null)
    setVinculoOPV('')
    setDataHoraNotificacao(null)
  }

  return (
    <>
      <Container fluid>
        <Form className="formCadastro">
        <div className="items-form">
          <Row>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Tipo<DommusRequiredSpan /></Form.Label>
              <Form.Control
                as="select"
                placeholder="Selecione"
                value={tipo}
                defaultValue={tipo}
                onChange={(event) => {
                  handleSetTipo(event.target.value)
                  limpaCamposPreenchidos()
                }}
              >
                <option>Selecione</option>
                {tiposAtendimento.length != 0 ? (
                  tiposAtendimento.map((item) => (
                    <option
                      value={item.id_tipo_atendimento}
                      key={item.id_tipo_atendimento}
                    >
                      {item.nome}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Control>
            </Form.Group>
          </Row>

          {![6, 7].includes(Number(tipo)) && (
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Escolha a pessoa que irá executar este atendimento: <DommusRequiredSpan/></Form.Label>
                <select
                  className="form-control"
                  placeholder="Selecione..."
                  value={createdFor}
                  onChange={(event) => setCreatedFor(event.target.value)}
                >
                  <option>Selecione</option>
                  {operatorsOptions.length && operatorsOptions.map((operator, index) => <option key={index} value={operator.value}>{operator.label}</option>)}
                </select>
              </Form.Group>
            </Row>
          ) || <></>}

          {tipo == 1 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    Telefone: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Selecione"
                    value={contato}
                    onChange={(event) => setContato(event.target.value)}
                  >
                    <option>Selecione</option>
                    {telefonesDisponiveis.length != 0 ? (
                      telefonesDisponiveis.map((item) => (
                        <option value={item.telefone} key={item.telefone}>
                          {item.nome + ": " + celularMask(item.telefone)}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Observação:</Form.Label>
                  <Form.Control
                    type="textarea"
                    value={observacao}
                    onChange={(event) => setObservacao(event.target.value)}
                    as="textarea"
                    rows="3"
                  />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}
          {tipo == 2 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    E-mail: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Selecione"
                    value={contato}
                    onChange={(event) => setContato(event.target.value)}
                  >
                    <option>Selecione</option>
                    {emailsDisponiveis.length != 0 ? (
                      emailsDisponiveis.map((item) => (
                        <option value={item.email} key={item.email}>
                          {item.nome + ": " + item.email}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Observação:</Form.Label>
                  <Form.Control
                    value={observacao}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setObservacao(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Feedback:</Form.Label>
                  <Form.Control
                    value={feedback}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setFeedback(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}
          {tipo == 3 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    E-mail/WhatsApp: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Selecione"
                    value={contato}
                    onChange={(event) => setContato(event.target.value)}
                  >
                    <option>Selecione</option>
                    {emailsDisponiveis.length != 0 ? (
                      emailsDisponiveis.map((item) => (
                        <option value={item.email} key={item.email}>
                          {item.nome + ": " + item.email}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                    {telefonesDisponiveis.length != 0 ? (
                      telefonesDisponiveis.map((item) => (
                        <option value={item.telefone} key={item.telefone}>
                          {item.nome + ": " + celularMask(item.telefone)}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Observação:</Form.Label>
                  <Form.Control
                    value={observacao}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setObservacao(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Feedback:</Form.Label>
                  <Form.Control
                    value={feedback}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setFeedback(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}
          {tipo == 4 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    Data: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    value={data}
                    type="date"
                    min="2020-01-01"
                    placeholder="00/00/0000"
                    onChange={(event) => setData(event.target.value)}
                    onBlur={() => {
                      if (data >= "2020-01-01") {
                      } else {
                        setData("");
                        Swal.fire({
                          titleText: "Ooops...",
                          text: "Escolha uma data posterior ao ano de 2020 ",
                          icon: "warning",
                        });
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    Hora: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    value={hora}
                    type="time"
                    onChange={(event) => setHora(event.target.value)}
                    defaultValue="00:00"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formLocalAtendimento">
                  <Form.Label>
                    Local Atendimento: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Selecione"
                    value={localAtendimento}
                    onChange={(event) => {
                      setLocalAtendimento(event.target.value)
                    }}
                  >
                    <option>Selecione</option>
                    {locaisAtendimentos.length != 0 ? (
                      locaisAtendimentos.map((item) => (
                        <option value={item.id_local} key={item.id_local}>
                          {item.nome}{ item.endereco != null ?  ": " + item.endereco : ''}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formLocalAtendimento">
                  <Form.Label>Tipo da Visita:</Form.Label>
                  <Form.Control as="select" value={idTipoVisita} onChange={event => setIdTipoVisita(event.target.value)}>
                    {tiposVisita.length ? tiposVisita.map(tipo => <option value={tipo.id_tipo_visita} key={`id-tipo-visita-${tipo.id_tipo_visita}`}>{tipo.nome}</option>) : <></>}
                  </Form.Control>
                </Form.Group>
              </Row>              
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Descrição Local:</Form.Label>
                  <Form.Control
                    value={local}
                    type="text"
                    as="textarea"
                    rows="3"
                    onChange={(event) => setLocal(event.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Observação:</Form.Label>
                  <Form.Control
                    value={observacao}
                    type="textarea"
                    as="textarea"
                    rows="3"
                    onChange={(event) => setObservacao(event.target.value)}
                  />
                </Form.Group>
              </Row>              
              {((atendimento.tarefa_realizada && atendimento.visita) || tipoAtendimentoSelecionado?.permitir_conclusao_no_cadastro) ? (
                <>
                  <DommusToggle title="Detalhes da Visita" toggle={true}>
                    <Row>
                      <Form.Group as={Col} className={`${!visitaRealizada ? 'pt-3' : 'pt-0'} mb-0`}>
                        <DommusCheckboxToggle 
                          classe='background-vermelho' label="Tarefa Realizada" 
                          value={tarefaRealizada} setValue={(e) => {
                            setTarefaRealizada(e ? 1 : 0)
                            setMotivoVisita("")
                          }}
                        />
                          {tarefaRealizada ?
                            <DommusCheckboxToggle 
                              classe='background-vermelho' label="Visita Realizada" 
                              value={visitaRealizada} setValue={(e) => {
                                setVisitaRealizada(e ? 1 : 0)
                                }}
                            />
                            : <></>
                            }
                      </Form.Group>
                      {(tarefaRealizada && !visitaRealizada) && (
                        <Form.Group as={Col} controlId="formNome">
                          <Form.Label>Motivo da visita não ter sido realizada: <DommusRequiredSpan/></Form.Label>
                          <select value={motivoVisita} className="form-control" onChange={(event) => setMotivoVisita(event.target.value)}>
                            <option value="">Selecione um Motivo</option>
                            {motivosVisita.map((option, index) => <option key={index} value={option.id_tipo_oportunidade_atendimento_visita}>{option.nome}</option>)}
                          </select>
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Descrição da visita:<DommusRequiredSpan/></Form.Label>
                        <Form.Control
                          type="textarea"
                          value={descricaoVisita}
                          onChange={(event) => setDescricaoVisita(event.target.value)}
                          as="textarea"
                          rows="2"
                        />
                      </Form.Group>
                    </Row>
                    {(((atendimento?.visita?.visita_realizada && atendimento?.tarefa_realizada) || tipoAtendimentoSelecionado?.permitir_conclusao_no_cadastro && tarefaRealizada) && visitaRealizada) ? 
                      <Row>
                        <FormProvider {...methods}>                  
                          <AtendimentoParticipantesVisita/>
                        </FormProvider>
                      </Row>
                      : <></>
                    }
                  </DommusToggle>
                </>
              ) : (<></>)}
            </>
          ) : (
            <></>
          )}
          {tipo == 5 ? (
            <>
              <DommusToggle title={"Dados da tarefa"} toggle={true}>
                <Row>
                  <Form.Group as={Col} controlId="formTitulo">
                    <Form.Label className="inputLabel">
                      Título: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      onBlur={() => verificaError()}
                      name="titulo"
                      type="text"
                      value={titulo}
                      onChange={(event) => setTitulo(event.target.value)}
                    />
                    {errorTitulo ? (
                      <p className="campoError">Campo Obrigatório</p>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId="formTipo">
                    <Form.Label className="inputLabel">
                      Tipo de tarefa: <DommusRequiredSpan />
                    </Form.Label>
                    <Select
                      onBlur={() => verificaError()}
                      name="chamados"
                      placeholder="Selecionar"
                      value={buscaTarefaPorId(idTipoTarefa)}
                      onChange={(event) => setIdTipoTarefa(event.value)}
                      isSearchable={true}
                      options={listaTarefas}
                    />
                    {erroTarefa ? (
                      <p className="campoError">Campo Obrigatório</p>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="formNome">
                    <Form.Label>
                      Data: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      value={data}
                      min="2020-01-01"
                      type="date"
                      placeholder="00/00/0000"
                      onChange={(event) => {
                        setData(event.target.value);
                      }}
                      onBlur={() => {
                        if (data >= "2020-01-01") {
                        } else {
                          setData("");
                          Swal.fire({
                            titleText: "Ooops...",
                            text: "Escolha uma data posterior ao ano de 2020 ",
                            icon: "warning",
                          });
                        }
                      }}
                    />

                    {/* <Form.Label>
                      Data Inicial:
                      <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      value={dataInicial}
                      type="datetime-local"
                      onChange={(event) => setDataInicial(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Data Final:</Form.Label>
                    <Form.Control
                      value={dataFim}
                      type="datetime-local"
                      onChange={(event) => setDataFim(event.target.value)}
                    />*/}
                  </Form.Group>
                  <Form.Group as={Col} controlId="formNome">
                    <Form.Label>
                      Hora: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      value={hora}
                      type="time"
                      onChange={(event) => {
                        setHora(event.target.value);
                      }}
                      defaultValue="00:00"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Observação:</Form.Label>
                    <Form.Control
                      value={observacao}
                      type="textarea"
                      as="textarea"
                      onChange={(event) => setObservacao(event.target.value)}
                      rows="3"
                    />
                  </Form.Group>
                </Row>
              </DommusToggle>
              <DommusToggle title={"Notificação"} toggle={false}>
                <Row>
                  <Form.Group as={Col} lg={4} sm={12} controlId="formTipo">
                    <Form.Label>
                      Escolha daqui quando deseja ser notificado:
                    </Form.Label>
                    <Form.Control
                      value={dataHoraNotificacao}
                      type="datetime-local"
                      onChange={(event) =>
                        setDataHoraNotificacao(event.target.value)
                      }
                    />
                  </Form.Group>                 
                </Row>
              </DommusToggle>
              {(tipoAtendimentoSelecionado?.permitir_conclusao_no_cadastro) ? (
                <DommusToggle title="Detalhes da Tarefa" toggle={true}>
                  <Form.Group as={Col} className={`${!visitaRealizada ? 'pt-3' : 'pt-0'} mb-0`}>
                    <DommusCheckboxToggle 
                      classe='background-vermelho' label="Tarefa Realizada" 
                      value={tarefaRealizada} setValue={(e) => {
                        setTarefaRealizada(e ? 1 : 0)
                        setMotivoVisita("")
                      }}
                    />
                  </Form.Group>
                </DommusToggle>
              ): <></>}
            </>
          ) : (
            <></>
          )}
          {tipo == 6 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Proposta:</Form.Label>
                  <Form.Control
                    value={observacao}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setObservacao(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}
          {tipo == 7 ? (
            <>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>Comentário:</Form.Label>
                  <Form.Control
                    value={observacao}
                    type="textarea"
                    as="textarea"
                    onChange={(event) => setObservacao(event.target.value)}
                    rows="3"
                  />
                </Form.Group>
              </Row>
            </>
          ) : tipo == oportunidadeService.idsTiposAtendimento.FECHAMENTO_DE_PARCERIA ? (
            <FechamentoParceria data={data} setData={setData} hora={hora} setHora={setHora} observacao={observacao} setObservacao={setObservacao} arquivo={arquivo} setArquivo={setArquivo}/>
          ) : tipo == oportunidadeService.idsTiposAtendimento.VENDA_PARCEIRO ? (
            <VendaParceiro idOportunidade={vinculoOPV} setIdOportunidade={setVinculoOPV}/>
          ) : (
            <></>
          )}
          </div>
          <Row className="formFooter">
            <Col>
              <button
                type="button"
                onClick={() => salvarAtendimento()}
                className="btn btn-success"
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ marginRight: "15px" }}
                />
                Salvar
              </button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}
