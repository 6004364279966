import crm from "./crm";

export const TipoVisitaService = {
    buscar: () => {
        return crm.get('tipo-visita')
    },
    buscarPorOpv: (idOportunidade:any) => {
        return crm.get('/oportunidade/' + idOportunidade + '/tipo-visita');
    }
};
