import React from 'react';
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";
import LogoutService from "../../../services/LogoutService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";

export function Logout(){

    function logoutSistema() {
        Swal.fire({
            title: 'Deseja realmente sair do sistema?',
            text: "Confirme para deslogar do sistema!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, sair!',
            cancelButtonText: 'Cancelar!'
        }).then((result) => {
            if (result.isConfirmed) {
                trackPromise( LogoutService.sairPerfilUser().then(response => {
                    localStorage.clear();
                    window.location.replace(process.env.REACT_APP_GU_FRONT+'/modulo/logout');
                }).catch(error => {
                    Swal.fire(
                        'Ooops... Ocorreu um erro ao sair do sistema.',
                        '',
                        'error'
                    )
                }) )
            }
        })
    }

    return <>
        <li onClick={() => logoutSistema()}>
            <FontAwesomeIcon
                icon={faPowerOff}
                color="#f44336"
                data-tip="Sair"
            />
        </li>
    </>

}
