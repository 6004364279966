export const DateHelper = {

    validarData: (date: string) => {
        return new Date(date).toString() !== 'Invalid Date' && date;
    },

    validarHora: (hora: string) => {
        return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(hora) && hora;
    }

};