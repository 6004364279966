import React from "react";
import {
  faFacebookSquare,
  faGoogle,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAlignCenter,
  faAllergies,
  faBullhorn,
  faCalendarMinus,
  faComments,
  faGlobeAmericas,
  faHouseUser,
  faLaptopMedical,
  faMicrophone,
  faMobileAlt,
  faPhoneSquareAlt,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MidiaType } from "../../pages/DetalheOportunidade/Cadastro/types";

interface MidiaSocialIconeProps {
  midia: MidiaType
}

export default function MidiaSocialIcone({ midia }: MidiaSocialIconeProps) {
  const MediaIcons = {
    faGoogle: faGoogle,
    faFacebookSquare: faFacebookSquare,
    faInstagramSquare: faInstagramSquare,
    faTwitterSquare: faTwitterSquare,
    faLinkedin: faLinkedin,
    faHouseUser: faHouseUser,
    faPhoneSquareAlt: faPhoneSquareAlt,
    faLaptopMedical: faLaptopMedical,
    faGlobeAmericas: faGlobeAmericas,
    faTv: faTv,
    faMicrophone: faMicrophone,
    faBullhorn: faBullhorn,
    faComments: faComments,
    faMobileAlt: faMobileAlt,
    faAllergies: faAllergies,
    faCalendarMinus: faCalendarMinus,
    faAlignCenter: faAlignCenter,
    default: faBullhorn
  } as any;

  const icon = MediaIcons[midia.icone || 'default']
  const color = midia.cor;
  const description = midia.descricao;

  return (
    <>
      <OverlayTrigger
        key="tooltipDescription"
        placement="top"
        overlay={
          <Tooltip id='tooltipDescription'>
            {description}
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          icon={icon}
          color={color || undefined}
        />
      </OverlayTrigger>
    </>
  );
}
