import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import OportunidadeService from "../../../../services/OportunidadeService";
import campanhaService from "../../../../services/CampanhaService";
import empreendimentoService from "../../../../services/EmpreendimentoService";
import LeadService from "../../../../services/LeadService";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import DommusOptionalSpan from "../../../../components/DommusOptionalSpan";
import DommusToggle from "../../../../components/DommusToggle";
import { trackPromise } from "react-promise-tracker";
import Alert from "react-bootstrap/Alert";
import stringHelper from "../../../../helpers/StringHelper";
import { PhoneHelper } from "../../../../helpers/PhoneHelper";
import Swal from "sweetalert2";
import guService from "../../../../services/GuService";
import { celularMask } from "../../../../components/InputTextMask";
import { CanaisContext } from "../../../../contexts/CanaisContext";
import { FiltroGlobalContext } from "../../../../contexts/FiltroGlobalContext";
import { OportunidadeContext } from "../../../../components/GlobalContext/globalContext";

export default function EditarLead({
  atualizarLista,
  closeModal,
  dados,
  oportunidade,
  refresh,
}) {
  const [nome, setNome] = useState(dados.nome);
  const [empreendimento, setEmpreendimento] = useState(dados.id_empreendimento);
  const [celular, setCelular] = useState(dados.telefone);
  const [email, setEmail] = useState(dados.email);
  const [campanha, setCampanha] = useState(dados.id_campanha);
  const [countCampanha, setCountCampanha] = useState("");
  const [countMidias, setCountMidias] = useState("");
  const [midia, setMidia] = useState(dados.id_midia);
  const [origem, setOrigem] = useState(dados.id_origem ? dados.id_origem : 1);
  const [travaCampo, setTravaCampo] = useState(false);
  const [campoTravado, setCampoTravado] = useState("");
  const [idProcesso, setIdProcesso] = useState("");
  const [idLead, setIdLead] = useState("");
  const [idOportunidade, setIdOportunidade] = useState("");
  const tipoUsuario = guService.getLocalState("tipoUsuario");
  const tipoPerfil = guService.getLocalState("tipoPerfil");
  const [listaMidias, setMidias] = useState([]);
  const [listaOrigens, setOrigens] = useState([]);
  const [listaCampanhas, setCampanhas] = useState([]);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [idCanal, setIdCanal] = useState(dados.id_canal)
  const { buscarCanais, canais } = useContext(CanaisContext)
  const { configExibirCanalOPV } = useContext(FiltroGlobalContext)
  const { atualizarOportunidade } = useContext(OportunidadeContext);

  useEffect(() => {
    buscarCanais({ oculto: 0 })
    trackPromise(
      Promise.all([
        campanhaService.buscarCampanhas(),
        OportunidadeService.buscarMidias(),
        OportunidadeService.buscarOrigens(),
        empreendimentoService.buscarEmpreendimentos(),
      ])
        .then((res) => {
          const local_campanhas = res[0].data;
          setCampanhas(local_campanhas);
          const local_midias = res[1].data;
          setMidias(local_midias);
          const local_origens = res[2].data;
          setOrigens(local_origens);
          const lista_emprendimento = res[3].data;
          setEmpreendimentos(lista_emprendimento);
        })
        .catch((error) => {}
          // swal(
          //   "Ooops...",
          //   "Ocorreu um erro ao buscar as dados: " + error,
          //   "error"
          // )
        )
    );
  }, []);

  function montaOptionsOrigem() {
    let optionsOrigem;
    if (tipoPerfil == "R") {
      optionsOrigem = listaOrigens.map((origem) =>
        origem.tipo == "R" ? (
          <option
            key={origem.id_origem}
            selected={buscarValueOrigem() == origem.id_origem}
            value={origem.id_origem}
          >
            {origem.origem}
          </option>
        ) : (
          <></>
        )
      );
    } else {
      optionsOrigem = listaOrigens.map((origem) => (
        <option
          key={origem.id_origem}
          selected={buscarValueOrigem() == origem.id_origem}
          value={origem.id_origem}
        >
          {origem.origem}
        </option>
      ));
    }
    return optionsOrigem;
  }

  function buscarValueOrigem() {
    let id_origem = 0;
    if (origem == 1 && id_origem == 0) {
      if (tipoUsuario == 8 || tipoUsuario == 7) {
        id_origem = buscarIdOrigem("C");
        if (campanha == "") {
          setCampanha(buscarCampanhaOrigem(id_origem));
        }
        if (midia == "") {
          setMidia(buscarMidiaOrigem(id_origem));
        }
      } else if (tipoUsuario == 26 || tipoUsuario == 25) {
        if (tipoPerfil == "R") {
          id_origem = buscarIdOrigem("R");
        } else {
          id_origem = listaOrigens.id_origem;
        }
      } else {
        id_origem = listaOrigens.id_origem;
      }
    } else {
      id_origem = origem;
    }
    return id_origem;
  }

  function buscarIdOrigem(tipo) {
    let index = listaOrigens.findIndex(function (object) {
      return object.tipo === tipo;
    });
    return listaOrigens[index].id_origem;
  }
  function buscarMidiaOrigem(id) {
    let index = listaOrigens.findIndex(function (object) {
      return object.id_origem === id;
    });
    if (listaOrigens[index].midias != "null") {
      listaMidias = JSON.parse(listaOrigens[index].midias);

      setCountMidias(listaMidias ? listaMidias.length : 1);
    }

    return listaMidias != null && listaMidias.length > 0 ? listaMidias[0] : 1;
  }

  function buscarCampanhaOrigem(id) {
    let index = listaOrigens.findIndex(function (object) {
      return object.id_origem === id;
    });
    if (listaOrigens[index].campanhas != "null") {
      listaCampanhas = JSON.parse(listaOrigens[index].campanhas);
      setCountCampanha(listaCampanhas ? listaCampanhas.length : 0);
    }

    return listaCampanhas != null && listaCampanhas.length > 0
      ? listaCampanhas
      : "";
  }

  function validaEmail(campo) {
    if (!stringHelper.IsEmail(email) && email.length > 0) {
      Swal.fire({
        titleText: "E-mail inválido",
        text: "Preencha um e-mail válido prosseguir",
        icon: "error",
      });
      return;
    } else {
      buscaDados(campo);
    }
  }

  function buscaDados(campo) {
    if (celular != "" || email != "") {
      const envolvidos = {
        telefone:
          celular != "" && celular != null
            ? celular
                .split(" ")
                .join("")
                .split("-")
                .join("")
                .replace(/[()]/g, "")
                .slice(-11)
            : "",
        email: stringHelper.IsEmail(email) ? email : "",
      };
        LeadService.buscarEnvolvidos(envolvidos, campo).then((response) => {
          if (response.data.status) {
            if (
              (campo == "CELULAR" && celular != "") ||
              (campo == "E-MAIL" && email != "")
            ) {
              setCampoTravado(campo);
            }
            if(response.data.id_oportunidade !== oportunidade && response.data.id_lead !== dados.id_lead){
              setTravaCampo(true);
              setIdProcesso(response.data.id);
              setIdOportunidade(response.data.id_oportunidade);
              setIdLead(response.data.id_lead);
            }
          } else {
            setCampoTravado("");
            setTravaCampo(false);
          }
        })
    } else {
      setCampoTravado("");
      setTravaCampo(false);
    }
  }

  function prepararTelefoneSalvar(telefone) {
    let telefoneCadastro = telefone != ""
        ? telefone
          .split(" ")
          .join("")
          .split("-")
          .join("")
          .replace(/[()]/g, "")
      : "";

  
    telefoneCadastro = telefoneCadastro.replace("+55", "");

    let primeiroDigito = telefoneCadastro[0]
    if ( ![2,3,4,5].includes(Number(primeiroDigito)) && telefoneCadastro.length == 8) {
        telefoneCadastro = "9" + telefoneCadastro;
    }

    if ( PhoneHelper.verificarDDIBrasileiro(telefoneCadastro) ) {
      telefoneCadastro = "+55" + telefoneCadastro;
    }
    return telefoneCadastro;
  }

  function salvarOportunidade(id) {
    if (!nome || !email || !celular) {
      if ([7, 8, 9, 10, 26].includes(Number(tipoUsuario))) {
        setOrigem(1);
      }
      if (!email && !celular) {
        Swal.fire({
          titleText: "Campos inválidos",
          text: "Preencha o e-mail ou o celular para prosseguir",
          icon: "warning",
        });
        return;
      } else {
        if (email && !stringHelper.isEmail(email)) {
          Swal.fire({
            titleText: "E-mail inválido",
            text: "Preencha um e-mail válido para prosseguir",
            icon: "warning",
          });
          setEmail("");
          return;
        }
      }
    }

    if (
      !campanha ||
      !midia
    ) {
      Swal.fire({
        titleText: "Atenção",
        text: "Preencha todos os campos obrigatórios",
        icon: "warning",
      });
      return;
    }

    const dados = {
      nome: nome,
      email: email,
      telefone: prepararTelefoneSalvar(celular),
      midia: {
        id_midia: midia,
      },
      campanha: {
        id_campanha: campanha,
      },
      id_origem: origem,
      id_midia: midia,
      id_campanha: campanha,
      id_lead: id,
      id_empreendimento: empreendimento,
      id_canal: idCanal || null
    };
    oportunidade = [];
    trackPromise(
      OportunidadeService.salvarLead(dados, oportunidade).then(
        (response) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Lead editado com sucesso!",
            icon: "success",
          });
          atualizarOportunidade(response?.data?.lead_oportunidades[0]?.oportunidade?.id_oportunidade);
          closeModal();
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao editar o lead: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  function montarOptionsMidia(){

    let checkItem = listaMidias.find((item)=>item.id_midia === dados.midia.id_midia);    
    if(!checkItem){
      setMidias((current)=>{return [...current, dados.midia]})
    }

    return listaMidias.sort((a,b)=> {return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0}).map((midia) => (
      <option key={midia.id_midia} value={midia.id_midia}>
        {midia.descricao}
      </option>
    ))
  }

  function defineMaskbyPhone(phone){
      if(phone){
        return phone[0] === '+' && phone[1] !== '5' && phone[2] !== '5' 
        ? phone
        : celularMask(phone);   
      }
  }

  return (
    <Form className="formCadastro">
      <DommusToggle title="Dados do Lead" toggle={true}>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Campanha: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control
              disabled={countCampanha === 1}
              as="select"
              custom
              value={campanha}
              onChange={(event) => setCampanha(event.target.value)}
            >
              <option>Selecione a campanha</option>
              {listaCampanhas.map((campanha) => (
                <option key={campanha.id_campanha} value={campanha.id_campanha}>
                  {campanha.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Mídia: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control
              disabled={countMidias === 1}
              as="select"
              value={midia}
              custom
              onChange={(event) => setMidia(event.target.value)}
            >
              <option>Selecione a mídia</option>
              {montarOptionsMidia()}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Origem: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control
              as="select"
              custom
              value={origem}
              onChange={(event) => setOrigem(event.target.value)}
            >
              {montaOptionsOrigem()}
            </Form.Control>
          </Form.Group>
          {configExibirCanalOPV && (
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Meio de Entrada do Lead: <DommusRequiredSpan /></Form.Label>
              <Form.Control as="select" value={idCanal} onChange={(event) => setIdCanal(event.target.value)}>
                <option value="" required>Selecione uma Opção</option>
                {canais.map(canal => <option value={canal.id_canal}>{canal.nome}</option>)}
              </Form.Control>
            </Form.Group>
          )}
        </Row>

        <Row>
          <Form.Group as={Col} md="8" controlId="formNome">
            <Form.Label>
              Nome: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome"
              onChange={(event) => setNome(event.target.value)}
              value={nome}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Empreendimento:</Form.Label>
            <Form.Control
              as="select"
              value={empreendimento}
              custom
              onChange={(event) => setEmpreendimento(event.target.value)}
            >
              <option>Selecione o empreendimento</option>
              {empreendimentos.filter((item) => {return item.ativo === '1' || parseInt(item.id) === empreendimento }).map((empreendimento) => (              
                <option key={empreendimento.id} value={empreendimento.id}>
                  {empreendimento.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="8" controlId="formNome">
            <Form.Label>
              E-mail: <DommusOptionalSpan />
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Digite o e-mail"
              onChange={(event) => setEmail(event.target.value)}
              onBlur={() => validaEmail("E-MAIL")}
              value={email}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Celular: <DommusOptionalSpan />
            </Form.Label>         
            <Form.Control
              className="form-control"              
              value={defineMaskbyPhone(celular)}
              placeholder="Digite o celular"
              maxLength={25}
              onBlur={() => buscaDados("CELULAR")}
              onChange={(e) => {setCelular(defineMaskbyPhone(e.target.value))}}
            />
          </Form.Group>
        </Row>
        {!celular && !email ? (
          <Alert variant="warning">
            Deve ser informado ao menos o endereço de e-mail ou o número do
            celular para cadastrar o lead.
          </Alert>
        ) : (
          <></>
        )}

        {travaCampo ? (
            dados.email &&
              <Alert variant="warning">
                Já existe{" "}
                {idProcesso != ""
                  ? "um processo cadastrado"
                  : idOportunidade != ""
                  ? "uma oportunidade cadastrada"
                  : "um lead cadastrado"}{" "}
                utilizando o <strong>{campoTravado}</strong> digitado.
              </Alert>
        ) : (
          <></>
        )}
      </DommusToggle>
      <Row className="formFooter">
        <Col>
          <button
            type="button"
            onClick={() => salvarOportunidade(dados.id_lead)}
            className="btn btn-success"
          >
            <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
            Salvar
          </button>
        </Col>
      </Row>
    </Form>
  );
}
