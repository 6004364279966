import React, { createContext, ReactElement, useState } from "react";
import { CanaisService } from "../../services/CanaisService";
import { toastErro } from "../../helpers/DommusSwal";
import { CanalType } from "./types";

interface CanaisContextProps {
  canais: CanalType[]
  buscarCanais: (filtros?: any) => void
}

export const CanaisContext = createContext({} as CanaisContextProps);

export function CanaisContextProvider({ children }: { children: ReactElement }) {
  const [canais, setCanais] = useState([])

  function buscarCanais(filtros=null) {
    if(canais.length) {
      return
    }
    CanaisService.buscarCanais(filtros)
      .then(response => setCanais(response.data))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        toastErro('Houve um erro ao tentar buscar os canais.')
      })
  }

  return (
    <CanaisContext.Provider value={{ canais, buscarCanais }}>
      {children}
    </CanaisContext.Provider>
  );
}