import React, { useState, useEffect, useContext } from "react";
import "../../DetalheOportunidade/Anexos/index.css";
import Alert from "react-bootstrap/Alert";
import DommusToggle from "../../../components/DommusToggle";
import {
  faPlus,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDommus from "../../../components/Modal";
import NovoAnexo from "../Anexos/NovoAnexo";
import { parseISO, format } from "date-fns";
import { Row, Col, Container } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import pdf from "../../../assets/documento-pdf.svg";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../components/DommusToast";
import OportunidadeService from "../../../services/OportunidadeService";
// import DocumentoService from "../../../services/DocumentoService";
import urlHelper from "../../../helpers/UrlHelper";
import { TiposDocumentosContext } from "../../../contexts/TiposDocumentosContext/TiposDocumentosContext";
import { OportunidadeContext } from "../../../components/GlobalContext/globalContext";

export default function Anexos({ history, dados, oportunidade }) {
  const [openNovoAnexo, setOpenNovoAnexo] = useState(false);
  const [anexos, setAnexos] = useState(dados);
  const {tiposDocumentos} = useContext(TiposDocumentosContext);
  const {atualizarOportunidade} = useContext(OportunidadeContext);

  useEffect(()=>{
    if(anexos !== dados){
      setAnexos(dados);
    }
  },[dados])

  function inserirLista(anexoInserir) {
    const listaAnexos = anexos.slice();
    listaAnexos.unshift(anexoInserir);
    setAnexos(listaAnexos);
    setOpenNovoAnexo(false);
  }

  function deleteAnexo(id) {
    Swal.fire({
      titleText: "Deseja excluir o arquivo ?",
      text: "Tem certeza que deseja excluir o arquivo? Os dados desta não serão mais visíveis no sistema.",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não, mantenha.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        trackPromise(
          OportunidadeService.excluirAnexo(id).then(
            (response) => {
              var index = anexos.findIndex(
                (x) => x.id_oportunidade_anexo === id
              );
              const listaAnexos = anexos.slice();
              listaAnexos.splice(index, 1);
              setAnexos(listaAnexos);
              atualizarOportunidade(oportunidade.id_oportunidade)
            },
            (error) => {
              errorToast.fire({
                text: "Ocorreu um erro ao excluir o atendimento: " + error,
              });
            }
          )
        );
      }
    });
  }

  return (
    <>
      <ReactTooltip />

      {anexos.length > 0 ? (
        <DommusToggle title="Anexos" toggle={true}>
          {anexos.map((item) => {
            const documento = tiposDocumentos.find(
              (documento) =>
                parseInt(documento.id) === parseInt(item.id_documento)
            );
            const nome =
              (documento && documento.descricao) ||
              ((item.tipo || false) && item.tipo.nome);
            return (
              <Row key={item.id_oportunidade_anexo}>
                <Col>
                  <div className="av-item select-file">
                    <div className="documentacao-item-wrapper">
                      <div className="form-group">
                        <div className="topoArquivo">
                          <h6>{nome}:</h6>
                          {oportunidade?.usuario_pode_editar && 
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className={"ellipsis-toggle"}
                                style={{padding: "0px 8px"}}
                              >
                                  <FontAwesomeIcon
                                    icon={faEllipsisV}
                                    color="#444444"
                                  />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    deleteAnexo(item.id_oportunidade_anexo)
                                  }
                                >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color="#444444"
                                    />{" "}
                                    Remover
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          }
                        </div>
                        <div className="upload-wrapper">
                          <div id="documento-0" className="documento-wrapper">
                            <div className="ajax-file-upload-container">
                              <div id="status-0" className="status">
                                <img src={pdf} border="0" />
                              </div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (item.link.match(/https/gi)) {
                                    urlHelper.download(item.link);
                                  } else {
                                    var newstr = item.link.replace(
                                      /http/gi,
                                      "https"
                                    );
                                    urlHelper.download(newstr);
                                  }
                                }}
                                target="_blank"
                                data-tip="Clique para visualizar!"
                                style={{ cursor: "pointer" }}
                              >
                                {nome}
                                <span>
                                  {format(
                                    parseISO(item.atualizado_em),
                                    "dd/MM/yyyy' às ' HH:mm:ss"
                                  )}
                                  <br />
                                  {item.envolvido &&
                                    `Envolvido: ${item.envolvido.nome}`}
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </DommusToggle>
      ) : (
        <Container fluid>
          <Alert variant="warning">Não existem anexos carregados.</Alert>
        </Container>
      )}
      {oportunidade?.usuario_pode_editar && 
        <div
          className="buttonNovaOportunidade"
          onClick={() => setOpenNovoAnexo(true)}
        >
          <FontAwesomeIcon icon={faPlus} color="#fff" />
        </div>
      }
      <ModalDommus
        open={openNovoAnexo}
        close={setOpenNovoAnexo.bind(false)}
        content={
          <NovoAnexo
            oportunidade={oportunidade}
            inserirLista={inserirLista}
            anexos={anexos}
            refresh={()=>{}}
          />
        }
        titulo="Novo Anexo"
      />
    </>
  );
}
