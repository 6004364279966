import React from "react";
import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { toastSucesso } from "../../helpers/DommusSwal";
import Swal from "sweetalert2";
import oportunidadeService from "../../services/OportunidadeService";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

interface FormBaseDisparoMassaProps {
  filtros: any;
  closeModal: () => void;
}

type FormSubmitType = {
  nome: string,
  filtros?: any
}

export function FormBaseDisparoMassa({ filtros, closeModal }: FormBaseDisparoMassaProps) {
  const { register, handleSubmit, errors } = useForm<FormSubmitType>()
  

  const handleOnSubmit = (data: FormSubmitType) => {
    data.filtros = filtros;
    trackPromise(oportunidadeService.criarBaseDisparo(data)).then((response) => {
      closeModal();
      toastSucesso("Base de disparo criado com sucesso!!");
    }).catch((erro) => {   
      if(erro?.response?.data?.message){
        Swal.fire(
          `Ooops... ${erro?.response?.data?.message}`,
          '',
          'error'
        )        
      }else{
        Swal.fire(
          `Ooops... Ocorreu um erro ao criar base de disparo:` + erro,
          '',
          'error'
        )
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Container fluid >
        <Row className="form-first-row">
          <Form.Group as={Col} lg="12" controlId="descricao">
            <Form.Label>Nome: </Form.Label>
            <Form.Control
              type="text"
              ref={register()}
              name={'nome'}
              required={true}
            />
            {errors.nome && (
                <small className="form-text text-danger">
                Campo Obrigatório*
                </small>
            )}
          </Form.Group>
        </Row>
        <Row>
          <Col className="text-right">
            <Button variant="success" type="submit"><FontAwesomeIcon icon={faSave} /> Salvar</Button>
          </Col>
        </Row>
      </Container>
    </form>
  )
}