import { useState, useEffect } from 'react';
import oportunidadeService from '../../../services/OportunidadeService';

const usePesquisaGeral = (pageNum = 1, pesquisa, setCampoPesquisa) => {
    const [results, setResults] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState({})
    const [hasNextPage, setHasNextPage] = useState(false)
    const [liberado, setLiberado] = useState(true)
    const [pesquisaState, setPesquisaState] = useState(pesquisa)
    const [idSetTimeout, setIdSetTimeout] = useState(null)
    
    const handlePesquisa = (pesquisa) => {
        const controller = new AbortController()
        const { signal } = controller

        oportunidadeService.buscaGeralOportunidades(pageNum, { signal }, {pesquisa: pesquisa} ).then(response=> {
            let data = response.data.lista;
            if(response.config.url.slice(-1) == 1){
                setResults(data)
            }else{
                setResults(prev => [...prev, ...data])
            }
            setHasNextPage(Boolean(data.length))
            setIsLoading(false)
            setLiberado(true)
        })
        .catch(e => {
            setIsLoading(false)
            if (signal.aborted) return
            setIsError(true)
            setError({ message: e.message })
        })

        return () => controller.abort();
    }

    useEffect(() => {
        setIsLoading(true)
        setIsError(false)
        setError({})
        clearTimeout(idSetTimeout);
        if(pesquisa?.length > 1 && liberado){
            setIdSetTimeout(
                setTimeout(() => {
                    handlePesquisa(pesquisa);
                }, 200)
            );   
        }            
    }, [pageNum, pesquisa ]);
    return { isLoading, isError, error, results, hasNextPage }
}

export default usePesquisaGeral