import React, { useState, useEffect, useContext } from 'react';
import { faClock, faPlus, faEllipsisV, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../DetalheOportunidade/Atendimento/index.css';
import Swal from 'sweetalert2';
import Alert from 'react-bootstrap/Alert';
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../components/DommusToast";
import { Row, Container } from 'react-bootstrap';
import ModalDommus from '../../../components/Modal';
import NovaPendencia from '../Pendencias/NovaPendencia';
import formatDommus from '../../../helpers/format';
import { parseISO, format } from 'date-fns';
import OportunidadeService from '../../../services/OportunidadeService';
import { Dropdown } from 'react-bootstrap';
import DommusToggle from "../../../components/DommusToggle";
import { OportunidadeContext } from '../../../components/GlobalContext/globalContext';
export default function Pendencia({ history, oportunidade, dados }) {
    const [openNovaPendencia, setOpenNovaPendencia] = useState(false);
    const [pendencias, setPendencias] = useState(dados);
    const [pendenciaSelecionada, setPendenciaSelecionada]  = useState(null);
    const {atualizarOportunidade} = useContext(OportunidadeContext);
    
    useEffect(()=>{
        if(pendencias !== dados){
            setPendencias(dados);
        }
    },[dados])
    
    function inserirLista(atendimento) {
        const listaPendencias = pendencias.slice();

        const atendimentoSalvo = listaPendencias.find((pendencia)=> Number(pendencia.id_oportunidade_pendencia) === Number(atendimento.id_oportunidade_pendencia))
        if(atendimentoSalvo){
            setPendencias((current)=>{
                return current.map((item)=>{
                    if(item.id_oportunidade_pendencia === atendimento.id_oportunidade_pendencia){
                        item = atendimento
                    }
                    return item;
                })
            })
        }else{
            listaPendencias.unshift(atendimento);
            setPendencias(listaPendencias);
        }
    }
    function deletePendencia(id) {
        Swal.fire({
            titleText: "Deseja excluir a pendencia " + '#' + formatDommus.zeroPad(id, 4) + "?",
            text: "Tem certeza que deseja excluir a pendencia " + '#' + formatDommus.zeroPad(id, 4) + "? Os dados desta não serão mais visíveis no sistema.",
            icon: 'question',
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, mantenha.',
            customClass: {
                confirmButton: 'btn btn-primary mr-3',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        }).then(
            (result) => {
                if (result.value) {
                    trackPromise(
                        OportunidadeService.excluirPendencia(id).then(
                            (response) => {
                                const listaPendencias = pendencias.slice();
                                var index = pendencias.findIndex(x => x.id_oportunidade_pendencia === id);
                                listaPendencias.splice(index, 1);
                                setPendencias(listaPendencias);
                                atualizarOportunidade(oportunidade.id_oportunidade);
                            },
                            (error) => {
                                errorToast.fire({ text: "Ocorreu um erro ao excluir a pendencia: " + error });
                            }
                        )
                    )
                }
            }
        );
    }
    function editPendencia(pendencia) {
        setPendenciaSelecionada(pendencia);
        setOpenNovaPendencia(true);
    }

    return <Container fluid>
        {pendencias.length > 0 ? pendencias.map((item, index) =>
            <Row key={index}>
                <div className="card" onClick={(e) => { }}>
                    <DommusToggle title={'#' + formatDommus.zeroPad(item.id_oportunidade_pendencia, 4) + ' - ' + 
                        item.pendencia_tipo.nome + 
                        (item.prazo && Date.parse(item.prazo) ? `(${format(parseISO(item.prazo), " dd/MM/yyyy 'às' HH:mm")})` : "")} toggle={true}>
                        <div className="cardTopo">
                            <div className="conteudoItem">
                                <div className="linha">
                                    <div className="texto">
                                        <strong>Descrição: </strong>{item.observacao}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardBaixo">
                            {
                            item.atualizado_em &&
                            <div className="data">
                                <FontAwesomeIcon icon={faClock} color="#444444" />
                                {formatDommus.formatDate(item.atualizado_em)}
                            </div>
                            }                            
                            {oportunidade?.usuario_pode_editar && 
                                <div className="icones">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className={"ellipsis-toggle"}>
                                            <FontAwesomeIcon icon={faEllipsisV} color="#444444" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => editPendencia(item)}><FontAwesomeIcon icon={faEdit} color="#444444" /> Editar</Dropdown.Item>
                                            <Dropdown.Item onClick={() => deletePendencia(item.id_oportunidade_pendencia)}><FontAwesomeIcon icon={faTrashAlt} color="#444444" /> Remover</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                        </div>
                    </DommusToggle>
                </div>
            </Row>
        ) : 
                <Alert variant="warning" >Não existem pendências cadastradas.</Alert>
           }

        <ModalDommus 
            open={openNovaPendencia} 
            close={()=>{
                setOpenNovaPendencia(false)
                setPendenciaSelecionada(null)
            }} 
            content={
            <NovaPendencia oportunidade={oportunidade.id_oportunidade} 
                            inserirLista={inserirLista.bind(this)}  
                            pendenciaSelecionada={pendenciaSelecionada}
                            refresh={()=>{
                                setOpenNovaPendencia(false)
                                atualizarOportunidade(oportunidade.id_oportunidade)
                                setPendenciaSelecionada(null)
                            }} 
            />} titulo={pendenciaSelecionada ? "Editar Pendência" : "Nova Pendencia"} />
        
        {oportunidade?.usuario_pode_editar && 
            <div className="buttonNovaOportunidade" onClick={() => setOpenNovaPendencia(true)}>
                <FontAwesomeIcon icon={faPlus} color="#fff" />
            </div>
        }
    </Container >
}
