import crm from './crm';

const LeadService = {
	buscarNaoDistribuidos:(filtros = null)=>{
		return crm.get('/lead/nao-distribuidos', {params: filtros});
	},
	recepcaoNaoDistribuidos:()=>{
		return crm.get('/lead/recepcao-nao-distribuidos');
	},
	buscarCountNaoDistribuidos:()=>{
		return crm.get('/lead/count-nao-distribuidos');
	},
	redistribuiLeads:()=>{
		return crm.get('/lead/recepcao-nao-distribuidos');
	},
	redistribuiLeadsEmMassa:(dados, oportunidade, alterarEquipeEmpreendimentoDiferente=false)=>{
		let uri = '/lead/redistribuir-em-massa';
		return crm['post'].call(crm, uri, {leads: dados, oportunidade, alterarEquipeEmpreendimentoDiferente: alterarEquipeEmpreendimentoDiferente});
	},
	buscarEnvolvidos: (dados, campo,flag) => {
        let uri = '/lead/buscar-envolvido';
        return crm['post'].call(crm, uri, {dados: dados, campo,flag});
    },
	alternarDistribuicao: () => {
        return crm.put('/lead/alternar-tipo-distribuicao');
    },
	buscaModoDistribuicao: () => {
        return crm.get('/lead/busca-modo-distribuicao');
    },
	validaEnvolvido: (dados, id_envolvido_ignorar) => {
		return crm.post('/lead/envolvido/valida-envolvido', {dados, id_envolvido_ignorar});
	},
	excluir: (idLead) => {
		return crm.delete('/lead/' + idLead);
	},
	historico: (idLead, tamanhoPagina, page) => {
		return crm.get(`lead/historico/${idLead}?limit=${tamanhoPagina}&page=${page}`);
	},

	
};

export default LeadService;
