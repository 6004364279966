import React, {useCallback, useEffect, useState} from 'react';
import { Button, Col, Form, Row} from "react-bootstrap";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import "./style.css";
import ofertaAtiva from "../../../../services/OfertaAtivaService";
import {trackPromise} from "react-promise-tracker";
import Swal from "sweetalert2";

export default function FormOferta({handleAddListaOferta, handleAtualizarListaOferta, closeModal, oferta, setAbaAtiva, ofertaAlteracao}) {

    const [titulo, setTitulo] = useState(oferta?.titulo);
    const [dataInicio, setDataInicio] = useState(oferta?.data_inicial);
    const [dataFim, setDataFim] = useState(oferta?.data_final);
    const [objetivo, setObjetivo] = useState(oferta?.objetivo);
    const [travarAcesso, setTravarAcesso] = useState(oferta ? oferta.acesso_fora_periodo : 1);
    const [travarResgate, setTravarResgate] = useState(oferta ? oferta.resgate_fora_periodo : 1);
    const [textoBtnSubmit, setTextoBtnSubmit] = useState('Cadastrar');

    useEffect(() => {
        if(oferta?.id_oferta_ativa) {
            setTextoBtnSubmit('Alterar');
            return;
        }
        setTextoBtnSubmit('Cadastrar');
    }, [oferta])

    const handleSubmitCadastro = useCallback(() => {

        if(dataInicio > dataFim) {
            Swal.fire({
                titleText: "Atenção",
                text: "A data final deve ser posterior a data inicial",
                icon: 'info'
            });
            return;
        }

        const oferta_data = {
            oferta: {
                titulo,
                data_inicial: dataInicio,
                data_final: dataFim,
                tipo_importacao: 'F',
                objetivo,
                filtro: {},
                acesso_fora_periodo: travarAcesso ?? 0,
                resgate_fora_periodo: travarResgate ?? 0
            }
        };

        if(oferta?.id_oferta_ativa) {
            handleAtualizarOferta(oferta_data, oferta.id_oferta_ativa);
            return;
        }
        handleCadastrarOferta(oferta_data);



    }, [
        titulo,
        dataInicio,
        dataFim,
        objetivo,
        travarAcesso,
        travarResgate,
        oferta
    ]);


    const handleCadastrarOferta = useCallback(oferta_data => {
        trackPromise(
            ofertaAtiva.store(oferta_data).then(response => {
                Swal.fire({
                    titleText: "Cadastrado!",
                    text: "Oferta ativa cadastrada com sucesso.",
                    icon: "success"
                }).then(() => {
                    closeModal();
                    handleAddListaOferta(response.data);
                })
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro!",
                    text: "Ocorreu um erro ao cadastrar a oferta ativa: " + e,
                    icon: "error"
                });
            })
        )
    }, []);

    const handleAtualizarOferta = useCallback((oferta_data, id_oferta_ativa) => {
        trackPromise(
            ofertaAtiva.update(oferta_data, id_oferta_ativa).then(response => {
                Swal.fire({
                    titleText: "Cadastrado!",
                    text: "Oferta ativa atualizada com sucesso.",
                    icon: "success"
                }).then(() => {
                    handleAtualizarListaOferta(response.data);
                    closeModal();
                })
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro!",
                    text: "Ocorreu um erro ao atualizar a oferta ativa: " + e,
                    icon: "error"
                });
            })
        )
    }, []);

    return (

        <Form className="formCadastro">

            <Row>
                <Form.Group as={Col} className="col-6" controlId="formNome">
                    <Form.Label>Titulo: <DommusRequiredSpan/></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o título"
                        value={titulo}
                        onChange={(event) => setTitulo(event.target.value)}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Data início: <DommusRequiredSpan/></Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Digite a data inicial"
                        value={dataInicio}
                        onChange={(event) => setDataInicio(event.target.value)}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Data fim: <DommusRequiredSpan/></Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Digite a data final"
                        value={dataFim}
                        onChange={(event) => setDataFim(event.target.value)}/>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <Form.Label>Objetivo:</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Digite o objetivo"
                        value={objetivo}
                        rows={3}
                        onChange={(event) => setObjetivo(event.target.value)}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <input
                        type="checkbox"
                        id="travarAcesso"
                        value={1}
                        checked={!!travarAcesso}
                        onChange={(event) => setTravarAcesso(!travarAcesso)}
                    />
                    <label htmlFor="travarAcesso">Travar acesso fora do período</label>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="formNome">
                    <input
                        type="checkbox"
                        id="travarResgate"
                        value={1}
                        checked={!!travarResgate}
                        onChange={(event) => setTravarResgate(!travarResgate)}
                    />
                    <label htmlFor="travarResgate">Travar resgate fora do período</label>
                </Form.Group>
            </Row>

            <Row>
                <Col>
                    <Button variant="primary" onClick={handleSubmitCadastro}>
                        {textoBtnSubmit}
                    </Button>
                </Col>
            </Row>
        </Form>

    );
}
