import React, {useEffect, useState} from 'react';
import './style.css'
import ReactTooltip from "react-tooltip";
import ItemFiltroGlobalLinha from "./ItemFiltroGlobalLinha";
import DommusTabelaPaginadaBack from '../../DommusTabelaPaginadaBack';

export default function ItemListaFiltroGlobal(props) {
    const [opvsSelecionadas, setopvsSelecionadas] = useState([]);
    const [todosSelecionados, setTodosSelecionados] = useState(false);

    
    const linhas =
        props.oportunidades.map(oportunidade => (
            <tr key={oportunidade.id_oportunidade}>
                <ItemFiltroGlobalLinha setarOpvsSelecionadas={setarOpvsSelecionadas.bind(this)} oportunidadesSelecionadas={opvsSelecionadas} oportunidade={oportunidade} />
            </tr>
        ));

    const thead = (
        <tr>
            <th style={{display: 'flex'}}>
                <input className="checkBoxTopo" type="checkbox" value={1}checked={todosSelecionados} onClick={() => handleCheckSelecionarTodos()}/>  
                <p style={{marginTop: '16px'}} onClick={() => handleCheckSelecionarTodos()}>Tabela de Oportunidades</p>
            </th>
        </tr>
    );

    const limit = props.limit;
    const page = props.page;
    const setLimit = props.setLimit;
    const setPage = props.setPage;
    const total = props.total;

    useEffect(() => {
        handleSelecionarTodos()
    }, [props.oportunidades, todosSelecionados])

    useEffect(() => {
        if(props.oportunidades.length != opvsSelecionadas.length) {
            setTodosSelecionados(false)
        }
    }, [opvsSelecionadas])

    function setarOpvsSelecionadas(selecionados){
        setopvsSelecionadas(selecionados)
        props.oportunidadesSelecionadas(selecionados);
    }
    
    function handleCheckSelecionarTodos(){
        setTodosSelecionados(todosSelecionados => !todosSelecionados);
    }

    function handleSelecionarTodos() {
        const selecionados = [];
        if (todosSelecionados) {
            props.oportunidades.map(opv => {
                selecionados.push(opv);
            })
            setTodosSelecionados(true);
            props.setTodosSelecionados(true)
            setopvsSelecionadas(selecionados);
            props.oportunidadesSelecionadas(selecionados);
        } else {
            setopvsSelecionadas([]);
            setTodosSelecionados(false);
            props.setTodosSelecionados(false)
            props.oportunidadesSelecionadas([]);
        }

    }

    return (
        <>
            <DommusTabelaPaginadaBack 
                setLimit={setLimit} 
                limit={limit}
                setPage={setPage}
                page={page} 
                linhasCabecalho={thead}
                linhasCorpo={linhas}
                total={total}
            />
            <ReactTooltip />
        </>
    );
}
