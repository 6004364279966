import React, { useState } from 'react'
import { faFileCirclePlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import DommusToggle from '../../../../components/DommusToggle';
import oportunidadeService from '../../../../services/OportunidadeService';
import ReactTooltip from 'react-tooltip';

export default function MudarParaOutraOPV(props) {
    const [novaOPV, setNovaOPV] = useState()
    
    function criaNovaOPV(){
        let oportunidade = {
            id_empreendimento: props?.oportunidade?.id_empreendimento,
            id_equipe_pdv: props?.oportunidade?.id_equipe_pdv,
            id_equipe_pre_atendimento: props?.oportunidade?.id_equipe_pre_atendimento,
            id_gerente_pdv: props?.oportunidade?.id_gerente_pdv,
            id_gerente_pre_atendimento: props?.oportunidade?.id_gerente_pre_atendimento,
            id_usuario_atendimento: props?.oportunidade?.id_usuario_atendimento,
            id_usuario_pre_atendimento: props?.oportunidade?.id_usuario_pre_atendimento,
            id_status_oportunidade: props?.oportunidade?.id_status_oportunidade || 4,
            resgate: props?.oportunidade?.resgate,
            ativo: props?.oportunidade?.ativo,
            criado_por: props?.oportunidade?.criado_por,
            ocultar_dados: props?.oportunidade?.ocultar_dados,
            id_substatus_oportunidade: props?.oportunidade?.id_substatus_oportunidade,
            data_venda: props?.oportunidade?.data_venda,
            data_contabilizacao: props?.oportunidade?.data_contabilizacao,
            valor_venda: props?.oportunidade?.valor_venda
        }
        let interesse = {
            caracteristicas: props?.oportunidade?.interesse?.caracteristicas,
            tipologia: props?.oportunidade?.interesse?.tipologia,
            empreendimento: props?.oportunidade?.interesse?.empreendimento,
            localizacao: props?.oportunidade?.interesse?.localizacao,
            informacoes: props?.oportunidade?.interesse?.informacoes,
            valor_disponivel: props?.oportunidade?.interesse?.valor_disponivel,
            pretensao_sinal: props?.oportunidade?.interesse?.pretensao_sinal,
            renda_familia: props?.oportunidade?.interesse?.renda_familia,
            id_oportunidade: props?.oportunidade?.interesse?.id_oportunidade,
            id_como_conheceu: props?.oportunidade?.interesse?.id_como_conheceu,
            id_nivel_interesse: props?.oportunidade?.interesse?.id_nivel_interesse,
            id_origem: props?.oportunidade?.interesse?.id_origem,
            saldo_fgts: props?.oportunidade?.interesse?.saldo_fgts,
            tempo_previsto_compra: props?.oportunidade?.interesse?.tempo_previsto_compra,
            possui_dependentes: props?.oportunidade?.interesse?.possui_dependentes,
            quem_indicou:  props?.oportunidade?.interesse?.quem_indicou,
            dados_dinamicos: props?.oportunidade?.interesse?.dados_dinamicos,
        }
        let lead_dados = {
            nome: props.dados.nome,
            email: props.dados.email
        }

        trackPromise(
            oportunidadeService.salvarNovoOPV( props.dados.id_lead, lead_dados, oportunidade, interesse).then(
              (response) => {
                Swal.fire("Sucesso!","Oportunidade criada com sucesso!");
                window.open(`/oportunidade/${response.data.id_oportunidade}`);
              },
              (error) => {
                Swal.fire("Ooops...", "Ocorreu um erro ao salvar a oportunidade: " + error.data, "error");
              }
            )
          )
    }
    function remanejarLead(){
      if(novaOPV){
        trackPromise(
            oportunidadeService.transferirLead(props.dados.id_lead, novaOPV).then(
              (response) => {
                Swal.fire("Sucesso!","Oportunidade transferida com sucesso!");
                window.open(`/oportunidade/${response.data.id_oportunidade}`);
              },
              (error) => {
                Swal.fire("Ooops...", "Ocorreu um erro ao salvar a oportunidade: " + error.data, "error");
              }
            )
          )
      }else{
        Swal.fire("Parece que não foi informada a nova OPV!");
      }
    }

    return (
        <Form className="formCadastro">
            <DommusToggle title={`OPV-${props.oportunidade.id_oportunidade}`} toggle={true}>
              <Row>
                <Form.Group as={Col} controlId="formNome">
                  <Row className="justify-content-md-center">
                    <Col md="0">
                      <Form.Label>
                        Para qual OPV deseja remanejar o lead {props.dados.nome}?
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon">OPV</InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder="00000"
                          aria-describedby="basic-addon"
                          onChange={(e) => { setNovaOPV(e.target.value) }} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </DommusToggle>
            <Row className="formFooter">
            <ReactTooltip />
                <Col>
                    <button
                        type="button"
                        onClick={() => remanejarLead()}
                        className="btn btn-success"
                        style={{ marginRight: "5px"}}
                        data-tip="Envia para a OPV informada"

                    >
                        <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
                        Transferir
                    </button>
                    <button
                        type="button"
                        onClick={() => criaNovaOPV()}
                        className="btn btn-success"
                        data-tip="Cria uma nova OPV"
                    >
                        <FontAwesomeIcon icon={faFileCirclePlus} style={{ marginRight: "15px"}} />
                        Criar Nova OPV
                    </button>
                </Col>
            </Row>
        </Form>
    );

}
