import React, { useCallback } from "react";
import "./index.css";
import { RespostaQuestionarioPublicotype } from "../../../../../../contexts/QuestionarioContext/types";
import { Col, Row } from "react-bootstrap";
import { TipoRespostaEnum } from "../../../../../../contexts/PerguntaContext/types";
import { celularMask, cepMask, cpfMask, formatarMoeda } from "../../../../../../components/InputTextMask";
import moment from "moment";

interface RespostaPublicaItemProps {
    resposta: RespostaQuestionarioPublicotype;
}

export function RespostaPublicaItem({ resposta }: RespostaPublicaItemProps) {

    const handleMontarResposta = useCallback(() => {
        if(!resposta?.resposta || resposta?.resposta == ""){
            return <span style={{color: "GrayText"}}> --- </span>;
        }else{
            switch (resposta.pergunta?.id_tipo_resposta_pergunta) {
                case TipoRespostaEnum.TEXTO:
                    return resposta?.resposta;
                case TipoRespostaEnum.CEP:
                    return cepMask(resposta?.resposta ?? "");
                case TipoRespostaEnum.DATA:
                    return moment(resposta?.resposta).format("DD/MM/YYYY");
                case TipoRespostaEnum.CPF:
                    return cpfMask(resposta?.resposta ?? "");
                case TipoRespostaEnum.TELEFONE:
                    return celularMask(resposta?.resposta ?? "");
                case TipoRespostaEnum.MOEDA:
                    return formatarMoeda(resposta?.resposta ?? "");
                case TipoRespostaEnum.CHECKBOX:
                    return resposta?.resposta.toString();
                case TipoRespostaEnum.SIMPLES_SELECAO:
                case TipoRespostaEnum.SIMPLES_SELECAO_CAMPOS_DINAMICOS:
                    return resposta?.resposta?.label;
                case TipoRespostaEnum.MULTIPLA_SELECAO:
                    case TipoRespostaEnum.MULTIPLA_SELECAO_CAMPOS_DINAMICOS:
                    return resposta?.resposta?.map((item:any) => item?.label ?? "").toString();
                default:
                    break;
            }
        }
    },[resposta]);

    return (
        <Row className="pergunta-card">
            <Col className="info resposta-item">
                <div className="enunciado-section">
                    <span>
                        {resposta?.pergunta?.enunciado}
                    </span>
                </div>
                <div className="resposta-section">
                    <label>Resposta: </label>
                    {handleMontarResposta()}
                </div>
            </Col>
        </Row>
    )
}