import crm from './crm';

const IconesService = {
	
	buscarIcones: (page, limit, busca = null) => {
        // return crm.get(`/icone`);
        if(busca?.length == 0){
            return crm.get(`/icone/page/${page}/limit/${limit}`);
        }else{
            return crm.get(`/icone/page/${page}/limit/${limit}/busca/${busca}`);
        }
    },
};

export default IconesService;