import crm from './crm';

const equipeService = {

    buscarEquipes: () => {
        return crm.get('/equipe');
    },
    buscarGerentes: () => {
        return crm.get('/equipe/gerentes');
    },
    buscarOperadores: () => {
        return crm.get('/equipe/operadores');
    }, 
    usuarioEmpreendimento: (idEmpreendimento) => {
        return crm.get('/equipe/usuarioEmpreendimento/'+idEmpreendimento);
    }, 
    buscarEquipesSemEmpreendimento: () => {
        return crm.get('/equipe/sem-empreendimento');
    },
	buscaMembrosEquipes: (empreendimento) => {
		return crm.get('equipe/empreendimento/'+empreendimento);
	},
    buscarOperadoresComFiltro: (filtro) => {
        return crm.get(`/equipe/operadores?search=${filtro}`)
    },
    buscarEquipesParaFiltro: () => {
        return crm.get('/equipe/visiveis');
    },
    buscarCoordenadoresPorEquipeEmpreendimento: (equipe, empreendimento) => {
        return crm.get(`/equipe/coordenadores/equipe/${equipe}/empreendimento/${empreendimento}`);
    },
    TIPO_EQUIPE_PRE_ATENDIMENTO: 'A',
    TIPO_EQUIPE_PDV: 'P'
};

export default equipeService;