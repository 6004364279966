import React, { createContext, ReactElement, useEffect, useState } from "react";
import { CamposDinamicosType } from "../../components/CamposDinamicos";
import CamposDinamicosService from "../../services/CamposDinamicosService";
import { TipologiaType } from "../../components/GlobalContext/types";
import { ComoConheceuType } from "../../pages/DetalheOportunidade/Cadastro/types";
import empreendimentoService from "../../services/EmpreendimentoService";
import unidadeService from "../../services/UnidadeService";
import oportunidadeService from "../../services/OportunidadeService";
import { swalErro } from "../../helpers/DommusSwal";
import { CamposTelaQualificacaoType } from "./types";

interface CamposDinamicosContextProps{
  listaCamposDinamicos: CamposDinamicosType[];
  listaLocalidades: String[];
  listaComoConheceu: ComoConheceuType[];
  listaTipologia: TipologiaType[];
  recarregarCamposDinamicos: () => void;
  recarregarLocalidades: () => void;
  recarregarTipologias: () => void;
  recarregarComoConheceu: () => void;
  resolverNomeCampoDinamico: (idCampo:number|string) => void;
  mapaCamposDinamicos: Map<number, CamposDinamicosType>;
  setListaCamposDinamicos: React.Dispatch<React.SetStateAction<CamposDinamicosType[]>>
  camposTelaQualificacao: CamposTelaQualificacaoType;
  setCamposTelaQualificacao: React.Dispatch<React.SetStateAction<CamposTelaQualificacaoType>>
}

export const CamposDinamicosContext = createContext({} as CamposDinamicosContextProps);

export function CamposDinamicosProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [listaCamposDinamicos, setListaCamposDinamicos] = useState<CamposDinamicosType[]>([]);
  const [mapaCamposDinamicos, setMapaCamposDinamicos] = useState<Map<number, CamposDinamicosType>>(new Map());
  const [listaLocalidades, setListaLocalidades] = useState<String[]>([])
  const [listaComoConheceu, setListaComoConheceu] = useState<ComoConheceuType[]>([]);
  const [listaTipologia, setListaTipologia] = useState<TipologiaType[]>([]);
  const [camposTelaQualificacao, setCamposTelaQualificacao] = useState<CamposTelaQualificacaoType>({ campos: [], total: 0 })

  const recarregarCamposDinamicos = () => {
    if(listaCamposDinamicos.length) {
      return 0
    }
    CamposDinamicosService.buscarCamposDinamicos()
      .then(response => setListaCamposDinamicos(response.data.campos))
      .catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao tentar carregar os campos dinamicos.')

        return false;
      })
    return 1
  }

   const recarregarLocalidades = () => {
    if(listaLocalidades.length) {
      return 0
    }
    empreendimentoService.buscarLocalidades()
      .then(response => {
        if(response.data && Array.isArray(response.data)){
          setListaLocalidades(response.data);
        }
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao tentar carregar as localidades.')

        return false;
      })
    return 1
  }

  const recarregarTipologias = () => {
    if(listaTipologia.length) {
      return 0
    }
    unidadeService.buscarTipologias()
      .then(response => setListaTipologia(response.data))
      .catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao tentar carregar as tipologias.')

        return false;
      })
    return 1
  }

  const recarregarComoConheceu = () => {
    if(listaComoConheceu.length) {
      return 0
    }
    oportunidadeService.buscarComoConheceu()
      .then(response => setListaComoConheceu(response.data))
      .catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao tentar carregar as opções de como conheceu.')

        return false;
      })
    return 1
  }

  useEffect(()=>{
    const novoMapa = new Map();
    listaCamposDinamicos.forEach((campo:CamposDinamicosType) => {
      novoMapa.set(Number(campo.id_campos_dinamicos), campo);
    });
    setMapaCamposDinamicos(novoMapa);
  }, [listaCamposDinamicos]);

  function resolverNomeCampoDinamico(idCampo:number|string, padrao = "Não Atribuído"){
    let campo = mapaCamposDinamicos.get(Number(idCampo));
    return (campo && campo.label) || padrao;
  };


  return (
    <CamposDinamicosContext.Provider value={{
        listaCamposDinamicos,
        listaLocalidades,
        listaTipologia,
        listaComoConheceu,
        recarregarCamposDinamicos,
        recarregarLocalidades,
        recarregarComoConheceu,
        recarregarTipologias,
        resolverNomeCampoDinamico,
        mapaCamposDinamicos,
        setListaCamposDinamicos,
        camposTelaQualificacao, 
        setCamposTelaQualificacao
    }}>
      {children}
    </CamposDinamicosContext.Provider>
  );
}
