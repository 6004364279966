import React, { useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from "sweetalert2";
import IconesService from "../../services/IconesService";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var iconesFa = []
var iconesFab = []

const ico = Object
.keys(Icons)
.filter(key => key !== "fas" && key !== "prefix" )
.map(icon => (
    iconesFa.push(icon),
    Icons[icon]
))  

const brands = Object
.keys(IconsBrands)
.filter(key => key !== "fab" && key !== "prefix" )
.map(icon => (
    iconesFab.push(icon),
    IconsBrands[icon]
))  

 library.add(ico, brands)

const SelectAsyncPaginate = (props) => {

  useEffect(() => {
    
  }, []);


  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    
    const responseJSON = await IconesService.buscarIcones(page, 10, searchQuery).then(response => {
        return response.data.lista
    }).catch(error => {
        Swal.fire(
            'Ooops... Ocorreu um erro ao carregar os icones.',
            '',
            'error'
        )
    }) 
    
    const listaOptiosIcones = 
      responseJSON.map((item, i) => ({
          label: (
              <>
                  <span style={{ paddingRight: "5px" }}>{item.chave}</span>
                  {      
                      iconesFa.map((icone, i) => (
                          icone === item.chave ?
                          <FontAwesomeIcon icon={Icons[`${item.chave}`]}
                          title="" /> : 
                          <></>
                      ))
                  }
                  {
                      iconesFab.map((icone, i) => (
                          icone === item.chave ?
                          <FontAwesomeIcon icon={IconsBrands[`${item.chave}`]}
                          title="" /> : 
                          <></>
                      ))
                  }
              </>   
          ),
          value: item.chave,
          id: item.id_icone
      }));
  
    return {
      options: listaOptiosIcones,
      hasMore: listaOptiosIcones.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1
      }
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      value={props.value || ""}
      loadOptions={loadOptions}
      onChange={onChange}
      isSearchable={true}
      placeholder="Selecione o ícone"
      additional={{
        page: 1
      }}
    />
  );
};

export default SelectAsyncPaginate;
