import crm from './crm';

const OrigemService = {
	buscarOrigens: () => {
        return crm.get('/origem');
    },
	editOrigem: (dados, id) => {
        let uri = '/origem/' + id;
        return crm['put'].call(crm, uri, {origem: dados});
    },
	storeOrigem: (dados) => {
        let uri = '/origem';
        return crm['post'].call(crm, uri, {origem: dados});
    },
    deleteOrigem: (id) =>{
        let uri = '/origem/' + id;
		return crm['delete'].call(crm, uri);
    }
};

export default OrigemService;
