import crm from "./crm";

const LocalAtendimentoService = {

    buscarDadosLocaisAtendimento: () => {
        return crm.get('/locais/lista');
    },
    buscarLocaisAtendimentoSemOcultos: () => {
        return crm.get('/locais/listaLocaisAtendimento');
    },
    storeLocalAtendimento: (data: any) => {
        let uri = '/locais';
        return crm['post'].call(crm, uri, data);
    },
    editLocalAtendimento: (data: any, id: number) => {
        let uri = '/locais/' + id;
        
        return crm.put(uri, data);
    }
};

export default LocalAtendimentoService;