import React from 'react'
import PhoneInput from "react-phone-number-input/input";

export function DommusPhoneInput({country="BR", international=false, value, placeholder="Digite o Telefone", onChange, onBlur=()=>{}, className="form-control"}) {
    return (
        <PhoneInput
            country={country}
            international={international}
            className={className}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}