import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";
import "./index.css"

export function AlertSemPemissaoPagina(){
    return(
        <Alert variant="warning" className="alert-component">
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            Seu usuário não tem permissão de visualização desta tela!
        </Alert>
    )
}