import crm from './crm';

const SubStatusService = {
	listaSubStatus:()=>{
		return crm.get('/substatus-oportunidade');
	},
	showSubStatus:(id)=>{
		return crm.get('/substatus-oportunidade/'+ id)
	},
	buscaSubstatusPorStatus: (id) => {
		return crm.get('/substatus-oportunidade/buscar-por-status/' + id)
	},
	recepcaoNaoDistribuidos:()=>{
		return crm.get('/lead/recepcao-nao-distribuidos');
	},
	editSubStatus: (dados, id) => {
        let uri = '/substatus-oportunidade/' + id;
        return crm['put'].call(crm, uri, dados //{status_oportunidade: dados}
        );
    },
	mudarPosicaoStatusOportunidade: (dados) => {
        let uri = '/status-oportunidade/mudar-posicao';
        return crm['put'].call(crm, uri, dados);
    },
	storeSubStatus: (dados) => {
        let uri = '/substatus-oportunidade';
        return crm['post'].call(crm, uri, dados);
    },
	excluirSubStatus: (id) => {
		return crm.delete('/substatus-oportunidade/' + id);
	}
};

export default SubStatusService;
