import { successToast } from "../components/DommusToast";

const stringHelper = {
	isBlank: (str) => {
		return (!str || /^\s*$/.test(str));
	},
	isEmpty: (str) => {
		return (!str || 0 === str.length);
	},
	isEmail: (email) => {
		var emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		return emailPattern.test(email);
	},
	isCpf: (strCPF) => {
		var Soma;
		var Resto;
		Soma = 0;
		if (strCPF == "00000000000") return false;

		for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;

		if ((Resto == 10) || (Resto == 11)) Resto = 0;
		if (Resto != parseInt(strCPF.substring(9, 10))) return false;

		Soma = 0;
		for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;

		if ((Resto == 10) || (Resto == 11)) Resto = 0;
		if (Resto != parseInt(strCPF.substring(10, 11))) return false;
		return true;
	},
	isJson: (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	},
	IsEmail: (email) => {
		var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
		if (reg.test(email)) {
			return true;
		}
		else {
			return false;
		}
	},

	toCapitalize: (text) => {


		if(typeof text != 'string' ){
			return '';
		}

		if(!text.trim()){
			return ''
		}


		text = text.toLowerCase();

		let arrayText = text.split(" ")

		if(!arrayText.length){
			return '';
		}


		for (let i = 0; i < arrayText.length; i++) {
			arrayText[i] = arrayText[i].charAt(0).toUpperCase() + arrayText[i].slice(1);
		  }

		let newText = arrayText.join(" ")
		return newText.trim()
	},


	copyBoard: (textToCopy)=>{

		let TempText = document.createElement("input");
		TempText.value = textToCopy;
		document.body.appendChild(TempText);
		TempText.select();

		document.execCommand("copy");
		document.body.removeChild(TempText);

		document.execCommand("copy");

		successToast.fire({
			text: "Item copiado para a área de transferência."
		})
	 },
	isURL: (string) => {
		let regex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;

		return regex.test(string);
	}


};
export default stringHelper;
