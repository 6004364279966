import React, { createContext, ReactElement, useEffect, useState } from "react";
import QuestionarioService from "../../services/QuestionarioService";
import { swalErro } from "../../helpers/DommusSwal";
import { PerguntaQuestionarioType, PermissoesQuestionarioOportunidadeType, QuestionarioOportunidadeType, QuestionarioPublicoType, QuestionarioType, RespostasQuestionarioType, UltimaAtualizacaoQuestionarioType } from "./types";
import { PerguntaType } from "../PerguntaContext/types";
import { format, parseISO } from "date-fns";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

interface QuestionarioContextProps {
  listaQuestionarios: QuestionarioType[];
  listaQuestionariosPublicos: QuestionarioOportunidadeType[];
  listaRespostas: RespostasQuestionarioType[];
  ultimasAtualizacoesQuestionario: any;
  permissoesQuestionarioOportunidade:  PermissoesQuestionarioOportunidadeType;
  getUltimaAtualizacaoQuestionario: (idQuestionario:number) => UltimaAtualizacaoQuestionarioType;
  recarregarQuestionarios: () => void;
  recarregarRespostas: (idOportunidade:number) => void;
  atualizarRespostas: (novasRespostas:RespostasQuestionarioType[]) => void;
  resolverResposta: (pergunta:PerguntaType, idQuestionario:number) => any;
  adicionarQuestionario: (data: QuestionarioType) => void;
  atualizarQuestionario: (data: QuestionarioType) => void;
  removerQuestionario: (idQuestionario: number) => void;
  atualizarPergunta: (perguntaAtualizada: PerguntaQuestionarioType) => void;
  adicionarPergunta: (idQuestionario:number, perguntas:PerguntaQuestionarioType[]) => void;
  removerPergunta: (perguntaRemover: PerguntaQuestionarioType) => void;
  buscarQuestionariosOportunidade: (idOportunidade:number) => void;
  adicionarQuestionarioPublico: (questionarioPublico:QuestionarioOportunidadeType) => void;
  adicionarAvalicaoOpiniao: (idQuestionario:number, questionarioPublico:QuestionarioPublicoType) => void;
  atualizarAvalicaoOpiniao: (idQuestionario:number, questionarioPublico:QuestionarioPublicoType) => void;
  buscarPermissoesQuestionarioOportunidade: () => void;
  removerAvaliacaoOpiniao: (idQuestionario:number, idQuestionarioPublico:number, expirado:boolean) => void;
}

export const QuestionarioContext = createContext({} as QuestionarioContextProps );

export function QuestionarioProvider({ children }: { children: ReactElement }) {
  const [listaQuestionarios, setListaQuestionarios] = useState<QuestionarioType[]>([]);  
  const [listaQuestionariosPublicos, setListaQuestionariosPublicos] = useState<QuestionarioOportunidadeType[]>([]);  
  const [listaRespostas, setListaRespostas] = useState<RespostasQuestionarioType[]>([]);
  const [ultimasAtualizacoesQuestionario, setUltimasAtualizacoesQuestionario] = useState<any>();
  const [permissoesQuestionarioOportunidade, setPermissoesQuestionarioOportunidade] = useState<PermissoesQuestionarioOportunidadeType>({} as PermissoesQuestionarioOportunidadeType);



  useEffect(()=>{
    let agrupadosQuestionario = listaRespostas.reduce((acc:any, resposta)=>{
      const key = resposta.id_questionario;
      if(!acc[key]){
        acc[key] = [];
      }
      acc[key].push(resposta);
      return acc;
    },{})

    setUltimasAtualizacoesQuestionario(agrupadosQuestionario);

  },[listaRespostas]);


  const getUltimaAtualizacaoQuestionario = (idQuestionario:number) =>  {    
    let perguntas:RespostasQuestionarioType[] = [];
    let retorno = {} as UltimaAtualizacaoQuestionarioType;
    if(Object.keys(ultimasAtualizacoesQuestionario).length){
      perguntas =  ultimasAtualizacoesQuestionario[idQuestionario] ?? null;
      if(perguntas && perguntas.length){
        retorno =  {
          data: perguntas[0]?.atualizado_em ? format(parseISO(perguntas[0]?.atualizado_em),"dd/MM/yyyy' às ' HH:mm:ss") : "",          
          usuario: perguntas[0].nome_atualizado_por ?? ""
        }
      }
    }
    return retorno;        
  }


  const recarregarQuestionarios = () => {
    if(listaQuestionarios.length) {
      return 0
    }
    QuestionarioService.buscar()
      .then(response => setListaQuestionarios(response.data.questionarios))
      .catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao carregar as questionarios');
      });
  };

  const recarregarRespostas = (idOportunidade:number) => {
    if(listaRespostas.length) {
      return 0
    }
    QuestionarioService.buscarRespostasPorOportunidade(idOportunidade)
      .then((response: any) => setListaRespostas(response.data))
      .catch((error)=>{
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao carregar as respostas');
      })
  }

  const atualizarRespostas = (novasResposta:RespostasQuestionarioType[]) => {
    setListaRespostas(novasResposta);
  }

  const resolverResposta = (pergunta:PerguntaType, idQuestionario:number) => {
    let resposta = listaRespostas.filter((resposta)=> (resposta.id_questionario === idQuestionario && resposta.id_pergunta === pergunta.id_pergunta));
    return resposta[0] || "";
  }

  const adicionarQuestionario = (data: QuestionarioType) => {
    setListaQuestionarios((current) => {
      return [data,...current];
    });
  };

  const atualizarQuestionario = (data: QuestionarioType) => {
    setListaQuestionarios((current) => {
      return current.map((questionario) => {
        if (questionario.id_questionario === data.id_questionario) {
          return data;
        }
        return questionario;
      });
    });
  };

  const removerQuestionario = (idQuestionario: number) => {
    setListaQuestionarios((current) => {
      return current.filter(
        (questionario) => questionario.id_questionario !== idQuestionario
      );
    });
  };

  const atualizarPergunta = (perguntaAtualizada:PerguntaQuestionarioType) => {
    setListaQuestionarios((current) => {
      return current.map((questionario)=>{
        if(questionario.id_questionario === perguntaAtualizada.id_questionario){
          let temp =  questionario.perguntas?.map((pergunta)=>{
            if(pergunta.id_pergunta === perguntaAtualizada.id_pergunta){
              return perguntaAtualizada;
            }else{
              return pergunta;
            }
          });    
          questionario.perguntas = temp;
        }        
        return questionario;
      }) as QuestionarioType[]
    });
  }


  const adicionarPergunta = (idQuestionario:number, perguntas:PerguntaQuestionarioType[])=>{
    setListaQuestionarios((current)=>{
      return current.map((questionario)=>{
        if(questionario.id_questionario == idQuestionario){
          if(questionario.perguntas?.length){
            questionario.perguntas = [...questionario.perguntas, ...perguntas] as PerguntaQuestionarioType[];
          }else{
            questionario.perguntas = perguntas;
          }
        }
        return questionario;
      })
    })
  }

  const removerPergunta = (perguntaRemover:PerguntaQuestionarioType) => {
    setListaQuestionarios((current) => {
      return current.map((questionario)=>{
        if(questionario.id_questionario === perguntaRemover.id_questionario){
          let temp =  questionario.perguntas?.filter((pergunta)=>pergunta.id_pergunta !== perguntaRemover.id_pergunta);
          questionario.perguntas = temp;
        }
        return questionario;
      }) as QuestionarioType[]
    })
  }

  const buscarPermissoesQuestionarioOportunidade = ()=>{
    trackPromise(QuestionarioService.buscarPermissoes()).then((response:any)=>{
      setPermissoesQuestionarioOportunidade(response.data);
    })
  }

  const buscarQuestionariosOportunidade = (idOportunidade:number)=>{
    trackPromise(QuestionarioService.buscarQuestionarioOportunidade(idOportunidade)).then((response:any) => {
      setListaQuestionariosPublicos(response.data);
    }).catch((error)=>{
      Swal.fire({
        titleText: "Ooops...",
        text: "Ocorreu um erro ao buscar questionários: " + error,
        icon: 'error'
      });
    });
  }

  const adicionarQuestionarioPublico = (questionarioPublico:QuestionarioOportunidadeType) => {
    setListaQuestionariosPublicos((current) => [...current,  questionarioPublico]);
  }

  const adicionarAvalicaoOpiniao = (idQuestionario:number, questionarioPublico:QuestionarioPublicoType) => {
    setListaQuestionariosPublicos((current) => {
      return current.map((item) => {
        if(item.id_questionario === idQuestionario){
          if(item?.questionario_publico && item?.questionario_publico.length){
            item.questionario_publico.push(questionarioPublico);
          }else{
            item.questionario_publico = [questionarioPublico]
          }
        }      
        return item;
      })
    })
  }

  const atualizarAvalicaoOpiniao = (idQuestionario:number, questionarioPublico:QuestionarioPublicoType) => {
    setListaQuestionariosPublicos((current) => {
      return current.map((item) => {
        if(item.id_questionario === idQuestionario){
          item.questionario_publico = item.questionario_publico.map((itemQuestionario)=>{
            if(itemQuestionario.id_questionario_publico === questionarioPublico.id_questionario_publico){
              return questionarioPublico;
            }
            return itemQuestionario;
          });
        }      
        return item;
      })
    })
  }

  const removerAvaliacaoOpiniao = (idQuestionario:number, idQuestionarioPublico:number, expirado:boolean)=>{
    setListaQuestionariosPublicos((current) => {
      return current.map((item) => {
        if(item.id_questionario === idQuestionario){
          if(item?.questionario_publico && item?.questionario_publico.length){
            item.questionario_publico = item.questionario_publico.filter((item) => item.id_questionario_publico !== idQuestionarioPublico);
          }
          if(expirado && item?.questionario_publico_expirados && item?.questionario_publico_expirados.length){
            item.questionario_publico_expirados = item.questionario_publico_expirados.filter((item) => item.id_questionario_publico !== idQuestionarioPublico);
          }
        }      
        return item;
      })
    })
  }

  return (
    <QuestionarioContext.Provider
      value={{
        listaQuestionarios,
        listaRespostas,
        ultimasAtualizacoesQuestionario,
        listaQuestionariosPublicos,
        permissoesQuestionarioOportunidade,
        getUltimaAtualizacaoQuestionario,
        recarregarQuestionarios,
        recarregarRespostas,
        atualizarRespostas,
        resolverResposta,
        adicionarQuestionario,
        atualizarQuestionario,
        removerQuestionario,
        adicionarPergunta,
        atualizarPergunta,
        removerPergunta,
        buscarQuestionariosOportunidade,
        adicionarQuestionarioPublico,
        adicionarAvalicaoOpiniao,
        atualizarAvalicaoOpiniao,
        buscarPermissoesQuestionarioOportunidade,
        removerAvaliacaoOpiniao,
      }}
    >
      {children}
    </QuestionarioContext.Provider>
  );
}
