import React,{Fragment} from 'react';
import './index.css';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import arrowCircleUpOutline from '@iconify-icons/eva/arrow-circle-up-outline';
import { AtendimentoService } from '../../../services/AtendimentoService';
import moment from 'moment';
import DommusBase64AudioPlayer from '../../DommusBase64AudioPlayer';

export default function HistoricoVoip({conteudos}){
  const criarPromiseGravacao = (callSid) => {
    return AtendimentoService.buscarGravacao.bind(this, callSid);
  };
  return <>
    {(conteudos && conteudos.length &&
      <div className="atendimentosdoDiaContent">
        <Table className="tebelaAtendimentosdoDiaContent" striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Status</th>
                    <th>ID</th>
                    <th>
                        Dia/Hora
                    </th>
                    <th>Duração</th>
                </tr>
            </thead>
            <tbody>
                {conteudos.map((chamados, index) => {
                  const detalhesChamado = JSON.parse(chamados.detalhes);
                  const promiseBuscaAudio = detalhesChamado.CallSid && criarPromiseGravacao(detalhesChamado.CallSid) || null;
                  return  (
                    <Fragment key={chamados.id}>
                      <tr>
                          <td>
                              {chamados.direcao === "recebido"
                                  ?
                                  <OverlayTrigger
                                      key="buttonRecebido"
                                      placement="top"
                                      overlay={
                                          <Tooltip id={`tooltip-recebida`}>
                                              Recebida
                                          </Tooltip>
                                      }
                                  >
                                      <Icon icon={arrowCircleUpOutline} width="2rem" color="#4CAF50" rotate="180deg" />
                                  </OverlayTrigger>
                                  :
                                  <OverlayTrigger
                                      key="buttonRecebido"
                                      placement="top"
                                      overlay={
                                          <Tooltip id={`tooltip-realizada`}>
                                              Realizada
                                          </Tooltip>
                                      }
                                  >
                                      <Icon icon={arrowCircleUpOutline} width="2rem" color="#265d9c" />
                                  </OverlayTrigger>}
                          </td>

                          <td>{chamados.id}</td>
                          <td>
                              {moment(chamados.criado_em).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td>{moment.utc(detalhesChamado.duracao * 1000).format('HH:mm:ss')}</td>
                        </tr>
                        <tr>
                          <td colSpan="4">{promiseBuscaAudio && <DommusBase64AudioPlayer promiseBuscaAudio={promiseBuscaAudio} indice={chamados.id}/>}</td>
                        </tr>
                    </Fragment>
                  )
                })
              }
            </tbody>
        </Table>
    </div>
    )
    || <div className="alert alert-info">Atendimento externo.</div>}
  </>
}
