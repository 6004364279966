import React, { SetStateAction, useEffect, useState } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DommusIntlCurrencyInput } from "../../../components/DommusIntlCurrencyInput";
import oportunidadeService from "../../../services/OportunidadeService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService"
import { InformacoesVendaGanhaInputsType, ObrigatoriedadeCamposVendaGanhaType } from "./types";
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import { OportunidadeType } from "../Cadastro/types";

interface InformacoesVendaGanhaProps {
    setRefresh: React.Dispatch<SetStateAction<boolean>>
    close: () => void
    oportunidade: OportunidadeType
}

export function InformacoesVendaGanha({oportunidade, setRefresh, close}: InformacoesVendaGanhaProps) {
    const [obrigatoriedadeCamposVendaGanha, setObrigatoriedadeCamposVendaGanha] = useState({} as ObrigatoriedadeCamposVendaGanhaType)
    const { handleSubmit, register, errors, setValue } = useForm<InformacoesVendaGanhaInputsType>()
    const onSubmit = (data: InformacoesVendaGanhaInputsType) => {
        if(!data.oportunidade.data_venda) {
            data.oportunidade.data_venda = null
        }
        if(!data.oportunidade.data_contabilizacao) {
            data.oportunidade.data_contabilizacao = null
        }
        if(!data.oportunidade.valor_venda) {
            data.oportunidade.valor_venda = null
        }
        data.oportunidade.id_oportunidade = oportunidade.id_oportunidade
        if(oportunidade.status?.tipo != "G") {
            data.enviarEtapaVendaGanha = true
        }
        trackPromise(oportunidadeService.salvarOportunidade(data))
            .then(() => {
                setRefresh((refresh) => !refresh)
                close()
                Swal.fire({
                    title: 'Sucesso!',
                    text: oportunidade.status?.tipo === "G" ? 'Dados da venda ganha alterados com sucesso.' : 'Oportunidade enviada para etapa de venda com sucesso.',
                    icon: 'success'
                })
            })
            .catch(erro => Swal.fire({
                title: 'Ooops',
                text: `Houve um erro ao tentar salvar as informações da venda ganhar. Status: ${erro.response?.status}, ${erro.response?.data?.message}. ${erro}`,
                icon: 'error'
            }))
    }
    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(() => {
        trackPromise(ConfiguracoesGestaoService.buscarConfiguracao('obrigatoriedade_campos_venda_ganha'))
            .then(response => setObrigatoriedadeCamposVendaGanha(response.data))
            .catch(erro => Swal.fire('Ooops...', 
                `Houve um erro ao tentar bucar a configuração de obrigatoriedade dos campos da venda ganha. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`,
                'error'
            ))
        setValue("oportunidade['valor_venda']", oportunidade.valor_venda)
    }, [])

    return (
        <Form className="formCadastro" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Valor da Venda {obrigatoriedadeCamposVendaGanha.valor_venda && <DommusRequiredSpan/>}</Form.Label>
                    <DommusIntlCurrencyInput
                        register={register}
                        name="oportunidade['valor_venda']" 
                        currency="BRL"
                        config={currencyConfig}
                        change={(e: any, value: number) => setValue("oportunidade['valor_venda']", value)}
                        required={obrigatoriedadeCamposVendaGanha.valor_venda}
                        defaultValue={oportunidade.valor_venda || ""}
                    />
                    {errors?.oportunidade?.valor_venda && <small className="text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/> Campo Obrigatório*</small>}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Data da Venda {obrigatoriedadeCamposVendaGanha.data_venda && <DommusRequiredSpan/>}</Form.Label>
                    <Form.Control 
                        type="date" 
                        name="oportunidade['data_venda']" 
                        ref={register({required: obrigatoriedadeCamposVendaGanha.data_venda})} 
                        defaultValue={oportunidade.data_venda || new Date().toLocaleDateString("en-CA")}
                    />
                    {errors?.oportunidade?.data_venda && <small className="text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/> Campo Obrigatório*</small>}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                <Form.Label>Data da Contabilização {obrigatoriedadeCamposVendaGanha.data_contabilizacao && <DommusRequiredSpan/>}</Form.Label>
                <Form.Control 
                    type="date" 
                    name="oportunidade['data_contabilizacao']" 
                    ref={register({required: obrigatoriedadeCamposVendaGanha.data_contabilizacao})} 
                    defaultValue={oportunidade.data_contabilizacao || ""}
                />
                {errors?.oportunidade?.data_contabilizacao && <small className="text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/> Campo Obrigatório*</small>}
                </Col>
            </Row>
            <Button className="float-right btn-success" type="submit">
                <FontAwesomeIcon icon='save'/> Salvar
            </Button>
        </Form>
    )
}