import crm from './crm';

const ParticipanteService = {
    buscar: () => {
        let uri = `/participante-tipo`        
        return crm.get(uri);
    },    
};

export default ParticipanteService;
