import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { swalErro, swalSucesso } from "../../helpers/DommusSwal";
import { TipoAtendimentoType } from "../../pages/DetalheOportunidade/Cadastro/types";
import { SubstatusType } from "../../pages/Configuracoes/FunilDeVendas/Funil/types";
import { StatusOportunidadeType } from "../../pages/Configuracoes/FunilDeVendas/Funil/types";
import { DesistenciaTipoType } from "../../pages/OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types";
import { ReguaNutricaoService } from "../../services/ReguaNutricaoService";
import { MeioComunicacaoConectaType, NutricaoAcaoType, NutricaoEventoType, NutricaoType, ReguaNutricaoContextType } from "./types";

const estadoInicial = {
    reguas: [],
    acoes: [],
    etapas: [],
    reguaNutricaoSelecionada: null,
    linhasTabela: [],
    substatus: [],
    tiposAtendimento: [],
    meiosComunicacao: [],
    motivosDesistencia: [],
    eventoSelecionado: undefined,
    setReguas: undefined,
    setAcoes: undefined,
    setEtapas: undefined,
    setReguaNutricaoSelecionada: undefined,
    setSubstatus: undefined,
    setTiposAtendimento: undefined,
    setMeiosComunicacao: undefined,
    setEventoSelecionado: undefined,
    setMotivosDesistencia: undefined
}
export const ReguaNutricaoContext = createContext<ReguaNutricaoContextType>(estadoInicial);

interface ReguaNutricaoProviderInterface {
    children: JSX.Element
}

export function ReguaNutricaoProvider({children}: ReguaNutricaoProviderInterface) {
    const [reguas, setReguas] = useState<NutricaoType[]>([])
    const [acoes, setAcoes] = useState<NutricaoAcaoType[]>([])
    const [etapas, setEtapas] = useState<StatusOportunidadeType[]>([])
    const [reguaNutricaoSelecionada, setReguaNutricaoSelecionada] = useState<NutricaoType|null>(null)
    const [linhasTabela, setLinhasTabela] = useState<JSX.Element[]>([])
    const [substatus, setSubstatus] = useState<SubstatusType[]>([])
    const [tiposAtendimento, setTiposAtendimento] = useState<TipoAtendimentoType[]>([])
    const [meiosComunicacao, setMeiosComunicacao] = useState<MeioComunicacaoConectaType[]>([])
    const [eventoSelecionado, setEventoSelecionado] = useState<NutricaoEventoType>()
    const [motivosDesistencia, setMotivosDesistencia] = useState<DesistenciaTipoType[]>([])

    useEffect(() => {
        constroiLinhasTabela()
    }, [reguas])

    function constroiLinhasTabela() {
        const linhasAtualizadasTabela = reguas.map(nutricao => {
          const textoEventos = nutricao.nutricaoEventos.map(evento => evento.assunto).join(' | ')

            return (
              <tr key={nutricao.id_nutricao}>
                <td>{nutricao.id_nutricao}</td>
                <td>{nutricao.nome}</td>
                <td className="text-center">{textoEventos.length > 255 ? `${textoEventos.substring(0, 252)}...` : textoEventos || "Nenhum evento cadastrado."}</td>
                <td className="text-center">
                  {moment(nutricao.criado_em).format("DD/MM/YYYY HH:mm").replace(' ', ' às ')}
                </td>
                <td className="text-center">
                  <div className="funilOportunidadeOpcoes">
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" className="funilOportunidadeOpcoes">
                        <FontAwesomeIcon icon='pencil-alt' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={() => setReguaNutricaoSelecionada(nutricao)}>
                          <FontAwesomeIcon icon='pencil-alt' className="mr-3"/> Editar
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as="button" onClick={() => excluirReguaNutricao(nutricao)}>
                          <FontAwesomeIcon icon='trash-alt' className="mr-3"/> Excluir
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            );
        });
        setLinhasTabela(linhasAtualizadasTabela)
    }

    function excluirReguaNutricao(reguaNutricao: NutricaoType) {
      Swal.fire({
        title: 'Tem Certeza?',
        text: `Deseja confirmar a exclusão da régua de nutrição: ${reguaNutricao.nome}?`,
        icon: 'question',
        confirmButtonText: 'Sim, excluir.',
        showCancelButton: true,
        cancelButtonText: 'Não, cancelar'
      }).then(result => {
        if(result.isConfirmed) {
          ReguaNutricaoService.excluir(reguaNutricao.id_nutricao)
            .then(() => {
              swalSucesso('Régua de nutrição excluído com sucesso.')
              setReguas(reguas => reguas.filter(regua => regua.id_nutricao != reguaNutricao.id_nutricao))
            })
            .catch(erro => swalErro(`Houve um erro ao tentar excluir a régua de nutrição. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`))
        }
      })
    }

    return (
        <ReguaNutricaoContext.Provider
            value={{
                reguas, setReguas,
                acoes, setAcoes,
                etapas, setEtapas,
                reguaNutricaoSelecionada, setReguaNutricaoSelecionada,
                linhasTabela,
                substatus, setSubstatus,
                tiposAtendimento, setTiposAtendimento,
                meiosComunicacao, setMeiosComunicacao,
                eventoSelecionado, setEventoSelecionado,
                motivosDesistencia, setMotivosDesistencia
        }}>
            {children}
        </ReguaNutricaoContext.Provider>
    )
}
