import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../DommusRequiredSpan";
import Swal from "sweetalert2";
import ValidaTipoArquivo from "../../helpers/ValidaTipoArquivo";
import { errorToast } from "../DommusToast";

interface FechamentoParceriaProps {
    data: string
    setData: Dispatch<SetStateAction<string>>
    hora: string
    setHora: Dispatch<SetStateAction<string>>
    observacao: string
    setObservacao: Dispatch<SetStateAction<string>>
    arquivo: null|object
    setArquivo: Dispatch<SetStateAction<null|object>>
}

export function FechamentoParceria({ data, setData, hora, setHora, observacao, setObservacao, arquivo, setArquivo }: FechamentoParceriaProps) {
    const montaArquivo = useCallback((event: any) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        if(ValidaTipoArquivo.verificaTipoArquivo(file)){
          reader.onload = function (upload) {
            let conteudoArquivo = upload?.target?.result as any
            let dadosArquivo = {
              conteudo: conteudoArquivo.split(/base64,/)[1],
              nome: file.name,
            };
            setArquivo(dadosArquivo);
            
          };
          reader.readAsDataURL(file);
        }else{
          errorToast.fire({
            text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo"
          })
        }
    }, []);

    return <>
        <Row>
            <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                    Data: <DommusRequiredSpan />
                </Form.Label>
                <Form.Control
                    value={data}
                    min="2020-01-01"
                    type="date"
                    placeholder="00/00/0000"
                    onChange={(event) => setData(event.target.value)}
                    onBlur={() => {
                        if(!(data >= "2020-01-01")) {
                            setData("");
                            Swal.fire('Ooops...', 'Escolha uma data posterior ao ano de 2020', 'warning');
                        }
                    }}
                />
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                    Hora: <DommusRequiredSpan />
                </Form.Label>
                <Form.Control value={hora} type="time" onChange={(event) => setHora(event.target.value)} defaultValue="00:00"/>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col} controlId="formNome">
                <Form.Label>Considerações:</Form.Label>
                <Form.Control value={observacao} type="textarea" as="textarea" onChange={(event) => setObservacao(event.target.value)} rows={3}/>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col} controlId="formNome">
              <div className="av-item select-file">
                <div className="documentacao-item-wrapper">
                  <div className="form-group">
                    <div>
                      <h6>Anexar Termo de Fechamento:</h6>
                    </div>
                    <div className="upload-wrapper">
                      <div id="documento-0" className="documento-wrapper">
                        <div className="ajax-upload-dragdrop">
                          <span>
                            <Form.Control type="file" accept="application/pdf, image/png, image/jpeg" className="form-control-file" onChange={montaArquivo}/>
                          </span>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
        </Row>
  </>
}