import React, {useState, useEffect} from 'react'
import { Col, Form, Row } from "react-bootstrap";
import {useForm} from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import EmpreendimentoService from '../../../services/EmpreendimentoService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import OportunidadeService from '../../../services/OportunidadeService';
import CampanhaService from '../../../services/CampanhaService';
import { celularMask } from '../../../components/InputTextMask';
import { CampanhaType, OrigemType } from '../../DetalheOportunidade/Cadastro/types'; 

interface FiltroOfertaAtivaProps {
    closeModal: () => void
    submiterFiltro: (filtros: FiltroOfertaAtivaType) => void
}

export type FiltroOfertaAtivaType = {
    page: number
    limit: number, 
    filtros: {
        nome: string
        email: string
        telefone: string
        id_campanha: string
        id_empreendimento: string
        id_origem: string
        data_inicio: string
        data_fim: string
    }
}

export type CorretorEmpreendimentoType = {
    id: string
    nome: string
    bairro: string | null
    cidade: string | null
    uf: string | null
    previsaoEntrega: string | null
    atualizadoEm: string | null
    modulos: string | null
    atrativos: string | null
    descricao: string | null
    workflow_ativo: string | null
    foto_principal: FotoPrincipalType | null
}

export type FotoPrincipalType = {
    id: number
    id_empreendimento: number | null
    nome: string
    referencia: string | null
    endereco: string | null
    principal: number | null
    background: number | null
    link_externo: string | null
}

export default function FiltroOfertaAtiva(props: FiltroOfertaAtivaProps) {
    const { handleSubmit } = useForm();
    const [nome, setnome] = useState('')
    const [telefone, settelefone] = useState('')
    const [campanha, setcampanha] = useState('')
    const [email, setemail] = useState('')
    const [empreendimento, setempreendimento] = useState('')
    const [origem, setorigem] = useState('')
    const [empreendimentos, setempreendimentos] = useState([] as Array<CorretorEmpreendimentoType>)
    const [origens, setorigens] = useState([] as Array<OrigemType>)
    const [campanhas, setcampanhas] = useState([] as Array<CampanhaType>)
    const [validaCampos, setvalidaCampos] = useState(true)

    useEffect(() => {
      buscaEmpreendimentos()
      buscaOrigens()
      buscaCampanhas()
    }, [])

    const submitFiltro = () => {
        if(nome != "" || email != "" || telefone != "" || campanha != "" || empreendimento != "" || origem != ""){
            setvalidaCampos(!validaCampos)

            let filtro = {
                "page": 0,
                "limit": 0, 
                "filtros": {
                    "nome": nome,
                    "email": email,
                    "telefone": telefone,
                    "id_campanha": campanha,
                    "id_empreendimento": empreendimento,
                    "id_origem": origem,
                    "data_inicio": "",
                    "data_fim": ""
                }
            }

            props.submiterFiltro(filtro)
            props.closeModal()
        }else{
            setvalidaCampos(!validaCampos)
        }

    }

    async function buscaOrigens(){
        await trackPromise(
            OportunidadeService.buscarOrigens().then((response) => {
                if (response.data !== undefined) {
                    setorigens(response.data);
                }
            }).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar as origens: " + error,
                icon: "error"
            }))
        )
    }

    async function buscaCampanhas(){
        await trackPromise(
            CampanhaService.buscarTodasCampanhas().then((response) => {
                if (response.data !== undefined) {
                    setcampanhas(response.data);
                }
            }).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                icon: "error"
            }))  
        )
    }

    async function buscaEmpreendimentos(){
        await trackPromise(
            EmpreendimentoService.buscarEmpreendimentosPorUsuario().then((response) => {
                if (response.data !== undefined) {
                    setempreendimentos(response.data);
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                icon: "error"
            }))  
        )
    }

    return (
        <Form onSubmit={handleSubmit(submitFiltro)} className="formCadastro">
            <Row>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>Nome:</Form.Label>
                    <Form.Control name="nome" 
                        type="text" 
                        value={nome} onChange={(e: any) => setnome(e.target.value)}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control name="nome" 
                        type="text" 
                        value={email} onChange={(e: any) => setemail(e.target.value)}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>Telefone:</Form.Label>
                    <Form.Control name="telefone" 
                        type="text" 
                        value={celularMask(telefone)} onChange={(e: any) => {
                            let celsemmascara = e.target.value.replace('-', '');
                            celsemmascara = celsemmascara.replace('(', '');
                            celsemmascara = celsemmascara.replace(')', '');
                            celsemmascara = celsemmascara.replace(/\s/g, '');
                            celsemmascara = celsemmascara.trim();
                            settelefone(celsemmascara)
                        }}
                        placeholder=""
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>Campanha: </Form.Label>
                    <select className="form-control custom-select"
                        id={'campanha'}
                        name="campanha"
                        value={campanha}
                        onChange={e => {
                            setcampanha(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            campanhas.map((item, i) => (
                                <option key={i} value={item.id_campanha}>{item.nome}</option>
                            ))
                        }
                    </select>
                </Form.Group>
                <div className="form-group col-xs-12 col-md-12 col-lg-4">
                    <label>Empreendimento: </label>
                    <select className="form-control custom-select"
                        id={'empreendimento'}
                        name="empreendimento"   
                        value={empreendimento}
                        onChange={e => {
                            setempreendimento(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            empreendimentos.map((item, i) => (
                                <option key={i} value={item.id}>{item.nome}</option>
                            ))
                        }
                    </select>
                </div>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>Origem: </Form.Label>
                    <select className="form-control custom-select"
                        id={'origem'}
                        name="origem"
                        value={origem}
                        onChange={e => {
                            setorigem(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            origens.map((item, i) => (
                                <option key={i} value={item.id_origem}>{item.origem}</option>
                            ))
                        }
                    </select>
                </Form.Group>
            </Row>
            {!validaCampos && <span className='help-text'>Selecione ou insera um dado para busca</span>}
            <button type="submit" className="btn btn-success float-right">
                <FontAwesomeIcon icon={faFilter}
                    style={{ marginRight: '10px' }} />Filtrar
            </button>    
        </Form> 
    )
}
