import React from "react";
import "./index.css"
import { TagOportunidadeType } from "../../contexts/TagOportunidadeContext/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface TagsOportunidadeProps {
    tags: TagOportunidadeType[] | [];
    maxTags?: number;
    useEllipse?: boolean;
}

export function TagsOportunidade({ tags, maxTags=2, useEllipse=true }: TagsOportunidadeProps) {
    return (
        <div className="tag-section">
            {tags && tags.map((tag, index) => {
                if (index <= (maxTags - 1) ) {
                    return (
                        <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                                <Tooltip id={String(`${tag.id_tag}-${tag.nome}`)}>
                                    {tag.nome}
                                </Tooltip>
                            }
                        >
                            <div className={`tag-nome ${useEllipse && "use-ellipse"}`}>
                                {tag.nome}
                            </div>
                        </OverlayTrigger>
                    );
                }
            })
            }
            {tags && tags.length > maxTags ?
                <OverlayTrigger
                    key="TagsAgrupadas"
                    placement="top"
                    overlay={
                        <Tooltip id={String("tags-agrupadas")}>
                            {tags && tags.map((tag, index) => {
                                if (index >= maxTags) {
                                    return tag?.nome + (index !== tags.length - 1 ? " \u{2022} " : "");
                                }
                            })}
                        </Tooltip>
                    }
                >
                    <div className="tag-agrupada">
                        + {tags.length - maxTags}
                    </div>
                </OverlayTrigger>
                : <></>
            }            
        </div>
    )
}
