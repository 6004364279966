import { trackPromise } from "react-promise-tracker";
import { InputsCadastroReguaNutricao } from "../components/CadastroReguaNutricao/types";
import crm from "./crm";

type SalvarEventoNutricaoType = {
    id_nutricao_acao: string
    prazo: string
    tipo: string
    id_contato_origem: string
    template: string
    cor: string
    frequencia: boolean
    id_referencia?: string
    enviar_apos?: string
    id_nutricao: number
    assunto: string
}

type AtualizarEventoNutricaoType = {
    id_nutricao_acao: string
    prazo: string
    tipo: string
    id_contato_origem: string
    template: string
    cor: string
    frequencia: boolean
    id_referencia?: string
    enviar_apos?: string
    id_nutricao: number
    assunto: string
    id_nutricao_evento: number
}

type ReordenarEventosNutricaoType = {
    id_nutricao_evento: number
    ordem: number
}

export const ReguaNutricaoService = {
    index: () => {
        return trackPromise(crm.get('/regua-nutricao'))
    },

    buscarAcoes: () => {
        return trackPromise(crm.get('/regua-nutricao/acao'))
    },

    salvar: (corpoRequisicao: InputsCadastroReguaNutricao) => {
        return trackPromise(crm.post('/regua-nutricao', corpoRequisicao))
    },

    atualizar: (corpoRequisicao: InputsCadastroReguaNutricao, id_regua_nutricao: number) => {
        return trackPromise(crm.put(`/regua-nutricao/${id_regua_nutricao}`, corpoRequisicao))
    },

    buscarMeiosDeComunicacao: () => {
        return trackPromise(crm.get(`/regua-nutricao/meio-comunicacao`))
    },

    salvarEvento: (corpoRequisicao: SalvarEventoNutricaoType) => {
        return trackPromise(crm.post(`/regua-nutricao/evento`, corpoRequisicao))
    },
    
    reordenarEventos: (corpoRequisicao: ReordenarEventosNutricaoType) => {
        return trackPromise(crm.put('/regua-nutricao/evento/reordenar', { eventos: corpoRequisicao }))
    },

    excluirEvento: (id_nutricao_evento: number) => {
        return trackPromise(crm.delete(`/regua-nutricao/evento/${id_nutricao_evento}`))
    },

    atualizarEvento: (corpoRequisicao: AtualizarEventoNutricaoType) => {
        return trackPromise(crm.put('/regua-nutricao/evento', corpoRequisicao))
    },

    excluir: (id_nutricao: number) => {
        return trackPromise(crm.delete(`/regua-nutricao/${id_nutricao}`))
    },

    buscarLogs: (id_oportunidade: number) => {
        return trackPromise(crm.get(`/regua-nutricao/logs/${id_oportunidade}`))
    }
}