import React, {useState, useEffect} from 'react'
import DommusToggle from '../../../components/DommusToggle';
import { Row } from "react-bootstrap";
import { parseISO, format } from "date-fns";
import NovaDesistencia from './Nova';
import Swal from 'sweetalert2';
import { trackPromise } from "react-promise-tracker";
import OportunidadeService from '../../../services/OportunidadeService';
import DesistenciaService from '../../../services/DesistenciaService';

export default function Desistencia({ id_oportunidade }) {

    const [tiposDesistencia, settiposDesistencia] = useState([])
    const [desistencias, setdesistencias] = useState([])
    const [atualizadesistencia, setatualizadesistencia] = useState(false)

    useEffect(() => {
        trackPromise(
            OportunidadeService.buscarTiposDesistencia().then(
                (response) => {
                    if (response.data !== '') {
                        settiposDesistencia(response.data);
                    }
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar a lista de tipos: " + error.data,
                        icon: "error"
                    });
                }
            )
        )
        buscaDesistencias()
    }, [atualizadesistencia]);
    
    async function buscaDesistencias() {
        await trackPromise(
              DesistenciaService.buscarDesistencias(id_oportunidade).then((response) => {
                if (response.data !== undefined) {
                  setdesistencias(response.data)
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os dependencias: " + error,
                icon: "error"
            }))            
        )
    } 

    function atualizarDesistencia(){
        setatualizadesistencia(!atualizadesistencia)
    }

    return (
        <>
            <DommusToggle title="Informações da(s) Desistência(s)" toggle={true}>
                <Row>
                    <div className="col-md-3">
                        <span className="">
                            <strong>Motivo da Desistência:</strong>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <span className="">
                            <strong>Considerações:</strong>
                        </span>
                    </div>
                    <div className="col-md-3">
                        <span className="">
                            <strong>Atualizado em:</strong>
                        </span>
                    </div>
                </Row>
                {
                    desistencias.map((des, i) => (
                        <>
                            <Row>
                                <div className="col-md-3">
                                    <span className="">
                                        {
                                            tiposDesistencia.length > 0 ?
                                            tiposDesistencia.map((tipo, i) => (

                                                tipo.id_desistencia_tipo == des.id_desistencia_tipo ?
                                                   <>{tipo.nome}</> 
                                                : <></>
                                            ))
                                            : <></>
                                        }
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <span className="">
                                        {des.consideracoes}
                                    </span>
                                </div>
                                <div className="col-md-3">
                                    <span className="">
                                    {des.atualizado_em &&
                                        format(
                                        parseISO(des.atualizado_em),
                                        "dd/MM/yyyy' às ' HH:mm"
                                    )}
                                    </span>
                                </div>
                            </Row>
                            <hr />
                        </>
                    ))
                }
            </DommusToggle>
            <DommusToggle title="Cadastrar uma nova desistencia" toggle={true}>
                <NovaDesistencia atualizarDesistencia={atualizarDesistencia.bind(this)} id_oportunidade={id_oportunidade}/>
            </DommusToggle>
        </>
    )
}
