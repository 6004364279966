import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { EmpreendimentoType } from "..";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import { OportunidadeContext } from "../../../../../components/GlobalContext/globalContext";

interface SelectEmpreendimentoProps {
    setEmpreendimento: (id_empreendimento: number) => void
    selectedEmpreendimento: number
    empreendimentos: Array<EmpreendimentoType>
    permissaoEditar?: boolean
}

export function SelectEmpreendimento({ setEmpreendimento, selectedEmpreendimento, empreendimentos, permissaoEditar=true }: SelectEmpreendimentoProps) {
    const [editMode, setEditMode] = useState(false)
    const { oportunidadeSelecionada } = useContext(OportunidadeContext);

    useEffect(()=>{
        if(oportunidadeSelecionada){
            setEditMode(false);
        }
    },[oportunidadeSelecionada])

    return (
        <Row className="row-cadastro-inicio">
            <Form.Group as={Col} controlId="formNome">
                <Form.Label>
                    <div className="divEdit">
                        <div style={{ fontSize: 14, fontWeight: "bold" }}> 
                            Empreendimento: <DommusRequiredSpan />
                        </div>
                        {permissaoEditar && 
                            <div className="dataTipEdit" onClick={(e) => setEditMode(!editMode)} data-tip="Editar">
                                <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                            </div>
                        }
                    </div>
                </Form.Label>

                {editMode ? (
                    <Form.Control style={{ marginBottom: 5 }} as="select" custom onChange={(e) => setEmpreendimento(Number(e.target.value))} value={selectedEmpreendimento || ""}>
                        <option value="0">Selecione o empreendimento</option>
                        {empreendimentos.filter((item)=> item.ativo === '1').map((empreendimento) => (
                            <option key={empreendimento.id} value={empreendimento.id}>
                                {empreendimento.nome}
                            </option>
                        ))}
                    </Form.Control>
                ) : selectedEmpreendimento ? empreendimentos.map((empreendimento) => Number(empreendimento.id) == selectedEmpreendimento ? (
                        <p style={{ fontSize: 14 }}>
                            {empreendimento.nome}
                        </p>
                    ) : ( 
                        <></>
                    )
                ) : (
                    <p style={{ fontSize: 14 }}>
                        Não atribuído
                    </p>
                ) 
            }
            </Form.Group>
        </Row>
    )
}