import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BlocoCamposDinamicos } from "./BlocoCamposDinamicos";
import { useFieldArray } from "react-hook-form";

interface FiltroQualificacaoProps {
  register: any;
  setValue: any;  
  control: any;
}

export function FiltroQualificacao({register, setValue, control}: FiltroQualificacaoProps) {

  const { fields, prepend, remove } = useFieldArray({
    name: "qualificacaoFiltro",
    control,
  });

  const addBloco = () => {
    prepend({
      campoDinamicoId: "",
      busca: "",
      relacao: "",
    });    
  };

  useEffect(()=>{
    if(fields.length > 1){
      let element = document.getElementById('conteudo-filtro');
      if(element){      
        element.scrollTop = element.scrollHeight;
      }
    }
  },[fields]);

  const removeBloco = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <Form className="formCadastro">
      <section className="blocos-filtro-qualificacao">
        <div className="d-flex flex-column-reverse">
          {fields.map((field:any, index:number) => {
            return (
              <BlocoCamposDinamicos
                names={[
                  `qualificacaoFiltro.${index}.campoDinamicoId`,
                  `qualificacaoFiltro.${index}.relacao`,
                  `qualificacaoFiltro.${index}.buscaTexto`,
                  `qualificacaoFiltro.${index}.inicio`,
                  `qualificacaoFiltro.${index}.fim`,
                  `qualificacaoFiltro.${index}.multiselect`,
                ]}
                register={register}
                index={index}
                removerBloco={removeBloco}
                control={control}
                setValue={setValue}
                key={field.id}
              />
            );
          })}
        </div>
        <Button
          type="button"
          variant="primary"
          className="btn-add-campo"
          onClick={() => {
            addBloco();
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          <span>Adicionar campos</span>
        </Button>
      </section>
    </Form>
  );
}
