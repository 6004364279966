import React, { useContext, useMemo } from "react";
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import { OportunidadeContext } from "../../../../../../components/GlobalContext/globalContext";
import { ArrayField, Controller, useFormContext } from "react-hook-form";
import { celularMask } from "../../../../../../components/InputTextMask";

interface AtendimentoParticipanteVisitaFormProps {
    participanteDefault: Partial<ArrayField<Record<string, any>, "id">>;
    index: number;
    names: string[];
    removerBloco: (index: number) => void;
}


export function AtendimentoParticipanteVisitaForm({ participanteDefault, index, names, removerBloco }: AtendimentoParticipanteVisitaFormProps) {
    const methods = useFormContext();
    const { participantes } = useContext(OportunidadeContext)

    const fieldParticipantes = useMemo(() => {
        return (
            <Controller
                name={names[0]}
                control={methods.control}
                as={
                    <Select
                        placeholder="Tipo ..."
                        className={`react-select-visita-form ${methods?.errors?.participantes && methods?.errors?.participantes[index]?.id_participante ? "field-empty" : ""}`}
                        classNamePrefix="react-select"
                        options={
                            participantes.map((participante) => {
                                return {
                                    label: participante.descricao,
                                    value: participante.id_participante_tipo
                                }
                            })
                        }
                    />
                }
            defaultValue={participanteDefault.id_participante}
            />
        )
    }, [participantes, participanteDefault]);

    return (
        <tr>
            <td>
                {fieldParticipantes}
            </td>
            <td>
                <input
                    type="text"
                    className="input-table"
                    ref={methods.register()}
                    name={names[1]}
                    placeholder="Nome ..."
                    defaultValue={participanteDefault?.nome ?? ""}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="input-table"
                    ref={methods.register()}
                    name={names[2]}
                    placeholder="(DD) 000000-0000"
                    defaultValue={celularMask(participanteDefault.contato) ?? ""}
                    onChange={(e) => {
                        methods.setValue(names[2], celularMask(e.target.value ?? ""));
                    }}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="input-table"
                    ref={methods.register()}
                    name={names[5]}
                    placeholder="Cidade ..."
                    defaultValue={participanteDefault?.cidade ?? ""}
                />
            </td>
            <td className="text-center pt-2">
                <input name={names[3]} ref={methods.register()} defaultValue={participanteDefault.id_oportunidade_atendimento_participante} hidden/>
                <input name={names[4]} ref={methods.register()} defaultValue={participanteDefault.id_oportunidade_atendimento} hidden/>
                <div className="btn-action-section">
                    <div
                        className="btn-action-linha"
                        onClick={() => {
                            removerBloco(index)                            
                        }}
                    >
                        <FontAwesomeIcon
                            className="btn-action-linha btn-excluir-linha"
                            icon={faTrash}
                        />
                    </div>
                </div>
            </td>
        </tr>
    )
}