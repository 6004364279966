import React, {useEffect, useState} from "react";
import {Calendar} from "react-big-calendar";
import "./index.css"
import moment from "moment";
import {trackPromise} from "react-promise-tracker";
import perfilUsuarioService from "../../services/PerfilUsuarioService";
import guService from "../../services/GuService";

export default function DommusCalendar({
    localizer,
    events,
    views,
    messages,
    startAccessor,
    endAccessor,
    formats,
    eventPropGetter,
    onSelectEvent,
    onNavigate=(val)=>{return},
    onView=(val)=>{return},
    style,
    steps,
    defaultView,
    onRangeChange,
    goToAgenda = true,
    popup=false,
    onTasksChange=(val)=>{return},
    onVisitsChange=(val)=>{return},
    agendaUsuario=false
}) {
    const [currentStep, setCurrentStep] = useState(steps ? steps[0] : guService.getLocalState('intervaloCalendario').toString())
    const [customFormats, setCustomFormats] = useState(formats)
    const [tasks, setTasks] = useState(true)
    const [visits, setVisits] = useState(true)


    useEffect(() => {
        let newFormats = formats
        newFormats.dayRangeHeaderFormat = ({
                                               start,
                                               end
                                           }) => moment(start).format("DD/MM") + " à " + moment(end).format("DD/MM")
        setCustomFormats(newFormats)

    }, [])

    function handleChangeCurrentStep(stepData) {
        trackPromise(
            perfilUsuarioService.alterarIntervaloCalendario(stepData)
                .then((response) => {
                    setCurrentStep(response.data)
                    guService.setLocalState('intervaloCalendario', response.data)
                })
        )
    }

    function intervalOptions() {
        return <>
            <div className="dommus-interval-step">
                <label>Intervalo em minutos: </label>
                <div className="dommus-interval-step-group">
                    {steps.map((step, index) => <div key={index}
                        className={'interval-step-item' + (currentStep == step ? ' active' : '')} onClick={() => {
                        setCurrentStep(step);
                        handleChangeCurrentStep(step)
                    }}>{step * 2}</div>)}
                </div>
            </div>
        </>
    }
    return (
        <>
            {events &&
            <Calendar
                popup={popup}
                localizer={localizer}
                events={events}
                views={views}
                messages={messages}
                startAccessor={startAccessor}
                endAccessor={endAccessor}
                formats={customFormats}
                eventPropGetter={eventPropGetter}
                onSelectEvent={onSelectEvent}
                onNavigate={onNavigate}
                onRangeChange={onRangeChange}
                onView={onView}
                style={style}
                step={currentStep}
                defaultView={defaultView}
                min={new Date(2020, 10, 0, 7, 0, 0)}
                max={new Date(2050, 10, 0, 23, 0, 0)}
            />}
            {!agendaUsuario && (
                <div className="dommus-custom-options">
                    {goToAgenda ?
                    <div className="agenda-do-usuario" onClick={() => {
                        window.open(`/agenda-do-usuario`);
                    }}>
                    Ir para Agenda do Usuário
                    </div> : <div></div>}
                    <div className="dommus-options">
                        <div className="dommus-type-options">
                            <label>Exibir: </label>
                            <div className="dommus-type-options-items">
                                <div className={'dommus-type-options-item' + (visits ? ' active' : '')} onClick={() => {
                                    setVisits(!visits);onVisitsChange(!visits)
                                }}>
                                    VISITAS
                                </div>
                                <div className={'dommus-type-options-item' + (tasks ? ' active' : '')} onClick={() => {
                                    setTasks(!tasks);onTasksChange(!tasks)
                                }}>
                                    TAREFAS
                                </div>
                            </div>
                        </div>
                        {intervalOptions()}
                    </div>
                </div>
            )}
        </>
    )
}
