import React, { useContext } from "react";
import "./index.css";
import { OportunidadePorStatusPaginadoType } from "../../../Visualizacao/FunilOportunidades/types";
import { Col, Container, Row } from "react-bootstrap";
import { celularMask } from "../../../../../components/InputTextMask";
import { EmpreendimentoContext } from "../../../../../components/GlobalContext/globalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBullhorn,
  faEnvelope,
  faPhoneAlt,
  faSuitcase,
  faUserCog,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import BibliotecaIcones from "../../../../../components/BibliotecaIcones";
import { DadosOportunidadeProps } from "../..";
import { StatusOportunidadeType } from "../../../../Configuracoes/FunilDeVendas/Funil/types";

interface ICardAlteracaoMassa {
  opv: OportunidadePorStatusPaginadoType;
  dadosAlterado?: DadosOportunidadeProps | null;
  etapaOpvAlterado?: StatusOportunidadeType | null;
}

export function CardAlteracaoMassa({
  opv,
  dadosAlterado,
  etapaOpvAlterado,
}: ICardAlteracaoMassa) {
  const {
    resolverNomeEmpreendimento,
    resolverNomeEquipe,
    resolverNomeGerente,
    resolverNomeOperador,
  } = useContext(EmpreendimentoContext);

  return (
    <Container fluid className="card">
      <Row>
        <Col className="icon-midia text-center" lg={1}>
          <div style={{ fontSize: "50px", marginTop: "12px" }}>
            <BibliotecaIcones
              optionsSelectComIcones={""}
              setIdSelect={""}
              chaveLabelSelect={""}
              chaveIdIconeSelecionado=""
              sizeIcone={""}
              colorIcone={opv.midia_cor}
              value={""}
              setValue={""}
              estilo={""}
              iconeSelecionado={""}
              chaveIcone={opv.midia_icone}
              tipoExibicao={"icone"}
            />
          </div>
        </Col>
        <Col className="informacoes-card">
          <Row>
            <Col className="info-card" lg={5}>
              <small>
                <b style={{ color: "var(--dommus-color-red)" }}>
                  OPV-{opv.id_oportunidade.toString().padStart(5, "0")}
                </b>
              </small>
              <b>{opv.envolvido_nome}</b>
            </Col>
            <Col lg={7}>
              <div className="etapa-section-card">
                <small
                  className="etapa-oportunidade-card"
                  style={{ backgroundColor: opv.etapa_cor, color:"white", fontWeight: 700 }}
                >
                  {" "}
                  {opv.etapa_nome}
                </small>
                {etapaOpvAlterado ? (
                  <>
                    <span className="arrow-card">{"\u2B95"}</span>
                    <small
                      className="etapa-oportunidade-card"
                      style={{ backgroundColor: etapaOpvAlterado?.cor, color:"white", fontWeight: 700 }}
                    >
                      {" "}
                      {etapaOpvAlterado?.nome}
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <div className="dados-card">
                <small className="item">
                  <FontAwesomeIcon icon={faEnvelope} />
                  {opv.envolvido_email || opv.lead_email || "---"}
                </small>
                <small className="item">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  {celularMask(opv.envolvido_telefone) ||
                    celularMask(opv.lead_telefone) ||
                    "---"}
                </small>
                <small className="item-">
                  <FontAwesomeIcon icon={faBullhorn} />
                  {opv.campanha_nome}
                </small>
              </div>
            </Col>
            <Col lg={7}>
              <div className="dados-card">
                {/* Empreendimento */}
                <div className="row-item">
                  <small className="item">
                    <FontAwesomeIcon icon={faBuilding} />
                    {opv?.id_empreendimento
                      ? resolverNomeEmpreendimento(opv?.id_empreendimento)
                      : opv?.nome_empreendimento}
                  </small>
                  {dadosAlterado?.id_empreendimento ? (
                    <>
                      <span id="arrow-card">{"\u2B95"}</span>
                      <small className="item">
                        {dadosAlterado.id_empreendimento
                          ? resolverNomeEmpreendimento(
                              Number(dadosAlterado?.id_empreendimento)
                            )
                          : "---"}
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* Equipe */}
                <div className="row-item">
                  <small className="item">
                    <FontAwesomeIcon icon={faUsers} />
                    {resolverNomeEquipe(
                      opv.id_equipe_pdv || opv.id_equipe_pre_atendimento || 0
                    )}
                  </small>
                  {dadosAlterado?.id_equipe_pdv ||  dadosAlterado?.id_equipe_pre_atendimento ? (
                    <>
                      <span id="arrow">{"\u2B95"}</span>
                      <small className="item">
                        {resolverNomeEquipe(
                          Number(dadosAlterado?.id_equipe_pdv) ||
                            Number(dadosAlterado?.id_equipe_pre_atendimento) ||
                            0
                        )}
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* Gestor */}
                <div className="row-item">
                  <small className="item">
                    <FontAwesomeIcon icon={faUserCog} />
                    {resolverNomeGerente(
                      opv.id_gerente_pdv || opv.id_gerente_pre_atendimento || 0
                    )}
                  </small>
                  {dadosAlterado?.id_gerente_pdv || dadosAlterado?.id_gerente_pre_atendimento ? (
                    <>
                      <span id="arrow">{"\u2B95"}</span>
                      <small className="item">
                        {resolverNomeGerente(
                          Number(dadosAlterado?.id_gerente_pdv) ||
                            Number(dadosAlterado?.id_gerente_pre_atendimento) ||
                            0
                        )}
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* Corretor */}
                <div className="row-item">
                  <small className="item">
                    <FontAwesomeIcon icon={faSuitcase} />
                    {resolverNomeOperador(
                      opv.id_usuario_atendimento ||
                        opv.id_usuario_pre_atendimento ||
                        0
                    )}
                  </small>
                  {dadosAlterado?.id_usuario_atendimento || dadosAlterado?.id_usuario_pre_atendimento ? (
                    <>
                      <span id="arrow-card">{"\u2B95"}</span>
                      <small className="item">
                        {resolverNomeOperador(
                          Number(dadosAlterado?.id_usuario_atendimento) ||
                            Number(dadosAlterado?.id_usuario_pre_atendimento) ||
                            0
                        )}
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
