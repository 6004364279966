import React from "react";
import formatDommus from "../../../../../helpers/format";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export default function CalcularSLA({ date, sla, sla_expira }) {
  let contagemDias;
  let mostrarTempoFaltante;
  let slaEmDias = Math.floor(sla / 60 / 24);
  let seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) > slaEmDias) {
      contagemDias = Math.floor(interval) - slaEmDias;
    }
  }
  if (!contagemDias) {
    contagemDias = 0;
  }

  let slaExpiraEmDias = Math.floor(sla_expira / 60 / 24);

  const dataExpira = moment(date)
    .add(Number(sla_expira), "minutes")
    .format("DD/MM/YYYY HH:mm");

  const dataLimite = moment(date)
    .add(Number(sla), "minutes")
    .format("YYYY-MM-DD HH:mm");
  const hoje = moment().format("YYYY-MM-DD HH:mm");
  const hojeFormat = moment().format("DD/MM/YYYY HH:mm");
  const estaAtrasado = hoje > dataLimite;

  const menos24h = moment(dataExpira)
    .subtract(Number(24), "hours")
    .format("DD/MM/YYYY HH:mm");

  let mensagemDias;
  if (estaAtrasado) {
    mensagemDias = "Atualizado em: "+moment(date).format("DD/MM/YYYY HH:mm") + " | Expirou em: " + dataExpira

  } else {
    mensagemDias =
      sla_expira != null
        ? "Atualizado em: "+moment(date).format("DD/MM/YYYY HH:mm") + " | Expira em: " + dataExpira
        : "Atualizado em: "+moment(date).format("DD/MM/YYYY HH:mm");
  }

  if (
    new Date(hojeFormat) >= new Date(menos24h) &&
    new Date(hojeFormat) <= new Date(dataExpira)
  ) {
    mostrarTempoFaltante = new Date(dataExpira) - new Date(hojeFormat);
  }

  return (
    <div className="data">
      <FontAwesomeIcon
        icon={faClock}
        data-tip={mensagemDias}
        color={estaAtrasado ? "#ECAC5D" : "#212529"}
      />
      {formatDommus.formatDate(date)}
      {/* mostrarTempoFaltante ? (
        <>
          <FontAwesomeIcon
            icon={faCircle}
            style={{ fontSize: 5, marginLeft: 5 }}
            color={"#212529"}
          />
          {moment(mostrarTempoFaltante).format("DD/MM/YYYY HH:mm")}
        </>
      ) : (
        <></>
      ) */}
    </div>
  );
}
