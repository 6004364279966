import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Table } from "react-bootstrap";
import { AtendimentoParticipanteVisitaForm } from "./AtendimentoParticipanteVisitaForm";
import "./index.css"
import { useFieldArray, useFormContext } from "react-hook-form";

export function AtendimentoParticipantesVisita() {
    const methods = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "participantes"
    })

    const handleAdicionarLinha = () => {
        append({
            id_oportunidade_atendimento_participante: null,
            id_oportunidade_atendimento: null,
            id_participante_tipo: null,
            nome: null,
            contato: null,
            cidade: null,
        })
    }

    const removerBloco = (indexRemove: number) => {
        remove(indexRemove)
    }

    return (
        <Col>
            <label className="label-tabela-visita">Estiveram presentes durante a visita: </label>
            <Table striped bordered hover size="sm">
                <thead className="thead-dark">
                    <tr>
                        <th style={{ width: "15%" }}>Tipo</th>
                        <th style={{ width: "38%" }}>Nome</th>
                        <th style={{ width: "20%" }}>Contato</th>
                        <th style={{ width: "25%" }}>Cidade</th>
                        <th style={{ width: "4%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((field, index) => {
                        return (
                            <AtendimentoParticipanteVisitaForm
                                key={field.id}
                                index={index}
                                names={[
                                    `participantes.${index}.id_participante_tipo`,
                                    `participantes.${index}.nome`,
                                    `participantes.${index}.contato`,
                                    `participantes.${index}.id_oportunidade_atendimento_participante`,
                                    `participantes.${index}.id_oportunidade_atendimento`,
                                    `participantes.${index}.cidade`,
                                ]}
                                participanteDefault={field}
                                removerBloco={removerBloco}
                            />
                        )
                    })}
                    <tr>
                        <td colSpan={10} onClick={() => { handleAdicionarLinha() }}>
                            <div className="add-linha">
                                <FontAwesomeIcon icon={faPlus} /><span>Nova linha</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Col>
    )
}