import React, { createContext, ReactElement, useEffect, useState } from "react";
// import { swalErro } from "../../helpers/DommusSwal";
import { TipoDocumentoType } from "./types";
import DocumentoService from "../../services/DocumentoService";

export const TiposDocumentosContext = createContext({});

export function TiposDocumentosProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [tiposDocumentos, setTiposDocumentos] = useState<Array<TipoDocumentoType>>([]);
  const [mapaTiposDocumentos, setMapaTiposDocumentos] = useState<Map<number, TipoDocumentoType>>(new Map());

  const recarregarTiposDocumentos = ()=>{
    DocumentoService.buscarDocumentosCadastro()
      .then(
        (response) => {
          let tiposDocumentos = response.data;
          if (Array.isArray(tiposDocumentos)) {
            setTiposDocumentos(response.data);
          } else {
            setTiposDocumentos([]);
          }
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
    });
  }

  useEffect(()=>{
    const novoMapa = new Map();
    tiposDocumentos.forEach((documento:TipoDocumentoType) => {
      novoMapa.set(Number(documento.id), documento);
    });
    setMapaTiposDocumentos(novoMapa);
  }, [tiposDocumentos]);

  function resolverNomeDocumento(idDocumento:number, padrao = "Não Atribuído"){
    let documento = mapaTiposDocumentos.get(Number(idDocumento));
    return (documento && documento.descricao) || padrao;
  };

  return (
    <TiposDocumentosContext.Provider value={{
        tiposDocumentos,
        recarregarTiposDocumentos,
        resolverNomeDocumento,
    }}>
      {children}
    </TiposDocumentosContext.Provider>
  );
}


