import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import '../NovaPendencia/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import OportunidadeService from '../../../../services/OportunidadeService';
import Swal from "sweetalert2";
import { trackPromise } from 'react-promise-tracker';
import { TiposPendenciasContext } from '../../../../contexts/tiposPendenciasContext/TiposPendenciasContext';
import { OportunidadeContext } from '../../../../components/GlobalContext/globalContext';
import { toastErro } from '../../../../helpers/DommusSwal';
import DommusRequiredSpan from '../../../../components/DommusRequiredSpan';

export default function NovaPendencia({ oportunidade, inserirLista, refresh, pendenciaSelecionada=null }) {
    const [tipo, setTipo] = useState('');
    const [id_oportunidade, setOportunidade] = useState(oportunidade);
    const [observacao, setObservacao] = useState('');
    const [data, setData] = useState('');
    const [hora, setHora] = useState('');

    const {tiposPendencias} = useContext(TiposPendenciasContext);
    const {atualizarOportunidade} = useContext(OportunidadeContext);

    useEffect(()=>{
        if(pendenciaSelecionada){
            setTipo(pendenciaSelecionada?.pendencia_tipo?.id_oportunidade_pendencia_tipo);
            setObservacao(pendenciaSelecionada?.observacao);
            if(pendenciaSelecionada.prazo){
                let dataHora = pendenciaSelecionada.prazo.split(' ');
                setData(dataHora[0]);
                setHora(dataHora[1]);
            }
        }
    },[pendenciaSelecionada]);
    
    function salvarPendencia() {
        if(!tipo || tipo == ""){
            toastErro("Campo obrigatório não preenchido!");
            return
        }
        var dados = {
            "id_oportunidade_pendencia_tipo": tipo,
            "observacao": observacao,
            "prazo": data + ' ' + hora,
            "id_oportunidade": id_oportunidade
        }
        
        if(pendenciaSelecionada){
            dados.id_oportunidade_pendencia = pendenciaSelecionada.id_oportunidade_pendencia;
        }

        if(dados.prazo.trim() === "" || !data || data === ""){
            dados.prazo = null;
        }

        trackPromise(
            OportunidadeService.salvarPendencia(dados).then(
                (response) => {
                    Swal.fire({
                        titleText: "Cadastrado!",
                        text: "Atendimento registrado com sucesso.",
                        icon: "success"
                    }).then(() => {
                        if (!(dados.id_oportunidade_pendencia || false)) {
                            inserirLista(response.data);
                            refresh();
                            atualizarOportunidade(id_oportunidade);
                        }else{
                            atualizarOportunidade(id_oportunidade);
                            refresh();
                        }
                    });
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar o atendimento: " + error,
                        icon: "error"
                    });
                }
            )
        )
    }
    return <>
        <Container fluid>
            <Form className="formCadastro">
                <Row>
                    <Form.Group as={Col} controlId="formGridState"  >
                        <Form.Label>Tipo <DommusRequiredSpan/></Form.Label>
                        <Form.Control as="select" defaultValue="Selecione" value={tipo} onChange={(event) => setTipo(event.target.value)}>
                            <option>Selecione</option>
                            {tiposPendencias.map(item =>
                                <option value={item.id_oportunidade_pendencia_tipo} key={item.id_oportunidade_pendencia_tipo}>{item.nome}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row >
                    <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Data:</Form.Label>
                        <Form.Control 
                            value={data}
                            type="date"
                            onChange={(event) => setData(event.target.value)}
                            />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Hora:</Form.Label>
                        <Form.Control
                            onChange={(event) => setHora(event.target.value)}
                            value={hora}
                            type="time"
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Observação:</Form.Label>
                        <Form.Control type="textarea" as="textarea" value={observacao} onChange={(event) => setObservacao(event.target.value)} rows="3" />
                    </Form.Group>
                </Row>


                <Row className="formFooter">
                    <Col>
                        <button type="button" onClick={() => salvarPendencia()} className="btn btn-success"><FontAwesomeIcon icon={faSave}
                            style={{ marginRight: '15px' }} />Salvar
					</button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>
}