import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import './upload.css';

export default class CSVReader1 extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleOnDrop = (data) => {
    this.props.recebedata(data);
  }

  handleOnError = (err, file, inputElem, reason) => {

  }

  handleOnRemoveFile = (data) => {
    this.props.cleardata(data);
  }

  bringToFront() {
  }

  render() {
    return (
      <div className="lercsv">
        {this.props.resetar ? true : this.handleOnRemoveFile}
        <CSVReader
          onDrop={this.handleOnDrop}
          onError={this.handleOnError}
          addRemoveButton
          isReset={this.props.resetar}
          onRemoveFile={this.handleOnRemoveFile}
        >
          <FontAwesomeIcon color="#45B058" icon={faFileCsv} size="3x" data-tip="Importação" />
          <span>Arraste o arquivo aqui ou click para procurar</span>
        </CSVReader>

      </div>
    )
  }
}
