import React, { createContext, ReactElement, SetStateAction, useState } from "react";
import { TipoHistoricoType } from "../../components/FormFiltroPadrao/types";

interface TipoHistoricoProps {
  tiposHistorico: TipoHistoricoType[]
  setTiposHistorico: React.Dispatch<SetStateAction<TipoHistoricoType[]>>
}

export const TipoHistoricoContext = createContext({} as TipoHistoricoProps);

export function TipoHistoricoProvider({ children}: { children: ReactElement }) {
  const [tiposHistorico, setTiposHistorico] = useState<TipoHistoricoType[]>([]);

  return (
    <TipoHistoricoContext.Provider value={{ tiposHistorico, setTiposHistorico }}>
      {children}
    </TipoHistoricoContext.Provider>
  );
}