/* eslint-disable react/display-name */
import React, {useState, useEffect, forwardRef, useImperativeHandle, Ref} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Swal from 'sweetalert2';
import equipeService from '../../services/EquipeService';
import OptionCarregando from '../../components/FormFiltroPadrao/OptionCarrengando'
import { CorretorType, EquipeType, GerenteEquipeType } from '../OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types';
import { trackPromise } from 'react-promise-tracker';
import { EquipesPrePdvRefType, GerenteType, OperadorType } from './types';
import { MultiSelect, Option } from 'react-multi-select-component';
import './index.css'

interface EquipesPrePdvProps {
    setusuarioPre: (val: string|Option[]) => void
    equipePre: string|Option[]
    setequipePre: (equipes: Option[]|string) => void
    gestorPre: string|Option[]
    setgestorPre: (val: string|Option[]) => void
    configuraExibicao?: string
    equipePdv: string|Option[]
    setequipePdv: (val: Option[]|string) => void
    empreendimento: string
    gerentePvd: string|Option[]
    setgerentePvd: (val: string|Option[]) => void
    setusuarioPdv: (val: string|Option[]) => void
    filtro?: boolean
    equipesPadroes?: boolean
    gerentesPadroes?: boolean
    operadoresPadroes?: boolean
    usuariosPdvMultiSelect?: Option[]
    usuariosPreMultiSelect?: Option[]
}

//carrega os gerentes / corretores por id equipe sem o empreendimento
const EquipesPrePdv = forwardRef( ({
    setusuarioPre,
    equipePre,
    setequipePre,
    gestorPre,
    setgestorPre,
    configuraExibicao,
    equipePdv,
    setequipePdv,
    empreendimento,
    gerentePvd,
    setgerentePvd,
    setusuarioPdv,
    filtro,
    equipesPadroes,
    gerentesPadroes,
    operadoresPadroes,
    usuariosPdvMultiSelect,
    usuariosPreMultiSelect
}: EquipesPrePdvProps, child: Ref<EquipesPrePdvRefType>) => {
   const [empreendimentoSelecionado, setempreendimentoSelecionado] = useState(empreendimento || null)
   const [equipes, setEquipes] = useState([] as Array<EquipeType>)
   const [gerentesPdv, setGerentesPdv] = useState<(GerenteType|GerenteEquipeType|Option)[]>([])
   const [gestoresPre, setgestoresPre] = useState<(GerenteType|GerenteEquipeType|Option)[]>([])
   const [usuariosPdv, setUsuariosPdv] = useState<(OperadorType|CorretorType|Option)[]>([])
   const [usuariosPreAtendimento, setUsuariosPreAtendimento] = useState<(OperadorType|CorretorType|Option)[]>([])
   const [opcoesEquipesPreAtendimento, setOpcoesEquipesPreAtendimento] = useState<Option[]>([])
   const [opcoesEquipesAtendimento, setOpcoesEquipesAtendimento] = useState<Option[]>([])
   const configuracaoExibicao = configuraExibicao || 'pre_pdv'
   const configsMultiSelect = {
        selectSomeItems: "Selecione",
        allItemsAreSelected: "Todos itens selecionados",
        selectAll: "Selecionar todos",
        search: "Pesquisar",
    }

  useEffect(() => {
    if(empreendimentoSelecionado){
        buscaEquipesPorEmpreendimento()
    }else{
        buscarEquipes()
        buscaGerentes()
        buscarOperadores()
    }
  }, [empreendimentoSelecionado]);

  useEffect(() => {
    montaOpcoesEquipes()
    if(equipePdv){
      buscarGerentesIdEquipe(equipePdv as string, 'A')
    }
    if(equipePre){
      buscarGerentesIdEquipe(equipePre as string, 'P')
    }
    if(gerentePvd){
      buscarCorretoresIdGerente(gerentePvd as string, 'A')
    }
    if(gestorPre){
      buscarCorretoresIdGerente(gestorPre as string, 'P')
    }
  }, [equipes])

  useImperativeHandle(child, () => ({
    mudarEmpreendimentoSelecionado(value){
        setempreendimentoSelecionado(value)
    },
    setStateEquipes(equipes) {
        setEquipes(equipes)
    },
    setStateGerentes(gerentes) {
        buscaGerentes(gerentes)
    },
    setStateOperadores(operadores) {
        buscarOperadores(operadores)
    }
  }));

  async function buscaEquipesPorEmpreendimento() {
      await trackPromise(equipeService
        .buscaMembrosEquipes(empreendimentoSelecionado)
        .then((response) => {
          if (response.data !== "") {
            let equipesPdvList = [];
            let equipesPreAtendimentoList = [];
            Object.values(response.data).forEach((equipe: any) => {
              if (equipe.tipo_equipe == "P") {
                equipesPdvList.push(equipe);
              } else {
                equipesPreAtendimentoList.push(equipe);
              }
            });

            setEquipes(response.data)
            // setEquipesPreAtendimento(equipesPreAtendimentoList);
            // setEquipesPdv(equipesPdvList);
            // setGerentePdvSelecionado(null)
            // setGerentePreAtendimentoSelecionado(null)
            // setUsuarioPdvSelecionado(null)
            // setUsuarioPreAtendimentoSelecionado(null)
           // setequipePdv('')
            setGerentesPdv([])
            setgestoresPre([])
            setUsuariosPdv([])
            setUsuariosPreAtendimento([])

          }
        })
    );
  }

  async function buscarEquipes() {
    if(equipesPadroes) return
    await trackPromise(equipeService.buscarEquipesSemEmpreendimento()
        .then((response) => {
            if (response.data !== undefined) {
                setEquipes(response.data);
            }
        }
        ).catch(error => Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar as equipes pdv: " + error,
            icon: "error"
        }))
    )

  }

  async function buscaGerentes(gerentesEquipes=[] as GerenteType[]) {
    let todosGerentes = [] as Array<GerenteType>
    let gerentes = [] as Array<GerenteType|Option>;
    let gestores = [] as Array<GerenteType|Option>;

    if(gerentesPadroes) {
        todosGerentes = gerentesEquipes
    } else {
        await trackPromise(
            equipeService.buscarGerentes().then((response) => {
                if (response.data !== undefined) {
                    todosGerentes = response.data as Array<GerenteType>
                }
            }).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao busca os gerentes/gestores: " + error,
                icon: "error"
            }))
        )
    }

    if (todosGerentes != undefined && todosGerentes.length) {
        todosGerentes.forEach((gerente) => {
          if (gerente.id_tipo_usuario == "7") {
            gerentes.push(gerente);
          } else if (gerente.id_tipo_usuario == "25") {
            gestores.push(gerente);
          }
        });
    }
    if(filtro) {
        gestores = gestores.map((gestor: any) => {
            return {
                label: gestor.nome_gerente,
                value: gestor.id_gerente
            }
        })

        gerentes = gerentes.map((gerente: any) => {
            return {
                label: gerente.nome_gerente,
                value: gerente.id_gerente
            }
        })
    }
    setgestoresPre(gestores);
    setGerentesPdv(gerentes);
  }

   async function buscarOperadores(operadores=[] as OperadorType[]) {
    let todosOperadores = [] as Array<OperadorType>
    let corretores = [] as (OperadorType|Option)[];
    let atendentes = [] as (OperadorType|Option)[];

    if(operadoresPadroes) {
        todosOperadores = operadores
    } else {
        await trackPromise(
            equipeService.buscarOperadores().then((response) => {
                if (response.data !== undefined) {
                    todosOperadores = response.data as Array<OperadorType>
                }
            }).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao busca os atendentes/corretores: " + error,
                icon: "error"
            }))
        )
    }
    if(todosOperadores != undefined && todosOperadores.length) {
        todosOperadores.forEach((atendente) => {
          if (atendente.id_tipo_usuario == "8") {
            corretores.push(atendente);
          } else if (atendente.id_tipo_usuario == "26") {
            atendentes.push(atendente);
          }
        });
      }
      if(filtro) {
        corretores = corretores.map((corretor: any) => {
            return {
                label: corretor.nome_operador,
                value: corretor.id_operador
            }
        })

        atendentes = atendentes.map((atendente: any) => {
            return {
                label: atendente.nome_operador,
                value: atendente.id_operador
            }
        })
      }
      setUsuariosPdv(corretores);
      setUsuariosPreAtendimento(atendentes);
  }

  function buscarGerentesIdEquipe(id_equipe: string, tipo_equipe: string) {
    let gerentes = [] as any[]

    equipes.map((eq) => {
        if(eq.id_equipe == id_equipe){
            gerentes = eq.gerentes
        }
    })

    if(filtro && (!id_equipe || !id_equipe.length)) {
        equipes.map(equipe => {
            if(!equipe.gerentes?.find(gerente => gerentes.map(gerente => gerente.id_usuario).includes(gerente.id_usuario)) && equipe.tipo_equipe == tipo_equipe) {
                gerentes = gerentes.concat(equipe.gerentes.map(gerente => {
                    return {
                        label: gerente.nome,
                        value: gerente.id_usuario
                    }
                }))
            }
        })
    }

    if(tipo_equipe == "P") {
        setGerentesPdv(gerentes)
    } else{
        setgestoresPre(gerentes)
    }
  }

  function buscarCorretoresIdGerente(id_gerente: string, tipo_equipe: string){
    let corretores = [] as Array<CorretorType>
    let corretoresGerenteSelecionado = [] as CorretorType[]
    let gerenteSelecionado = [] as GerenteEquipeType[]
    let corretoresGerenteSelecionadoFiltrados = new Set()
    if(tipo_equipe == 'P') {
        equipes.map((eq) => {
            if(equipePdv) {
                if(Number(eq.id_equipe) == Number(equipePdv)) {
                    eq.gerentes.map((g) => {
                        if(Number(g.id_usuario) == Number(id_gerente)) {
                            corretores = g.corretores
                        }
                    })
                }
            } else {
                gerenteSelecionado = eq.gerentes.filter(gerente => Number(gerente.id_usuario) == Number(id_gerente))
                if(gerenteSelecionado.length) {
                    corretoresGerenteSelecionado = corretoresGerenteSelecionado.concat(gerenteSelecionado[0].corretores)
                }
            }
        })
        if(filtro && (!id_gerente || !id_gerente.length)) {
            equipes.map(equipe => {
                if(equipe.tipo_equipe == tipo_equipe) {
                    equipe.gerentes.map(gerente => {
                        if(gerente.ativo) {
                            if(!gerente.corretores.find(corretor => corretores.map(corretor => corretor.id_usuario).includes(corretor.id_usuario))) {
                                corretores = corretores.concat(gerente.corretores)
                            }
                        }
                    })
                }
            })
        }
        if(corretoresGerenteSelecionado.length) {
            const corretoresFiltrados = corretoresGerenteSelecionado.filter((corretor) => {
                const corretorDuplicado = corretoresGerenteSelecionadoFiltrados.has(corretor.id_usuario);
                corretoresGerenteSelecionadoFiltrados.add(corretor.id_usuario);

                return !corretorDuplicado;
            }).filter(corretor => corretor.ativo);
            setUsuariosPdv(filtro ? corretoresFiltrados.map(corretor => {
                return {
                    label: corretor.nome,
                    value: corretor.id_usuario
                }
            }) : corretoresFiltrados)
            return
        }
        corretores = corretores.filter(corretor => corretor.ativo)
        setUsuariosPdv(filtro ? corretores.map(corretor => {
                return {
                    label: corretor.nome,
                    value: corretor.id_usuario
                }
            }) : corretores)
    }
    else {
        equipes.map((eq) => {
            if(equipePre) {
                if(Number(eq.id_equipe) == Number(equipePre)) {
                    eq.gerentes.map((g) => {
                        if(Number(g.id_usuario) == Number(id_gerente)) {
                            corretores = g.corretores
                        }

                    })
                }
            } else {
                gerenteSelecionado = eq.gerentes.filter(gerente => Number(gerente.id_usuario) == Number(id_gerente))
                if(gerenteSelecionado.length) {
                    corretoresGerenteSelecionado = corretoresGerenteSelecionado.concat(gerenteSelecionado[0].corretores)
                }
            }
        })
        if(filtro && (!id_gerente || !id_gerente.length)) {
            equipes.map(equipe => {
                if(equipe.tipo_equipe == tipo_equipe) {
                    equipe.gerentes.map(gerente => {
                        corretores = corretores.concat(gerente.corretores)
                     })
                }
            })
        }
        if(corretoresGerenteSelecionado.length) {
            const corretoresFiltrados = corretoresGerenteSelecionado.filter((corretor) => {
                const corretorDuplicado = corretoresGerenteSelecionadoFiltrados.has(corretor.id_usuario);
                corretoresGerenteSelecionadoFiltrados.add(corretor.id_usuario);

                return !corretorDuplicado;
            }).filter(corretor => corretor.ativo);
            setUsuariosPreAtendimento(filtro ? corretoresFiltrados.map(corretor => {
                return {
                    label: corretor.nome,
                    value: corretor.id_usuario
                }
            }) : corretoresFiltrados)
            return
        }
        corretores = corretores.filter(corretor => corretor.ativo)
        setUsuariosPreAtendimento(filtro ? corretores.map(corretor => {
            return {
                label: corretor.nome,
                value: corretor.id_usuario
            }
        }) : corretores)
    }
  }

  function montaOpcoesEquipes() {
    const opcoesEquipesPreAtendimento = equipes.filter(equipe => equipe.tipo_equipe === "A").map(equipe => {
        return {
            label: equipe.nome_equipe,
            value: equipe.id_equipe
        }
    })
    setOpcoesEquipesPreAtendimento(opcoesEquipesPreAtendimento)

    const opcoesEquipesAtendimento = equipes.filter(equipe => equipe.tipo_equipe === "P").map(equipe => {
        return {
            label: equipe.nome_equipe,
            value: equipe.id_equipe
        }
    })
    setOpcoesEquipesAtendimento(opcoesEquipesAtendimento)
  }

  function alteraEstadoEquipes(evento: Option[], tipo_equipe: string) {
    const equipesSelecionadas = evento.map(equipe => Number(equipe.value))
    let gestoresFiltrados = [] as Option[]
    let usuariosFiltrados = [] as Option[]
    let opcoesGerentesFiltradas = [] as Option[]
    let opcoesUsuariosFiltradas = [] as Option[]
    let gerenteSelecionadoFiltrados = new Set()
    let usuariosSelecionadoFiltrados = new Set()

    //filtra por gerentes das equipes selecionadas
    const gestoresEquipesSelecionadas = equipes.map(equipe => {
        if(equipesSelecionadas.includes(Number(equipe.id_equipe)) && tipo_equipe == equipe.tipo_equipe) {
            return equipe.gerentes
        }
    }).flat().filter((gerente) => {
        const gerenteDuplicado = gerenteSelecionadoFiltrados.has(gerente?.id_usuario);
        gerenteSelecionadoFiltrados.add(gerente?.id_usuario);

        return !gerenteDuplicado && gerente && gerente.ativo;
    })

    //filtra os gerentes da equipe de acordo com o tipo
    if((tipo_equipe == "P" ? gerentePvd.length : gestorPre.length) && Array.isArray(gerentePvd) && Array.isArray(gestorPre)) {
        const idsGestoresEquipesSelecionadas = gestoresEquipesSelecionadas.map(gerente => Number(gerente?.id_usuario))
        gestoresFiltrados = tipo_equipe == "P" ?
            gerentePvd.filter(gestor => idsGestoresEquipesSelecionadas.includes(Number(gestor.value))) :
            gestorPre.filter(gestor => idsGestoresEquipesSelecionadas.includes(Number(gestor.value)))
    }

    //forma as opções de gerentes e usuarios de acordo com o que for selecionado
    if(evento.length) {
        opcoesGerentesFiltradas = equipes.map(equipe => {
            if(equipesSelecionadas.includes(Number(equipe.id_equipe)) && tipo_equipe == equipe.tipo_equipe) {
                return equipe.gerentes.map(gerente => {
                    if(gerente.ativo) {
                        return {
                            label: gerente.nome,
                            value: gerente.id_usuario
                        }
                    }
                })
            }
        }).flat().filter(gerente => gerente) as any

        opcoesUsuariosFiltradas = gestoresEquipesSelecionadas.map(gerente => {
            if(gerente?.ativo) {
                return gerente?.corretores.map(corretor => {
                    if(corretor.ativo) {
                        return {
                            label: corretor.nome,
                            value: corretor.id_usuario
                        }
                    }
                })
            }
        }).flat() as any
    } else {
        opcoesGerentesFiltradas = equipes.map(equipe => {
            if(equipe.tipo_equipe == tipo_equipe) {
                return equipe.gerentes.map(gerente => {
                    if(gerente.ativo) {
                        return {
                            label: gerente.nome,
                            value: gerente.id_usuario
                        }
                    }
                })
            }
        }).flat().filter(gerente => gerente) as any

        opcoesUsuariosFiltradas = equipes.map(equipe => {
            if(equipe.tipo_equipe == tipo_equipe) {
                return equipe.gerentes.map(gerente => {
                    if(gerente.ativo) {
                        return gerente.corretores.map(corretor => {
                            if(corretor.ativo) {
                                return {
                                    label: corretor.nome,
                                    value: corretor.id_usuario
                                }
                            }
                        })
                    }
                })
            }
        }).flat(2).filter(usuario => usuario) as any
    }

    //retira duplicatas de usuarios
    opcoesUsuariosFiltradas = opcoesUsuariosFiltradas.filter((usuario) => {
        const usuarioDuplicado = usuariosSelecionadoFiltrados.has(usuario?.value);
        usuariosSelecionadoFiltrados.add(usuario?.value);

        return !usuarioDuplicado && usuario;
    }) as any

    //filtra pelos usuarios de acordo com equipe e gerente selecionados
    if((tipo_equipe == "P" ? usuariosPdvMultiSelect?.length : usuariosPreMultiSelect?.length) && Array.isArray(usuariosPreMultiSelect) && Array.isArray(usuariosPdvMultiSelect)) {
        const usuariosGestoresFiltrados = gestoresEquipesSelecionadas.map(gestor => {
            return gestor?.corretores.map(corretor => corretor.id_usuario)
        }).flat().filter(usuario => usuario)

        usuariosFiltrados = tipo_equipe == "P" ?
            usuariosPdvMultiSelect.filter(usuario => usuariosGestoresFiltrados.includes(usuario.value)) :
            usuariosPreMultiSelect.filter(usuario => usuariosGestoresFiltrados.includes(usuario.value))
    }

    //atualiza o valor (estado) e opcoes (estado) do multiselect de gerentes e usuarios
    if(tipo_equipe == "P") {
        setequipePdv(evento)
        setgerentePvd(gestoresFiltrados)
        setGerentesPdv(opcoesGerentesFiltradas)
        setusuarioPdv(usuariosFiltrados)
        setUsuariosPdv(opcoesUsuariosFiltradas)
    } else {
        setequipePre(evento)
        setgestorPre(gestoresFiltrados)
        setgestoresPre(opcoesGerentesFiltradas)
        setusuarioPre(usuariosFiltrados)
        setUsuariosPreAtendimento(opcoesUsuariosFiltradas)
    }
  }

  function alteraEstadoGestores(evento: Option[], tipo_equipe: string) {
    let usuariosFiltrados = [] as Option[]
    let opcoesUsuariosFiltradas = [] as Option[]
    let idsEquipesSelecionadas = [] as number[]
    let usuariosSelecionadoFiltrado = new Set()
    const idsGerentesFiltrados = evento.map(gerente => Number(gerente.value))

    if(Array.isArray(equipePdv) && Array.isArray(equipePre)) {
        idsEquipesSelecionadas = tipo_equipe == "P" ? equipePdv.map(equipe => Number(equipe.value)) : equipePre.map(equipe => Number(equipe.value))
    }
    if(idsGerentesFiltrados.length) {
        //filtra pelos gerentes selecionados
        const idsUsuariosGerentesFiltrados = equipes.map(equipe => {
            if((idsEquipesSelecionadas.length ? idsEquipesSelecionadas.includes(Number(equipe.id_equipe)) : true) && equipe.tipo_equipe == tipo_equipe) {
                return equipe.gerentes.map(gerente => {
                    if(gerente.ativo) {
                        return gerente.corretores.map(corretor => {
                            if(corretor.ativo) {
                                return corretor.id_usuario
                            }
                        })
                    }
                })
            }
        }).flat(2).filter(id_usuario => id_usuario)

        if(Array.isArray(usuariosPdvMultiSelect) && Array.isArray(usuariosPreMultiSelect)) {
            usuariosFiltrados = tipo_equipe == "P" ?
                usuariosPdvMultiSelect.filter(usuario => idsUsuariosGerentesFiltrados.includes(usuario.value)) :
                usuariosPreMultiSelect.filter(usuario => idsUsuariosGerentesFiltrados.includes(usuario.value))
        }

    }
    opcoesUsuariosFiltradas = equipes.map(equipe => {
        console.log(idsGerentesFiltrados)
        if(((idsEquipesSelecionadas.length ? idsEquipesSelecionadas.includes(Number(equipe.id_equipe)) : true) || !idsEquipesSelecionadas) && equipe.tipo_equipe == tipo_equipe) {
            return equipe.gerentes.map(gerente => {
                if((idsGerentesFiltrados.length ? idsGerentesFiltrados.includes(Number(gerente.id_usuario)) : true) && gerente.ativo) {
                    return gerente.corretores.map(corretor => {
                        if(corretor.ativo) {
                            return {
                                label: corretor.nome,
                                value: corretor.id_usuario
                            }
                        }
                    })
                }
            })
        }
    }).flat(2).filter((usuario) => {
        const usuarioDuplicado = usuariosSelecionadoFiltrado.has(usuario?.value);
        usuariosSelecionadoFiltrado.add(usuario?.value);

        return !usuarioDuplicado && usuario;
    }) as Option[]


    //atualiza o valor (estado) e opcoes (estado) do multiselect de usuarios
    if(tipo_equipe == "P") {
        setgerentePvd(evento)
        setusuarioPdv(usuariosFiltrados)
        setUsuariosPdv(opcoesUsuariosFiltradas)
    } else {
        setgestorPre(evento)
        setusuarioPre(usuariosFiltrados)
        setUsuariosPreAtendimento(opcoesUsuariosFiltradas)
    }
  }


  return (<>
    {
        configuracaoExibicao == 'pre_pdv' || configuracaoExibicao == 'pre' ?
        <Row className='form-first-row'>
            <Form.Group as={Col} lg="4" md="6">
                <Form.Label id="label-equipe-pre">Equipe Pré Atendimento: </Form.Label>
                {filtro ? (
                    <MultiSelect
                        options={opcoesEquipesPreAtendimento}
                        value={equipePre as Option[]}
                        labelledBy="label-equipe-pre"
                        overrideStrings={configsMultiSelect}
                        onChange={(evento: Option[]) => alteraEstadoEquipes(evento, 'A')}
                        className="multiselect-topo"
                    />
                ) : (
                    <Form.Control
                        as="select"
                        className="form-control"
                        custom
                        onChange={(event) =>{
                            setequipePre(event.target.value)
                            buscarGerentesIdEquipe(event.target.value, 'A')
                        }}
                    >
                    <option hidden value="0">Selecione</option>
                        {
                            equipes.length > 0 ?
                            equipes.map((equipe) => (
                                equipe.tipo_equipe == 'A' ?
                                <option
                                    key={equipe.id_equipe}
                                    value={equipe.id_equipe}
                                >
                                    {equipe.nome_equipe}
                                </option> : <></>
                            ))  :
                            <OptionCarregando />
                        }
                    </Form.Control>
                )}
            </Form.Group>
            <Form.Group as={Col} lg="4" md="6">
            <Form.Label id="label-gestor-pre">Gestor Pré Atendimento: </Form.Label>
                {filtro ? (
                        <MultiSelect
                            options={gestoresPre as Option[]}
                            value={gestorPre as Option[]}
                            labelledBy="label-gestor-pre"
                            overrideStrings={configsMultiSelect}
                            onChange={(evento: Option[]) => alteraEstadoGestores(evento, 'A')}
                            className="multiselect-topo"
                        />
                    ) : (
                    <Form.Control
                        as="select"
                        className="form-control"
                        custom
                        onChange={(event) => {
                            setgestorPre(event.target.value)
                            buscarCorretoresIdGerente(event.target.value, 'A')
                        }}
                    >
                    <option hidden value="0">Selecione</option>
                        {
                            gestoresPre.length > 0 ?
                            gestoresPre.map((gerente: any) => (
                                <option
                                    key={gerente.id_usuario ? gerente.id_usuario : gerente.id_gerente}
                                    value={gerente.id_usuario ? gerente.id_usuario : gerente.id_gerente}
                                >
                                    {gerente.nome ? gerente.nome : gerente.nome_gerente}
                                </option>
                            )) :
                            <OptionCarregando />
                        }
                    </Form.Control>
                    )
                }
            </Form.Group>
            <Form.Group as={Col} lg="4" md="6">
            <Form.Label>Atendente: </Form.Label>
            {filtro ? (
                <MultiSelect
                    options={usuariosPreAtendimento as Option[]}
                    value={usuariosPreMultiSelect as Option[]}
                    labelledBy="label-gerente-pdv"
                    overrideStrings={configsMultiSelect}
                    onChange={setusuarioPre}
                    className="multiselect-topo"
                />
            ) : (
                <Form.Control
                    as="select"
                    className="form-control"
                    custom
                    onChange={(event) => {
                        setusuarioPre(event.target.value)
                    }}
                >
                <option hidden value="0">Selecione</option>
                    {
                        usuariosPreAtendimento.length > 0 ?
                        usuariosPreAtendimento.map((corretor: any) => (
                            <option
                                key={corretor.id_usuario ? corretor.id_usuario : corretor.id_operador}
                                value={corretor.id_usuario ? corretor.id_usuario : corretor.id_operador}
                            >
                                {corretor.nome ? corretor.nome : corretor.nome_operador}
                            </option>
                        )) :
                        <OptionCarregando />
                    }
                </Form.Control>

            )}
            </Form.Group>
        </Row> : <></>
    }
    {
        configuracaoExibicao == 'pre_pdv' || configuracaoExibicao == 'pdv' ?
        <Row>
            <Form.Group as={Col} lg="4" md="6">
                <Form.Label id="label-equipe-pdv">Equipe PDV: </Form.Label>
                {filtro ? (
                    <MultiSelect
                        options={opcoesEquipesAtendimento}
                        value={equipePdv as Option[]}
                        labelledBy="label-equipe-pdv"
                        overrideStrings={configsMultiSelect}
                        onChange={(evento: Option[]) => alteraEstadoEquipes(evento, 'P')}
                        className="multiselect-topo"
                    />
                ) : (
                    <Form.Control
                        as="select"
                        className="form-control"
                        custom
                        onChange={(event) =>{
                            setequipePdv(event.target.value)
                            buscarGerentesIdEquipe(event.target.value, 'P')
                        }}
                    >
                    <option hidden value="0">Selecione</option>
                        {
                            equipes.length > 0 ?
                            equipes.map((equipe) => (
                                equipe.tipo_equipe == 'P' ?
                                <option
                                    key={equipe.id_equipe}
                                    value={equipe.id_equipe}
                                >
                                    {equipe.nome_equipe}
                                </option> : <></>
                            )) : (
                                <OptionCarregando />
                            )
                        }
                    </Form.Control>
                )
            }
            </Form.Group>
            <Form.Group as={Col} lg="4" md="6">
            <Form.Label id="label-gerente-pdv">Gerente PDV: </Form.Label>
            {filtro ? (
                <MultiSelect
                    options={gerentesPdv as Option[]}
                    value={gerentePvd as Option[]}
                    labelledBy="label-gerente-pdv"
                    overrideStrings={configsMultiSelect}
                    onChange={(evento: Option[]) => alteraEstadoGestores(evento, 'P')}
                    className="multiselect-topo"
                />
                ) : (
                <Form.Control
                    as="select"
                    className="form-control"
                    custom
                    onChange={(event) => {
                        setgerentePvd(event.target.value)
                        buscarCorretoresIdGerente(event.target.value, 'P')
                    }}
                >
                <option hidden value="0">Selecione</option>
                    {
                        gerentesPdv.length > 0 ?
                        gerentesPdv.map((gerente: any) => (
                            <option
                                key={gerente.id_usuario ? gerente.id_usuario : gerente.id_gerente}
                                value={gerente.id_usuario ? gerente.id_usuario : gerente.id_gerente}
                            >
                                {gerente.nome ? gerente.nome : gerente.nome_gerente}
                            </option>
                        )) :
                        <OptionCarregando />
                    }
                </Form.Control>
                )
            }
            </Form.Group>
            <Form.Group as={Col} lg="4" md="6">
                <Form.Label id="label-corretor-equipe">Corretor: </Form.Label>
                {filtro ? (
                    <MultiSelect
                        options={usuariosPdv as Option[]}
                        value={usuariosPdvMultiSelect as Option[]}
                        labelledBy="label-corretor-equipe"
                        overrideStrings={configsMultiSelect}
                        onChange={setusuarioPdv}
                        className="multiselect-topo"
                    />
                ) : (
                    <Form.Control
                        as="select"
                        className="form-control"
                        custom
                        onChange={(event) => {
                            setusuarioPdv(event.target.value)
                        }}
                    >
                    <option hidden value="0">Selecione</option>
                        {
                            usuariosPdv.length > 0 ?
                            usuariosPdv.map((corretor: any) => (
                                <option
                                    key={corretor.id_usuario ? corretor.id_usuario : corretor.id_operador}
                                    value={corretor.id_usuario ? corretor.id_usuario : corretor.id_operador}
                                >
                                    {corretor.nome ? corretor.nome : corretor.nome_operador}
                                </option>
                            )) : (
                                <OptionCarregando />
                            )
                        }
                    </Form.Control>
                )}
            </Form.Group>
        </Row> : <></>
    }
  </>);
})

export default EquipesPrePdv;
