import React, {useContext, useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import {GrupoQualificacaoContext} from '../../contexts/GruposQualificacaoContext/GrupoQualificacaoContext';
import {CamposDinamicosContext} from '../../contexts/CamposDinamicosContext/CamposDinamicosContext';

export default function InteresseCadastroView({interesse, empreendimento}){

  const {mapaGruposQualificacao, recarregarGruposQualificacao} = useContext(GrupoQualificacaoContext);
  const {mapaCamposDinamicos, recarregarCamposDinamicos} = useContext(CamposDinamicosContext);
  const [dadosSemGrupo, setDadosSemGrupo] = useState([]);
  const [gruposComDados, setGruposComDados] = useState([]);
  useEffect(() => {
    if(interesse){
      recarregarGruposQualificacao();
      recarregarCamposDinamicos();
    }
  }, [interesse]);

  useEffect(() => {
    if(mapaCamposDinamicos && mapaCamposDinamicos.size){
      const dadosDinamicos = interesse.dados_dinamicos && JSON.parse(interesse.dados_dinamicos) || {};
      const novoDadosSemGrupo = [];
      const novoGruposComDados = [];
      const novoMapaGruposComDados = new Map();
      for(let chave in dadosDinamicos ){
        if(mapaCamposDinamicos.has(Number(chave))){
          let campoDinamico = mapaCamposDinamicos.get(Number(chave));
          if(campoDinamico.empreendimentos_vinculados &&
            campoDinamico.empreendimentos_vinculados.some(
              (vinculado) => Number(vinculado?.value) === Number(empreendimento))
            ){
              let valorReal = ""
              try{
                valorReal = JSON.parse(dadosDinamicos[chave])
              }catch(error){
                if(dadosDinamicos[chave] && (typeof dadosDinamicos[chave] === 'string')){
                  valorReal = dadosDinamicos[chave];
                }
              }

              let valorRealIsArray = Array.isArray(valorReal);
              if(valorReal && ((!valorRealIsArray) || valorReal.length) ){

                const dadoCampo = {
                  rotulo: campoDinamico.label,
                  valor: (Array.isArray(valorReal) && valorReal.reduce((retorno, atual) => {
                    return (atual.label && ((retorno.length && retorno + " | " + atual.label) || atual.label)) || retorno;
                  }, "")) || valorReal
                };

                if(campoDinamico.id_grupo_campos_dinamicos){
                  let idGrupo = Number(campoDinamico.id_grupo_campos_dinamicos);
                  if(!novoMapaGruposComDados.has(idGrupo)){
                    novoMapaGruposComDados.set(idGrupo, [dadoCampo])
                  }else{
                    const novoArrayGrupo = novoMapaGruposComDados.get(idGrupo).slice();
                    novoArrayGrupo.push(dadoCampo);
                    novoMapaGruposComDados.set(idGrupo, novoArrayGrupo);
                  }
                }else{
                  novoDadosSemGrupo.push(dadoCampo);
                }
              }
          }
        }
      }
      for(let [chave, campos] of novoMapaGruposComDados){
        if(mapaGruposQualificacao.has(chave) && campos.length){
          let grupoQualificacao = mapaGruposQualificacao.get(chave);
          const objetoGrupo = {
            rotulo: grupoQualificacao.descricao,
            campos: campos
          };
          novoGruposComDados.push(objetoGrupo);
        }
      }
      setDadosSemGrupo(novoDadosSemGrupo);
      setGruposComDados(novoGruposComDados);
    }
  }, [interesse, mapaCamposDinamicos])



  return   <div className="pessoas-wrapper">
      <div className="slick-slider pessoa-carousel">
        <div className="item-slide">
          <div className="pessoa">
            <div className="info-contato">
              <div className="dados">
                <header>Interesse</header>
                <div className="data">
                  <div className="item">
                    <span className="telres">
                      <strong>Origem: </strong>
                      {(interesse && interesse.origem && interesse.origem.origem) ||
                        "Não selecionado"}
                    </span>
                    <span className="celular">
                      <strong>Temperatura: </strong>
                      {(interesse && interesse.nivelInteresse && interesse.nivelInteresse.descricao) ||
                        "Não selecionado"}
                    </span>
                    {
                      (dadosSemGrupo && dadosSemGrupo.length &&
                       dadosSemGrupo.map((campo, indice) => <span key={indice}>
                          <strong>{campo.rotulo}: </strong> {campo.valor}
                        </span>)) || ""
                    }
                  </div>
                </div>
              </div>
              {
                (gruposComDados && gruposComDados.length &&
                   gruposComDados.map((grupo, indice) => <div className="dados" key={indice}>
                     <header>{grupo.rotulo}</header>
                     <div className="data">
                       <div className="item">
                         {
                           (grupo.campos && grupo.campos.length &&
                            grupo.campos.map((campo, indice) => <span key={indice}>
                               <strong>{campo.rotulo}: </strong> {campo.valor}
                             </span>)) || ""
                         }
                       </div>
                     </div>
                   </div>)) || ""
              }
            </div>
            <div className="info-endereco">
              <div className="dados">
                <header>Lead Scoring</header>
                <div className="data">
                  <Alert variant="warning">
                    Nenhum parâmetro de pontuação configurado
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}
