import React from 'react';
import './App.css';
import './assets/poppins/poppins.css';
import 'dotenv';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'intl-tel-input/build/css/intlTelInput.css';
//import './assets/fontawesome/all.css'

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";
import Widgets from './components/Widgets';

function App() {

	return (
		<>
		<BrowserRouter>
			<DommusPromiseLoading/>
			<Routes/>
		</BrowserRouter>
		<Widgets />
		</>
	);
}

export default App;
