import React, { useState, useEffect, useContext, memo, useMemo, useCallback } from "react";
import { Col, Row, Dropdown, Badge } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Drilldown from 'highcharts/modules/drilldown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import guService from "../../../../services/GuService";
import Swal from "sweetalert2";
import {
  faComment,
  faEdit,
  faPen,
  faEnvelope,
  faExclamationCircle,
  faHome,
  faPhone,
  faSuitcase,
  faTrashAlt,
  faBuilding,
  faChevronDown,
  faSortUp,
  faChevronUp,
  faBars,
  faPlus,
  faTags
} from "@fortawesome/free-solid-svg-icons";

import { celularMask } from "../../../../components/InputTextMask";
// npm install --save-dev @iconify-icons/fluent
import MidiaSocialIcone from "../../../../components/MidiaSocialIcone";
// import CustomEvents from "highcharts-custom-events";
import DommusToggle from "../../../../components/DommusToggle";
import formatDommus from "../../../../helpers/format";
import OportunidadeService from "../../../../services/OportunidadeService";
import DommusTabelaPaginadaBack from "../../../../components/DommusTabelaPaginadaBack";
import swal1 from "sweetalert";
import ReactTooltip from "react-tooltip";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../../components/DommusToast";
import EditarInteresse from "../../ListaOportunidades/ColunaOportunidade/OportunidadeCard/EditarInteresse";
import "./index.css";
import { format, parseISO } from "date-fns";
import ModalDommus from "../../../../components/Modal";
// CustomEvents(Highcharts);
import CalcularSLA from "../../ListaOportunidades/ColunaOportunidade/OportunidadeCard/CalcularSLA";
import { OportunidadeContext } from "../../../../components/GlobalContext/globalContext";
import { NivelInteresseType } from "../../../../components/FormFiltroPadrao/types";
import { MidiaType } from "../../../DetalheOportunidade/Cadastro/types";
import { opcoesGrafico } from "./opcoesGrafico";
import {AtendimentoType} from "./../OportunidadesListaPaginada/types/AtendimentoType";
import NovoAtendimento from "./../../../DetalheOportunidade/Atendimento/NovoAtendimento/"
import { OportunidadePorStatusPaginadoType } from "./types";
import moment from "moment";
import Status from "../../../DetalheOportunidade/Status";
import { DistributionForm } from "../../../DetalheOportunidade/Cadastro/DistributionForm";
import { NivelInteresseIcone } from "../OportunidadesListaPaginada/NivelInteresseIcone";
import { TagOportunidadeFormulario } from "../../../../components/TagsFormulario";
import { TagsOportunidade } from "../../../../components/TagsOportunidade";
import { FunilContext } from "../../../../contexts/FunilContext";
import DommusSwitch from "../../../../components/DommusSwitch";
import perfilUsuarioService from "../../../../services/PerfilUsuarioService";
import { swalErro } from "../../../../helpers/DommusSwal";
import ModalFullScreenOportunidade from "../../../../components/ModalFullScreenOportunidade";
import DetalheOportunidade from "../../../DetalheOportunidade";

interface FunilOportunidadesProps {
  interesses: Array<NivelInteresseType>
}

export function parseSla(sla: string) {
  if(!sla) return 3 * 24 * 60
  let arrSla = sla.split(', ')
  let day = parseInt(arrSla[0].split('d')[0])
  let hour = (arrSla[1] && parseInt(arrSla[1].split('h')[0])) || 0
  let min = (arrSla[2] && parseInt(arrSla[2].split('m')[0])) || 0

  return (day * 24 * 60) + (hour * 60) + min;
}



export default function FunilOportunidades({ interesses }: FunilOportunidadesProps) {
  const [linhas, setLinhas] = useState([]);
  const [page, setPage] = useState(1);
  const [tamanhoPagina, setTamanhoPagina] = useState(8);
  const [total, setTotal] = useState();
  const [oportunidadeSelecionada, setOportunidadeSelecionada] = useState({} as OportunidadePorStatusPaginadoType);
  const [openModal, setOpenModal] = useState(false);
  const [interesseSelecionado, setInteresseSelecionado] = useState("");
  const [opvTipo, setOpvTipo] = useState(null as string | null);
  const globalOportunidadeContext = useContext(OportunidadeContext)
  const [currentEtapaId, setCurrentEtapaId] = useState(0)
  const [openModalEmpreendimento, setOpenModalEmpreendimento] = useState(false)
  const [idOportunidadeState,setIdOportunidade] = useState<number>(0);
  const [statusState, setStatusState] = useState({id_oportunidade:0,status_id_oportunidade:0,id_substatus:0})
  const [openStatus, setOpenStatus] = useState(false)
  const [openModalTagsOportunidade,setOpenModalTagsOportunidade] = useState<boolean>(false);
  const [showLabelFunil, setShowLabelFunil] = useState(true);
  const {listaFunis} = useContext(FunilContext);
  const [possuiExpiracaoDesistencia, setPossuiExpiracaoDesistencia] = useState(true)
  const [openModalOportunidade, setOpenModalOportunidade] = useState<boolean>(false);

  const abrirOportunidadeModal = useMemo(()=>{
      return guService.getLocalState('abrirOportunidadeModal') === "S";
  },[])

  function filtrar(sort: string, tipo: string) {
    let meusFiltros = guService.getLocalState("filtros")
      ? JSON.parse(guService.getLocalState("filtros") || "")
      : "";
    if (meusFiltros.oportunidade != undefined) {
      meusFiltros.oportunidade.expirados_desistencias =
        globalOportunidadeContext.checked === false ? true : false;
      meusFiltros.oportunidade.tipo_ordenacao = sort;
      meusFiltros.oportunidade.forma_ordenacao = tipo;
    } else {
      meusFiltros = {
        oportunidade: {
          expirados_desistencias: globalOportunidadeContext.checked === false ? true : false,
          tipo_ordenacao: sort,
          forma_ordenacao: tipo,
        },
      };
    }

    globalOportunidadeContext.buscarFiltroFunil(meusFiltros);
  }

  async function abreOportunidade(oportunidade: OportunidadePorStatusPaginadoType) {
    if(abrirOportunidadeModal){
      setOpenModalOportunidade(true);
      setOportunidadeSelecionada(oportunidade);
    }else{
      window.open(`/oportunidade/${oportunidade.id_oportunidade}`);
    }
  }

  const editEmpreendimento = (oportunidade:OportunidadePorStatusPaginadoType) => {
    setOpenModalEmpreendimento(true)
    setOportunidadeSelecionada(oportunidade);
  }

  const irParaAtendimento = (oportunidade:OportunidadePorStatusPaginadoType) => {
    if(abrirOportunidadeModal){
      setOpenModalOportunidade(true);
      setOportunidadeSelecionada(oportunidade);
    }else{
      window.open(`/oportunidade/${oportunidade.id_oportunidade}?aba=atendimento`);
    }
  }

  const [openModalNovoAtendimento,setOpenModalNovoAtendimento] = useState<boolean>(false);
  const [novoAtendimento,setNovoAtendimento] = useState<AtendimentoType>({id_oportunidade:0,lead_nome:''});

  //Todo: Abre o modal para registro de um novo atendimento
  const abrirModalNovoAtendimento = (id_oportunidade:number,lead_nome:string) =>  {
    setOpenModalNovoAtendimento(true)
    setNovoAtendimento({id_oportunidade,lead_nome});
  }

  const handleAlterarEtapa = (id_oportunidade:number, status_id_oportunidade: number, id_substatus:any) => {
    setStatusState({id_oportunidade, status_id_oportunidade, id_substatus})
    setOpenStatus(true)
  }

  // const atualizarOportunidade = useCallback((oportunidadeAtualizada:OportunidadePorStatusPaginadoType)=>{
  // setOportunidades((current)=>{
  //     return current.map((oportunidade)=>{
  //         if(oportunidade.id_oportunidade === oportunidadeAtualizada.id_oportunidade){
  //             return oportunidadeAtualizada;
  //         }
  //         return oportunidade;
  //     }) as OportunidadePorStatusPaginadoType[]
  // });
  // },[]);

  function alterarVisualizacaoFunilPerfil(chave:string, value:string){
    let payload = {
      [chave]: value
    }
    trackPromise(perfilUsuarioService.atualizarConfiguracao({id_usuario: guService.getLocalState('idUsuario'), perfil: payload}))
      .then(() => {
        guService.setLocalState(chave, value);
      })
      .catch(erro => swalErro(`Houve um erro ao tentar alterar dados da visualização. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`))
  }

  function buscaOportunidades(id:number) {
    trackPromise(
      OportunidadeService.buscarOportunidadesPorStatusPaginado(
        id,
        8,
        page,
        globalOportunidadeContext.filtroFunil
      ).then(
        (response) => {
          let novasLinhas = [];
          if(response.data.lista && Array.isArray(response.data.lista)){
             novasLinhas = response.data.lista.map((lista:OportunidadePorStatusPaginadoType) => {
              function buscaInteresse() {
                let Interesse = undefined;
                if (lista.interesse?.length && interesses && Array.isArray(interesses)) {
                  interesses.map((interesse) => {
                    if(interesse?.id_nivel_interesse === lista.interesse[0]?.id_nivel_interesse) {
                      Interesse = interesse?.descricao.toString();
                    }
                  });
                }
                return Interesse;
              }
              
              let countLeadsUnificados = lista.leads_oportunidade.filter((element)=>element.ativo == '1')?.length || 0;
              return (
                <>
                  <ReactTooltip />
                  <tr key={lista.id_oportunidade}>
                    <td>
                      <div
                        className="midiaIndice"
                        onClick={() => {
                          abreOportunidade(lista);
                        }}
                      >
                        <MidiaSocialIcone
                          midia={{
                            descricao: lista.midia_descricao,
                            icone: lista.midia_icone,
                            cor: lista.midia_cor,
                          } as MidiaType}
                        />
                      </div>
                      <div
                        className="funilOportunidadeID"
                        onClick={() => {
                          abreOportunidade(lista);
                        }}
                      >
                        {formatDommus.zeroPad(lista.id_oportunidade, 5)}
                      </div>
                    </td>
                    <td>
                      <div
                        className="funilOportunidadeNome"
                        onClick={() => {
                          abreOportunidade(lista);
                        }}
                      >
                        {lista.envolvido_nome}
                        {countLeadsUnificados > 1 &&
                            <div
                                className="lead-unificado"
                                data-tip={
                                countLeadsUnificados +
                                " Leads Unificados"
                                }
                            >
                                {countLeadsUnificados}
                            </div>
                        }
                      </div>
                      <div className="funilOportunidadeEmail">
                        {lista.lead_email}
                      </div>
                      <div className="funilOportunidadeTelefone">
                        {celularMask(lista.lead_telefone)}
                      </div>
                        <div
                          onClick={(e:any) => {editEmpreendimento(lista)}}
                          >
                          <FontAwesomeIcon
                            icon={faBuilding}
                            style={{ marginRight: "5px" }}
                            color="#444444"
                          />
                          {lista.nome_empreendimento
                          ? lista.nome_empreendimento.length > 28
                          ? lista.nome_empreendimento.substring(0, 27) + "..."
                          : lista.nome_empreendimento
                          : "Não atribuído"}
                      </div>
                      <TagsOportunidade tags={lista.tags_oportunidade ?? []} maxTags={3} />
                    </td>
                    <td>
                      <div>
                        <FontAwesomeIcon
                          icon={faSuitcase}
                          color="#444444"
                          style={{ marginRight: 5 }}
                        />
                        {lista.atendente
                          ? lista.atendente.length > 14
                            ? lista.atendente.substring(0, 14) + "..."
                            : lista.atendente
                          : "Não atribuído"}
                      </div>
                      <div className="funilOportunidadeicones">
                      {lista.usuario_pode_editar && 
                        <div onClick={() => abrirModalNovoAtendimento(lista.id_oportunidade,lista.lead_nome)}>
                          <FontAwesomeIcon icon={faPlus} color="#4DAF50"/>
                        </div>
                      }
                        {lista.pendencias > 0 ? (
                          <FontAwesomeIcon
                            data-tip={
                              lista.pendencias +
                              " Pendência" +
                              (lista.pendencias > 1 ? "s" : "")
                            }
                            icon={faExclamationCircle}
                            color="#444444"
                          />
                        ) : (
                          <></>
                        )}
                        {lista.visit > 0 ? (
                          <div onClick={() => irParaAtendimento(lista)} role="button">
                            <FontAwesomeIcon data-tip={lista.visit + " Visita" + (lista.visit > 1 ? "s" : "")} icon={faHome} color="#444444"/>
                          </div>
                        ) : (
                          <></>
                        )}
                        {lista.phone > 0 ? (
                         <div onClick={() => irParaAtendimento(lista)} role="button">
                           <FontAwesomeIcon
                            data-tip={
                              lista.phone +
                              " Telefonema" +
                              (lista.phone > 1 ? "s" : "")
                            }
                            icon={faPhone}
                            color="#444444"
                          />
                         </div>
                        ) : (
                          <></>
                        )}
                        {lista.mail > 0 ? (
                          <div onClick={() => irParaAtendimento(lista)} role="button">
                            <FontAwesomeIcon data-tip={lista.mail + " Mensage" + (lista.mail > 1 ? "ns" : "m")} icon={faEnvelope} color="#444444"/>
                          </div>
                        ) : (
                          <></>
                        )}
                        {lista.comment > 0 ? (
                          <div onClick={() => irParaAtendimento(lista)} role="button">
                            <FontAwesomeIcon icon={faComment} data-tip={lista.comment +" Comentário" + (lista.comment > 1 ? "s" : "")} color="#444444"/>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {lista.atendimentos.length ? (
                        <Badge
                          variant="info"
                          style={{fontSize: '9pt'}}
                          data-tip={`Visita a ser realizada dia ${moment(lista.atendimentos[0].data).format('DD/MM')} as ${lista.atendimentos[0].hora?.substring(0, 5)} horas`}
                          onClick={() => irParaAtendimento(lista)}
                          role="button"
                        >
                          <FontAwesomeIcon icon='calendar-check'/> {`${moment(lista.atendimentos[0].data).format('DD/MM')} ${lista.atendimentos[0].hora?.substring(0, 5)}`}
                        </Badge>
                      ) : <></>}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        alignItems: "center !important",
                        justifyContent: "center !important",
                      }}
                    >
                      {buscaInteresse() != undefined ? (
                        <NivelInteresseIcone
                            interesses={interesses}
                            oportunidade={lista}
                            setInteresseSelecionado={setInteresseSelecionado}
                            setOpenModal={setOpenModal}
                            setOportunidadeSelecionada={setOportunidadeSelecionada}
                        />                        
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <div className="funilOportunidadeData">
                        {format(
                          parseISO(lista.atualizado_em),
                          "dd/MM/yyyy' às  ' HH:mm"
                        )}
                      </div>
                      <div className="badgeTempo">
                        <CalcularSLA
                          date={lista.atualizado_em}
                          sla={parseSla(lista.sla || "")}
                          sla_expira={parseSla(lista.sla_expira || "")}
                        />
                      </div>
                      <div
                        className={`badgeEtapa ${!lista.usuario_pode_editar && "usuario-nao-permitido"}`}
                        style={{ backgroundColor: `${lista.etapa_cor}`, cursor: `${lista.usuario_pode_editar ? "pointer" : "usuario-nao-permitido"}` }}
                        onClick={(e:any) => {
                          if(lista?.usuario_pode_editar){
                            handleAlterarEtapa(lista.id_oportunidade, lista.id_status_oportunidade, lista.id_substatus_oportunidade)
                          }
                        }}
                      >
                        {lista.etapa_nome.length > 25
                          ? lista.etapa_nome.substr(0, 25) + "..."
                          : lista.etapa_nome}
                      </div>
                    </td>
                    <td>
                      <div className="funilOportunidadeOpcoes">
                        <Dropdown>
                          <Dropdown.Toggle className={`funilOportunidadeDropdown ${!lista?.usuario_pode_editar && "usuario-nao-permitido"}`} disabled={!lista.usuario_pode_editar}>
                            <FontAwesomeIcon icon={faPen} color="#fff" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                abreOportunidade(lista);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                color="#B5DEFF"
                                style={{ marginRight: 10 }}
                              />
                              Editar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                setOportunidadeSelecionada(lista)
                                setOpenModalTagsOportunidade(true)
                            }}>
                                <FontAwesomeIcon
                                    icon={faTags}
                                    color='#118ab2'
                                    style={{marginRight:10}}
                                />
                                Alterar Etiquetas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                removerOportunidade(lista.id_oportunidade);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                color="#E05D5D"
                                style={{ marginRight: 10 }}
                              />
                              Remover
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })
          }
          setLinhas(novasLinhas);

          setTotal(response.data.count || 0);
        },
        (error) => {
          swal1(
            "Ooops...",
            "Ocorreu um erro ao buscar as oportunidades: " + error.data,
            "error"
          );
        }
      )
    );
  }

  function setTabelaData(event: string) {
    globalOportunidadeContext.colunas.map((coluna, index) => {
      if (coluna.nome == event) {
        setPage(1)
        setCurrentEtapaId(coluna.id_status_oportunidade)
        buscaOportunidades(coluna.id_status_oportunidade);
        globalOportunidadeContext.setTitulo(event);
      }
    });
  }

  const removerOportunidade = (id_oportunidade: number) => {
    Swal.fire({
      titleText: "Deseja excluir a oportunidade " + id_oportunidade + "?",
      text:
        "Tem certeza que deseja excluir a oportunidade " +
        id_oportunidade +
        "? Os dados desta não serão mais visíveis no sistema.",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não, mantenha.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        trackPromise(
          OportunidadeService.excluirOportunidade(id_oportunidade).then(
            (response) => {},
            (error) => {
              errorToast.fire({
                text: "Ocorreu um erro ao excluir a oportunidade: " + error,
              });
            }
          )
        );
      }
    });
  };

  Drilldown(Highcharts);

  const makeSeriesConfig = (values: any) => {

    let funilId = parseInt(guService.getLocalState("idFunil") ?? "0");

    if(listaFunis.length){
      let funilSelecionado = listaFunis.find((funil)=>{return funil.id_funil === funilId});
      if(funilSelecionado){
        let etapasFunil = funilSelecionado?.funil_status_oportunidade?.map((etapas)=> etapas?.id_status_oportunidade);
        let tiposEtapas = funilSelecionado?.funil_status_oportunidade?.map((etapas)=> {return etapas?.status_oportunidade?.tipo});
        if(tiposEtapas?.includes('D') ||  tiposEtapas?.includes('E')){
          setPossuiExpiracaoDesistencia(true);
          if(!globalOportunidadeContext.checked){            
            values = values.filter((etapa:any)=>{
              return !['D', 'E'].includes(etapa.tipo);
            })
          }
        }else{
          setPossuiExpiracaoDesistencia(false);
        }
        if(etapasFunil?.length){
          values = values.filter((item:any)=>etapasFunil?.includes(item.idEtapa));
        }
      }
    }
    
    var max_value = null as any;
    values.forEach(function (v: any) {
      if (!max_value || max_value < v.value) {
        max_value = v.value;
      }
    });
    const padding = {
      name: "padding",
      dataLabels: {
        enabled: false,
      },
      data: [] as any,
    };
    const dadosPrincipais = {
      name: "Leads",
      width: "auto",
      height: "auto",
      fill: "none",
      cursor: "pointer",
      events: {
        click: function (event: any) {
          setTabelaData(event.point.name);
        },
      },
      data: [] as any,
      dataLabels: {
        className: "NumeroLabel",
        enabled: true,
        color: "#FFFF",
      },
    };
    values.forEach((v: any) => {
      var w = (max_value - v.value) / 2;
      padding.data.push({
        name: v.name,
        y: w,
        color: "white",
      });

      dadosPrincipais.data.push({
        name: v.name,
        y: v.value,
        color: v.color,
        drilldown: v.name,
      });
    });

    return [padding, dadosPrincipais, padding];
  };

  const makeSeriesDrilldown = (values:any)=>{

    const dados = [] as any;

    Array.isArray(values) && values.forEach(function(element:any) {
      const dadosPrincipais = {
        id: element.categoria,
        name: element.categoria,
        width: "auto",
        height: "auto",
        fill: "none",
        cursor: "pointer",
        events: {
          click: function (event: any) {
            console.log(event.point.name);
            // setTabelaData(event.point.name);
          },
        },
        data: [] as any,
        dataLabels: {
          className: "NumeroLabel",
          enabled: true,
          color: "#666",
        },
      };
      element.data.forEach((v: any) => {
        dadosPrincipais.data.push({
          id: v.name,
          name: v.name,
          y: v.value,
        });
      });
      if(element.data.length != 0){
        dados.push(dadosPrincipais);
      }
    });
    return dados;
  }

  const thead = (
    <tr>
      <th
        style={{ cursor: "pointer", justifyContent: "space-between" }}
        onClick={() => {
          let formaOrdenacao = opvTipo === "asc" ? "desc" : "asc";
          setOpvTipo(formaOrdenacao);
          filtrar("oportunidade", formaOrdenacao);
        }}
      >
        Nº OPV
        <FontAwesomeIcon className="iconeOrdem" color="#fff" icon={opvTipo == "asc" ? faSortUp : 'sort-down'} />
      </th>
      <th
        style={{ cursor: "pointer", justifyContent: "space-between" }}
        onClick={() => {
          let formaOrdenacao = opvTipo === "asc" ? "desc" : "asc";
          setOpvTipo(formaOrdenacao);
          filtrar("lead", formaOrdenacao);
        }}
      >
        Dados do Lead
        <FontAwesomeIcon className="iconeOrdem" color="#fff" icon={opvTipo == "asc" ? faSortUp : 'sort-down'} />
      </th>
      <th
        style={{
          width: "20%",
          maxWidth: "20%",
          cursor: "pointer",
          justifyContent: "space-between",
        }}
        onClick={() => {
          let formaOrdenacao = opvTipo === "asc" ? "desc" : "asc";
          setOpvTipo(formaOrdenacao);
          filtrar("atendente", formaOrdenacao);
        }}
      >
        Atendente/Contatos
        <FontAwesomeIcon className="iconeOrdem" color="#fff" icon={opvTipo == "asc" ? faSortUp : 'sort-down'} />
      </th>
      <th
        style={{
          cursor: "pointer",
          justifyContent: "space-between",
          width: "15%",
          maxWidth: "15%",
        }}
        onClick={() => {
          let formaOrdenacao = opvTipo === "asc" ? "desc" : "asc";
          setOpvTipo(formaOrdenacao);
          filtrar("temperatura", formaOrdenacao);
        }}
      >
        Temperatura
        <FontAwesomeIcon className="iconeOrdem" color="#fff" icon={opvTipo == "asc" ? faSortUp : 'sort-down'} />
      </th>
      <th
        style={{
          cursor: "pointer",
          justifyContent: "space-between",
          width: "15%",
          maxWidth: "15%",
        }}
        onClick={() => {
          let formaOrdenacao = opvTipo === "asc" ? "desc" : "asc";
          setOpvTipo(formaOrdenacao);
          filtrar("atualizacao", formaOrdenacao);
        }}
      >
        Ultima atualização
        <FontAwesomeIcon className="iconeOrdem" color="#fff" icon={opvTipo == "asc" ? faSortUp : 'sort-down'} />
      </th>
      <th className="text-center" style={{ width: "3%" }}>
        Opções
      </th>
    </tr>
  );

  useEffect(() => {
    setShowLabelFunil(guService.getLocalState('exibir_nome_etapas_funil') === "1");
    globalOportunidadeContext.setChecked(guService.getLocalState('exibir_desistencia_expiracao_funil') === "1");
    buscaOportunidades(currentEtapaId || globalOportunidadeContext?.colunas[0]?.id_status_oportunidade);
  }, [page, tamanhoPagina, globalOportunidadeContext.filtroFunil]);

  const graficoEtapas = useMemo(() =>
  <HighchartsReact highcharts={Highcharts} 
  options={opcoesGrafico(globalOportunidadeContext.categorias, 
  makeSeriesConfig, globalOportunidadeContext.data, 
  makeSeriesDrilldown, globalOportunidadeContext.dataSubCategorias, showLabelFunil)} />
  ,[globalOportunidadeContext.data, showLabelFunil])

  return (
    <div id="pagfunil">
      <Row>
        <Col md={12} lg={4}>
          <DommusToggle toggle={true} title={"Funil de Vendas"}>
            {graficoEtapas}
            {
              possuiExpiracaoDesistencia &&
              <div className="checkbox-funil" style={{marginTop: "20px"}}>
                <DommusSwitch
                  label="Exibir etapas expiração e desistência"
                  checked={globalOportunidadeContext.checked} 
                  clickEvent={(e: any) => {
                    let value = !globalOportunidadeContext.checked;
                    globalOportunidadeContext.setChecked(value)
                    alterarVisualizacaoFunilPerfil('exibir_desistencia_expiracao_funil', (value ? '1': '0'));
                  }} 
                  tooltip={globalOportunidadeContext.checked ? "Inativar" : "Ativar"}
                  />              
              </div>
            }
            <div className="checkbox-funil">
              <DommusSwitch
                label="Exibir nomes das etapas"
                checked={showLabelFunil} 
                clickEvent={(e: any) => {
                  let value = !showLabelFunil;
                  setShowLabelFunil(value);
                  alterarVisualizacaoFunilPerfil('exibir_nomes_etapas_funil', (value ? '1': '0'));
                }} 
                tooltip={showLabelFunil ? "Inativar" : "Ativar"}
              />              
            </div>
          </DommusToggle>
        </Col>
        <Col md={12} lg={8} >
          <DommusToggle toggle={true} title={globalOportunidadeContext.titulo + "  (" + total +")"}>
            <div className="tabelaFunil">
              <DommusTabelaPaginadaBack
                data={undefined}
                setLimit={setTamanhoPagina}
                limit={tamanhoPagina}
                page={page}
                total={total}
                linhasCorpo={linhas}
                linhasCabecalho={thead}
                setPage={setPage}
              />
            </div>
          </DommusToggle>
        </Col>
      </Row>
      <ModalDommus
        open={openModal}
        size={"md"}
        close={() => setOpenModal(false)}
        children={
          <EditarInteresse
            setRefresh={undefined}
            refresh={undefined}
            close={undefined}
            atualiza={undefined}
            listaInteresses={(Array.isArray(interesses) && interesses) || []}
            selecionado={interesseSelecionado}
            oportunidade={oportunidadeSelecionada}
          />
        }
        titulo="Editar interesse"
      />

        <ModalDommus
          open={openModalEmpreendimento}
          size={"lg"}
          close={() => setOpenModalEmpreendimento(false)}
          children={
            <DistributionForm id_oportunidade={oportunidadeSelecionada.id_oportunidade} permissao_editar={oportunidadeSelecionada.usuario_pode_editar} closeModal={()=>{setOpenModalEmpreendimento(false)}}/>
          }
          titulo="Editar Empreendimento"
        />

      {openModalNovoAtendimento &&
        <ModalDommus
          open={openModalNovoAtendimento}
          size={"md"}
          close={() => setOpenModalNovoAtendimento(false)}
          children={
            <NovoAtendimento
              close={() => setOpenModalNovoAtendimento(false)}
              oportunidade={novoAtendimento?.id_oportunidade}
              atendimento={""}
              inserirLista={() => setPage(1)}
              index={""}
              atualizaLista={""}
              setTabIndex={undefined}          />
          }

            titulo="Nova Atividade"
            nomeCliente={novoAtendimento.lead_nome}
            opv={novoAtendimento.id_oportunidade}
        />

      }
      <ModalDommus
              open={openStatus}
              close={setOpenStatus.bind(false)}
              size={'md'}
              children={
                <Status
                      id_oportunidade={statusState.id_oportunidade}
                      statusAtual={statusState.status_id_oportunidade}
                      id_substatus_oportunidade={statusState.id_substatus != null ?
                          statusState.id_substatus :
                          statusState.id_substatus}
                      reload={false}
                      manipuladorMudancaStatus={() => {} }
                      closeModal={() => setOpenStatus(false)} 
                      setTabIndex={undefined}                />
              }
              titulo="Alterar Etapa e Status"
            />
      <TagOportunidadeFormulario
        openModal={openModalTagsOportunidade} 
        setOpenModal={setOpenModalTagsOportunidade}                
        oportunidade={oportunidadeSelecionada}
        // atualizarOportunidade={atualizarOportunidade}
      />
      <ModalFullScreenOportunidade
          open={openModalOportunidade}
          close={() => setOpenModalOportunidade(false)}                
          titulo={`OPV - ${oportunidadeSelecionada.id_oportunidade}`}
      >
          {openModalOportunidade && 
              <DetalheOportunidade id={oportunidadeSelecionada.id_oportunidade}/>
          }
      </ModalFullScreenOportunidade>
    </div>
  );
}
