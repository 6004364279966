import React, { createContext, ReactElement, useEffect, useState } from "react";
import { TiposPendenciasType } from "./types";
import oportunidadeService from "../../services/OportunidadeService";

export const TiposPendenciasContext = createContext({});

export function TiposPendenciasProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [tiposPendencias, setTiposPendencias] = useState<Array<TiposPendenciasType>>([]);
  const [mapaTiposPendencias, setMapaTiposPendencias] = useState<Map<number, TiposPendenciasType>>(new Map());

  const recarregarTiposPendencias = ()=>{
    oportunidadeService.buscarTiposPendencia().then(
        (response) => {
            setTiposPendencias(Object.values(response.data));
        },
        (error) => {            
        }
    )
  }

  useEffect(()=>{
    const novoMapa = new Map();
    tiposPendencias.forEach((pendencia:TiposPendenciasType) => {
      novoMapa.set(Number(pendencia.id_oportunidade_pendencia_tipo), pendencia);
    });
    setMapaTiposPendencias(novoMapa);
  }, [tiposPendencias]);

  function resolverNomePendencia(idPendencia:number, padrao = "Não Atribuído"){
    let pendencia = mapaTiposPendencias.get(Number(idPendencia));
    return (pendencia && pendencia.nome) || padrao;
  };

  return (
    <TiposPendenciasContext.Provider value={{
        tiposPendencias,
        recarregarTiposPendencias,
        resolverNomePendencia,
    }}>
      {children}
    </TiposPendenciasContext.Provider>
  );
}


