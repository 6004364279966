import crm from './crm';

const AutomacaoService = {
	buscarAutomacoes: () => {
        return crm.get('/automacao');
    },
	buscarAutomacoesTipos: () => {
        return crm.get('/automacao/tipo');
    },
	buscarAutomacoesDataBaseTipos: () => {
        return crm.get('/automacao/data-base/tipos');
    },
	buscarAutomacoesCondicionanteTipos: () => {
        return crm.get('/automacao/condicionante/tipos');
    },
	editAutomacao: (dados, id) => {
        let uri = '/automacao/' + id;
        return crm['put'].call(crm, uri, dados);
    },
    storeAutomacaoFunilEtapa: (idEtapaFunil, automacoes) => {
        let uri = `/automacao/etapa-funil/${idEtapaFunil}`;
        return crm['post'].call(crm, uri, automacoes);
    },
    editAutomacaoFunilEtapa: (idAutomacao, idEtapaFunil, data) => {
        let uri = `/automacao/${idAutomacao}/etapa-funil/${idEtapaFunil}`;
        return crm['put'].call(crm, uri, data);
    },
    reordenarAutomacoes: (idEtapaFunil, ordem) => {
        let uri = `/automacao/etapa-funil/${idEtapaFunil}/reordenar`;
        return crm['put'].call(crm, uri, {ordem});
    },
	storeAutomacao: (dados) => {
        let uri = '/automacao';
        return crm['post'].call(crm, uri, dados);
    },
    buscarTiposRegra: () => {
        let uri = '/automacao-tipo-regra';
        return crm['get'].call(crm, uri);
    },
    deleteAutomacaoFunil: (idAutomacao, idEtapaFunil) => {
        let uri = `/automacao/${idAutomacao}/etapa-funil/${idEtapaFunil}`;
        return crm['delete'].call(crm, uri);
    },
    deleteAutomacao: (id) =>{
        let uri = '/automacao/' + id;
        return crm['delete'].call(crm, uri);
    },
    deleteRegra: (id) =>{
        let uri = '/automacao/regra/' + id;
        return crm['delete'].call(crm, uri);
    },
};

export default AutomacaoService;
