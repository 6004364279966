import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {limpaMascaraMoeda} from "../InputTextMask";


export default function InputMoeda({ maskOptions, value, onChangeHandle, ...inputProps }) {

    const defaultMaskOptions = {
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 10, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    }

    if(value == undefined) {
        value = '';
    }

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })
    return <>
        <MaskedInput
            className="form-control"
            mask={currencyMask}
            value={String(value).replace('.', ',')}
            onChange={e => onChangeHandle(limpaMascaraMoeda(e.target.value))}
            {...inputProps}
        />
    </>

}
