import React from "react";
import IntlCurrencyInput from "react-intl-currency-input"

export function DommusIntlCurrencyInput({ name="", required=false, register=(name, required) => {}, currency, config, defaultValue="0", change, disabled=false }) {
    return (
        <IntlCurrencyInput     

            name={name}
            currency={currency}
            config={config} 
            className="form-control"
            defaultValue={defaultValue}
            onChange={change}
            disabled={disabled}
            {...register(name, {required})}
        />
    )
}