import React, { createContext, ReactElement, useState } from "react";

import { trackPromise } from "react-promise-tracker";
import { swalErro } from "../../helpers/DommusSwal";
import { ContatoNotificacaoType } from "./types";
import ContatoNotificacaoService from "../../services/ContatoNotificacaoService";

interface ContatoNotificacaoContextProps {
  listaContatosNotificacoes: ContatoNotificacaoType[];
  recarregarContatosNotificacoes: () => void;
  adicionarContatoNotificacao: (data:ContatoNotificacaoType) => void;
  atualizarContatoNotificacao: (data:ContatoNotificacaoType) => void;
  removerContatoNotificacao: (idContatoNotificacao:number) => void;
}

export const ContatoNotificacaoContext = createContext(
  {} as ContatoNotificacaoContextProps
);

export function ContatoNotificacaoProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [listaContatosNotificacoes, setListaContatosNotificacoes] = useState<
    ContatoNotificacaoType[]
  >([]);

  const recarregarContatosNotificacoes = () => {
    trackPromise(ContatoNotificacaoService.buscar())
      .then((response: any) => {
        setListaContatosNotificacoes(response.data);
      })
      .catch((error) => {
        swalErro(
          `Houve um erro ao carregar as Automações: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`
        );
      });
  };

  const adicionarContatoNotificacao = (data: ContatoNotificacaoType) => {
    setListaContatosNotificacoes((current) => {
      return [data, ...current];
    });
  };

  const atualizarContatoNotificacao = (data: ContatoNotificacaoType) => {
    setListaContatosNotificacoes((current) => {
      return current.map((ContatoNotificacao) => {
        if (ContatoNotificacao.id_contato_notificacao_erro === data.id_contato_notificacao_erro) {
          return data;
        }
        return ContatoNotificacao;
      });
    });
  };

  const removerContatoNotificacao = (idContatoNotificacao: number) => {
    setListaContatosNotificacoes((current) => {
      return current.filter(
        (ContatoNotificacao) =>
          ContatoNotificacao.id_contato_notificacao_erro !== idContatoNotificacao
      );
    });
  };

  return (
    <ContatoNotificacaoContext.Provider
      value={{
        listaContatosNotificacoes,
        recarregarContatosNotificacoes,
        adicionarContatoNotificacao,
        atualizarContatoNotificacao,
        removerContatoNotificacao,
      }}
    >
      {children}
    </ContatoNotificacaoContext.Provider>
  );
}
