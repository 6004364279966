import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import DommusTabelaPaginada from "../../../../components/DommusTabelaPaginada";
import {trackPromise} from "react-promise-tracker";
import OfertaAtivaService from "../../../../services/OfertaAtivaService";
import Swal from "sweetalert2";
import LinhaUsuarioPermissao from "./LinhaUsuarioPermissao";
import './style.css';

export default function Permissions({oferta, ofertaAlteracao, closeModal}) {

    const [formEquipeVendas, setFormEquipeVendas] = useState('');
    const [formGerente, setFormGerente] = useState('');
    const [usuariosAlterarPermissao, setUsuariosAlterarPermissao] = useState([]);

    const [marcarTodos, setMarcarTodos] = useState(false);

    const [listaEquipesVendas, setListaEquipesVendas] = useState([]);
    const [listaGerentes, setListaGerentes] = useState([]);
    const [listaCorretores, setListaCorretores] = useState([]);

    useEffect(() => {
        if (oferta) {
            trackPromise(
                OfertaAtivaService.permissao.buscaEquipes(oferta.id_oferta_ativa).then(res => {
                    setListaEquipesVendas(res.data);
                }).catch(e => {
                    Swal.fire({
                        titleText: "Erro!",
                        text: "Ocorreu um erro ao buscar as equipes de venda: " + e,
                        icon: "error"
                    });
                }))
        }
    }, [oferta]);

    useEffect(() => {
        if (formEquipeVendas) {
            const gerentes = listaEquipesVendas.find(equipe => equipe.id_equipe === formEquipeVendas).gerentes;
            setListaGerentes(gerentes);
        }
        setFormGerente('');
    }, [formEquipeVendas]);

    useEffect(() => {
        if (formGerente && formEquipeVendas) {
            const gerentes = listaEquipesVendas.find(equipe => equipe.id_equipe == formEquipeVendas).gerentes;
            const gerente = gerentes.find(gerente => gerente.id_usuario == formGerente);
            if (!gerente) {
                setFormGerente('');
                setListaCorretores([]);
                return;
            }
            setListaCorretores(gerente.corretores);
            return;
        }
        setListaCorretores([]);
        setMarcarTodos(false);
        setUsuariosAlterarPermissao([]);
    }, [formGerente, formEquipeVendas])

    const handleSetUsuarioPermissao = useCallback((id_usuario, permissao) => {
        const usuarios = usuariosAlterarPermissao;
        const indexUsuario = usuarios.findIndex(usuario => usuario.id_usuario == id_usuario);
        if (indexUsuario >= 0) {
            usuarios[indexUsuario] = {id_usuario, permissao};
        } else {
            usuarios.push({id_usuario, permissao});
        }

        setUsuariosAlterarPermissao(usuarios);
    }, [usuariosAlterarPermissao]);

    const handlePersistirPermissoes = useCallback(() => {
            const formData = {
                usuarios: usuariosAlterarPermissao,
                id_oferta_ativa: oferta.id_oferta_ativa
            }
            trackPromise(
                OfertaAtivaService.permissao.store(formData).then(res => {
                    Swal.fire({
                        titleText: "Sucesso!",
                        text: "Permissões alteradas com sucesso!",
                        icon: "success"
                    }).then(() => {
                        closeModal();
                    })
                }).catch(error => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                        icon: "error"
                    })
                })
            )
        },
        [usuariosAlterarPermissao],
    );


    const handleMarcarTodos = useCallback(() => {
        const permissaoTodos = {"acessar": true, "agendar": true, "resgatar": true, "descartar": true};
        const permissaoNenhum = {"acessar": false, "agendar": false, "resgatar": false, "descartar": false};

        setListaCorretores(items => {
            return items.map(corretor => {
                    let auxiliarPermissao = ( marcarTodos && permissaoTodos ) || permissaoNenhum;

                    corretor.permissoes = auxiliarPermissao;
                    handleSetUsuarioPermissao(corretor.id_usuario, auxiliarPermissao);

                    return corretor;
            })
        });
        setMarcarTodos(!marcarTodos);
        setUsuariosAlterarPermissao(listaCorretores);
    }, [listaCorretores, marcarTodos])


    const linhas = useMemo(() => {
        return listaCorretores.map((usuario, index) => {
            return <LinhaUsuarioPermissao
                usuario={usuario}
                permissoes={usuario.permissoes}
                setUsuarioPermissao={handleSetUsuarioPermissao}
            />
        });
    }, [listaCorretores])


    const thead = (
        <tr>
            <th>Corretores <a onClick={handleMarcarTodos} className="btn btn-primary btn-sm ml-3"
                              style={{cursor: "pointer"}}>Marcar Todos</a></th>
            <th className="text-center" style={{width: '30%'}}>Permissões</th>
        </tr>
    );

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Equipe de Vendas:</Form.Label>
                    <Form.Control as="select" custom value={formEquipeVendas}
                                  onChange={(event) => setFormEquipeVendas(event.target.value)}>
                        <option value="">Selecione a equipe</option>
                        {listaEquipesVendas.length > 0 && listaEquipesVendas.map(equipe => (
                            <option value={equipe.id_equipe} key={equipe.id_equipe}>{equipe.nome_equipe}</option>))}
                    </Form.Control>
                </Form.Group>
            </Row>

            {formEquipeVendas &&
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Gerente da Equipe:</Form.Label>
                    <Form.Control as="select" custom value={formGerente}
                                  onChange={(event) => setFormGerente(event.target.value)}>
                        <option value="">Selecione o gerente</option>
                        {listaGerentes.map(usuario => (
                            <option value={usuario.id_usuario} key={usuario.id_equipe}>{usuario.nome}</option>))}
                    </Form.Control>
                </Form.Group>
            </Row>
            }

            {formGerente &&
            <>
                <Row>
                    <Col>
                        <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <button onClick={handlePersistirPermissoes} className="btn btn-success">Salvar</button>
                    </Col>
                </Row>
            </>
            }
        </>
    );
}
