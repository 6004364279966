import React, {useCallback, useEffect, useState} from 'react';
import * as PropTypes from "prop-types";
import Form from 'react-bootstrap/Form'

class Checkbox extends React.Component {
    render() {
        return null;
    }
}

Checkbox.propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.string,
    className: PropTypes.any,
    children: PropTypes.node
};
export default function LinhaUsuarioPermissao({usuario, permissoes, setUsuarioPermissao}) {

    const [acessar, setAcessar] = useState(permissoes.acessar);
    const [resgatar, setResgatar] = useState(permissoes.resgatar);
    const [descartar, setDescartar] = useState(permissoes.descartar);
    const [agendar, setAgendar] = useState(permissoes.agendar);
    const [formData, setFormData] = useState([]);

    const handleChangePermission = useCallback((tipo) => {
        const permissaoLocal = {acessar, resgatar, descartar, agendar}
        permissaoLocal[tipo] = !permissaoLocal[tipo];
        setUsuarioPermissao(usuario.id_usuario, permissaoLocal)
    }, [
        acessar, resgatar, descartar, agendar
    ]);

    useEffect(() => {
        setAcessar(permissoes.acessar)
        setResgatar(permissoes.resgatar)
        setDescartar(permissoes.descartar)
        setAgendar(permissoes.agendar)
    }, [permissoes])

    return (
        <tr key={usuario.id_oferta_ativa}>
            <td>{usuario.nome}</td>

            <td className="d-flex justify-content-center permission-block">
                <Form.Group controlId={`acessar-label-${usuario.id_usuario}`} className="checkbox-permissao">
                    <Form.Check type="checkbox" label="Acessar" onClick={() => {
                        setAcessar(!acessar);
                        handleChangePermission('acessar')
                    }} checked={acessar}/>
                </Form.Group>

                <Form.Group controlId={`resgatar-label-${usuario.id_usuario}`} className="checkbox-permissao">
                    <Form.Check type="checkbox" label="Resgatar" onClick={() => {
                        setResgatar(!resgatar);
                        handleChangePermission('resgatar')
                    }} checked={resgatar}/>
                </Form.Group>

                <Form.Group controlId={`descartar-label-${usuario.id_usuario}`} className="checkbox-permissao">
                    <Form.Check type="checkbox" label="Descartar" onClick={() => {
                        setDescartar(!descartar);
                        handleChangePermission('descartar')
                    }} checked={descartar}/>
                </Form.Group>

                <Form.Group controlId={`agendar-label-${usuario.id_usuario}`} className="checkbox-permissao">
                    <Form.Check type="checkbox" label="Agendar" onClick={() => {
                        setAgendar(!agendar);
                        handleChangePermission('agendar')
                    }} checked={agendar}/>
                </Form.Group>



            </td>
        </tr>
    )
}
