import React, {useCallback, useState} from 'react';

import './style.css';
import ReactTooltip from "react-tooltip";

export default function DommusCheckboxToggle({label="", value, setValue, tooltip, classe='', labelDesativado=''}) {
    const [checked, setChecked] = useState(value);

    const handleToggleChecked = useCallback((newStatus) => {
        setChecked(newStatus)
        setValue(newStatus)
    }, []);

    return (
        <>
            <ReactTooltip/>
            <label className={`${classe} form-switch`}>
                <span>{labelDesativado}</span>
                <input type="checkbox" checked={checked}/>
                <i onClick={() => handleToggleChecked(!checked)} data-tip={tooltip}></i>
                {label}
            </label>
        </>
    )
}
