import React, { useContext, useRef ,useEffect} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './index.css';
import DommusToggle from "../../DommusToggle";
import moment from 'moment';
import stringHelper from "../../../helpers/StringHelper";
import { EmpreendimentoContext } from '../../GlobalContext/globalContext';
import HistoricoVoip from '../HistoricoVoip';
export default function ModalHistorico({ dados, tipo,idAtendimentoProspecta, idAcaoHistorico, conteudos = [],isConecta,carregarMaisConteudosConecta}) {

    const {resolverNomeOperador, resolverNomeGerente, resolverNomeEquipe} = useContext(EmpreendimentoContext);

    const listaMensagensConteudoRef = useRef(null);

    moment.locale('pt-br');
    let dadosParseados = "";
    if(dados != undefined && dados != null && dados != ""  && stringHelper.isJson(dados)){
        dadosParseados = JSON.parse(dados);
    }else if(dados != undefined && dados != null && dados != ""){
        dadosParseados = dados;
    }else{
        dadosParseados = 'Dados indisponíveis';
    }

    useEffect(() => {
        const handleScroll = () => {
           
            if(isConecta){
                if(listaMensagensConteudoRef.current.scrollTop == 0){
                    carregarMaisConteudosConecta();
                }
            }
          
        };
    
        const listaMensagensElement = listaMensagensConteudoRef.current;

        if(listaMensagensElement){
            listaMensagensElement.addEventListener('scroll', handleScroll);
        }
       
    
        // Cleanup do evento ao desmontar o componente
        return () => {
            if(listaMensagensElement){
                listaMensagensElement.removeEventListener('scroll', handleScroll);
            }
           
        };
    }, []);



    return <Form className="formCadastro">
        <DommusToggle title={"Ação: " + tipo} toggle={true}>
          {
          (  Number(idAcaoHistorico) === 7 && <HistoricoVoip conteudos={conteudos} /> ) ||
              (  stringHelper.isJson(dados)  && dados != undefined && dados != null && dados != "" ?
                    [1, 2, 24, 28, 31].includes(idAcaoHistorico) ?
                    <>
                        <Row>
                            <div className="listaMensagens">
                                {dadosParseados?.descricao === null ?
                                <div><strong>Lead distribuido para:</strong></div>
                                : <strong><div>{dadosParseados?.descricao}</div></strong>
                                }
                                <br/>
                                <div><strong>Equipe: </strong>{resolverNomeEquipe(dadosParseados.id_equipe)}</div>
                                <div><strong>Gerente: </strong>{resolverNomeGerente(dadosParseados.id_gerente)}</div>
                                <div><strong>Operador: </strong>{resolverNomeOperador(dadosParseados.id_atendente)}</div>
                            </div>
                        </Row>
                    </>
                    :
                    <Row>
                        <div className="listaMensagens">
                            {(dadosParseados != null && dadosParseados instanceof Array) ? dadosParseados.map((itemMensagem, index) =>
                                <div key={index}>
                                    {
                                        itemMensagem.tipo == 'received' ?
                                            <>
                                                <div className="linhaMensagemReceived">
                                                    <div className="mensagemConteudoReceived">
                                                        {itemMensagem.conteudo}
                                                        <span>{moment(itemMensagem.data).format('DD/MM/YYYY hh:mm:ss')}</span>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className="linhaMensagemSent">
                                                    <div className="mensagemConteudoSent">
                                                        {itemMensagem.conteudo}
                                                        <span>{moment(itemMensagem.data).format('DD/MM/YYYY hh:mm:ss')}</span>
                                                    </div>
                                                </div>

                                            </>
                                    }
                                </div>) : <>Dados Indisponíveis</>}
                        </div>
                    </Row>
                    :
                    <>


                            {conteudos.length == 0 &&

                            <Row>
                            <Form.Group as={Col} controlId="formNome">
                                <Form.Control type="textarea" rows="15" value={dadosParseados} as="textarea" disabled />
                            </Form.Group>
                            </Row>

                            }


                            {conteudos.length > 0 &&

                                <Row>

                                     <div className='listaMensagens' ref={listaMensagensConteudoRef}>

                                            {conteudos.map((conteudo,index) => (
                                                <div key={index}>
                                                    {conteudo.direcao == 'recebido' &&
                                                        <div className="linhaMensagemReceived">
                                                        <div className="mensagemConteudoReceived">
                                                        {conteudo.conteudo}
                                                        <span>{moment(conteudo.criado_em).format('DD/MM/YYYY HH:mm:ss')}</span>
                                                    </div>
                                                    </div>

                                                    }

                                                    {conteudo.direcao == 'enviado' &&
                                                     <div className="linhaMensagemSent">
                                                     <div  className="mensagemConteudoSent">
                                                         <div dangerouslySetInnerHTML={{__html:conteudo.conteudo}}/>
                                                         <span>{moment(conteudo.criado_em).format('DD/MM/YYYY HH:mm:ss')}</span>
                                                    </div>
                                                    </div>

                                                    }
                                                </div>
                                            ))}

                                     </div>
                                </Row>

                            }
                    </>

            )}
        </DommusToggle>
    </Form>

}
