import crm from './crm';

const MidiaService = {
	editMidia: (dados, id) => {
        let uri = '/midia/' + id;
        return crm['put'].call(crm, uri, {midia: dados});
    },
    deleteMidia: (id)=>{
        let uri = '/midia/' + id;
        return crm['delete'].call(crm, uri);
    },
	storeMidia: (dados) => {
        let uri = '/midia';
        return crm['post'].call(crm, uri, {midia: dados});
    },
	buscarMidias: (filtros=null) => {
        return crm.get('/midia', { params: { filtros } });
    },
    buscarTodasMidias: () => {
        return crm.get('/midia/todos');
    },
	iconesMidia: () => {
		return crm.get('/midia/icones');
    },
    salvarInvestimentoMidias: (midiasCustos) => {
        return crm.post('/midia/investimento', midiasCustos)
    },
    buscarMidiasComCustos: (filtros=null, limite, pagina) => {
        return crm.get('/midia/investimento', { params: { filtros, limite, page: pagina } })
    }
};

export default MidiaService;
