import React,{ createContext, useReducer} from 'react';
import { ContextAHPType } from './types/ContextAHPType/index';
import { initialStateType } from './types/initialStateType';
import { filtroReducer,filtroInitialState } from '../../hooks/reducers/filtroReducer';
import { actionReducerType } from '../../hooks/reducers/types/actionReducerType';
import { ContextType } from './types/ContextType';
import { tipoHistoricoReducer, tipoInitialStateType } from '../../hooks/reducers/tipoHistoricoReducer';

const initialState: initialStateType = {
    filtro: filtroInitialState,
    tiposHistorico: tipoInitialStateType,
}

export const Context = createContext<ContextType>({
    state: initialState,
    dispatch: () => null
});


const mainReducer = (state:initialStateType,action:actionReducerType) => {
    return({
        filtro: filtroReducer(state.filtro,action),
        tiposHistorico: tipoHistoricoReducer(state.tiposHistorico,action),

    })
}


export const ContextAcompanhamentoHistoricoProvider= ({children}: ContextAHPType) => {
   const [state,dispatch] = useReducer(mainReducer,initialState);
   return(
        <Context.Provider value={{state,dispatch}}>
            {children}
        </Context.Provider>
   )
}
