import React, { useEffect, useRef, useState } from "react";
import guService from "./services/GuService";
import axios from 'axios';


function MicroFrontend({ name, host, type}) {
  const [conectaToken, setConectaToken] = useState(null);
  const widgetsRef = useRef(null)
  useEffect(() => {
    if(guService.getLocalState('authToken')){
      axios.get(`https://api.atendimento.dommus.com.br/gu-api/login/${guService.getLocalState('authToken')}`).then((res) => {
        setConectaToken(res.data.access_token);
      });
    }
  }, [guService.getLocalState('authToken')]);
  useEffect(() => {
    if(conectaToken && name && host && widgetsRef.current){
      const scriptId = `micro-frontend-script-${name}`;
      const linkId = `micro-frontend-styles-${name}`;
      const vendorScriptId = `micro-frontend-vendor-script-${name}`;
      const vendorLinkId = `micro-frontend-vendor-styles-${name}`;
      const widgetsWrapper = widgetsRef.current.parentNode;
      const existingScript = widgetsWrapper.getElementById(scriptId);
      const existingLink = widgetsWrapper.getElementById(linkId);
      const existingVendorScript = widgetsWrapper.getElementById(vendorScriptId);
      const existingVendorLink = widgetsWrapper.getElementById(vendorLinkId);
      const children = [];
      if (!existingVendorScript) {
        const vendorScript = document.createElement('script');
        vendorScript.src =`${host}/static/js/vendors.js`;
        vendorScript.id = vendorScriptId;
        children.push(vendorScript);
      }
      if (!existingVendorLink) {
        const vendorLink = document.createElement('link');
        vendorLink.href =`${host}/static/css/vendors.css`;
        vendorLink.rel = "stylesheet";
        vendorLink.id = vendorLinkId;
        children.push(vendorLink);
      }
      if (!existingScript) {
        const script = document.createElement('script');
        script.src =`${host}/static/js/main.js`;
        script.id = scriptId;
        children.push(script);
      }
      if (!existingLink) {
        const link = document.createElement('link');
        link.href =`${host}/static/css/styles.css`;
        link.rel = "stylesheet";
        link.id = linkId;
        children.push(link);
      }

      if(children.length){
        const documentFragment = document.createDocumentFragment();
        children.forEach(c => documentFragment.appendChild(c));
        widgetsWrapper.appendChild(documentFragment);
      }

    }

  }, [conectaToken, name, host, widgetsRef]);



  return <>
        {conectaToken &&  <div
            id="dommus-widgets"
            data-token={conectaToken}
            data-widgettype={type}
            data-tenancy={guService.getLocalState('idInquilino')}
            data-user={guService.getLocalState('idUsuario')}
            ref={widgetsRef}
          ></div>}
        </>

}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
