import React from "react";
import { Row, Col } from "react-bootstrap";
import Pagination from "react-bootstrap/cjs/Pagination";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

export default function DommusTabelaPaginadaBack({
  data,
  limit,
  setLimit,
  page,
  setPage,
  linhasCorpo,
  total,
  linhasCabecalho,
  isBordered = true,
}) {
  const inicioArray = (page - 1) * limit;
  const tamanhoArray =
    inicioArray + limit < total ? inicioArray + limit : total;
  const paginaFinal = Math.ceil(total / limit);
  const itensPaginacao = [];
  const page_config = {
    limite: 15,
    min_paginas: 3,
  };
  let ultimaPaginaFoiOculta = false;
  for (let numeroPagina = 1; numeroPagina <= paginaFinal; numeroPagina++) {
    const paginaItem = (
      <Pagination.Item
        key={numeroPagina}
        active={numeroPagina === page}
        onClick={() => {
          setPage(numeroPagina);
        }}
      >
        {numeroPagina}
      </Pagination.Item>
    );

    let numeroPaginaOculta;

    if (
      paginaFinal <= page_config.limite ||
      (page - 2 <= numeroPagina && page + 2 >= numeroPagina) ||
      page === numeroPagina ||
      numeroPagina <= page_config.min_paginas ||
      numeroPagina > paginaFinal - page_config.min_paginas
    ) {
      itensPaginacao.push(paginaItem);
      ultimaPaginaFoiOculta = false;
    } else {
      if (!ultimaPaginaFoiOculta) {
        if (page < numeroPagina) {
          numeroPaginaOculta = page + page_config.min_paginas + 2;
        } else {
          numeroPaginaOculta = page - page_config.min_paginas - 2;
        }

        if (numeroPaginaOculta > paginaFinal || numeroPaginaOculta < 1) {
          numeroPaginaOculta = page;
        }

        let paginaOculta = (
          <Pagination.Item
            key={numeroPagina}
            active={false}
            onClick={() => {
              setPage(numeroPaginaOculta);
            }}
          >
            ...
          </Pagination.Item>
        );
        itensPaginacao.push(paginaOculta);
        ultimaPaginaFoiOculta = true;
      }
    }
  }

  const opcoesTabela = Object.assign({
    responsive: false,
    bordered: isBordered,
    hover: true,
  });

  return (
    <>
      <Row>
        <Col>
          <div className={"card card-table "}>
            <Table className={"tabela-dommus"} {...opcoesTabela}>
              <thead className={"thead-dark"}>{linhasCabecalho}</thead>
              <tbody className="campanhasMobile">{linhasCorpo}</tbody>
            </Table>
          </div>
          <div className="d-sm-flex justify-content-between">
            <Form.Group controlId="paginationPageSize">
              <select
                  className="form-control"
                  tabIndex={0}
                  value={limit}
                  onChange={(e) => {
                    setLimit(Number(e.target.value));
                    setPage(1)
                  }}
              >
                  <option value={8}>8</option>
                  <option value={16}>16</option>
                  <option value={24}>24</option>
                  <option value={32}>32</option>
                  <option value={40}>40</option>
              </select>
              <Form.Text className="text-muted">Itens por página.</Form.Text>
            </Form.Group>
            <Pagination>
              <Pagination.First
                onClick={() => {
                  setPage(1);
                }}
              />
              <Pagination.Prev
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1);
                  }
                }}
              />
              {itensPaginacao}
              <Pagination.Next
                onClick={() => {
                  if (page !== paginaFinal) {
                    setPage(page + 1);
                  }
                }}
              />
              <Pagination.Last
                onClick={() => {
                  setPage(paginaFinal);
                }}
              />
            </Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}
