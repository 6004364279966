import axios from 'axios';
import guService from "./GuService";

const crm = axios.create({
    baseURL: process.env.REACT_APP_CRM_API,
    headers: {
        'Authorization': guService.getLocalState('token'),
        'Content-Type': 'application/json' 
    }
});

export default crm;
