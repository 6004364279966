import React from 'react';

export default function ItemBarraTitulo({children, classe="", ...props}) {
    let customClass = 'item-barra';
    customClass += classe ? ' ' + classe : '';
    return (
        <div className={customClass} {...props}>
            {children}
        </div>
    );
}
