import crm from './crm';

const GrupoQualificacaoService = {
	buscar: (page, limit) => {
        let uri = '';
        if(page && limit){
            uri = `/grupo-campos-dinamicos/paginado?limit=${limit}&page=${page}`;
        }else{
            uri = '/grupo-campos-dinamicos';
        }        
        return crm.get(uri);
    },	
    store: (dados) => {
        let uri = `/grupo-campos-dinamicos`;
        return crm['post'].call(crm, uri, dados);
    },    
	update: (id, dados) => {
        let uri = '/grupo-campos-dinamicos/' + id;
        return crm['put'].call(crm, uri, dados);
    },
    delete: (id) =>{
        let uri = '/grupo-campos-dinamicos/' + id;
        return crm['delete'].call(crm, uri);
    },
};

export default GrupoQualificacaoService;
