import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import DommusToggle from "../../../../components/DommusToggle";
import { EmpreendimentoContext, OportunidadeContext } from "../../../../components/GlobalContext/globalContext";
import empreendimentoService from "../../../../services/EmpreendimentoService";
import equipeService from "../../../../services/EquipeService";
import guService from "../../../../services/GuService";
import oportunidadeService from "../../../../services/OportunidadeService";
import perfilUsuarioService from "../../../../services/PerfilUsuarioService";
import { OportunidadeType } from "../types";
import { DistributionEquipeAtendimento } from "./DistributionEquipeAtendimento";
import "./index.css";
import { SelectEmpreendimento } from "./SelectEmpreendimento";
import { DetalhesOportunidadeContext } from "../../../../contexts/DetalhesOportunidadeContext/DetalhesOportunidadeContext";
import { swalErro } from "../../../../helpers/DommusSwal";
import { EquipeType } from "../../../OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types";

interface DistributionFormProps {
    id_oportunidade: number|OportunidadeType
    permissao_editar?: boolean
    closeModal?: () => void
}

export type UserGuardType = {
    id: number 
    nome: string
}

export type EmpreendimentoType = {
    atrativos: string | null
    atualizado_em: string
    bairro: string | null
    cidade: string | null
    descricao: string | null
    exibir_portal_corretor: string
    id: string
    modulos: string
    nome: string
    previsao_entrega: string | null
    uf: string | null
    workflow_ativo: string
    vinculo_prospecta_vende: string
    ativo: string
}

export type PerfilUsuarioType = {
    id_usuario: number
    ativo: string
    exibicao: string | undefined
    ordenacao_kanban: string
    notificacoes: string | null
    recebe_email: number
    horario_atendimento: string
    nome_usuario: string | null
    perfil: PerfilType | null | undefined
    id_perfil: number | undefined
    tipo: string | undefined
    recepcao_leads: string | undefined
    recebe_chat: string | undefined
    recebe_voip: string | undefined
    alteracao_em_massa: string | undefined
    visualiza_equipes_inativas: string | undefined
    visualiza_todas_equipes: string | undefined
    permissoes: string | null | undefined
    online: number | undefined
    visto_ultimo: string | null | undefined
    ids_notificacao_onesignal: string | null | undefined
    agenda_visualizacao: string | undefined
    agenda_tipos_habilitados: string | undefined
    agenda_tipos_escolhidos: string | undefined
    intervalo_calendario: number | undefined
    changelog: number | undefined
    criado_em: string | null | undefined
    atualizado_em: string | null | undefined
    locais: string | null | undefined
    ocultar_visitas_realizadas: number | undefined
}

export type PerfilType = {
    id_perfil: number
    id_usuario_tipo: number
    descricao: string | null
    opcoes: string | null
    permissoes: string | null | undefined
    criado_em: string | null | undefined
    atualizado_em: string | null | undefined
    status: string | undefined
    perfil_dommus: string | undefined
    oculto: string | undefined
    ativo: number | undefined
}

export function DistributionForm({ id_oportunidade, permissao_editar=true, closeModal }: DistributionFormProps) { 
    const [empreendimentos, setEmpreendimentos] = useState([] as Array<EmpreendimentoType>)
    const [selectedEmpreendimento, setSelectedEmpreendimento] = useState(0)
    const [selectedEquipePreAtendimento, setSelectedEquipePreAtendimento] = useState(1)
    const [selectedGerentePreAtendimento, setSelectedGerentePreAtendimento] = useState(0)
    const [selectedAtendente, setSelectedAtendente] = useState(0)
    const [selectedEquipePdv, setSelectedEquipePdv] = useState(1)
    const [selectedGerentePdv, setSelectedGerentePdv] = useState(0)
    const [selectedCorretor, setSelectedCorretor] = useState(0)
    const [guardGerentePreAtendimento, setGuardGerentePreAtendimento] = useState({} as UserGuardType)
    const [guardAtendente, setGuardAtendente] = useState({} as UserGuardType)
    const [guardGerentePdv, setGuardGerentePdv] = useState({} as UserGuardType)
    const [guardCorretor, setGuardCorretor] = useState({} as UserGuardType)
    const [refreshEmpreendimento, setRefreshEmpreendimento] = useState(false as boolean)
    const [equipesEmpreendimento, setEquipesEmpreendimento] = useState<EquipeType[]>([]);
    const typeUser = Number(guService.getLocalState("tipoUsuario"));
    const global = useContext(EmpreendimentoContext);  
    const { dadosPublicosUsuarios, setDadosPublicosUsuarios, idOportunidadeSelecionada, setIdOportunidadeSelecionada } = useContext(DetalhesOportunidadeContext)
    const { oportunidadeSelecionada, setOportunidadeSelecionada } = useContext(OportunidadeContext)

    let equipePDV = 1; 
    let atendentePDV = {} as UserGuardType;
    let corretorPDV = {} as UserGuardType;

    useEffect(() => {
        searchEmpreendimentos()
        findOportunidade()
    }, [])

    useEffect(()=>{
        if(selectedEmpreendimento){
            buscarEquipesEmpreendimento(selectedEmpreendimento);            
        }
    },[selectedEmpreendimento])

    function buscarEquipesEmpreendimento(empreendimento:number) {
        trackPromise(
            equipeService.buscaMembrosEquipes(empreendimento)
                .then((response) => {
                if (response.data !== "") {
                    setEquipesEmpreendimento(response.data);
                }
            })
        );
    }

    function searchEmpreendimentos() {
        global.empreendimentos?.length ? setEmpreendimentos(global.empreendimentos) : empreendimentoService.buscarEmpreendimentos()
            .then(res => {
                setEmpreendimentos(res.data)
                global.setEmpreendimentos(res.data)
            })
            .catch(error => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                    icon: 'error'
                });
            })
    }

    function findOportunidade() {
        if(typeof id_oportunidade == 'object') {
            handleSetDataOpv(id_oportunidade)
            setOportunidadeSelecionada(id_oportunidade)
            
            return
        }
        if((oportunidadeSelecionada != null) && oportunidadeSelecionada.id_oportunidade == id_oportunidade) {
            handleSetDataOpv(oportunidadeSelecionada)
            return
        }
        oportunidadeService.buscarOportunidade(id_oportunidade)
            .then(res => {
                handleSetDataOpv(res.data)
                setOportunidadeSelecionada(res.data)
            })
            .catch(error => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar a oportunidade: " + error,
                    icon: 'error'
                });
            })
    }

    function searchEquipes(id_empreendimento: number) {
        if(id_empreendimento == global.idEmpreendimentoSelecionado) {
            return
        }
        equipeService.buscaMembrosEquipes(id_empreendimento)
            .then(res =>{
                global.setIdEmpreendimentoSelecionado(id_empreendimento)
                global.setEquipesEmpreendimentoUsuario(res.data)
            })
            .catch(error => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar as equipes: " + error,
                    icon: 'error'
                });
            })
    }

    function searchUsers(oportunidade: OportunidadeType) {
        if(idOportunidadeSelecionada == oportunidade.id_oportunidade) {
            handleSetUsers(dadosPublicosUsuarios, oportunidade)

            return
        }
        let ids_usuarios = [oportunidade.id_gerente_pdv, oportunidade.id_usuario_atendimento, oportunidade.id_gerente_pre_atendimento, oportunidade.id_usuario_pre_atendimento]
        ids_usuarios = ids_usuarios.filter(id_usuario => id_usuario)
        perfilUsuarioService.buscarPerfisUsuarios(ids_usuarios as any)
            .then(res => {                
                setIdOportunidadeSelecionada(oportunidade.id_oportunidade)
                setDadosPublicosUsuarios(res.data)
                handleSetUsers(res.data, oportunidade)
            })
            .catch(error => {
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao buscar as equipes: " + error,
                    icon: 'error'
                });
            })
    }

    function handleSetUsers(users: Array<PerfilUsuarioType>, oportunidade: OportunidadeType) {
        const gerentePreAtendimento = users.find(user => user.id_usuario == oportunidade.id_gerente_pre_atendimento)
        const atendente = users.find(user => user.id_usuario == oportunidade.id_usuario_pre_atendimento)
        const gerentePdv = users.find(user => user.id_usuario == oportunidade.id_gerente_pdv)
        const corretor = users.find(user => user.id_usuario == oportunidade.id_usuario_atendimento)

        if(gerentePreAtendimento) {
            setGuardGerentePreAtendimento({
                id: gerentePreAtendimento.id_usuario,
                nome: gerentePreAtendimento.nome_usuario
            } as UserGuardType)
        }
        if(atendente) {
            setGuardAtendente({
                id: atendente.id_usuario,
                nome: atendente.nome_usuario
            } as UserGuardType)
        }
        if(gerentePdv) {
            setGuardGerentePdv({
                id: gerentePdv.id_usuario,
                nome: gerentePdv.nome_usuario
            } as UserGuardType)

            atendentePDV = {
                id: gerentePdv.id_usuario,
                nome: gerentePdv.nome_usuario
            } as UserGuardType
        }
        if(corretor) {
            setGuardCorretor({
                id: corretor.id_usuario,
                nome: corretor.nome_usuario
            } as UserGuardType)
            
            corretorPDV = {
                id: corretor.id_usuario,
                nome: corretor.nome_usuario
            } as UserGuardType
        }
    }

    function handleSetDataOpv(oportunidade: OportunidadeType) {
        setSelectedEmpreendimento(oportunidade.id_empreendimento ? oportunidade.id_empreendimento : 0)
        setSelectedGerentePreAtendimento(oportunidade.id_gerente_pre_atendimento ? oportunidade.id_gerente_pre_atendimento : 0)
        setSelectedAtendente(oportunidade.id_usuario_pre_atendimento ? oportunidade.id_usuario_pre_atendimento : 0)
        setSelectedGerentePdv(oportunidade.id_gerente_pdv ? oportunidade.id_gerente_pdv : 0)
        setSelectedCorretor(oportunidade.id_usuario_atendimento ? oportunidade.id_usuario_atendimento : 0)
        searchEquipes(oportunidade.id_empreendimento ? oportunidade.id_empreendimento : 0)
        searchUsers(oportunidade)
        setSelectedEquipePreAtendimento(oportunidade.id_equipe_pre_atendimento ? oportunidade.id_equipe_pre_atendimento : 0)
        setSelectedEquipePdv(oportunidade.id_equipe_pdv ? oportunidade.id_equipe_pdv : 0)
        equipePDV = oportunidade.id_equipe_pdv ? oportunidade.id_equipe_pdv : 0;
    }

    function handleSetEmpreendimento(id_empreendimento: number) {
        setSelectedEmpreendimento(id_empreendimento)
        setSelectedEquipePreAtendimento(0)
        setSelectedGerentePreAtendimento(0)
        setSelectedAtendente(0)
        setSelectedEquipePdv(0)
        setSelectedGerentePdv(0)
        setSelectedCorretor(0)
        setGuardAtendente({nome: "", id: 0})
        setGuardCorretor({nome: "", id: 0})
        setGuardGerentePdv({nome: "", id: 0})
        setGuardGerentePreAtendimento({nome: "", id: 0})
        searchEquipes(id_empreendimento)
        setRefreshEmpreendimento(!refreshEmpreendimento)
        equipePDV = 0;
        atendentePDV = {nome: "", id: 0};
        corretorPDV = {nome: "", id: 0};
    }

    function checkObject(value: {}) 
    {
        return Object.keys(value).length === 0
            && value.constructor === Object;
    }

    async function swalEvoluirOportunidade() {

        let verificarEquipeAtendimento = ( selectedEquipePdv && selectedGerentePdv && selectedCorretor )
        let oportunidade = id_oportunidade as OportunidadeType;
        if ( equipePDV !== 0 && checkObject(atendentePDV) && checkObject(corretorPDV) && verificarEquipeAtendimento && oportunidade?.status?.tipo  !== "P" )
        {
            return await Swal.fire({
                title: 'Você deseja Evoluir a Oportunidade para a primeira Etapa do Funil do Corretor?',
                icon: 'question',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sim ',
                cancelButtonText: 'Não ',
                customClass: {
                    confirmButton: 'btn btn-primary mr-3',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            }).then((result) => {
                return result.isConfirmed ? true : false
            })
        }
        return false;
    }

    function exibirAlertaSucesso() {
        Swal.fire({
            titleText: "Sucesso",
            text: "Dados de Distribuição alterados",
            icon: "success",
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: false,
        }).then(() => {
            global.setLeadCriado(true)
            if(closeModal){
                closeModal();
            }
            // window.location.reload();
        })
    }

    function saveOpv() {
        let enviarFunildoCorretor = {enviar: false};

        if (!selectedAtendente && !selectedCorretor) {
            Swal.fire({
                titleText: "Atenção!",
                text: "Você deve selecionar um usuário de atendente ou corretor para distribuir a oportunidade",
                icon: "warning",
            });

            return;
        }
        if (!selectedEmpreendimento || 
            ((typeUser == 7 || typeUser == 8) && !selectedGerentePdv) ||
            ((typeUser == 7 || typeUser == 8) && !selectedEquipePdv) ||
            (typeUser == 8 && !selectedCorretor) ||
            ((typeUser == 25 || typeUser == 26) && !selectedGerentePreAtendimento) ||
            ((typeUser == 25 || typeUser == 26) && !selectedEquipePreAtendimento) ||
            (typeUser == 26 && !selectedAtendente)
        ) {
            Swal.fire({
                titleText: "Atenção",
                text: "Preencha todos os campos de distribuição",
                icon: "warning",
            });

            return;
        }

        const data = {
            oportunidade: {
                id_empreendimento: selectedEmpreendimento === 0 ? null : selectedEmpreendimento,
                id_equipe_pre_atendimento: selectedEquipePreAtendimento === 0 ? null : selectedEquipePreAtendimento,
                id_gerente_pre_atendimento: selectedGerentePreAtendimento === 0 ? null : selectedGerentePreAtendimento,
                id_usuario_pre_atendimento: selectedAtendente === 0 ? null : selectedAtendente,
                id_gerente_pdv: selectedGerentePdv === 0 ? null : selectedGerentePdv,
                id_equipe_pdv: selectedEquipePdv === 0 ? null : selectedEquipePdv,
                id_usuario_atendimento: selectedCorretor === 0 ? null : selectedCorretor,
                id_oportunidade: typeof id_oportunidade == 'object' ? id_oportunidade.id_oportunidade : id_oportunidade,
            },
        };

        swalEvoluirOportunidade().then(evoluir => {
            enviarFunildoCorretor.enviar = evoluir;

            trackPromise(oportunidadeService.salvarOportunidade(data, enviarFunildoCorretor).then((response: any) => {
                    setOportunidadeSelecionada(response.data.oportunidade);
                    const atendentesAtendimentosAtribuidos = response.data.atendentesAtendimentosAtribuidos
                    if(atendentesAtendimentosAtribuidos?.contemAtendimentos && (!atendentesAtendimentosAtribuidos?.corretor || !atendentesAtendimentosAtribuidos?.atendente)) {
                        Swal.fire({
                            titleText: 'Atualizar Atendimentos do Dommus Conecta?',
                            text: 'Existem atendimentos atrelados a esta oportunidade. Deseja alterar o responsável destes atendimentos?',
                            icon: 'question',
                            showCloseButton: false,
                            showCancelButton: true,
                            showConfirmButton: !atendentesAtendimentosAtribuidos?.corretor,
                            showDenyButton: !atendentesAtendimentosAtribuidos?.atendente,
                            confirmButtonText: 'Sim, para o corretor.',
                            denyButtonText: 'Sim, para o atendente.',
                            cancelButtonText: 'Não, obrigado',
                            customClass: {
                                confirmButton: 'btn dommus-button',
                                denyButton: 'btn dommus-button-2',
                                cancelButton: 'btn btn-danger'
                            }
                        }).then((result) => {
                            let idAtendente = null
                            if(result.isConfirmed) {
                                idAtendente = data.oportunidade.id_usuario_atendimento
                            } else if(result.isDenied) {
                                idAtendente = data.oportunidade.id_usuario_pre_atendimento
                            }
                            if(idAtendente) {
                                atualizarAtendentesOportunidade(data.oportunidade.id_oportunidade, idAtendente)
                            }
                            exibirAlertaSucesso()
                        })
                    } else {
                        exibirAlertaSucesso()
                    }
                },
                (error: any) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar a distribuição: " + error,
                        icon: "error",
                    });
                }
            ));
        });
    }

    function atualizarAtendentesOportunidade(idOportunidade: number, idAtendente: number) {
        oportunidadeService.atualizarAtendenteOportunidade(idOportunidade, idAtendente)
            .catch((error) => {
                console.log(error)
                console.log(error.response)
                swalErro('Houve um erro ao tentar atualizar os atendentes dos atendimentos atrelados a esta oportunidade.')
            })
    }

    return (
        <div className="distribuicaoInterna-cadastro">
          <DommusToggle altura='box-altura' title="Conversão do Lead" toggle={true}>
            <SelectEmpreendimento setEmpreendimento={handleSetEmpreendimento} selectedEmpreendimento={selectedEmpreendimento} empreendimentos={empreendimentos} permissaoEditar={permissao_editar}/>
            <DistributionEquipeAtendimento 
                equipes={equipesEmpreendimento.filter(equipe => equipe.tipo_equipe == "A")} 
                typeUser={typeUser} 
                selectedEquipe={selectedEquipePreAtendimento}
                setSelectedEquipe={setSelectedEquipePreAtendimento}
                selectedGerente={selectedGerentePreAtendimento}
                setSelectedGerente={setSelectedGerentePreAtendimento}
                selectedAtendente={selectedAtendente}
                setSelectedAtendente={setSelectedAtendente}
                guardGerente={guardGerentePreAtendimento}
                guardAtendente={guardAtendente}
                setGuardAtendente={setGuardAtendente}
                setGuardGerente={setGuardGerentePreAtendimento}
                typeEquipe="A"
                refreshEmpreendimento={refreshEmpreendimento}
                permissaoEditar={permissao_editar}
            />
            <DistributionEquipeAtendimento 
                equipes={equipesEmpreendimento.filter(equipe => equipe.tipo_equipe == "P")} 
                typeUser={typeUser} 
                selectedEquipe={selectedEquipePdv}
                setSelectedEquipe={setSelectedEquipePdv}
                selectedGerente={selectedGerentePdv}
                setSelectedGerente={setSelectedGerentePdv}
                selectedAtendente={selectedCorretor}
                setSelectedAtendente={setSelectedCorretor}
                guardGerente={guardGerentePdv}
                guardAtendente={guardCorretor}
                setGuardAtendente={setGuardCorretor}
                setGuardGerente={setGuardGerentePdv}
                typeEquipe="P"
                refreshEmpreendimento={refreshEmpreendimento}
                permissaoEditar={permissao_editar}
            />
            <Row className="formFooter mt-2 cadastro">
                <Col>
                    <button type="button" onClick={() => saveOpv()} className="btn btn-success" disabled={!permissao_editar}>
                    <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }}/>
                        Salvar Conversão
                    </button>
                </Col>
            </Row>
          </DommusToggle>
        </div>
    )
}