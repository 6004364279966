import { actionReducerType } from "../types/actionReducerType";
import { tipoHistoricoReducerType } from "./types";


export const tipoInitialStateType:tipoHistoricoReducerType = {
    tipos: 
    [
        {
           
          
            id_tipo_historico_lead: 0,
		    descricao:'',
		    ativo: 0,
		    id_status_oportunidade: null
        }
    ]
}


export const tipoHistoricoReducer = (state:tipoHistoricoReducerType,action: actionReducerType) => 
{
    switch(action.type){

        case 'FILL_TIPOS':
            state.tipos = action.payload.tipos;
            return state;
        break;
    }

    return state
}