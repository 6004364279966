import React, { createContext, ReactElement, useState } from "react";
import { PerfilUsuarioType } from "../../pages/DetalheOportunidade/Cadastro/DistributionForm";
import { HistoricoLeadType } from "../../pages/DetalheOportunidade/Cadastro/types";
import { ReferenciaHistoricoType } from "./types";

interface DetalhesOportunidadeProps {
  dadosPublicosUsuarios: PerfilUsuarioType[]
  setDadosPublicosUsuarios: React.Dispatch<React.SetStateAction<PerfilUsuarioType[]>>
  idOportunidadeSelecionada: number
  setIdOportunidadeSelecionada: React.Dispatch<React.SetStateAction<number>>
  permissaoEditarLead: boolean|null
  setPermissaoEditarLead: React.Dispatch<React.SetStateAction<boolean|null>>
  historicos: HistoricoLeadType[]
  setHistoricos: React.Dispatch<React.SetStateAction<HistoricoLeadType[]>>
  referenciaHistorico: ReferenciaHistoricoType|null
  setReferenciaHistorico: React.Dispatch<React.SetStateAction<ReferenciaHistoricoType|null>>
}

export const DetalhesOportunidadeContext = createContext({} as DetalhesOportunidadeProps);

export function DetalhesOportunidadeProvider({ children}: { children: ReactElement }) {
  const [dadosPublicosUsuarios, setDadosPublicosUsuarios] = useState<PerfilUsuarioType[]>([])
  const [idOportunidadeSelecionada, setIdOportunidadeSelecionada] = useState(0)
  const [permissaoEditarLead, setPermissaoEditarLead] = useState<boolean|null>(null)
  const [historicos, setHistoricos] = useState<HistoricoLeadType[]>([])
  const [referenciaHistorico, setReferenciaHistorico] = useState<ReferenciaHistoricoType|null>(null)

  return (
    <DetalhesOportunidadeContext.Provider value={{ 
        dadosPublicosUsuarios, setDadosPublicosUsuarios, 
        idOportunidadeSelecionada, setIdOportunidadeSelecionada,
        permissaoEditarLead, setPermissaoEditarLead,
        historicos, setHistoricos,
        referenciaHistorico, setReferenciaHistorico
      }}>
      {children}
    </DetalhesOportunidadeContext.Provider>
  );
}